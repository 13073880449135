import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAreaControlData, IAreaControlPathPoint } from 'src/app/api/models/dto/area-control.dto';

export interface IAreaControlState {
  isLoading: boolean;
  isDrawingMode: boolean;
  isEditMode: boolean;
  areaPolygons: IAreaControlData[];
  selectedPolygon: IAreaControlData;
  selectedColor: string;
  predefinedPolygons: IAreaControlData[];
  editablePoints: IAreaControlPathPoint[];
}

export function createInitialState(): IAreaControlState {
  return {
    isLoading: false,
    isDrawingMode: false,
    isEditMode: false,
    areaPolygons: [],
    selectedPolygon: null,
    selectedColor: null,
    predefinedPolygons: [],
    editablePoints: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'area-control' })
export class AreaControlStore extends Store<IAreaControlState> {

  public constructor() {
    super(createInitialState());
  }
}
