import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { ImportHttpService } from 'src/app/api/import-http.service';

const NAV_PATH = '/dashboard/fuel-cards';
const PAGE_TITLE = 'page-title.fuel-card';

interface ImportError {
  row: number;
  errorMessage: string;
}

@Component({
  selector: 'app-fuel-card-page',
  templateUrl: './fuel-card-page.component.html',
  styleUrls: ['./fuel-card-page.component.scss']
})
export class FuelCardPageComponent implements OnInit, OnDestroy {
  public isLoading = false;

  public file: any;
  private subs = new Subscription();

  public importErrors: ImportError[];

  public invalidFormat = false;

  public constructor(
    private navigationService: NavigationService,
    private importService: ImportHttpService,
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.FuelCard,
      pageTitle: PAGE_TITLE,
      panelWidth: 400
    });
    this.navigationService.showContentPanel(NAV_PATH);
  }

  addFile() {
    this.isLoading = true;
    this.importService.uploadFile(this.file).subscribe((response: ImportError[]) => {
      this.invalidFormat = false;
      if (response.length > 0) {
        if (response && response[0].row === -1) {
          this.invalidFormat = true;
        }
      }
      this.importErrors = response;
      this.isLoading = false;
      this.file = null;
    }, (error) => {
      this.isLoading = false;
      this.file = null;

    });
  }

  onFileAdded(name: string) {
    this.isLoading = false;
  }

  upload(files) {
    if (files.length === 0) {
      return;
    }
    for (const file of files) {
      this.file = file;
    }
  }

  public ngOnDestroy(): void { }
}
