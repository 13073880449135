import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { Subscription } from 'rxjs';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { distinctUntilChanged, filter, map, switchMapTo, take, tap, flatMap } from 'rxjs/operators';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { CarRemindersService } from '../../../store/car-reminders/state/car-reminders.service';
import { CarRemindersQuery } from '../../../store/car-reminders/state/car-reminders.query';
import { Router } from '@angular/router';
import { CarsQuery } from '../../../store/cars/state/cars.query';
import { DOCUMENT } from '@angular/common';
import { CarsService } from '../../../store/cars/state/cars.service';
import { IReminderDTO } from 'src/app/api/models/dto/reminders.dto';
import { ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { ICarConciseDTO } from 'src/app/api/models/dto/car-concise.dto';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { NavigationQuery } from 'src/app/store/navigation/state/navigation.query';

const NAV_PATH = '/dashboard/car-reminders';
const BACK_PATH = '/dashboard/cars';
const PAGE_TITLE = 'page-title.car-reminders';
const PAGE_TITLE_ADD = 'page-title.car-reminders-add';
const PAGE_TITLE_EDIT = 'page-title.car-reminders-edit';

export enum RemindersEnum {
  'REMINDER_NONE' = 0,
  'REMINDER_OIL' = 1,
  'REMINDER_STK' = 2
}
@Component({
  selector: 'app-car-reminders-page',
  templateUrl: './car-reminders-page.component.html',
  styleUrls: ['./car-reminders-page.component.scss']
})
export class CarRemindersPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  public reminders: IReminderDTO[];
  public selectedCar: ICarConciseDTO;
  public lastTachoValue: number;
  public title = PAGE_TITLE;

  public constructor(
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private routerQuery: RouterQuery,
    private carRemindersState: CarRemindersService,
    private router: Router,
    public readonly carRemindersQuery: CarRemindersQuery,
    public readonly carsQuery: CarsQuery,
    private carsHttp: CarsHttpService,
    @Inject(DOCUMENT) document,
    private carState: CarsService
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.CarReminders,
      pageTitle: PAGE_TITLE
    });

    this.subs.add(this.carRemindersQuery.activeEditReminder$.subscribe(result => {
      if (result) {
        if (result?.name === '') {
          this.navigationService.setPageTitle(PAGE_TITLE_ADD);
        } else {
          this.navigationService.setPageTitle(PAGE_TITLE_EDIT);
        }
        this.navigationService.setBackUrl(`${ NAV_PATH }/${ result.carId }`);
        this.navigationService.enableBackAction(true);
      }
    }));

    this.subs.add(this.carRemindersQuery.isNewReminder$.subscribe(result => {
      if (!result) {
        this.navigationService.setPageTitle(PAGE_TITLE);
        this.navigationService.clearBackUrl();
        this.navigationService.enableBackAction(false);
      }
    }));

    this.subs.add(this.navigationQuery.backButtonClicked$.subscribe((data) => {
      if (!!data) {
        this.hideRemindersList(true);
        this.carRemindersState.setIsStartEditing(false);
        this.navigationService.enableBackAction(false);
        this.navigationService.setPageTitle(PAGE_TITLE);
        this.navigationService.clearBackUrl();
      }
    }));

    this.navigationService.showContentPanel(NAV_PATH);

    this.carState.fetchGroupsWithCars(true);

    const urlParams$ = this.routerQuery.selectParams().pipe(
      distinctUntilChanged()
    );

    const noCarID$ = urlParams$.pipe(
      take(1),
      filter((params) => !params.car_id),
      switchMapTo(this.carRemindersQuery.carID$.pipe(
        take(1)
      )),
      filter((carID) => !!carID),
      tap((carID) => this.router.navigateByUrl(`${ NAV_PATH }/${ carID }`))
    );

    const hasCarID$ = urlParams$.pipe(
      filter((params) => !!params.car_id),
      map((params) => parseInt(params.car_id as any, 10)),
      distinctUntilChanged(),
      tap((carID) => this.carRemindersState.selectCar(carID))
    );

    this.subs.add(
      hasCarID$.pipe(
        flatMap(carID => this.carsHttp.fetchCarLastTachoValue(carID))).subscribe(value => {
          this.lastTachoValue = value?.tachoValue;
        }
        )
    );

    this.subs.add(
      noCarID$.subscribe()
    );

    this.subs.add(
      hasCarID$.pipe(
        flatMap(id => this.carsHttp.fetchCarConcise(id))).subscribe(car => {
          this.selectedCar = car;
        })
    );
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.subs.unsubscribe();
    this.carRemindersState.clearStore();
  }

  public async changeCar(carID: number | null): Promise<void> {
    if (!!carID) {
      await this.router.navigateByUrl(`${ NAV_PATH }/${ carID }`);
    }
  }

  public getCars(cars: ICarDTO[], carid: number): string {
    if (cars.length > 0) {
      return cars.find(x => x.carID === carid).spz;
    }
  }

  public async isEddited(eddited: boolean): Promise<void> {
    this.hideRemindersList(eddited);
  }

  public hideRemindersList(eddited: boolean): void {
    const x = document.getElementById('reminderList');
    if (x.classList.contains('hide')) {
      x.classList.remove('hide');
    } else {
      x.classList.add('hide');
    }
  }
}
