export enum DriveType {
  Private = 1,
  Business = 0,
  All = -1
}

export interface IDriveBookTotalsDTO {
  totalDistance: number;
  totalDuration: number;
  totalNumberOfTrips: number;
  privateDistance: number;
  privateDuration: number;
  privateNumberOfTrips: number;
  businessDistance: number;
  businessDuration: number;
  businessNumberOfTrips: number;
  speedLimitExceeded: boolean;
}

export interface IGroupedDriveBooksDTO extends IDriveBookTotalsDTO {
  driveBooks: IDriveBookDTO[];
}

export interface IDriveBookDTO {
  driveBookId: number;
  driverId: number;
  carId: number;
  purpose: string;
  traceDistance: number;
  driveType: DriveType;
  fuelRefillLitres: number;
  fuelRefillPrice: number;
  receipt: any | null;
  tachoStart: number;
  tachoStop: number;
  dateTimeStart: string;
  dateTimeStop: string;
  gpsPosStartX: number;
  gpsPosStartY: number;
  gpsPosStopX: number;
  gpsPosStopY: number;
  startDescription: string | null;
  stopDescription: string | null;
  maxSpeed: number;
  speeding: number;
  note: string | null;
  deleted: boolean;
  newTacho: number;
  closed: boolean;
  newDistance: number;
  totalTime: number;
  isLocked: boolean;
  speedLimitExceeded: boolean;
}

export interface IDriveBookDayDTO {
  day: string;
  driveBooks: IGroupedDriveBooksDTO;
  selectAllIsChecked?: boolean;
}

export interface IDriveBooksDTO extends IDriveBookTotalsDTO {
  driveBooks: IDriveBookDayDTO[];
}

export interface IDriveBookPathDataDTO {
  date: any;
  speed: number;
  latitude: number;
  longitude: number;

}

export interface IDriveBookPathWrapperDTO {
  drivebookId: number;
  paths: IDriveBookPathDataDTO[];
}
