import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { ICarGroupDTO, ICarPositionExtra } from 'src/app/api/models/dto/car-groups.dto';
import { ICarsCurrentPosition, ICarCurrentPosition } from 'src/app/api/models/cars-current-position.model';
import { CarsPositionStore, ICarsPositionsState, createInitialState } from './cars-position.store';
import { CarsQuery } from '../cars/state/cars.query';
import { MapService } from '../map/state/map.service';
import { CarsPositionQuery } from './cars-position.query';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CarsHttpService } from 'src/app/api/cars-http.service';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class CarsPositionService {

  public constructor(
    public carsPositionStore: CarsPositionStore,
    private carsQuery: CarsQuery,
    private carsPositionQuery: CarsPositionQuery,
    private mapService: MapService,
    private utilities: UtilitiesService,
    public carsHttpService: CarsHttpService
  ) { }

  public selectCar(carID: number): void {
    this.carsPositionStore.update((state) => {
      const index = state.selectedCarsIDs.indexOf(carID);
      let nextIDs: number[] = [];

      if (index >= 0) {
        nextIDs = [...state.selectedCarsIDs];
        nextIDs.splice(index, 1);
      } else {
        nextIDs = [...state.selectedCarsIDs, carID];
      }

      return {
        selectedCarsIDs: nextIDs
      };
    });

    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  public selectGroup(carGroup: ICarGroupDTO): void {
    this.carsPositionStore.update((state) => {
      const selectGroup = state.selectedCarsIDs.indexOf(carGroup.cars[0].carID) >= 0;
      let nextIDs: number[] = [];

      if (selectGroup) {
        nextIDs = [...state.selectedCarsIDs];
        carGroup.cars.forEach((car) => {
          const index = nextIDs.indexOf(car.carID);
          if (index >= 0) {
            nextIDs.splice(index, 1);
          }
        });
      } else {
        nextIDs = [...state.selectedCarsIDs];
        const ids = carGroup.cars.map((c) => c.carID);
        ids.forEach((id) => {
          const index = nextIDs.indexOf(id);
          if (index < 0) {
            nextIDs.push(id);
          }
        });
      }
      return {
        selectedCarsIDs: nextIDs
      };
    });

    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  public clearSelectedCarIDs(): void {
    this.carsPositionStore.update({ selectedCarsIDs: [] });
  }

  public setCarsCurrentPosition(newCarPositions: ICarsCurrentPosition): void {
    this.carsPositionStore.update((state) => {
      let carPositions = [...state.carPositions];
      for (const newLastPosition of newCarPositions.lastPositions) {
        const prevPosition = carPositions.find(x => x.carId === newLastPosition.carId);

        carPositions = this.assignPoints(carPositions, prevPosition, newLastPosition);

      }
      return {
        carPositions,
      };
    });
    this.carsPositionQuery.selectedCarsPoints$.pipe(
      take(1)
    ).subscribe((points) => this.mapService.setPoints(points));
  }

  private assignPoints(
    carPositions: ICarPositionExtra[],
    prevLastPosition: ICarPositionExtra,
    newLastPosition: ICarCurrentPosition,
  ): ICarPositionExtra[] {
    const newPosition = {
      carId: newLastPosition.carId,
      latitude: newLastPosition.latitude,
      longitude: newLastPosition.longitude,
      lastKnownDate: newLastPosition.lastKnownDate,
      isKeyOn: newLastPosition.isKeyOn,
      driverName: newLastPosition.driverName,
      direction: newLastPosition.direction,
      speed: newLastPosition.speed
    } as ICarPositionExtra;

    const cars = [...carPositions.filter(c => c.carId !== newLastPosition.carId)];

//    const speed = !!prevLastPosition ? this.utilities.calculateSpeed(prevLastPosition, newPosition) : 0;
    const isOnline = newPosition.isKeyOn;
     //PRX tady se da vypocitat smer

    cars.push(
      {
        carId: newPosition.carId,
        latitude: newPosition.latitude,
        longitude: newPosition.longitude,
        speed: newPosition.speed,
        isOnline,
        isKeyOn: newPosition.isKeyOn,
        previousPositions: {
          latitude: !!prevLastPosition ? prevLastPosition.latitude : null,
          longitude: !!prevLastPosition ? prevLastPosition.longitude : null
        },
        lastKnownDate: newPosition.lastKnownDate,
        lastKnownPositionAdress: newPosition.lastKnownPositionAdress,
        driverName: newPosition.driverName,
        direction: newPosition.direction
      }
    );

    return cars;
  }

  public clearIdExceptOne(objectId: number): void {
    this.carsPositionStore.update((state) => ({
      ...state,
      selectedCarsIDs: state.selectedCarsIDs.filter(p => p === objectId)
    }));
  }

  public clearStore(): void {
    this.carsPositionStore.update(createInitialState());
  }

  public getNewPositionsFromServer(lastQueryTime: number): Observable<number> {

    return new Observable<number>(observer => {
  
        this.carsHttpService.fetchCarsPositionsByUserID(lastQueryTime).subscribe(newCarPositions=>{

          if(newCarPositions){
            this.setCarsCurrentPosition(newCarPositions);
            // Emit the result as a number value
            observer.next(newCarPositions.lastTickTime);
          }
          else{
            observer.next(lastQueryTime);
          }
      
          // Complete the observable
          observer.complete();
   
        },
        ()=>{
          observer.next(lastQueryTime);
          observer.complete();
        }
        );
    });

  } 

}
