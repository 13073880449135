<mat-sidenav-container class="sidenav-sidebar" autosize fullscreen>
  <mat-sidenav #sidenav mode="side" [(opened)]="openedSidebar" [fixedInViewport]="true">
    <mat-action-list class="content">
      <div class="top-content">
        <div mat-list-item class="logo">
          <a [href]="contactCompanyUrl" target="_blank" [matTooltip]="tooltipText"
            matTooltipPosition="right">
            <img [src]="smallLogoPath">
          </a>
        </div>

        <app-sidebar-item icon="directions_car" [label]="'cars-list' | translate" path="/dashboard/cars"
          [title]="'vehicles'" [activeItem]="activeMenuItem">
        </app-sidebar-item>

        <app-sidebar-item icon="menu_book" [label]="'drive-book' | translate" path="/dashboard/drive-book"
          [title]="'drive-book'" [activeItem]="activeMenuItem">
        </app-sidebar-item>

        <span *ngxPermissionsOnly="['reminder-list']">
          <app-sidebar-item icon="warning" [label]="'reminders' | translate" path="/dashboard/car-reminders"
            [title]="'car-reminders'" [activeItem]="activeMenuItem">
          </app-sidebar-item>
        </span>

        <span *ngxPermissionsOnly="['export']">
          <app-sidebar-item icon="assignment" [label]="'export' | translate" path="/dashboard/export" [title]="'export'"
            [activeItem]="activeMenuItem">
          </app-sidebar-item>
        </span>

        <span *ngxPermissionsOnly="['stats']">
          <app-sidebar-item
            icon="bar_chart"
            [label]="'stats' | translate"
            path="/dashboard/stats"
            [title]="'stats'"
            [activeItem]="activeMenuItem">
          </app-sidebar-item>
        </span>

        <span *ngxPermissionsOnly="['areas']">
          <app-sidebar-item icon="my_location" [label]="'area-control' | translate" path="/dashboard/area-control" [title]="'area-control'"
            [activeItem]="activeMenuItem">
          </app-sidebar-item>
        </span>

        <span *ngxPermissionsOnly="['import']">
          <app-sidebar-item icon="credit_card" [label]="'fuel-cards' | translate" path="/dashboard/fuel-cards" [title]="'fuel-card'"
            [activeItem]="activeMenuItem">
          </app-sidebar-item>
        </span>

        <app-sidebar-item icon="help" [label]="'help' | translate" path="/dashboard/help" [title]="'help'"
          [activeItem]="activeMenuItem">
        </app-sidebar-item>
      </div>

      <div class="bottom-content" mat-list-item>
        <button mat-list-item [matMenuTriggerFor]="languageMenu" [matTooltip]="'select-language' | translate"
          matTooltipPosition="right">
          <mat-icon class="transparent">language</mat-icon>
          <div class="label mat-caption">{{ ('language-' + language.getLanguage()) | translate }}</div>
        </button>
        <button mat-list-item [matTooltip]="'logout' | translate" matTooltipPosition="right" (click)="logout()">
          <mat-icon class="transparent">exit_to_app</mat-icon>
          <div class="label mat-caption">{{ 'logout' | translate }}</div>
        </button>
      </div>
    </mat-action-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-content></app-content>
    <app-user-credentials></app-user-credentials>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #languageMenu="matMenu" yPosition="above" xPosition="after">
  <button mat-menu-item (click)="setLanguage('cs')">{{ 'language-cs' | translate }}</button>
  <button mat-menu-item (click)="setLanguage('sk')">{{ 'language-sk' | translate }}</button>
  <button mat-menu-item (click)="setLanguage('en')">{{ 'language-en' | translate }}</button>
  <button *ngIf="showTranslationKeys" mat-menu-item (click)="setLanguage('de')">{{ 'translation-keys' | translate }}</button>
</mat-menu>
