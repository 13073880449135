<form [formGroup]="form">
  <div class="vehicle-container">
    <div class="vehicle-heading">
      {{ 'drive-book.vehicle' | translate }}
    </div>
    <mat-form-field [floatLabel]="'never'" [ngClass]="{'is-filled ': !!form.get('car').value  && !!allCars && allCars.length}">
      <mat-select formControlName="car" [placeholder]="'drive-book.select-vehicle' | translate" disableOptionCentering>
        <span  *ngxPermissionsOnly="['car-list', 'admin-car-list']">
        <mat-optgroup *ngFor="let group of allCars" [label]="group?.group?.name + ' (' + group?.cars?.length + ')'">
          <mat-option *ngFor="let car of group.cars | sortVehicles : '' " [value]="car?.carID">
            <span><b>{{ car.spz }}</b> {{ car.toolTipText }}</span>
          </mat-option>
        </mat-optgroup>
      </span>
      </mat-select>
    </mat-form-field>
  </div>
</form>
