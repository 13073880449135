import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ICarConciseDTO } from 'src/app/api/models/dto/car-concise.dto';

export enum DriveTypeString {
  All = 'all',
  Business = 'business',
  Private = 'private'
}
export enum DriveTypeOverwrite {
  'no-change' = -1,
  'all-public' = 0,
  'all-private' = 1
}
export enum SumType {
  'total-sum' = 0,
  'daily-sum' = 1
}
export enum DriveType {
  'private' = 1,
  'business' = 0,
  'all' = -1
}
export interface IExportFilter {
  intervalFrom: Date;
  intervalTo: Date;
  type: DriveTypeString;
}

export interface IExportState {
  filter: IExportFilter;
  loadingDocument: boolean;
  selectedCar?: ICarConciseDTO | null;
  selectedCarID: number;
}

export function createInitialState(): IExportState {
  const intervalFrom = new Date();
  intervalFrom.setUTCHours(0, 0, 0, 0);
  const intervalTo = new Date();
  intervalTo.setUTCHours(0, 0, 0, 0);
  return {
    selectedCarID: null,
    selectedCar: null,
    loadingDocument: false,
    filter: {
      intervalFrom,
      intervalTo,
      type: DriveTypeString.All
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'export' })
export class ExportStore extends Store<IExportState> {

  public constructor() {
    super(createInitialState());
  }

}
