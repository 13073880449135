<div class="reminders-list" *ngxPermissionsOnly="['reminder-list']">
    <ng-container>
      <table mat-table [dataSource]="reminders" class="">

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="header-first-cell wrapper">{{ 'reminders.type' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">           
            <img [ngClass]="getClass(item)" [src]="getReminderIcon(item.type)" class="icon-img">             
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ 'reminders.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">{{ (item['name']) }}</td>
        </ng-container>

        <ng-container matColumnDef="nextReminder">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ 'reminders.next-limit' | translate }}</th>
          <td mat-cell *matCellDef="let item">{{getReminderLimits(item)}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ 'reminders.send-emails' | translate }}</th>
            <td mat-cell *matCellDef="let item" matTooltipPosition="right" [matTooltip]="item.mails" >
             {{sendMails(item) | translate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef class="wrapper"  matTooltipPosition="below" [matTooltip]="'reminders.active-tooltip' | translate">
            {{ 'reminders.active' | translate }}
          </th>
          <td mat-cell *matCellDef="let item">
            <mat-icon style="transform: scale(0.8);" class="actions-icon transparent">{{isActive(item)}}</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="solve">
          <th mat-header-cell *matHeaderCellDef class="wrapper"></th>
          <td mat-cell *matCellDef="let item">
            <button *ngIf="canSolveReminder(item)" mat-stroked-button class="blue-button" (click)="solveReminder(item)" style="transform: scale(0.85);">
              {{ 'reminders.resolve' | translate }}</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="wrapper distance-cell actionButtons">
          </th>
          <td mat-cell *matCellDef="let element" class="action-link actionButtons">
            <span *ngxPermissionsOnly="['reminder-add-edit']">
              <button [matMenuTriggerFor]="actions" mat-icon-button class="actions-button"
                [matTooltip]="'vehicles.vehicles-options' | translate">
                <mat-icon class="actions-icon transparent">more_vert</mat-icon>
              </button>
            </span>
            <mat-menu #actions="matMenu" xPosition="before" yPosition="below">
              <app-car-action-item (action)="updateReminder(element['conditions'][0]['reminderId'], element)"
                icon="edit" [text]="'Update' | translate">
              </app-car-action-item>
              <app-car-action-item (action)="removeReminder(element['conditions'][0]['reminderId'])"
                icon="delete_forever" [text]="'Delete' | translate">
              </app-car-action-item>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="cols"></tr>
        <tr mat-row *matRowDef="let row; columns: cols;"></tr>
      </table>

      <ng-container *ngIf="(!reminders) && (carRemindersQuery.carID$ | async)">
        <app-content-loading type="progress-bar"></app-content-loading>
      </ng-container>

      <div *ngIf="reminders === undefined || reminders.length === 0" class="centered-info-secondary">
        <br>
        <p>{{ 'reminders.no-reminders' | translate }}</p>
      </div>
    </ng-container>
   <ui-divider></ui-divider>
  <ng-container *ngxPermissionsOnly="['reminder-add-edit']">
    <button [disabled]="isEnabled(carRemindersQuery.carID$ | async)" type="button" mat-button mat-raised-button color="primary"
        (click)="setNewReminder()" class="new-reminder-button">
        {{ 'reminders.new-reminder' | translate }}
      </button>
    </ng-container>
</div>
