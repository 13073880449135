import { Injectable } from '@angular/core';
import { DriveBookDetailStore, createInitialState } from './drive-book-detail.store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DriveBookHttpService } from '../../../api/drive-book-http.service';
import { IFormValues } from '../../../pages/drive-book-detail/edit-form/edit-form.component';
import { IPutDriveBookDTO, INewDriveBookDTO } from '../../../api/models/dto/put-drive-book.dto';
import { DriveBookDetailQuery } from './drive-book-detail.query';
import { IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';

@Injectable({ providedIn: 'root' })
export class DriveBookDetailService {

  public constructor(
    private driveBookDetailStore: DriveBookDetailStore,
    private driveBookHttp: DriveBookHttpService,
    private driveBookDetailQuery: DriveBookDetailQuery
  ) { }

  public loadDriveBook(driveBookID: number): void {
    this.driveBookDetailStore.update({
      loadingDriveBook: true,
      driveBook: null,
      driveBookID
    });

    this.driveBookHttp.fetchDriveBook(driveBookID).pipe(
      tap((driveBook) => this.driveBookDetailStore.update({
        loadingDriveBook: false,
        driveBook
      }))
    ).subscribe();
  }

  public addNewDriveBook(carID: number, v: IFormValues): Observable<INewDriveBookDTO> {

    const newDriveBook: INewDriveBookDTO = {
      carId: carID,
      type: v.drive.driveType,
      driverId: v.drive.driver,
      drivePurpose: v.drive.purpose,
      distance: v.drive.newDistance,
      tachoChange: v.newTachometer,
      fuelAmount: v.fuel.fuelRefillLitres,
      fuelPrice: v.fuel.fuelRefillPrice,
      startDate: v.start.dateTime,
      startTacho: v.start.tachometer,
      startPlace: v.start.location,
      stopDate: v.stop.dateTime,
      stopTacho: v.stop.tachometer,
      stopPlace: v.stop.location,
      note: v.note
    };

    return this.driveBookHttp.addNewDriveBook(newDriveBook);
  }

  public updateDriveBook(driveBookID: number, values: IFormValues): Observable<IDriveBookDTO> {
    this.driveBookDetailStore.update({
      loadingDriveBook: true,
    });
    return this.driveBookDetailQuery.driveBookNotNull$.pipe(
      take(1),
      filter((driveBook) => driveBook.driveBookId === driveBookID),
      switchMap((driveBook) => of(values).pipe(
        map((v) => ({
          drivebookId: driveBookID,
          type: v.drive.driveType,
          driverId: v.drive.driver,
          drivePurpose: v.drive.purpose,
          distance: !v.drive.newDistance ? driveBook.traceDistance : v.drive.newDistance,
          tachoChange: !v.newTachometer ? null : v.newTachometer,
          fuelAmount: v.fuel.fuelRefillLitres,
          fuelPrice: v.fuel.fuelRefillPrice,
          bill: null,
          startDate: v.start.dateTime,
          startPlace: v.start.location,
          stopDate: v.stop.dateTime,
          stopTacho: v.stop.tachometer,
          stopPlace: v.stop.location,
          note: v.note
        } as IPutDriveBookDTO))
      )),
      switchMap((updatedDriveBook) =>
        this.driveBookHttp.updateDriveBook(driveBookID, updatedDriveBook).pipe(
          tap(() => this.driveBookDetailStore.update({
            loadingDriveBook: false,
          }))
        )),
    );
  }

  public clearStore(): void {
    this.driveBookDetailStore.update(createInitialState());
  }
}
