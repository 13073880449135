import { ICarColor } from './car-color.model';
import { ICarFuel } from './car-fuel.model';
import { IDriveBookPath } from './drive-book-path.model';

export interface ICar {
  // main details
  id: number;
  name: string;
  serialNumber: string;
  plateNumber: string;
  description: string;
  vin: string;
  seats: number;
  maxSpeed: number;
  supervision: boolean;
  buildingMachine: boolean;
  ccsCardNumber: string;
  note: string;
  color: ICarColor;
  fuel: ICarFuel;

  // security details
  alarm: string;
  gpsAntenna: string;
  radioAntenna: string;
  coordinates: any[];

  currentLocation: IDriveBookPath;

}

export enum VehicleStateEnum {
  KEY_ON_SPEED_ON_DATA_ON = 'KEY_ON_SPEED_ON_DATA_ON',
  KEY_ON_SPEED_ON_DATA_OFF = 'KEY_ON_SPEED_ON_DATA_OFF',
  KEY_OFF_SPEED_ON_DATA_ON = 'KEY_OFF_SPEED_ON_DATA_ON',
  KEY_OFF_SPEED_ON_DATA_OFF = 'KEY_OFF_SPEED_ON_DATA_OFF',
  KEY_ON_SPEED_OFF_DATA_ON = 'KEY_ON_SPEED_OFF_DATA_ON',
  KEY_ON_SPEED_OFF_DATA_OFF = 'KEY_ON_SPEED_OFF_DATA_OFF',
}
