import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { IconSnackBarComponent } from '../ui-components/icon-snackbar/icon-snackbar-component';

const DURATION = 4000;
const DURATION_LONG = 10000;
const DURATION_EXTRA_LONG = 60000;
const VERTICAL_POSITION = 'top';
const BOTTOM_POSITION = 'bottom';
const HORIZONTAL_POSITION = 'center';
const CSS_CLASS_INFO = 'snackbar-info';
const CSS_CLASS_ERROR = 'snackbar-error';
const CSS_CLASS_UPDATE_INFO = 'snackbar-update-info';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(private snackbar: MatSnackBar) {
  }

  showInfo(text: string) {
    this.snackbar.open(text, 'x', {
      duration: DURATION,
      verticalPosition: VERTICAL_POSITION,
      horizontalPosition: HORIZONTAL_POSITION,
      panelClass: CSS_CLASS_INFO
    });
  }

  showError(text: string) {
    this.snackbar.open(text, 'x', {
      duration: DURATION_LONG,
      verticalPosition: VERTICAL_POSITION,
      horizontalPosition: HORIZONTAL_POSITION,
      panelClass: CSS_CLASS_ERROR
    });
  }

  showUpdateDialog(text: string, onUpdate: () => void) {
    const notification = this.snackbar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: text,
        clickCallback: onUpdate,
        icon: 'info'
      },
      duration: DURATION_EXTRA_LONG,
      verticalPosition: BOTTOM_POSITION,
      horizontalPosition: HORIZONTAL_POSITION,
      panelClass: CSS_CLASS_UPDATE_INFO,
    });
  }

  closeSnackbar(): void {
    this.snackbar.dismiss();
  }
}
