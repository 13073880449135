import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { IReminderDTO, IUpcomingReminderDTO } from '../../../api/models/dto/reminders.dto';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CarRemindersService } from 'src/app/store/car-reminders/state/car-reminders.service';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { Guid } from 'guid-typescript';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';
import { CarRemindersHttpService } from 'src/app/api/car-reminders-http.service';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { ICarConciseDTO } from 'src/app/api/models/dto/car-concise.dto';

@Component({
  selector: 'app-reminders-list',
  templateUrl: './reminders-list.component.html',
  styleUrls: ['./reminders-list.component.scss'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'cs'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class RemindersListComponent implements OnInit, OnDestroy {

  @Input()
  public reminders: IReminderDTO[];

  @Input()
  public carID$: Observable<number> | null = null;

  @Input()
  public detailCarID: number | null = null;

  @Output()
  public isEdited = new EventEmitter<boolean>();

  private subs = new Subscription();

  public cols = ['type', 'name', 'nextReminder', 'email', 'active', 'solve', 'actions'];

  public upcomingReminders: IUpcomingReminderDTO[];

  public car: ICarConciseDTO;

  public carID: number;

  public constructor(
    private carsQuery: CarsQuery,
    private dialog: MatDialog,
    private carRemindersState: CarRemindersService,
    private carReminderHttp: CarRemindersHttpService,
    private carsHttp: CarsHttpService,
    public readonly carRemindersQuery: CarRemindersQuery) { }

  ngOnInit(): void {

    if (this.carID$) {
      this.subs.add(this.carID$.subscribe(ID => {
        if (!!ID) {
          this.carID = ID;
          this.carsHttp.fetchCarConcise(ID).subscribe(car => this.car = car);
          this.carRemindersState.fetchUpcomingRemindersByCarID(this.carID).subscribe(
            reminders => this.upcomingReminders = reminders.reminders
          );
        }
      }));
    } else {
          this.carID = this.detailCarID;
          this.carsHttp.fetchCarConcise(this.carID).subscribe(car => this.car = car);
          this.carRemindersState.fetchUpcomingRemindersByCarID(this.carID).subscribe(
            reminders => this.upcomingReminders = reminders.reminders
          );
        }

    this.subs.add(this.carsQuery.allCars$.subscribe((cars) => {
        if (cars?.length > 0) {
          const carDTO = cars?.find(c => c.carID === this.carID);
          if (this.car !== undefined) {
            if (carDTO?.activeReminders !== null) {
              this.upcomingReminders = carDTO.activeReminders;
            }
          }
        }
      }));
  }

  private openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  public removeReminder(reminderID: string): void {
    this.openConfirmDialog('reminder.list.confirm.message').afterClosed().subscribe((result) => {
      if (result) {
        this.carRemindersState.deleteReminder(reminderID).subscribe((data: any) => {
          if (data.deleted) {
            this.carRemindersState.setDeletedReminder(reminderID);
          }}
        );
      }
    });
  }

  public solveReminder(reminder: IReminderDTO) {
    const upcomingReminder = this.upcomingReminders.find(x => x.reminderId === reminder.reminderId);
    this.carRemindersState.solveReminder(reminder, upcomingReminder, this.car.spz, this.car.toolTipText);
  }

  public updateReminder(reminderID: number, reminder: IReminderDTO): void {
    this.carRemindersState.setEditedReminder(true, 'update', reminder);
    this.isEdited.emit(true);
  }

  setNewReminder(): void {
    this.carRemindersState.setIsStartEditing(true);
    this.carRemindersState.setAction('create');
    const emptyReminder = {
      reminderId: Guid.create().toString(),
      carId: this.carID,
      conditions: [],
      eventLevel: 0,
      mails: '',
      message: '',
      name: '',
      sendMail: false,
      type: -1
    };
    this.carRemindersState.setReminder(emptyReminder);
    this.isEdited.emit(true);
  }

  isEnabled(carId: number): boolean {
    return (carId < 0 || !carId);
  }

  public getReminderLimits(reminder: IReminderDTO) {
    if (!!this.upcomingReminders) {
      const upcomingReminder = this.upcomingReminders.find(x => x.reminderId === reminder.reminderId);
      if (!!upcomingReminder) {
        const year = new Date(upcomingReminder.nextDate).getFullYear();
        const date = year !== 1 ? moment.utc(upcomingReminder.nextDate).format('D. M. YYYY') : '';
        const tacho = upcomingReminder.nextTachometer === -1 ? '' : upcomingReminder.nextTachometer.toLocaleString() + ' km';
        if (date !== '' && tacho !== '') {
          return date + ' nebo ' + tacho;
        }
        return date + tacho;
      }
      return '';
    }
    return '';
  }

  public canSolveReminder(reminder: IReminderDTO) {
    const upcomingReminder = this.upcomingReminders?.find(x => x.reminderId === reminder.reminderId);
    if (upcomingReminder !== undefined) {
      return true;
    }
    return false;
  }

  public getClass(reminder: IReminderDTO): string {
    let level = - 1;
    if (!!this.upcomingReminders === null || this.upcomingReminders === undefined) {
      return;
    }
    if (!!this.upcomingReminders) {
      const upcomingReminder = this.upcomingReminders.find(x => x.reminderId === reminder.reminderId);
      if (upcomingReminder !== undefined) {
        level = upcomingReminder.level;
      }
    }
    switch (level) {
      case 2:
        return 'red-svg';
      case 1:
        return 'red-svg';
      case 0:
        return 'orange-svg';
      case -1:
        return 'none-svg';
      default:
        return '';
    }
  }

  public isActive(reminder: IReminderDTO) {
    if (!!this.upcomingReminders) {
      const upcomingReminder = this.upcomingReminders.find(x => x.reminderId === reminder.reminderId);
      if (!upcomingReminder) {
        return 'clear';
      } else {
        return 'check';
      }
    }
  }

  public sendMails(reminder: IReminderDTO) {
    if (reminder.mails !== '') {
      return 'reminders.yes';
    }
    return 'reminders.no';
  }

  public getReminderIcon(type: number): string {
    switch (type) {
      case 0: {
        // TODO: change to icon for general reminder, once we get a new one from desginer
        return './../../../../assets/images/engine-oil.svg';
      }
      case 1: {
        return './../../../../assets/images/engine-oil.svg';
      }
      case 2: {
        return './../../../../assets/images/stk.svg';
      }
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
