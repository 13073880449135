import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// tslint:disable-next-line:max-line-length
import { IAreaControlData, AreaRestrictionType, IAreaVehicleDialogInfo, IAreaVehicleEditRequest } from 'src/app/api/models/dto/area-control.dto';
import { ICarDTO, ICarPositionExtra } from 'src/app/api/models/dto/car-groups.dto';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IMapPoint } from 'src/app/store/map/state/map.store';
import { AreaControVehicleEditDialogComponent } from '../../area-control-vehicle-edit-dialog/area-control-vehicle-edit-dialog.component';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { MapService } from 'src/app/store/map/state/map.service';
import { MapQuery } from 'src/app/store/map/state/map.query';

@Component({
  selector: 'app-area-control-tab',
  templateUrl: './area-control-tab.component.html',
  styleUrls: ['./area-control-tab.component.scss']
})
export class AreaControlTabComponent implements OnInit, OnDestroy {

  @Input() carId: number;

  private subs = new Subscription();
  public selectedPolygon: IAreaControlData;
  public vehicles: ICarDTO[];
  public vehiclesPositions: ICarPositionExtra[];
  public isLoading = false;
  public areasByCar: IAreaControlData[];
  public selectedCarIds: number[];
  public carPoints: IMapPoint[];

  displayedColumns: string[] = ['name', 'restrictionType', 'notifications', 'showOnMap', 'actions'];
  dataSource = new MatTableDataSource();

  public constructor(
    private areaControlService: AreaControlService,
    private carsQuery: CarsQuery,
    private areaControlQuery: AreaControlQuery,
    private carsPositionQuery: CarsPositionQuery,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private utilityService: UtilitiesService,
    private carService: CarsService,
    private mapService: MapService,
    private mapQuery: MapQuery
  ) { }

  ngOnInit(): void {

    if (!this.vehicles || this.vehicles.length === 0) {
      this.carService.fetchGroupsWithCars(true);
    }
    this.subs.add(this.areaControlService.getAreasByCarIdUrl(this.carId));
    this.subs.add(this.areaControlQuery.areaPolygons$.subscribe((polygons) => {
      if (polygons.length > 0) {
        this.areasByCar = polygons;
        this.proccessAreas(this.areasByCar);
      }
    }));
    this.subs.add(this.carsQuery.carGroups$.subscribe((groups) => {
      this.vehicles = !!groups ? groups.reduce((accumulator, obj) => [...accumulator, ...obj.cars], []) : [];
    }));

    this.subs.add(this.areaControlQuery.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    }));
    this.subs.add(this.carsPositionQuery.carsPosition$.subscribe((positions) => {
      this.vehiclesPositions = positions;
    }));
    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((polygon) => {
      this.selectedPolygon = polygon;
    }));
    this.subs.add(this.mapQuery.points$.subscribe((points) => {
      this.carPoints = points;
    }));
  }

 public async onAssignArea() {
    if (!this.carId) {
      return;
    }
    const areaVehicle: IAreaVehicleDialogInfo = {
      areaId: null,
      sendNotifications: true,
      type: AreaRestrictionType.FORBIDDEN,
      vehicleId: this.carId,
      notificationEmail: '',
      notificationPhone: '',
      areaName: ''
    };
    const vehicle = this.vehicles?.find(veh => veh.carID === this.carId);
    this.dialog.open(AreaControVehicleEditDialogComponent,
      { data: { areaVehicle, vehicle, withDropdown: true }, minWidth: 550 });
  }

  public onAreaVehicleEdit(row: any): void {
    if (!row) {
      return;
    }
    const areaVehicle: IAreaVehicleDialogInfo = {
      areaId: row.areaId,
      sendNotifications: row.sendNotifications,
      type: row.restrictionType,
      vehicleId: row.vehicleId,
      notificationEmail: row.email,
      notificationPhone: row.sms,
      areaName: row.areaName
    };
    const vehicle = this.vehicles?.find(veh => veh.carID === this.carId);
    this.dialog.open(AreaControVehicleEditDialogComponent,
      { data: { areaVehicle, vehicle }, minWidth: 550 }).afterClosed().subscribe((result) => {
        if (!!result && result !== false) {
         this.onAreaVehicleSave(result, row.areaId);
        }
      });
  }

  public isRestrictionMet(element): boolean {
    if (element.restrictionType === AreaRestrictionType.ALLOWED && element.inArea) {
      return true;
    }
    if (element.restrictionType === AreaRestrictionType.FORBIDDEN && !element.inArea) {
      return true;
    }
    return false;
  }

  public proccessAreas(areas: IAreaControlData[]): void {
    const tableData = [];
    for (const area of areas) {
      if (!area || !this.vehiclesPositions || this.vehiclesPositions.length === 0) {
        return;
      }
      const polygonPath: IMapPoint[] = area.points.map(p => ({ latitude: p.lat, longitude: p.lng }));
      const areaVehicle = !!area.vehicles ? area.vehicles.find(v => v.vehicleId === this.carId) : null;
      const vehiclePosition = this.vehiclesPositions.find(v => v.carId === this.carId);
      if (areaVehicle?.type === AreaRestrictionType.NOT_SET) {
        continue;
      }
      const vehicle = {
        areaId: area.id,
        areaName: area.name,
        vehicleId: this.carId,
        sendNotifications: !!areaVehicle ? areaVehicle.sendNotifications : false,
        restrictionType: !!areaVehicle ? areaVehicle.type : AreaRestrictionType.NOT_SET,
        inArea: this.utilityService.isInAreaPolygon(vehiclePosition, polygonPath),
        email: !!areaVehicle ? areaVehicle.notificationEmail : null,
        sms: !!areaVehicle ? areaVehicle.notificationPhone : null,
      };
      tableData.push(vehicle);
    }
    this.dataSource.data = tableData;
    this.changeDetector.detectChanges();
  }

  public onAreaVehicleSave(result: IAreaVehicleEditRequest, id: number): void {
    this.areaControlService.editAreaVehicle(id, result, this.carId, () => {});
  }

  rowShowOnMap(row: any): void {
    if (!!this.selectedPolygon && this.selectedPolygon?.id === row?.areaId) {
      this.areaControlService.setSelectedPolygon(null);
    } else {
      const polygon = this.areasByCar.find(x => x.id === row.areaId);
      this.areaControlService.setSelectedPolygon(polygon);
      this.processCarWithPolygon();
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.areaControlService.setSelectedPolygon(null);
    this.mapService.clearPoints();
  }

  displayCar() {
    this.carService.selectCar(this.carId);
    this.processCarWithPolygon();
  }

  processCarWithPolygon() {
    const pointsTotal: IMapPoint[] = [];
    if (!!this.carPoints) {
      pointsTotal.push(...this.carPoints);
    }
    if (!!this.selectedPolygon) {
      const polygonPoints = this.utilityService.calculatePointsFromPolygon(this.selectedPolygon.points);
      pointsTotal.push(...polygonPoints);
    }
    const center = this.utilityService.calculateCenterPoint(pointsTotal);
    const radius = this.utilityService.calculateRadius(pointsTotal);
    const zoom = this.utilityService.calculateZoomLevel(radius);
    this.mapService.setZoom(zoom);
    this.mapService.setCenterPoint(center);
  }
}
