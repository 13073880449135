<app-page-wrapper [hasSidePadding]="true" [hasTopPadding]="true" [whiteBackground]="true">
  <ng-template appPageContent>

    <div class="edit-container">
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="handleSubmit($event)" [style.fontSize.px]="14">
        <div class="title"> {{ 'drive-book.edit-form.drive.title' | translate }} </div>
        <div formGroupName="drive">
          <div class="column-input-2">
            <mat-form-field class="left-column">
              <mat-label>{{ 'drive-book.edit-form.drive.drive-type' | translate }}</mat-label>
              <mat-select formControlName="driveType">
                <mat-option *ngFor="let driveType of driveTypes" [value]="driveType[0]">
                  {{ driveTypeLangKey(driveType[1]) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="right-column" *ngIf="drivers?.drivers?.drivers.length > 1">
              <mat-label>{{ 'drive-book.edit-form.drive.driver' | translate }}</mat-label>
              <mat-select formControlName="driver">
                <mat-option *ngFor="let driver of drivers.drivers.drivers" [value]="driver.nPersonID">
                  {{ driver?.strSurname | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field class="row-input-left">
            <input matInput [placeholder]="'drive-book.edit-form.drive.purpose' | translate" formControlName="purpose">
          </mat-form-field>
          <div class="column-input-2">
          <ng-container *ngxPermissionsOnly="['drivebook-edit']">
            <ng-container *ngIf="!!drivebook?.driveBookId">
              <mat-form-field class="left-column">
                <input matInput
                  [placeholder]="('drive-book.edit-form.drive.new-distance' | translate) + ' ( ' + ('drive-book.edit-form.drive.former' | translate)
                    + (distance | number) + ' km)'" type="number" formControlName="newDistance">
                <div matSuffix class="mat-small">km</div>
              </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!drivebook?.driveBookId">
                <mat-form-field class="left-column">
                  <input matInput required [placeholder]="('drive-book.edit-form.drive.enter-new-distance' | translate)"
                    type="number" formControlName="newDistance">
                  <div matSuffix class="mat-small">km</div>
                </mat-form-field>
              </ng-container>
              <div class="mini-input" style="margin-left: 10px;" *ngIf="!!drivebook?.driveBookId">
                <div class="left">
                  <mat-form-field appearance="none">
                    <mat-label>{{'drive-book.edit-form.other.max-speed' | translate}}</mat-label>
                    <input readonly matInput formControlName="maxSpeed">
                  </mat-form-field>
                </div>
                <div class="right">
                  <mat-form-field appearance="none">
                    <mat-label>{{'drive-book.edit-form.other.avg-speed' | translate}}</mat-label>
                    <input matInput readonly formControlName="avgSpeed">
                  </mat-form-field>    
              </div>        
            </div>
          </ng-container>
        </div>
        </div>

        <span *ngxPermissionsOnly="['drivebook-edit']">
          <div class="title"> {{ 'drive-book.edit-form.start.title' | translate }} </div>
          <div class="start-block" formGroupName="start">
              <ng-container *ngIf="!drivebook?.driveBookId">
                <div class="column-input-2">
                <mat-form-field class="left-column">
                  <input matInput formControlName="date" [matDatepicker]="datePickerStart" (dateChange)="selectDate($event, true)"
                  [placeholder]="'drive-book.edit-form.start.date' | translate">
                  <mat-datepicker-toggle matSuffix [for]="datePickerStart"></mat-datepicker-toggle>
                  <mat-datepicker #datePickerStart></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="right-column">
                  <input matInput [disableClick]="true" [format]="24" formControlName="time" [ngxTimepicker]="timePickerStart"
                  [placeholder]="'drive-book.edit-form.start.time' | translate">
                  <ngx-material-timepicker-toggle matSuffix [for]="timePickerStart">
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker #timePickerStart></ngx-material-timepicker>
                </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="!!drivebook?.driveBookId">
                <div class="column-input-2">
                  <div class="mini-input">
                  <mat-form-field class="left" appearance="none">
                    <mat-label>{{'drive-book.edit-form.start.time' | translate}}</mat-label>
                    <input matInput readonly formControlName="date" [matDatepicker]="datePickerStart">
                    <mat-datepicker #datePickerStart></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="right" appearance="none" style="padding-left: 10px;">
                    <input matInput [disableClick]="true" [format]="24" formControlName="time" [ngxTimepicker]="timePickerStart">
                    <ngx-material-timepicker #timePickerStart></ngx-material-timepicker>
                  </mat-form-field>
                  </div>   
                  <mat-form-field class="right-column" appearance="none">
                    <mat-label>{{'drive-book.edit-form.start.tachometer' | translate}}</mat-label>
                    <input matInput readonly formControlName="tachometer">
                  </mat-form-field>
              </div>
              </ng-container>
              <mat-form-field class="row-input-left">
                <input matInput [placeholder]="'drive-book.edit-form.start.location' | translate"
                  type="text" formControlName="location">
              </mat-form-field>
            </div>
        </span>

        <span *ngxPermissionsOnly="['drivebook-edit']">
          <div class="title"> {{ 'drive-book.edit-form.stop.title' | translate }} </div>
          <div class="stop-block" formGroupName="stop">
              <ng-container *ngIf="!drivebook?.driveBookId">
                <div class="column-input-2">
                <mat-form-field class="left-column">
                  <input matInput formControlName="date" [matDatepicker]="dateStop" [min]="form.value.start.date"
                  (dateChange)="selectDate($event, false)" [placeholder]="'drive-book.edit-form.stop.date' | translate">
                  <mat-datepicker-toggle matSuffix [for]="dateStop"></mat-datepicker-toggle>
                  <mat-datepicker #dateStop></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="right-column">
                  <input matInput [disableClick]="true" [format]="24" formControlName="time" [placeholder]="'drive-book.edit-form.stop.time' | translate"
                    [ngxTimepicker]="timePickerStop">
                  <ngx-material-timepicker-toggle matSuffix [for]="timePickerStop">
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker #timePickerStop></ngx-material-timepicker>
                </mat-form-field>
                </div>
              </ng-container>  
              <ng-container *ngIf="!!drivebook?.driveBookId">
                <div class="column-input-2">
                  <div class="mini-input">
                    <mat-form-field class="left">
                      <input matInput formControlName="date" [matDatepicker]="dateStop" [min]="form.value.start.date"
                      (dateChange)="selectDate($event, false)" [placeholder]="'drive-book.edit-form.stop.date' | translate">
                      <mat-datepicker-toggle matSuffix [for]="dateStop"></mat-datepicker-toggle>
                      <mat-datepicker #dateStop></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="right" style="padding-left: 10px;">
                      <input matInput [disableClick]="true" [format]="24" formControlName="time" [placeholder]="'drive-book.edit-form.stop.time' | translate"
                    [ngxTimepicker]="timePickerStop">
                  <ngx-material-timepicker-toggle matSuffix [for]="timePickerStop">
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker #timePickerStop></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                  <mat-form-field class="right-column">
                    <input matInput [required]="true" [placeholder]="'drive-book.edit-form.stop.tachometer' | translate"
                      type="number" formControlName="tachometer">
                    <div matSuffix class="mat-small">km</div>
                  </mat-form-field> 
                </div>             
              </ng-container>  
              <mat-form-field class="row-input-left">
                <input matInput [placeholder]="'drive-book.edit-form.stop.location' | translate"
                  type="text" formControlName="location">
              </mat-form-field>                       
          </div>
        </span>

        <div class="title"> {{ 'drive-book.edit-form.fuel.title' | translate }} </div>
        <div class="fuel-block" formGroupName="fuel">
          <div class="column-input-2">
            <mat-form-field class="left-column">
              <input matInput [placeholder]="'drive-book.edit-form.other.fuel-refill-litres' | translate" type="number"
                formControlName="fuelRefillLitres">
              <div matSuffix class="mat-small">{{ 'drive-book.edit-form.other.litres' | translate}}</div>
            </mat-form-field>
            <mat-form-field class="right-column">
              <input matInput [placeholder]="'drive-book.edit-form.other.fuel-refill.price' | translate" type="number"
                formControlName="fuelRefillPrice">
              <div matSuffix class="mat-small">CZK</div>
            </mat-form-field>
          </div>
        </div>
        <span *ngxPermissionsOnly="['drivebook-edit']">
          <div class="title"> {{'drive-book.edit-form.drive.tachometer-change' | translate}} </div>
          <div class="column-input-2">
            <mat-form-field class="left-column">
              <input matInput [placeholder]="'drive-book.edit-form.other.tachometer' | translate" type="number"
                formControlName="newTachometer">
              <div matSuffix class="mat-small">km</div>
            </mat-form-field>
          </div>
        </span>
        <div class="title"> {{'drive-book.edit-form.note' | translate}} </div>
        <div class="note-block">
          <mat-form-field class="row-input-left">
            <textarea matInput formControlName="note" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=3>
        </textarea>
          </mat-form-field>
        </div>

        <div class="actions-block">
          <p style="font-size:12px;" [innerHTML]="'required-info' | translate"></p>
          <div class="action-buttons"> 
            <div class="cancel">
              <button type="button" (click)="handleCancel()" mat-raised-button>
                {{ 'form-actions.back' | translate }}
              </button>
            </div>
            <div class="save">
              <button type="submit" mat-raised-button color="primary"
                [disabled]="form.invalid">
                {{ 'form-actions.save' | translate }}
              </button>
            </div>
          </div> 
        </div>
      </form>
    </div>
  </ng-template>
</app-page-wrapper>