<app-page-wrapper [hasSidePadding]="false" [hasTopPadding]="false" [isLoading]="isLoading">
  <ng-template appPageContent>
    <div class="wrapper">
      <div class="area-info">
        <form [formGroup]="form" (ngSubmit)="savePolygon()">
          <div class="area-container">
            <div class="area-heading">
              {{ 'area-control.area' | translate }}
            </div>
            <div class="area-form-data">
              <mat-form-field class="area-type" [floatLabel]="'never'">
                <input
                  type="text"
                  matInput
                  formControlName="areaName"
                  [placeholder]="'area-control.area-name' | translate">
                <mat-error *ngIf="!!form?.controls?.areaName?.errors?.forbiddenValues">
                    {{ "area-control.forbidden-name" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <div class="color-preview" [style.backgroundColor]="areaColor" matPrefix></div>
                <input
                  autocomplete="off"
                  type="text"
                  matInput
                  (colorPickerChange)="colorChanged()"
                  [(colorPicker)]="areaColor"
                  [cpPosition]="'bottom'"
                  [cpOutputFormat]="'hex'"
                  [cpAlphaChannel]="false"
                  [cpPositionOffset]="'50%'"
                  [cpPositionRelativeToArrow]="true"
                  formControlName="areaColor"
                  [placeholder]="'area-control.area-color' | translate">
              </mat-form-field>
              <ui-divider></ui-divider>

              <div class="button-container">
                <button type="button" class="back-button" mat-button mat-raised-button (click)="goBack()">
                  {{ 'form-actions.back' | translate }}
                </button>
                <button type="submit" mat-button mat-raised-button color="primary" [disabled]="!form?.valid">
                  {{ 'area-control.save-area' | translate }}
                </button>
              </div>

              <ui-divider></ui-divider>
              <p class="info-text" [innerHTML]="'area-control.info-text-edit' | translate"></p>
            </div>
          </div>
        </form>
      </div>
    </div>   
  </ng-template>
</app-page-wrapper>
