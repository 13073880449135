import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { Subscription } from 'rxjs';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { DriveBookDetailService } from '../../../store/drive-book-detail/state/drive-book-detail.service';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { DriveBookDetailQuery } from '../../../store/drive-book-detail/state/drive-book-detail.query';
import { IFormValues } from '../edit-form/edit-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DriveBookService } from 'src/app/store/drive-book/state/drive-book.service';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { UrlRoutes } from 'src/app/app-routes';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IDriveBookDTO } from 'src/app/api/models/dto/drive-books.dto';

const PAGE_TITLE = 'page-title.drive-book-detail';

@Component({
  selector: 'app-drive-book-detail-page',
  templateUrl: './drive-book-detail-page.component.html',
  styleUrls: ['./drive-book-detail-page.component.scss']
})
export class DriveBookDetailPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  private drivebook: IDriveBookDTO;
  private backUrl: string;
  private driveBookID: number | null = null;

  public constructor(
    private navigationService: NavigationService,
    private driveBookDetailState: DriveBookDetailService,
    private routerQuery: RouterQuery,
    private router: Router,
    public readonly driveBookDetailQuery: DriveBookDetailQuery,
    private driveBookState: DriveBookService,
    private carsState: CarsService,
    private route: ActivatedRoute,
    private utilityService: UtilitiesService
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.DriveBook,
      pageTitle: PAGE_TITLE,
      backUrl: UrlRoutes.urls.drivebook.home,
      panelWidth: 500
    });

    this.subs.add(this.driveBookDetailQuery.driveBook$.
      subscribe(drivebook => {
      this.drivebook = drivebook;
      this.route.paramMap
      .pipe(map(() => window.history.state)).subscribe(params => {
         if (params?.previous === 'fueling') {
          this.backUrl = this.utilityService.format(UrlRoutes.urls.dashboardCarsDetailUrl, this.drivebook?.carId);
          this.navigationService.setBackUrl(this.backUrl);
        } else {
          this.backUrl = UrlRoutes.urls.drivebook.home;
          this.navigationService.setBackUrl(this.backUrl);
      }  });
    }));

    this.navigationService.showContentPanel('/dashboard/drive-book');

    this.subs.add(
      this.routerQuery.selectParams().pipe(
        distinctUntilChanged(),
        filter((params) => !!params.drive_book_id),
        map((params) => parseInt(params.drive_book_id as any, 10)),
        distinctUntilChanged(),
        tap((driveBookID) => this.driveBookID = driveBookID)
      ).subscribe((driveBookID) => this.driveBookDetailState.loadDriveBook(driveBookID))
    );
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.subs.unsubscribe();
  }

  public submitForm(driveBookID: number, data: IFormValues): void {
    this.driveBookDetailState.updateDriveBook(driveBookID, data).subscribe(async () => {
      this.driveBookDetailState.loadDriveBook(driveBookID);
      this.driveBookState.removeAllDrivebooks();
      this.driveBookState.removeTotals();
      this.driveBookState.updateDriveBookData();
      this.carsState.fetchGroupsWithCars(true);
    });

  }

  public async cancelForm(): Promise<void> {
    await this.navigateBackToDriveBook();
  }

  private async navigateBackToDriveBook(): Promise<boolean> {
    return await this.router.navigateByUrl(this.backUrl);
  }

}
