import { Injectable } from '@angular/core';
import { ExportHttpService } from 'src/app/api/export-http.service';
import { IExportRequest } from 'src/app/api/models/export-request.model';
import { ExportStore } from './export.store';
import { IExportSchedules, IExportSchedule, ICreateExportSchedule } from 'src/app/api/models/dto/export-schedule.dto';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportService {

  public constructor(
    private exportStore: ExportStore,
    private exportHttp: ExportHttpService
  ) { }

  public exportToPDF(exportRequest: IExportRequest) {
    return this.exportHttp.exportToPDF(exportRequest);
  }

  public exportToDocx(exportRequest: IExportRequest) {
    return this.exportHttp.exportToDocx(exportRequest);
  }

  public exportToXLSX(exportRequest: IExportRequest) {
    return this.exportHttp.exportToXLSX(exportRequest);
  }

  public exportToXML(exportRequest: IExportRequest) {
    return this.exportHttp.exportToXML(exportRequest);
  }

  public setFilter(filter: any): void {
    this.exportStore.update(filter);
  }

  public fetchExportScheduleByCarId(carID: number): Observable<IExportSchedules> {
    return this.exportHttp.fetchExportScheduleByCarId(carID);
  }

  public fetchExportScheduleById(id: number): Observable<IExportSchedule> {
    return this.exportHttp.fetchExportScheduleById(id);
  }

  public updateExportSchedule(id: number, exportSchedule: IExportSchedule) {
    return this.exportHttp.updateExportSchedule(id, exportSchedule);
  }

  public createExportSchedule(carId: number, exportSchedule: ICreateExportSchedule) {
    return this.exportHttp.createExportSchedule(carId, exportSchedule);
  }

  public deleteExportSchedule(id: number) {
    return this.exportHttp.deleteExportSchedule(id);
  }

}
