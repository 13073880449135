<app-page-wrapper [isLoading]="loadingData" [hasSidePadding]="true" [whiteBackground]="true">
  <ng-template appPageContent *ngxPermissionsOnly="['car-edit', 'car-detail']">
    <app-car-edit-form
      (submitAction)="submitForm(car.carId, $event)"
      (cancelAction)="cancelForm()"
      [car]="car"
      [colors]="colors"
      [fuels]="fuels"
      [bodies]="bodies">
    </app-car-edit-form>
  </ng-template>
</app-page-wrapper>
