import { Injectable } from '@angular/core';
import { NotifyService } from './notify.service';
import { SwUpdate } from '@angular/service-worker';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoggerService } from './logger.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  textLabel: string;
  constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotifyService,
    private loggerService: LoggerService,
    private translate: TranslateService) {
    if (swUpdate?.isEnabled) {
      swUpdate.checkForUpdate().then(() => loggerService.logInfo('[SW Update] Checking for updates...'));
    }
    this.translate.get('application.update-available').subscribe(translation => {
      this.textLabel = translation;
    });
  }

  checkForUpdate$(): Observable<boolean> {
    return this.swUpdate.available.pipe(map(() => {
      this.loggerService.logWarning('[SW Update] New update is available');
      this.notificationService.showUpdateDialog(this.textLabel, this.onUpdate);
      return true;
    }));
  }

  onUpdate = (): void => {
    if (this.swUpdate?.isEnabled) {
      this.swUpdate.activateUpdate().then(
        () => {
          this.reloadApplication();
        }, () => {
          this.reloadApplication();
        });
    } else {
      this.reloadApplication();
    }
  }

  reloadApplication(): void {
    this.loggerService.logWarning('[SW Update] Reloading app...');
    window.location.reload();
  }
}
