import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// tslint:disable-next-line:max-line-length
import { IAreaVehicleEditRequest, AreaRestrictionType, IAreaVehicleDialogInfo, IAreaControlData } from 'src/app/api/models/dto/area-control.dto';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { AreaControlHttpService } from 'src/app/api/area-control-http-service';

interface IDialogInput {
  areaVehicle: IAreaVehicleDialogInfo;
  vehicle: ICarDTO;
  withDropdown: boolean;
}

@Component({
  selector: 'app-area-control-vehicle-edit-dialog',
  templateUrl: './area-control-vehicle-edit-dialog.component.html',
  styleUrls: ['./area-control-vehicle-edit-dialog.component.scss']
})
export class AreaControVehicleEditDialogComponent implements OnInit {
  form: FormGroup;
  areaRestrictionTypes = AreaRestrictionType;
  public areas: IAreaControlData[];
  public constructor(
    private fb: FormBuilder,
    private notifyService: NotifyService,
    private translateService: TranslateService,
    private utilitiesService: UtilitiesService,
    private areaControlService: AreaControlService,
    private areaControlHttp: AreaControlHttpService,
    public dialogRef: MatDialogRef<AreaControVehicleEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogInput
  ) {
  }

  ngOnInit(): void {
    this.areaControlHttp.fetchUserAreas().subscribe(
      res => {
        const filteredAreas = res.areas.filter(area => !area.vehicles.some(vehicle => vehicle.vehicleId === this.data?.vehicle.carID ));
        const mappedArreas = [];
        filteredAreas.forEach((area) => {
          const points = area.points.map((point) => ({ id: point.id, lng: point.longitude, lat: point.latitude, order: point.order }));
          const mappedArea: IAreaControlData = {
            ...area,
            points
          };
          mappedArreas.push(mappedArea);
        });
        this.areas = mappedArreas;
    });

    this.form = this.fb.group({
      area: [
        !!this.data?.areaVehicle?.areaName ? this.data?.areaVehicle?.areaName : '',
        [Validators.required],
      ],
      type: [
        this.data?.areaVehicle?.type === 0 ? 1 : this.data?.areaVehicle?.type,
        [Validators.required],
      ],
      email: [
        !!this.data?.areaVehicle?.notificationEmail ? this.data?.areaVehicle?.notificationEmail : null,
        [this.utilitiesService.validateEmails(false)]
      ],
      phone: [
        !!this.data?.areaVehicle?.notificationPhone ? this.data?.areaVehicle?.notificationPhone : null,
        [this.utilitiesService.validatePhones(false)]
      ],
    });
  }

  onSave(): void {
    if (!this.form.value.phone && !this.form.value.email) {
      this.notifyService.showError(this.translateService.instant('guardian.phone-email-missing'));
      return;
    }
    const request: IAreaVehicleEditRequest = {
      areaId: !!this.data?.areaVehicle?.areaId ? this.data?.areaVehicle?.areaId : this.form.value.area,
      sendNotifications: true,
      type: this.form.value.type,
      notificationEmail: this.form.value.email,
      vehicleId: this.data?.areaVehicle?.vehicleId,
      notificationPhone: this.form.value.phone,
    };
    if (!!request) {
      this.areaControlService.editAreaVehicle(request.areaId, request, this.data?.vehicle.carID, () => {
        this.dialogRef.close(request);
      });
    }
  }

  onDisableAreaControl(): void {
    const request: IAreaVehicleEditRequest = {
      areaId: this.data?.areaVehicle?.areaId,
      sendNotifications: false,
      type: AreaRestrictionType.NOT_SET,
      notificationEmail: this.form.value.email,
      notificationPhone: this.form.value.phone,
      vehicleId: this.data?.areaVehicle?.vehicleId,
    };
    this.dialogRef.close(request);
  }
}
