<div class="container">
  <p class="title"> {{ 'user-menu.profile-settings' | translate }} </p>
  <mat-divider cdkFocusRegionstart></mat-divider>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
  <mat-tab-group [dynamicHeight]='true' [animationDuration]="0" style="height: 450px;">
    <mat-tab [label]="'profile.contact-details' | translate">
      <app-profile-contact-details (onClose)="closeDialog()"
        [contactData]="contactData"
        (onSave)="onSaveProfile($event)"></app-profile-contact-details>
    </mat-tab>
    <ng-container *ngxPermissionsOnly="['password-change']">
      <mat-tab [label]="'profile.password-change' | translate">
        <app-profile-change-password (onClose)="closeDialog()"
          (onSave)="onChangePassword($event)"></app-profile-change-password>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <mat-card cdkFocusRegionstart class="notification" *ngIf="!filledProfile">
    <mat-icon class="transparent info-icon">info</mat-icon>
    <div>
      {{'profile.warning-message' | translate }}
    </div>
    <ui-divider></ui-divider>
    <div>
      <button mat-button mat-raised-button  (click)="skipWarning()">
        {{'profile.skip-warning' | translate }}
      </button>
    </div>
  </mat-card>
</div>
