import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { DriveBookHttpService } from 'src/app/api/drive-book-http.service';
import { ICarDriverDTO, ICarDriversDTO } from 'src/app/api/models/dto/car-driver.dto';
import { NotifyService } from 'src/app/services/notify.service';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import { DriveBookService } from 'src/app/store/drive-book/state/drive-book.service';
import { DriveTypeOverwrite, IDriveBookMultiplyEditRequest } from 'src/app/store/drive-book/state/drive-book.store';

@Component({
    selector: 'app-drive-book-edit-dialog',
    templateUrl: './drive-book-edit-dialog.component.html',
    styleUrls: ['./drive-book-edit-dialog.component.scss']
})

export class DriveBookEditDialogComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public drivers: ICarDriversDTO;
  public purpose = new FormControl('');
  public overwriteOptions = [DriveTypeOverwrite['no-change'], DriveTypeOverwrite['all-public'], DriveTypeOverwrite['all-private']];
  public deletePurposes = false;
  private subs = new Subscription();

  public emptyDriver: ICarDriverDTO = {
    nPersonID: -1,
    nCarID: null,
    strName: null,
    strSurname: this.translateService.instant('drive-book.edit-form.drive.no-driver')
  };

  public noDriverChange: ICarDriverDTO = {
    nPersonID: 0,
    nCarID: null,
    strName: null,
    strSurname: this.translateService.instant('drive-book.edit-form.drive.no-change')
  };

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<DriveBookEditDialogComponent>,
    private driveBookQuery: DriveBookQuery,
    private driveBookService: DriveBookService,
    private driveBookHttpService: DriveBookHttpService,
    private carsHttpService: CarsHttpService,
    private notifyService: NotifyService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const carId = this.driveBookQuery.selectedCarID;
    this.getDrivers(carId);
    this.form = this.fb.group({
      driveTypeOverWrite: DriveTypeOverwrite['no-change'],
      driver: 0,
      purpose: null,
      deletePurposes: this.deletePurposes
    });
    this.subs.add(
      this.form.controls.deletePurposes.valueChanges.subscribe(value => {
        this.deletePurposes = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  save(): void {
    const request: IDriveBookMultiplyEditRequest = {
      driveBooksIDs: this.driveBookQuery.getValue().selectedDriveBookIDs,
      driveTypeOverWrite: this.form.value.driveTypeOverWrite,
      purpose: this.getPurpose(this.form.value.purpose),
      driverId: this.form.value.driver === 0 ? null : this.form.value.driver
    };
    this.driveBookHttpService.multiplyEditDrivebooks(request).subscribe((response) => {
      if (!response) {
        this.driveBookService.updateDriveBookData();
        this.notifyService.showInfo(this.translateService.instant('drive-book.edit-dialog.success'));
      } else {
        this.notifyService.showError(this.translateService.instant('drive-book.edit-dialog.fail'));
      }
    },
    ((error) => {
      this.notifyService.showError(this.translateService.instant('drive-book.edit-dialog.error'));
    }));
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getPurpose(purpose: string): string {
    if (this.deletePurposes) {
      return '';
    } else if (purpose === null || purpose?.length < 1) {
      return null;
    } else {
      return purpose;
    }
  }

  getDrivers(carId: number): void {
    this.carsHttpService.fetchDriversByCarId(carId).subscribe(drivers => {
      if (drivers.drivers.drivers.length > 0) {
        this.drivers = {
          carId: 0,
          drivers: {
            drivers: [this.noDriverChange, this.emptyDriver, ...drivers.drivers.drivers]
          }
        };
      } else {
        return;
      }
    });
  }
}
