<div>
    <div class="filter-wrapper">
      <form class="drive-book-filter-form">
        <div class="date-input from">
          <mat-form-field>
            <input  matInput
                    [matDatepicker]="filterDateFrom"
                    [value]="filter.from"
                    (dateChange)="selectDate($event, true)"
                    [placeholder]="'filter.from' | translate">

            <mat-datepicker-toggle matSuffix [for]="filterDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #filterDateFrom></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="date-input to">
          <mat-form-field>
            <input  matInput
                    [matDatepicker]="filterDateTo"
                    [value]="filter.to"
                    (dateChange)="selectDate($event, false)"
                    [placeholder]="'filter.to' | translate">

            <mat-datepicker-toggle matSuffix [for]="filterDateTo"></mat-datepicker-toggle>
            <mat-datepicker #filterDateTo></mat-datepicker>
          </mat-form-field>
        </div>
        <button type="submit" mat-button mat-raised-button color="primary" (click)="submitSearchForm()">{{ 'stats-page.button-submit' | translate }}</button>
      </form>
    </div>
</div>
