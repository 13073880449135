export class UserInfo {
  email: string;
  username: string;
  role: string;
  fullName?: string;
  id: number;
}

export class IUserInfoDTO {
  id: number;
  personId: number;
  username: string;
  emailAddress?: string;
  userGroupId: number;
  userGroupName: string;
  groupRightsId: number;
  groupRightsName: string;
  hasFilledProfile: boolean;
  gdpr: boolean;
  userPermissions: IPermission[];
  fullName: string;
}

export interface IUserContactDataRequest {
  id?: number;
  emailAddress?: string;
  fullName?: string;
  workspaceId: number;
}

export interface IUserChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface IUserSetChangePassword {
  token: string;
  newPassword: string;
}

export interface IPermission {
  id: number;
  name: string;
}

export interface IUserLoginResponse {
  accessToken: string;
  expiresIn: number;
  permissions: IPermission[];
  role: string;
  tokenType: string;
  username: string;
}
