<div>
<div class="title">
	{{'reminders.dialog-resolve' | translate}}
</div>
<mat-divider></mat-divider>
<div class="container">
	<mat-dialog-content>
		<p [innerHTML]="message">
		</p>
	</mat-dialog-content>
	<p>{{info}}</p>
	<mat-dialog-actions align="center">
		<button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
		<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	</mat-dialog-actions>
</div>
</div>