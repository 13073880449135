import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ui-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent {
  @Input() value = '';
  @Input() name = 'password';
  @Input() placeholder = 'password';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInputChange = new EventEmitter<any>();

  public constructor() { }

  onChange(event: any): void {
    this.onInputChange.emit({ name: this.name, value: event.target.value });
  }
}
