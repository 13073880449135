<ng-container *ngIf="formInitialized">
  <div class="edit-container">
    <form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
      <div class="column-input-2">
        <mat-form-field class="left-column">
          <input type="text" class="disabled" readonly matInput formControlName="serialNumber"
            [placeholder]="'car-edit-form.serial-number' | translate">
        </mat-form-field>
        <mat-form-field class="right-column">
          <input type="text" required matInput formControlName="description" [placeholder]="'car-edit-form.description' | translate">
        </mat-form-field>

      </div>
      <div class="column-input-2">
        <mat-form-field class="left-column">
          <input type="text" required matInput formControlName="plateNumber" [placeholder]="'car-edit-form.plate-number' | translate">
        </mat-form-field>

        <mat-form-field class="right-column">
          <input type="text" matInput formControlName="vin" [placeholder]="'car-edit-form.vin' | translate">
        </mat-form-field>
      </div>
      <div class="column-input-2">
        <mat-form-field class="left-column">
          <mat-label>{{ 'car-edit-form.color' | translate }}</mat-label>
          <mat-select formControlName="color">
            <mat-option *ngFor="let color of colors" [value]="color.nCarColorID">
              {{ color.keyVal | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="right-column" >
          <mat-label>{{ 'car-edit-form.fuel' | translate }}</mat-label>
          <mat-select formControlName="fuel">
            <mat-option *ngFor="let fuel of fuels" [value]="fuel.nFuelID">
              {{ fuel.keyVal | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="column-input-2">
        <mat-form-field class="left-column icon-select">
          <mat-label>{{ 'car-edit-form.body' | translate }}</mat-label>
          <mat-select formControlName="body">
            <mat-select-trigger>
              <div class="row v-center">
                <img [src]="form?.value?.body | bodyIcon: bodies" class="body-icon">
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <div>{{ (form?.value?.body | bodyName: bodies) | translate }}</div>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let body of bodies" [value]="body.nCarBodyID">
              <div class="row v-center">
                <img [src]="body | bodyIcon" class="body-icon">
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <div>{{ body.keyVal | translate }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="right-column">
          <input type="text" matInput formControlName="ccsCardNumber" [placeholder]="'car-edit-form.ccs-card-number' | translate">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <textarea matInput [placeholder]="'car-edit-form.note' | translate" formControlName="note" matTextareaAutosize
            matAutosizeMinRows=1 matAutosizeMaxRows=3>
        </textarea>
        </mat-form-field>
      </div>
      <div class="actions-block">
        <div class="cancel">
          <button (click)="handleCancel()" mat-raised-button
            type="button">{{ 'form-actions.back' | translate }}</button>
        </div>
        <div class="save" *ngxPermissionsOnly="['car-edit']">
          <button mat-raised-button color="primary" type="submit">{{ 'form-actions.save' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
