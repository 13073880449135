import { OnInit, OnDestroy, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDayStatsDTO } from 'src/app/api/models/dto/stats.dto';
import { StatsQuery } from 'src/app/store/stats/state/stats.query';

@Component({
  selector: 'app-stats-calendar-day',
  templateUrl: './stats-calendar-day.component.html',
  styleUrls: ['./stats-calendar-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsCalendarDayComponent implements OnInit, OnDestroy {

  private subs = new Subscription();

  public validCarStars = [];

  @Input()
  public stats: IDayStatsDTO;

  public constructor(
    public statsQuery: StatsQuery,
  ) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
