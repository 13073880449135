  <div mat-dialog-content class="title">
    {{ message | translate }}
  </div>
  <mat-divider></mat-divider>
<div class="confirm-dialog-container">
  <div mat-dialog-actions class="action-buttons">
    <button mat-button mat-raised-button [mat-dialog-close]="false">
      {{ 'confirm-modal.no' | translate }}
    </button>

    <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
      {{ 'confirm-modal.yes' | translate }}
    </button>
  </div>
</div>
