import { Pipe, PipeTransform } from '@angular/core';
import { ICarGroupDTO } from 'src/app/api/models/dto/car-groups.dto';

@Pipe({
  name: 'isGroupSelected',
  pure: true
})
export class IsGroupSelectedPipe implements PipeTransform {

  public transform(group: ICarGroupDTO, selectedCarIds: number[]): boolean {
    return group?.cars.map(car => (car.carID)).every(id => selectedCarIds?.includes(id));

  }
}
