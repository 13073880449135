import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../api/auth.service';
import { Router } from '@angular/router';
import { CarsSignalRService } from 'src/app/services/carsSignalR.service';
import { MatDialog } from '@angular/material';
import { ProfileComponent } from '../profile/profile.component';
import { Subscription } from 'rxjs';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { UrlRoutes } from 'src/app/app-routes';
import { UserHttpService } from 'src/app/api/user.service';
import { switchMap } from 'rxjs/operators';
import { ProfileService } from 'src/app/store/profile/state/profile.service';

@Component({
  selector: 'app-user-credentials',
  templateUrl: './user-credentials.component.html',
  styleUrls: ['./user-credentials.component.scss']
})
export class UserCredentialsComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  public constructor(
    private auth: AuthService,
    private router: Router,
    private signalRService: CarsSignalRService,
    private dialogService: MatDialog,
    private profileQuery: ProfileQuery,
    private userHttpService: UserHttpService,
    private profileService: ProfileService,
  ) { }

  public fullName: string;

  ngOnInit(): void {
    this.subs.add(this.profileQuery.profileFilled$.subscribe((filled) => {
      if (filled === false) {
        this.navigateToProfile();
      }
    }));

    this.subs.add(this.profileQuery.contactData$.subscribe((contactData) => {
      this.fullName = contactData?.fullName;
    }));
  }

  public get userText(): string {
    return this.auth.getUser().username.substring(0, 1).toLocaleUpperCase();
  }

  public get userFullUsername(): string {
    return this.auth.getUser().username;
  }

  public logout(): void {
    this.auth.logoutUser();
    this.signalRService.stopConnection();
    this.profileService.saveUrl(this.router.url);
    this.router.navigateByUrl(UrlRoutes.urls.loginUrl);
  }

  public get isAdmin(): boolean {
    return this.auth.isAdmin();
  }

  public navigateToAdministration(): void {
    this.router.navigateByUrl(UrlRoutes.adminUrls.dashboard);
  }

  public navigateToProfile(): void {
    const dialogRef = this.dialogService.open(ProfileComponent, {
      width: '600px',
      minHeight: '500px',
      position: {
        top: '50px',
      }
    });

    dialogRef.afterClosed().pipe(
      switchMap(x => this.userHttpService.fetchContactData())).subscribe(
        profile => {
          this.fullName = profile.fullName;
        });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
