import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../api/auth.service';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DURATION, VERTICAL_POSITION, HORIZONTAL_POSITION, CSS_CLASS } from '../constants/snackbar-constants';
import { UrlRoutes } from '../app-routes';
import { LoggerService } from '../services/logger.service';
import { CarsSignalRService } from '../services/carsSignalR.service';
import { ProfileService } from '../store/profile/state/profile.service';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  isUnauthorized = false;
  constructor(
    private snackbar: MatSnackBar,
    public authService: AuthService,
    private logger: LoggerService,
    private router: Router,
    private translateService: TranslateService,
    private signalRService: CarsSignalRService,
    private profileService: ProfileService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        this.isUnauthorized = true;
        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403 || err.status === 401) {
            let status = `error-${ err.status.toString() }`;
            if (req.url.toLowerCase().includes('/token')) {
              status = 'error-401-login';
            } else if (req.url.toLowerCase().includes('/grouphierarchy') && err.status === 403) {
              status = 'error-403-no-vehicles';
            }
            const msg = this.translateService.instant(status) as string;
            this.logger.logError('Authorization - an error occured: ', msg);
            this.snackbar.open(msg, 'x', {
              duration: DURATION,
              verticalPosition: VERTICAL_POSITION,
              horizontalPosition: HORIZONTAL_POSITION,
              panelClass: CSS_CLASS
            });

            if ((err.status === 401 || status === '403-no-vehicles') && this.isUnauthorized) {
              this.isUnauthorized = false;
              this.logger.logWarning('Authorization - doing logout');
              this.signalRService.stopConnection();
              this.authService.logoutUser();
              if (!this.router.url.includes('login')) {
                this.profileService.saveUrl(this.router.url);
              }
              this.router.navigate([UrlRoutes.urls.loginUrl]);
            }
          }
          return throwError(err);
        }
      }));
  }
}
