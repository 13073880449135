import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CarRemindersStore, CarRemindersState } from './car-reminders.store';

@Injectable({ providedIn: 'root' })
export class CarRemindersQuery extends Query<CarRemindersState> {

  public isLoadingReminders$ = this.select((state) => state.loadingReminders);

  public carID$ = this.select((state) => state.carID);

  public isLoading$ = this.select((state) => state.loadingReminders);

  public reminders$ = this.select((state) => state.reminders);

  public isNewReminder$ = this.select((state) => state.isStartEditing);

  public action$ = this.select((state) => state.action);

  public activeEditReminder$ = this.select((state) => state.activeEditReminder);

  public getAction() {
    return this.getValue().action;
  }

  public getEditReminder() {
    return this.getValue().activeEditReminder;
  }

  public constructor(protected store: CarRemindersStore) {
    super(store);
  }
}
