<ng-container *ngIf="personId !== -1">
<mat-card class="basic-card card-full-width card-mw-large">
  <div class="flexLayout">
    <span>
      <h3>{{ 'user.list' | translate }}</h3>
    </span>
    <span class="spacer"></span>
    <span class="actionButtons">
      <span>
        <button mat-icon-button data-test="refresh-button" (click)="refresh()" [matTooltip]="'form-actions.refresh' | translate">
          <mat-icon matListIcon>refresh</mat-icon>
        </button>
      </span>
      <span>
        <button mat-icon-button data-test="add-user" routerLink="/admin/users/add" [matTooltip]=" 'user.add' | translate">
          <mat-icon matListIcon>add</mat-icon>
        </button>
      </span>
    </span>

  </div>
  <br>
  <div class="table-container">
    <table mat-table [dataSource]="userList" matSort class="basic-table wide-table">
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> {{ 'user.username' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> {{ 'user.email' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> {{ 'user.user-group' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.userGroupName | translate }} </td>
      </ng-container>
      <ng-container matColumnDef="GDPR">
        <th mat-header-cell *matHeaderCellDef> {{ 'user.gdpr' | translate }}  
          <mat-icon [matTooltip]="'user.GDPR' | translate" style="margin-left: 5px;">info_outlined</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox disabled [checked]="element.gdpr" style="margin-left: 10px;">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'form-actions.actions' | translate }} </th>
        
        <td mat-cell *matCellDef="let element">
          <button [matMenuTriggerFor]="actions" mat-icon-button class="actions-button"
            [matTooltip]="'vehicles.vehicles-options' | translate" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #actions="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item (click)="goToDetail(element.id)">
              <mat-icon>info</mat-icon>
              <span>{{'Update' | translate}}</span>
            </button>
            <button mat-menu-item (click)="removeUser(element.id)">
              <mat-icon>delete_forever</mat-icon>
              <span>{{'Delete' | translate}}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'blocked-row': row.blocked == true}"
        (click)="goToDetail(row.id)"></tr>
    </table>
    <mat-progress-bar *ngIf="loadingData" mode="indeterminate" color="accent"></mat-progress-bar>
    <div *ngIf="userList === undefined || userList.length === 0"
      class="centered-info-secondary">
      <br>
      <p>{{ 'user-menu.no-data' | translate }}</p>
    </div>
  </div>
  <ng-container *ngIf="resultsLength > pageSize">
    <mat-paginator #paginator
      [length] = "resultsLength"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPageFired($event)">
    </mat-paginator>
  </ng-container>
</mat-card>
</ng-container>

<ng-container *ngIf="personId === -1">
  <mat-card class="basic-card card-mw-large warning-card">
    <ui-divider></ui-divider>
    <div>
        {{ 'admin.warning.person-id-not-set' | translate }}     
    </div>  
    <ui-divider></ui-divider>
  </mat-card>
</ng-container>

<app-admin-roles-description>
</app-admin-roles-description>