import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WeekDaysString, WeekDay } from 'src/app/api/models/dto/guardian.dto';

const KEY_PREFIX = 'weekday';

@Pipe({
  name: 'weekday',
  pure: false
})
export class WeekdayPipe implements PipeTransform {

  public constructor(
    private translateService: TranslateService
  ) {}

  public transform(value: number): string {

    switch (value) {
      case WeekDay.Monday:
        return this.translate(WeekDaysString.Monday);
      case WeekDay.Tuesday:
        return this.translate(WeekDaysString.Tuesday);
      case WeekDay.Wednesday:
        return this.translate(WeekDaysString.Wednesday);
      case WeekDay.Thursday:
        return this.translate(WeekDaysString.Thursday);
      case WeekDay.Friday:
        return this.translate(WeekDaysString.Friday);
      case WeekDay.Saturday:
        return this.translate(WeekDaysString.Saturday);
      case WeekDay.Sunday:
        return this.translate(WeekDaysString.Sunday);
      default:
        return '';
    }
  }

  private translate(weekday: string): string {
    return this.translateService.instant(`${KEY_PREFIX}.${weekday}`) as string;
  }

}
