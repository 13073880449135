<div class="background" [@background]="'in'" [ngStyle]="{'background-image': 'linear-gradient(to top, rgba(209, 209, 209, 0.315), rgb(255, 255, 255)),' +'url(' + backgroundImg + ')'}"></div>
<div class="container" [@fade]="'in'">
<div data-test="logo" class="logo-avatar">
  <a target="_blank" [href]="contactCompanyUrl">
    <img [src]="logoPath" alt="">
  </a>
</div>
  <router-outlet></router-outlet>
</div>
<p class="version">{{version}}</p>
