export class UserLoginInfo {
  username: string;
  permissions: IUserPermission[];
  token: string;
  role: string;
  id: number;
}

export interface IUserPermission {
  id: number;
  name: string;
}
