import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';

export interface IDriveBookDetailState {
   driveBookID: number | null;
   loadingDriveBook: boolean;
   driveBook: IDriveBookDTO | null;
}

export function createInitialState(): IDriveBookDetailState {
  return {
    driveBookID: null,
    loadingDriveBook: false,
    driveBook: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'drive-book-detail' })
export class DriveBookDetailStore extends Store<IDriveBookDetailState> {

  public constructor() {
    super(createInitialState());
  }

}
