<app-page-wrapper [isLoading]="isLoading" [hasSidePadding]="false" [hasTopPadding]="false">
  <ng-template appPageContent>
    <div>
      <mat-form-field class="search-form-field" [floatLabel]="'never'" color="primary" appearance="standard">
          <mat-icon class="transparent input-icon search-icon" matPrefix>search</mat-icon>
        <input  type="text"
                matInput
                autocomplete="off"
                [(ngModel)]="searchQuery"
                [placeholder]=" 'vehicles.search' | translate">

          <button type="button" class="clear-search" (click)="searchClear()" mat-button matSuffix mat-icon-button *ngIf="!!searchQuery">
          <mat-icon class="transparent input-icon">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <ng-container *ngxPermissionsOnly="['car-list', 'admin-car-list']">
    <app-car-group-row
      *ngFor="let group of carGroups"
      [group]="group"
      [isExpanded]="carGroups?.length < defaultMaxGroupsOpenedCount || searchQuery?.length > 1"
      [searchQuery]="searchQuery"
      [selectedCarIds]="selectedCarIds"
      [updateTriger]="updateTriger"
      (selectCar)="selectCar($event)"
      (selectGroup)="selectGroup($event)">
    </app-car-group-row>
  </ng-container>
  </ng-template>
</app-page-wrapper>
