import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ILoginUser } from './models/user/user-login';
import { Observable, of } from 'rxjs';
import { AddEditUser } from './models/user/user-add-edit';
import * as USER_ENDPOINTS from './endpoints/user.endpoints';
import * as AUTH_ENDPOINTS from './endpoints/auth.endpoints';
import {
  IUserInfoDTO,
  IUserContactDataRequest,
  IUserChangePassword,
  IUserSetChangePassword,
} from './models/user/user-info';
import { catchError } from 'rxjs/operators';
import { DoNotShowError } from '../constants/error-constants';
import { NotifyService } from '../services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { Paginated } from './models/pagination.model';
import { UrlsService } from './urls.service';
import { AppConfigService } from '../services/appConfig.service';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
              private notifyService: NotifyService,
              private translateService: TranslateService,
              private urls: UrlsService,
              private appConfigService: AppConfigService) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
  }

  public loadAuthData(token): Observable<IUserInfoDTO> {
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${ token }`);
    return this.http.get<IUserInfoDTO>(AUTH_ENDPOINTS.authDataUrl(this.appConfigService.configuration.API_URL), { headers });
  }
  public loginUser(payload: ILoginUser): any {
    return this.http.post(AUTH_ENDPOINTS.loginUserUrl(this.appConfigService.configuration.API_URL), payload, { headers: this.headers });
  }

  public refreshToken(): any {
    return this.http.post(AUTH_ENDPOINTS.refreshTokenUrl(this.appConfigService.configuration.API_URL), { headers: this.headers });
  }

  public sendForgotPassword(username: string): any {
    return this.http.post(
      AUTH_ENDPOINTS.forgotPasswordUrl(this.appConfigService.configuration.API_URL), { username }, { headers: this.headers }
    );
  }

  public setForgotPassword(payload: IUserSetChangePassword): any {
    return this.http.post(
      AUTH_ENDPOINTS.setForgotPasswordUrl(this.appConfigService.configuration.API_URL), payload, { headers: this.headers }
    );
  }

  public sendContactData(payload: IUserContactDataRequest): any {
    return this.http.post(
      AUTH_ENDPOINTS.sendContactDataUrl(this.appConfigService.configuration.API_URL), payload, { headers: this.headers }
    );
  }

  public fetchContactData(): Observable<IUserInfoDTO> {
    return this.http.get<IUserInfoDTO>(AUTH_ENDPOINTS.authDataUrl(this.appConfigService.configuration.API_URL), { headers: this.headers });
  }

  public sendChangePassword(payload: IUserChangePassword): any {
    return this.http.post(
      AUTH_ENDPOINTS.sendChangePasswordUrl(this.appConfigService.configuration.API_URL), payload, { headers: this.headers }
    );
  }

  public getWorkspaceByBaseUrl(url: string): Observable<any> {
    return this.http.get(this.urls.getWorkspaceByBaseUrl(url), { headers: this.headers });
  }

  getUserListByLoggedUser(id: number, page?: Paginated): Observable<any> {
    return this.http.post(
      USER_ENDPOINTS.GetAllUsersByLoggedUser(this.appConfigService.configuration.API_URL, id), page, { headers: this.headers }
    );
  }

  getUser(id?: number): Observable<any> {
    return this.http.get(USER_ENDPOINTS.getUserUrl(this.appConfigService.configuration.API_URL, id), { headers: this.headers });
  }

  getAllUserGroups(): Observable<any> {
    return this.http.get(USER_ENDPOINTS.getAllUserGroups(this.appConfigService.configuration.API_URL), { headers: this.headers });
  }

  editUser(userId: number, user: AddEditUser) {
    return this.http.put(USER_ENDPOINTS.updateUser(this.appConfigService.configuration.API_URL, userId), user, { headers: this.headers });
  }

  addUser(user: AddEditUser) {
    return this.http.post(USER_ENDPOINTS.addUser(this.appConfigService.configuration.API_URL), user, { headers: this.headers }).pipe(
      catchError((err) => {
        if (err.error.detail === DoNotShowError) {
          this.notifyService.showError(this.translateService.instant('user.duplicite-user-error'));
        }
        return of(undefined);
      }
      ));
  }

  deleteUser(userId: number) {
    return this.http.delete(USER_ENDPOINTS.deleteUser(this.appConfigService.configuration.API_URL, userId), { headers: this.headers });
  }
}
