<ng-container *ngIf="dataSource.data.length > 0">
<div class="area-info">
    <ng-container>
      <table mat-table [dataSource]="dataSource" class="area-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.area-name" | translate }}
          </th>
          <td mat-cell *matCellDef="let areaData">{{ areaData?.areaName}}</td>
        </ng-container>

        <ng-container matColumnDef="restrictionType">
            <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.vehicle-area-type" | translate }}
            </th>
            <td mat-cell *matCellDef="let areaData">               
                 <ng-container [ngSwitch]="areaData?.restrictionType">
                <div *ngSwitchCase="0">
                  {{'area-control.not-set' | translate }}
                </div>
                <div *ngSwitchCase="1">
                  {{'area-control.forbidden-area' | translate }}
                </div>
                <div *ngSwitchCase="2">
                  {{'area-control.allowed-area' | translate }}
                </div>
              </ng-container></td>
        </ng-container>
        <ng-container matColumnDef="notifications">
            <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.notifications" | translate }}
            </th>
            <td mat-cell *matCellDef="let areaData">{{ areaData?.email }} {{ areaData?.sms }} </td>
          </ng-container>
        <ng-container matColumnDef="showOnMap">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.show-on-map" | translate }}
          </th>
          <td mat-cell *matCellDef="let area">       
            <div class="show-on-map">
              <mat-icon class="transparent" [ngClass]="isRestrictionMet(area) ? 'inArea' : 'outOfArea'"  
                (click)="displayCar()" (click)="$event.preventDefault()" (click)="$event.stopPropagation()">
                  gps_fixed
                </mat-icon>
              <span class="tooltipText" *ngIf="!!area.inArea"> {{'area-control.vehicle-position-in-desc' | translate }}</span>
              <span class="tooltipText" *ngIf="!area.inArea"> {{'area-control.vehicle-position-out-desc' | translate }}</span>
            </div> 
        </td>
        </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell class="wrapper" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let area">
              <ng-container *ngxPermissionsOnly="['areas', 'car-area-control-edit']">  
                <div class="action-buttons">
                    <mat-icon class="transparent" (click)="onAreaVehicleEdit(area)"
                    (click)="$event.preventDefault()" (click)="$event.stopPropagation();">
                      edit
                </mat-icon>
                </div>
              </ng-container>
            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowShowOnMap(row)"></tr>
      </table>
    </ng-container>
  </div>
</ng-container>

  <ui-divider></ui-divider>
  <button type="button" mat-button  mat-raised-button  color="primary" (click)="onAssignArea()">
    {{ 'area-control-tab.assign-area' | translate }}
  </button>
