import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { PageContentDirective } from '../directives/page-content.directive';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageWrapperComponent {

  /* tslint:disable */
  private _templateRef: TemplateRef<any>;
  private _loading: boolean;
  private _hasFullHeight: boolean;

  @Input() hasSidePadding = false;
  @Input() hasTopPadding = false;
  @Input() whiteBackground = false;
  /* tslint:enable */

  @ContentChild(PageContentDirective)
  public set content(pageContent: PageContentDirective) {
    this._templateRef = !pageContent ? null : pageContent.templateRef;
    this.cdr.markForCheck();
  }

  @Input()
  public set isLoading(loading: boolean) {
    this._loading = loading;
    this.cdr.markForCheck();
  }

  @Input()
  public set fullHeight(hasFullHeight: boolean) {
    this._hasFullHeight = hasFullHeight;
    this.cdr.markForCheck();
  }

  public get templateRef(): TemplateRef<any> {
    return this._templateRef;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get hasFullHeight(): boolean {
    return this._hasFullHeight;
  }

  public constructor(
    private cdr: ChangeDetectorRef
  ) { }

}
