import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appPageContent]'
})
export class PageContentDirective {

  public constructor(
    public templateRef: TemplateRef<any>
  ) {}

}
