<mat-card class="login-form-container">
  <mat-progress-bar *ngIf="loadingData" mode="indeterminate" color="accent"></mat-progress-bar>
  <div class="flexLayout headerContainer">
    <span>
      <span class="main-form-header"> {{ 'login' | translate }} </span>
    </span>
    <span class="spacer"></span>
    <span>
      <span class="">
        <button mat-button [matMenuTriggerFor]="langSelector" matTooltip="{{ 'language' | translate }}" data-test="switch-language-button">
          {{ selectedLanguage | uppercase }}
          <mat-icon class="transparent">language</mat-icon>
        </button>
        <mat-menu #langSelector="matMenu" x-position="after" y-position="below">
            <button mat-menu-item (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
            <button mat-menu-item (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
            <button mat-menu-item (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
            <button *ngIf="showTranslationKeys" mat-menu-item (click)="changeLanguage('de')">{{ 'translation-keys' | translate }}</button>
        </mat-menu>
      </span>
    </span>
  </div>
  <form [formGroup]="form" data-test="login-form" class="form" (ngSubmit)="login()">
    <mat-form-field class="full-width">
      <input type="text" matInput formControlName="username" data-test="username" [placeholder]="'user.username' | translate">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput [placeholder]="'password' | translate" #p1 formControlName="password" data-test="password" type="password">
      <button type="button" data-test="show-password-icon" tabindex="-1" mat-button matSuffix mat-icon-button
        [matTooltip]="(p1.type === 'password' ? 'show-password' : 'hide-password') | translate"
        (click)="p1.type === 'password' ? p1.type ='text' : p1.type='password'">
        <mat-icon>{{ p1.type === 'text'? 'lock' : 'lock_open'}}</mat-icon>
      </button>
    </mat-form-field>
    <button mat-button type="button" data-test="forgot-password-button" class="main-button" (click)="onForgotPassword()">{{ 'login.forgot-password' | translate }}</button>
    <ui-divider></ui-divider>
    <p *ngIf="errorLogin" class="error-label centered">{{ 'error-login-label' | translate }}</p>
    <button mat-raised-button type="submit" data-test="login-submit-button" color="primary" class="main-button"
      [disabled]="loadingData || (form.invalid && form.touched)">{{ 'login' | translate }}</button>
  </form>
</mat-card>
