import { Injectable } from '@angular/core';
import { ICarGuardianStore } from './car-guardian.store';
import { CarGuardianHttpService } from 'src/app/api/car-guardian-http.service';
import { IGuardian } from 'src/app/api/models/dto/guardian.dto';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CarGuardianService {

  public constructor(
    public carGuardianStore: ICarGuardianStore,
    private carGuardianHttp: CarGuardianHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public setIsLoading(loading: boolean): void {
    this.carGuardianStore.update({ loading });
  }

  public fetchCarGuardian(carId: number) {
    this.setIsLoading(true);
    this.carGuardianHttp.fetchCarGuardian(carId).subscribe(guardian => {
      this.carGuardianStore.update({ guardian });
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public clearStore(): void {
    this.carGuardianStore.update({
      guardian: null,
      loading: false
    });
  }

  public createSchedule(carID: number, schedule: IGuardian, callback: () => void) {
    this.setIsLoading(true);
    this.carGuardianHttp.createCarGuardian(carID, schedule).subscribe(x => {
      this.notifyService.showInfo(this.translateService.instant('guardian.saved'));
      this.setIsLoading(false);
      this.fetchCarGuardian(carID);
      callback();
    }, error => {
      if (error.error.errors.PhoneNumber) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      } else {
        this.setIsLoading(false);
        this.notifyService.showError(this.translateService.instant('guardian.not-saved'));
      }
    });
  }

  public updateSchedule(carId: number, guardianId: number, schedule: IGuardian, callback: () => void) {
    this.setIsLoading(true);
    if (!schedule.phoneNumber && !schedule.email && schedule.active) {
      this.notifyService.showError(this.translateService.instant('guardian.phone-email-missing'));
      return;
    }
    this.carGuardianHttp.updateCarGuardian(guardianId, schedule).subscribe(x => {
      this.notifyService.showInfo(this.translateService.instant('guardian.saved'));
      this.setIsLoading(false);
      this.fetchCarGuardian(carId);
      callback();
    }, error => {
      if (error.error.errors.PhoneNumber) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      } else {
        this.setIsLoading(false);
        this.notifyService.showError(this.translateService.instant('guardian.not-saved'));
      }
    });
  }

  public initArray(days, hours): any {
    const array = [];
    for (let d = 0; d < days?.length; d++) {
      array.push([]);
      for (const h of hours) {
        array[d].push(false);
      }
    }
    return array;
  }
}
