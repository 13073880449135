import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IReminderDTO } from '../../../api/models/dto/reminders.dto';

export interface CarRemindersState {
   carID: number | null;
   loadingReminders: boolean;
   reminders: IReminderDTO[];
   isStartEditing?: boolean;
   action?: string;
   activeEditReminder: IReminderDTO;
}

export function createInitialState(): CarRemindersState {
  return {
    carID: null,
    loadingReminders: false,
    reminders: [],
    isStartEditing: false,
    action: '',
    activeEditReminder: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'car-reminders' })
export class CarRemindersStore extends Store<CarRemindersState> {

  public constructor() {
    super(createInitialState());
  }

}
