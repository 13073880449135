import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { MapService } from 'src/app/store/map/state/map.service';
import { ICarDTO, ICarPositionExtra } from '../../../api/models/dto/car-groups.dto';
import { CarRemindersService } from 'src/app/store/car-reminders/state/car-reminders.service';
import { VehicleStateEnum } from 'src/app/api/models/car.model';
import { UrlRoutes } from 'src/app/app-routes';
import { IUpcomingReminderDTO } from 'src/app/api/models/dto/reminders.dto';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';
import { NavigationQuery } from 'src/app/store/navigation/state/navigation.query';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';
import { CarMovementEditDialogComponent } from 'src/app/pages/car-movement-dialog/car-movement-edit-dialog.component';

export enum RemindersEnum {
  'REMINDER_NONE' = 0,
  'REMINDER_OIL' = 1,
  'REMINDER_STK' = 2
}
@Component({
  selector: 'app-car-row',
  templateUrl: './car-row.component.html',
  styleUrls: ['./car-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class CarRowComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  currentPosition: ICarPositionExtra;
  @Input() updateTriger: number;
  @Input() car: ICarDTO;
  @Input() isSelected: boolean;
  vehicleStates = VehicleStateEnum;
  showContentPanel: boolean;

  public constructor(
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private currentVehiclePosition: CarsPositionQuery,
    private mapService: MapService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private carRemindersService: CarRemindersService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.currentVehiclePosition.carsPosition$.subscribe((data) => {
      if (!!data) {
        this.currentPosition = data.find(p => p.carId === this.car.carID);
        this.changeDetector.detectChanges();
      }
    }));
    this.subscriptions.add(this.navigationQuery.showContentPanel$.subscribe((data) => {
      this.showContentPanel = data;
      this.changeDetector.detectChanges();
    }));
  }

  public navigateToRidesBook(): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/drive-book`;
    const path = `${ basePath }/${ this.car.carID }`;

    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public navigateToCarDetail(): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-detail`;
    const path = `${ basePath }/${ this.car.carID }`;

    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public navigateToCarEdit(): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-detail`;
    const path = `${ basePath }/${ this.car.carID }/edit`;

    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public navigateToCarReminders(): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-reminders`;
    const path = `${ basePath }/${ this.car.carID }`;

    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public navigateToReminderDetail(reminder: IUpcomingReminderDTO): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-reminders`;
    const path = `${ basePath }/${ this.car.carID }`;
    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public switchMovement(): void {
    this.dialog.open(CarMovementEditDialogComponent, { data: this.car, minWidth: 550 });
  }

  public getClass(activeReminders: IUpcomingReminderDTO[]): string {
    let maxLevel = -1;
    activeReminders.forEach(reminder => {
      if (reminder.level > maxLevel) {
        maxLevel = reminder.level;
      }
    });
    switch (maxLevel) {
      case 2:
        return 'blinking';
      case 1:
        return 'alarm';
      case 0:
        return 'warning';
      case -1:
        return 'none';
    }
  }

  public setActiveHoverVehicle(isActive: boolean): void {
    if (isActive) {
      this.mapService.setFocusOnVehicle(this.car.carID);
    } else {
      this.mapService.setFocusOnVehicle(null);
    }
  }

  public resolveRemider(reminder: IUpcomingReminderDTO) {
    this.carRemindersService.getReminder(reminder.reminderId).subscribe(
      result => {
        this.carRemindersService.solveReminder(result, reminder, this.car.spz, this.car.toolTipText);
      }
    );
  }

  openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  getReminderName(type: number) {
    return RemindersEnum[type];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
