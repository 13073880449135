import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { IAreaControlData, IAreaControlEditRequest, IAreaControlPathPoint } from 'src/app/api/models/dto/area-control.dto';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UrlRoutes } from 'src/app/app-routes';

const PAGE_TITLE = 'page-title.area-control-edit';

@Component({
  selector: 'app-area-control-edit-page',
  templateUrl: './area-control-edit-page.component.html',
  styleUrls: ['./area-control-edit-page.component.scss']
})
export class AreaControlEditPageComponent implements OnInit, OnDestroy {

  public selectedPolygon: IAreaControlData;
  public areaColor: string;
  public areaPoints: IAreaControlPathPoint[];
  public activeAreaId: number;
  public isLoading: boolean;
  public form: FormGroup;
  public areaPolygons: IAreaControlData[];
  private subs = new Subscription();

  public constructor(
    private navigationState: NavigationService,
    private areaControlService: AreaControlService,
    private navigationService: NavigationService,
    public readonly areaControlQuery: AreaControlQuery,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private utilityService: UtilitiesService,
  ) { }

  public ngOnInit(): void {
    this.subs.add(this.route.url.subscribe((data) => {
      this.activeAreaId = parseInt(this.route.snapshot.paramMap.get('area_id'), 10);
    }));

    this.subs.add(this.areaControlQuery.areaPolygons$.subscribe((polygons) => {
      this.areaPolygons = polygons;
      if (!!this.activeAreaId && !!polygons && polygons.length > 0) {
        const polygon = polygons.find(p => p.id === this.activeAreaId);
        if (!!polygon) {
          this.areaControlService.setSelectedPolygon(polygon);
          this.areaControlService.setEditMode(true);
        } else {
          this.router.navigate([`/dashboard/area-control`]);
        }
      }
    }));

    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((polygon) => {
      if (!polygon) {
        this.areaControlService.fetchUserAreas();
        return;
      }
      this.selectedPolygon = polygon;
      this.areaColor = this.selectedPolygon.color;
      this.areaControlService.setSelectedColor(this.areaColor);
      this.areaControlService.seteditablePoints(this.selectedPolygon.points);
    }));
    this.navigationState.initPage({
      activeMenuItem: ActiveMenuItem.AreaControl,
      pageTitle: PAGE_TITLE,
      backUrl: this.utilityService.format(UrlRoutes.urls.areaControl.area, this.activeAreaId),
      panelWidth: 500
    });
    this.subs.add(this.areaControlQuery.editablePoints$.subscribe((points) => {
      this.areaPoints = points;
    }));

    this.subs.add(this.areaControlQuery.isEditMode$.subscribe((isEditMode) => {
      if (!isEditMode) {
        this.router.navigate([`/dashboard/area-control`]);
      }
    }));

    this.subs.add(this.areaControlQuery.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    }));
    this.navigationService.showContentPanel(this.utilityService.format(UrlRoutes.urls.areaControl.edit, this.activeAreaId));

    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    const forbiddenNames = this.areaPolygons
      .filter(area => area.id !== this.activeAreaId && area.name !== this.selectedPolygon.name)
      .map(filteredAreas => (filteredAreas.name));

    return this.fb.group({
      areaName: new FormControl(this.selectedPolygon?.name, [Validators.required, this.nameExists(forbiddenNames)]),
      areaColor: new FormControl(this.selectedPolygon?.color, Validators.required),
    });
  }

  colorChanged(): void {
    this.form.patchValue({ areaColor: this.areaColor });
    this.areaControlService.setSelectedColor(this.areaColor);
  }

  savePolygon(): void {
    const request: IAreaControlEditRequest = {
      color: this.form.value.areaColor,
      name: this.form.value.areaName,
      id: this.selectedPolygon.id,
      points: this.areaPoints.map(point => ({ order: point.order, latitude: point.lat, longitude: point.lng })),
      type: this.selectedPolygon.type,
    };
    this.areaControlService.setAreaPolygons(null);
    this.areaControlService.setSelectedPolygon(null);
    this.areaControlService.editArea(this.selectedPolygon.id, request);
  }

  public ngOnDestroy(): void {
    this.areaControlService.setEditMode(false);
    this.subs.unsubscribe();
  }

  nameExists(forbiddenValues: string[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (forbiddenValues.indexOf(c.value) !== -1) {
        return { forbiddenValues: true };
      }
      return null;
    };
  }

  public goBack() {
    this.router.navigate([`/dashboard/area-control`]);
  }
}
