import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserHttpService } from 'src/app/api/user.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';
import { Paginated } from 'src/app/api/models/pagination.model';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit, AfterViewInit, OnDestroy {

  public loadingData = true;
  public UserId;
  public personId;

  public userList = [];
  public displayedColumns: string[] = ['username', 'email', 'role', 'GDPR', 'actions'];
  private subs = new Subscription();

  public pageSizeOptions = [ 10, 20, 50, 100];
  public pageSize = 20;
  public currentPage = 0;
  public resultsLength = 0;
  public paginatedObject: Paginated = {
      pageIndex: this.currentPage,
      pageSize: this.pageSize
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private apiService: UserHttpService,
    private router: Router,
    public readonly profileQuery: ProfileQuery,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.subs.add(this.profileQuery.contactData$.subscribe(data => {
      if (!!data) {
        this.personId = data.personId;
        if (this.personId === -1) {
          this.loadingData = false;
          return;
        }
        this.UserId = data.id;
        this.getUsers();
      }
    }));
  }

  goToDetail(id) {
    this.router.navigate(['/admin/users/edit/' + id]);
  }

  private openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  removeUser(id: number) {
    this.openConfirmDialog('user.confirm-delete').afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteUser(id).subscribe(response =>
          this.getUsers()
        );
      }
    });
  }

  refresh() {
    this.getUsers();
  }

  getUsers() {
    this.loadingData = true;
    this.apiService.getUserListByLoggedUser(this.UserId, this.paginatedObject).subscribe(data => {
        this.resultsLength = data.totalCount;
        this.userList = data.users.filter(item => item.id !== this.UserId);
        this.loadingData = false;
      });
  }

  onPageFired(event) {
    this.paginatedObject = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    };
    this.apiService.getUserListByLoggedUser(this.UserId, this.paginatedObject).subscribe((data) => {
        this.userList = data.users; }
      );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
