import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAreaControlData, AreaRestrictionType, IAreaVehicle } from 'src/app/api/models/dto/area-control.dto';
import { ICarDTO, ICarPositionExtra } from 'src/app/api/models/dto/car-groups.dto';
import { MatTableDataSource, MatDialog, MatDialogRef } from '@angular/material';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IMapPoint } from 'src/app/store/map/state/map.store';
import { UrlRoutes } from 'src/app/app-routes';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-area-control-list',
  templateUrl: './area-control-list.component.html',
  styleUrls: ['./area-control-list.component.scss']
})
export class AreaControlListComponent implements OnInit {

  private subs = new Subscription();
  public selectedPolygon: IAreaControlData;
  public vehicles: ICarDTO[];
  public vehiclesPositions: ICarPositionExtra[];
  public isLoading = false;

  @Input()
  public areas: IAreaControlData[];

  displayedColumns: string[] = ['color', 'name', 'showOnMap', 'actions'];
  dataSource = new MatTableDataSource();

  public constructor(
    private areaControlService: AreaControlService,
    private carsQuery: CarsQuery,
    private areaControlQuery: AreaControlQuery,
    private carsPositionQuery: CarsPositionQuery,
    private dialog: MatDialog,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private utilityService: UtilitiesService,
  ) { }

  ngOnInit(): void {
    this.subs.add(this.areaControlQuery.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    }));

    this.subs.add(this.carsQuery.carGroups$.subscribe((groups) => {
      this.vehicles = !!groups ? groups.reduce((accumulator, obj) => [...accumulator, ...obj.cars], []) : [];
      if (this.vehicles.length > 0) {
        this.proccessSelectedArea(this.selectedPolygon);
      }
    }));
    this.subs.add(this.carsPositionQuery.carsPosition$.subscribe((positions) => {
      this.vehiclesPositions = positions;
      if (!!this.vehiclesPositions && this.vehiclesPositions.length > 0) {
        this.proccessSelectedArea(this.selectedPolygon);
      }
    }));
    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((polygon) => {
      this.proccessSelectedArea(polygon);
    }));
  }

  public async onNewArea(): Promise<void> {
    await this.router.navigateByUrl(`/dashboard/area-control/add`);
  }

  public async onAreaSelect(area: IAreaControlData): Promise<void> {
    if (!area) {
      return;
    }
    if (!!this.selectedPolygon && this.selectedPolygon?.id === area?.id) {
      this.areaControlService.setSelectedPolygon(null);
    } else {
      this.areaControlService.setSelectedPolygon(area);
    }
  }

  public async onAreaDetail(area: IAreaControlData): Promise<void> {
    if (!area) {
      return;
    }
    await this.router.navigateByUrl(this.utilityService.format(UrlRoutes.urls.areaControl.area, area.id));
  }

  public proccessSelectedArea(area: IAreaControlData): void {
    this.selectedPolygon = area;
    if (!area || !this.vehiclesPositions || this.vehiclesPositions.length === 0 || !this.vehicles) {
      return;
    }
    const polygonPath: IMapPoint[] = area.points.map(p => ({ latitude: p.lat, longitude: p.lng }));
    const tableData: IAreaVehicle[] = this.vehicles.map((vehicle) => {
      const areaVehicle = !!area.vehicles ? area.vehicles.find(v => v.vehicleId === vehicle.carID) : null;
      const vehiclePosition = this.vehiclesPositions.find(v => v.carId === vehicle.carID);

      return {
        vehicleName: vehicle.spz,
        vehicleDescription: vehicle.toolTipText,
        vehicleId: vehicle.carID,
        sendNotifications: !!areaVehicle ? areaVehicle.sendNotifications : false,
        type: !!areaVehicle ? areaVehicle.type : AreaRestrictionType.NOT_SET,
        inArea: this.utilityService.isInAreaPolygon(vehiclePosition, polygonPath),
        notificationEmail: !!areaVehicle ? areaVehicle.notificationEmail : null,
        notificationPhone: !!areaVehicle ? areaVehicle.notificationPhone : null,
        editable: false,
      };
    });
    this.dataSource.data = tableData;
    this.changeDetector.detectChanges();
  }

  public handleDeleteArea(area: IAreaControlData): void {
    if (!area) {
      return;
    }
    this.openConfirmDialog('area-control.confirm-delete').afterClosed().subscribe((result) => {
      if (result) {
        this.areaControlService.setSelectedPolygon(null);
        this.areaControlService.deleteArea(area.id);
      }
    });
  }

  public async onAreaEdit(area: IAreaControlData): Promise<void> {
    await this.router.navigateByUrl(`/dashboard/area-control/${area.id}/edit`);
  }

  private openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

}
