import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ICarGuardianStore, ICarGuardianState } from './car-guardian.store';

@Injectable({ providedIn: 'root' })
export class ICarGuardianQuery extends Query<ICarGuardianState> {

  public isLoading$ = this.select((state) => state.loading);

  public guardian$ = this.select((state) => state.guardian);

  public constructor(protected store: ICarGuardianStore) {
    super(store);
  }
}
