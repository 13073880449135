import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { ICarDriverDTO, ICarDriversDTO } from 'src/app/api/models/dto/car-driver.dto';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { MapService } from 'src/app/store/map/state/map.service';
import { DriveBookQuery } from '../../../store/drive-book/state/drive-book.query';
import { DriveBookService } from '../../../store/drive-book/state/drive-book.service';
import { DriveTypeString, IDriveBookFilter } from '../../../store/drive-book/state/drive-book.store';
import { CUSTOM_FILTERS } from '../custom-filter/filters/list';
import { DateInterval } from '../custom-filter/filters/types';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  @Input()
  public forExport: boolean;

  public filter: IDriveBookFilter;

  public filterForm = this.createForm();

  private subs = new Subscription();

  public customFilters = Object.values(CUSTOM_FILTERS);

  public isExpanded = false;

  public isCarSelected = false;

  public drivers: ICarDriversDTO;

  public selectedDriverId: number;

  public emptyDriver: ICarDriverDTO = {
    strName: null,
    strSurname: 'drive-book.edit-form.drive.no-driver',
    nPersonID: 0,
    nCarID: null
  };

  public readonly driveTypes = [
    DriveTypeString.All,
    DriveTypeString.Business,
    DriveTypeString.Private
  ];

  public constructor(
    private fb: FormBuilder,
    private driveBookState: DriveBookService,
    private driveBookQuery: DriveBookQuery,
    private carsQuery: CarsQuery,
    private mapState: MapService,
    private carsState: CarsService,
    private carsHttp: CarsHttpService
  ) { }

  public ngOnInit(): void {
    this.drivers = {
      carId: 0,
      drivers: {
        drivers: [this.emptyDriver]
      }
    };

    this.subs.add(
      this.driveBookQuery.selectedCarID$.subscribe((carId) => {
        if (carId !== null) {
          this.setDrivers(carId);
          this.updateDriver(0);
        } else {
          this.filterForm = this.createForm();
        }}
      ));

    this.subs.add(this.carsQuery.allCars$.subscribe(cars => {
      if (!this.isCarSelected && cars.length > 0) {
        this.carsState.clearSelectedCarIDs();
        this.driveBookState.removeAllDrivebooks();
        this.driveBookState.removeTotals();
        this.driveBookState.updateDriveBookData();
      }
    })
    );

    this.subs.add(
      this.driveBookQuery.filter$.subscribe((filter) => {
        this.filter = filter;
        this.filterForm = this.createForm(filter.intervalFrom, filter.intervalTo, filter.type, !!filter.driver ? filter.driver : 0);
        this.filterForm.valueChanges.pipe(
          map((values) => ({
            intervalFrom: !values.intervalFrom ? moment(new Date()) : values.intervalFrom,
            intervalTo: !values.intervalTo ? moment(new Date()) : values.intervalTo,
            type: !values.type ? DriveTypeString.All : values.type as DriveTypeString,
            driver: values.driver
          })),
          map((values) => ({
            ...values,
            intervalFrom: (!(values.intervalFrom instanceof Date) ? moment(values.intervalFrom.toDate()) : values.intervalFrom) as Date,
            intervalTo: (!(values.intervalTo instanceof Date) ? moment(values.intervalTo.toDate()) : values.intervalTo) as Date
          }))
        ).subscribe((values) => {
          this.driveBookState.updateFilter(values);
        });
      })
    );

    this.subs.add(
      this.driveBookQuery.isCarSelected$.subscribe((isSelected) => {
        this.isCarSelected = isSelected;
      }));

    this.subs.add(
      this.driveBookQuery.selectedCarID$.subscribe(() => {
        this.toogleExpanded(true);
      }));

    this.subs.add(this.driveBookQuery.driveBooks$.subscribe(result => {
      if (result?.length > 0) {
        this.toogleExpanded(false);
      }
    }));

    this.subs.add(
      this.filterForm.controls.driver.valueChanges.subscribe(value => {
        this.selectedDriverId = value;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public setDrivers(carId: number) {
    this.carsHttp.fetchDriversByCarId(carId).subscribe(drivers => {
      this.drivers.drivers.drivers = [this.emptyDriver, ...drivers.drivers.drivers];
    });
  }

  private createForm(filterFrom = new Date(), filterTo = new Date(), type = DriveTypeString.All, driver: number = 0): FormGroup {
    const form = this.fb.group({
      intervalFrom: [filterFrom, Validators.required],
      intervalTo: [filterTo, Validators.required],
      type: [type, Validators.required],
      driver: [driver]
    });

    return form;
  }

  public driveTypeNameKey(driveType: DriveTypeString): string {
    return `drive-book.totals.${driveType.toLocaleLowerCase()}`;
  }

  public applyCustomFilter(interval: DateInterval): void {
    const [filterFrom, filterTo] = interval;

    this.driveBookState.updateFilter({
      intervalFrom: filterFrom,
      intervalTo: filterTo
    });
  }

  public submitSearchForm(e: Event): void {
    this.mapState.clearPoints();
    this.carsState.clearSelectedCarIDs();
    this.driveBookState.removeAllDrivebooks();
    this.driveBookState.removeTotals();
    this.driveBookState.updateDriveBookData();
  }

  public toogleExpanded(isExpanded: boolean): void {
    this.isExpanded = isExpanded;
  }

  public selectDate(dateEvent: MatDatepickerInputEvent<Moment>, from: boolean): void {
    const dateMoment = dateEvent.value as Moment;
    const date = dateMoment.local().toDate();
    if (from) {
      const intervalTo = this.filterForm.value.intervalTo;
      if (date > intervalTo) {
        this.driveBookState.updateFilter({ intervalTo: date });
      }
      this.driveBookState.updateFilter({ intervalFrom: date });
    } else {
      this.driveBookState.updateFilter({ intervalTo: date });
    }
  }

  public updateDriver(driverId: number) {
    this.filterForm.patchValue({
      driver: driverId
    });
    this.driveBookState.updateFilter({ driver: driverId });
    this.selectedDriverId = driverId;
  }

  public getDriverById(): string {
    const id = this.driveBookQuery.filter.driver;
    if (id > 0) {
      return this.drivers.drivers.drivers.find(driver => driver.nPersonID === id).strSurname;
    }
    return this.emptyDriver.strSurname;
  }

  get showType(): boolean {
    return this.filterForm.value.type !== 'all';
  }

  get showDriver(): boolean {
    return this.driveBookQuery.filter.driver > 0;
  }
}
