import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UrlsService } from './urls.service';
import { IDriveBookDayDTO, IDriveBookDTO, IDriveBooksDTO, IDriveBookTotalsDTO } from './models/dto/drive-books.dto';
import { IDriveBookPathDTO } from './models/dto/drive-book-path.dto';
import { IDriveBookDetailDTO } from './models/dto/drive-book-detail.dto';
import { IPutDriveBookDTO, INewDriveBookDTO } from './models/dto/put-drive-book.dto';
import * as moment from 'moment';
import { NotifyService } from '../services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { DoNotShowError } from '../constants/error-constants';
import { IDriveBookLockRequest, IDriveBookMultiplyEditRequest } from '../store/drive-book/state/drive-book.store';

@Injectable()
export class DriveBookHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public fetchDriveBooksAndTotals(
    carID: number,
    intervalFrom = new Date(),
    intervalTo = new Date(),
    driveType: number,
    driverID: number
  ): Observable<[IDriveBookTotalsDTO, IDriveBookDayDTO[]]> {
    const from = new Date(intervalFrom.getTime());
    const to = new Date(intervalTo.getTime());
    const body = {
      from: moment(from).startOf('day').format(),
      to: moment(to).endOf('day').format(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: driveType,
      driver: driverID ? driverID : 0
    };
    return this.http.post<IDriveBooksDTO>(this.urls.fetchDriveBooks(carID), body).pipe(
      switchMap((response) => forkJoin([
        of(response).pipe(
          map((data) => ({
            businessDistance: data.businessDistance,
            businessDuration: data.businessDuration,
            businessNumberOfTrips: data.businessNumberOfTrips,
            privateDistance: data.privateDistance,
            privateDuration: data.privateDuration,
            privateNumberOfTrips: data.privateNumberOfTrips,
            totalDistance: data.totalDistance,
            totalDuration: data.totalDuration,
            totalNumberOfTrips: data.totalNumberOfTrips
          } as IDriveBookTotalsDTO))
        ),
        of(response.driveBooks.map(item => ({ ...item, selectAllIsChecked: false })))
      ]))
    );
  }

  public fetchDriveBookPaths(driveBookID: number): Observable<IDriveBookPathDTO> {
    return this.http.get<IDriveBookPathDTO>(this.urls.fetchDriveBookPaths(driveBookID)).pipe(
      catchError((err) => {
        if (err.error.detail === DoNotShowError) {
          this.notifyService.showError(this.translateService.instant('drive-book.list.warning'));
        }
        return of({
          driveBookPath: {
            drivebookId: 0,
            path: []
          }
        } as IDriveBookPathDTO);
      })
    );
  }

  public fetchDriveBook(driveBookID: number): Observable<IDriveBookDTO> {
    return this.http.get<IDriveBookDetailDTO>(this.urls.fetchDriveBook(driveBookID)).pipe(
      map((response) => response.driveBook)
    );
  }

  public updateDriveBook(driveBookID: number, driveBook: IPutDriveBookDTO): Observable<IDriveBookDTO> {
    return this.http.put<IDriveBooksDTO>(this.urls.updateDriveBook(driveBookID), driveBook).pipe(
      switchMap(() => this.fetchDriveBook(driveBookID))
    );
  }

  public addNewDriveBook(newDriveBook: INewDriveBookDTO): Observable<INewDriveBookDTO> {
    return this.http.post<INewDriveBookDTO>(this.urls.addNewDriveBook(), newDriveBook).pipe(
      map((response) => response)
    );
  }

  public multiplyEditDrivebooks(request: IDriveBookMultiplyEditRequest): Observable<any> {
    return this.http.post<IDriveBookMultiplyEditRequest>(this.urls.multiplyEditDrivebooks(), request);
  }

  public lockDriveBooks(request: IDriveBookLockRequest): Observable<any> {
    return this.http.post<IDriveBookLockRequest>(this.urls.lockDriveBooks(), request);
  }

  public unlockDriveBooks(request: IDriveBookLockRequest): Observable<any> {
    return this.http.post<IDriveBookLockRequest>(this.urls.unlockDriveBooks(), request);
  }
}
