import { ICarConciseDTO } from '../dto/car-concise.dto';

export class AddEditUser {
  userId: number;
  loggedUserId: number;
  username: string;
  password: string;
  emailAddress: string;
  fullName: string;
  gdpr: boolean;
  userGroupId: number;
  assignedCars: ICarConciseDTO[];
}
