<app-page-wrapper [hasSidePadding]="true" [hasTopPadding]="false" [isLoading]="isLoading">
  <ng-template appPageContent>
    <div class="wrapper">
      <h4>{{ 'fuel-card.header' | translate }}</h4>
      <p>{{ 'fuel-card.text1' | translate }}</p>
      <span *ngxPermissionsOnly="['import']">
        <button type="button" class="upload-button" mat-button (click)="imgFileInput.click()">
          <mat-icon class="transparent" *ngIf="!file">note_add</mat-icon>
          {{ !file ? ('fuel-card.upload-file' | translate) : file.name}}
        </button>
      </span>
      <input style="visibility:hidden;" type="file" #imgFileInput (change)="upload(imgFileInput.files)"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
      <span  *ngxPermissionsOnly="['import']">
        <button mat-raised-button color="primary" (click)="addFile()" [disabled]="!file">
          {{'fuel-card.upload-button' | translate }}
        </button>
      </span>
    </div>

    <mat-list *ngIf="importErrors && importErrors.length === 0 && !invalidFormat">
      <mat-card>
        <mat-list-item>
          <mat-icon mat-list-icon>done</mat-icon>
          <p class="line" mat-line style="font-size:14px;"> {{'fuel-card.ok-message' | translate }} </p>
        </mat-list-item>
      </mat-card>
    </mat-list>

    <mat-list *ngIf="importErrors && invalidFormat">
      <mat-card>
        <mat-list-item>
          <mat-icon mat-list-icon>warning</mat-icon>
          <p class="line" mat-line style="font-size:14px;"> {{'import-error-wrong-format' | translate}} </p>
        </mat-list-item>
      </mat-card>
    </mat-list>

    <mat-list *ngIf="importErrors && importErrors.length > 0 && !invalidFormat">
      <mat-card>
        <mat-list-item *ngFor="let error of importErrors">
          <mat-icon mat-list-icon>warning</mat-icon>
          <p *ngIf="error.row > 0" class="line" mat-line style="font-size:14px;">
            {{'fuel-card.error-message' | translate: {row: error.row} }} {{error.errorMessage | translate }}
          </p>
          <p *ngIf="error.row === 0" class="line" mat-line style="font-size:14px;"> {{error.errorMessage}} </p>
          <mat-divider *ngIf="importErrors.length > 1"></mat-divider>
        </mat-list-item>
      </mat-card>
    </mat-list>

    <div class="wrapper">
      <h4>{{ 'fuel-card.cards-support' | translate }}</h4>
      <img width="150" src="../../../assets/images/ccs.jpg"/>
      <img width="150" src="../../../assets/images/shell.jpg"/>
      <img width="150" src="../../../assets/images/dkv.png"/>
      <img width="150" src="../../../assets/images/omv.png"/>
      <img width="150" src="../../../assets/images/benzina.png"/>
      <h5>{{ 'fuel-card.cards-support-next' | translate }}</h5>
    </div>


  </ng-template>
</app-page-wrapper>