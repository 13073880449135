import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CarsStore, ICarsState } from './cars.store';
import { from, of } from 'rxjs';
import { flatMap, map, toArray, distinct } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CarsQuery extends Query<ICarsState> {

  public readonly carGroups$ = this.select((state) => state.carGroups);

  public readonly isLoadingCars$ = this.select((state) => state.loading);

  public readonly changed$ = this.select((state) => state.changed);

  public readonly allCars$ = this.select((state) => state.carGroups).pipe(
    flatMap((data) => of(data).pipe(
      flatMap((carGroups) => from(carGroups ? carGroups : []).pipe(
        map((carGroup) => carGroup.cars),
        flatMap((cars) => from(cars))
      )),
      distinct((car) => car.carID),
      toArray()
    ))
  );

  public constructor(protected store: CarsStore) {
    super(store);
  }
}
