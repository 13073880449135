import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WeekDaysString, WeekDay } from 'src/app/api/models/dto/guardian.dto';
import { PeriodType } from 'src/app/api/models/dto/export-schedule.dto';

const KEY_PREFIX = 'export.period-type';

@Pipe({
  name: 'periodType',
  pure: false
})
export class PeriodTypePipe implements PipeTransform {

  public constructor(
    private translateService: TranslateService
  ) {}

  public transform(value: number): string {

    switch (value) {
      case PeriodType.daily:
        return this.translate(PeriodType.daily);
      case PeriodType.weekly:
        return this.translate(PeriodType.weekly);
      case PeriodType.monthly:
        return this.translate(PeriodType.monthly);
      default:
        return '';
    }
  }

  private translate(periodType: number): string {
    return this.translateService.instant(`${KEY_PREFIX}.${PeriodType[periodType]}`) as string;
  }

}
