import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

const CLASS_HIDDEN = 'hidden';
const CLASS_DISPLAY_NONE = 'display-none';

@Directive({
  selector: '[appHidden]'
})
export class HiddenDirective implements OnInit, OnChanges {

  @Input('appHidden')
  public displayNone = false;

  public constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.applyCss();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.applyCss();
  }

  private applyCss(): void {
    const el = this.elRef.nativeElement;

    this.renderer.removeClass(el, CLASS_HIDDEN);
    this.renderer.removeClass(el, CLASS_DISPLAY_NONE);

    this.renderer.addClass(el, this.displayNone ? CLASS_DISPLAY_NONE : CLASS_HIDDEN);
  }

}
