<div class="dialog-wrapper" [ngClass]="{'delete-dialog': action == 'Delete'}">
  <h1 mat-dialog-title class="title">
    <ng-container *ngIf="action != 'Delete'">
      <ng-container *ngIf="type == 1">
        {{ 'remider.condition.box.distance' | translate }}
      </ng-container>
      <ng-container *ngIf="type == 2 ||  type == 3 ||  type == 4">
        {{ 'remider.condition.box.date' | translate }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="action == 'Delete'">
      <strong> {{ action | translate }}</strong> {{ 'remider.condition.box.rule' | translate }}
    </ng-container>
  </h1>
  <mat-divider></mat-divider>
  <div *ngIf="action != 'Delete'; else elseTemplate">
	<div class="container">
    <ng-container *ngIf="type == 1">
      <div class="column-input-3">
        <p class="left-column"> {{ 'remider.condition.box.afterdrive' | translate }}</p>
        <mat-form-field class="middle-column">
          <input onkeypress="return (event.charCode != 45 && event.charCode != 46 && event.charCode != 44)" 
          matInput type="number" min="1" [placeholder]="'condition.distance' | translate" name="name" [(ngModel)]="condition.number">
        </mat-form-field>
        <p class="right-column"> km</p>
      </div>

        <mat-checkbox [(ngModel)]="condition.repeat">
          {{ 'condition.repeat' | translate }}
        </mat-checkbox>

      <div class="column-input-3" *ngIf="condition.repeat">
        <p class="left-column"> {{ 'remider.condition.box.afterdriverepeat' | translate }}</p>
        <mat-form-field class="middle-column">
          <input matInput type="number" min="1" onkeypress="return (event.charCode != 45 && event.charCode != 46 && event.charCode != 44)" 
          [placeholder]="'condition.distance.repeat' | translate" name="name" [(ngModel)]="condition.data">
        </mat-form-field>
        <p class="right-column"> km</p>
      </div>

      </ng-container>

      <ng-container *ngIf="this.type == 2 || this.type == 3 || this.type == 4">
        <div class="column-input-3">
          <label class="left-column-small">{{ 'remider.condition.box.firstremind' | translate }}</label>
          <mat-form-field class="middle-column">
            <input matInput required [matDatepicker]="filterDateFrom" [(ngModel)]="dateValid" (dateChange)="selectDate($event)"
             [min]="minDate" [placeholder]="'remider.condition.box.choose-date' | translate">

            <mat-datepicker-toggle matSuffix [for]="filterDateFrom"></mat-datepicker-toggle>
            <mat-datepicker #filterDateFrom></mat-datepicker>
          </mat-form-field>
        </div>
          <mat-checkbox [(ngModel)]="condition.repeat">
            {{ 'remider.condition.box.remind' | translate }}
          </mat-checkbox>
      

        <div *ngIf="condition.repeat">
          <mat-radio-group class="date-radio-group" (change)="repeatRadioChange($event)">
            <mat-radio-button class="date-radio-button " [value]="1" [checked]="type == 2">
              {{ 'remider.condition.box.onceyear' | translate }}
            </mat-radio-button>
            <ui-divider></ui-divider>
            <mat-radio-button class="date-radio-button " [value]="3" [checked]="type == 4">
              {{ 'remider.condition.box.two-years' | translate }}
            </mat-radio-button>
            <mat-radio-button class="date-radio-button " [value]="2" [checked]="type == 3">
              <div class="column-input-5">
                <p class="first-column"> {{ 'remider.condition.box.firstafter' | translate }}</p>
                <mat-form-field class="second-column">
                  <input #monthBox matInput type="number" min="0"
                    [placeholder]="'remider.condition.box.monthset' | translate" name="name" [(ngModel)]="condition.data"
                    [disabled]="type!==3">
                </mat-form-field>
                <p class="third-column"> {{ 'remider.condition.box.month' | translate }} </p>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>

      <div mat-dialog-actions class="actionButtons">
        <button mat-button mat-raised-button (click)="closeDialog()" mat-flat-button>{{ 'Cancel' | translate }}</button>
        <button [disabled]="isDisabled()" mat-button mat-raised-button (click)="doAction()" *ngIf="type > 0 && step == 2"
          color="primary">{{ action | translate }}</button>
      </div>
      <p style="font-size: 12px;" [innerHTML]="'required-info' | translate"></p>
    </div>
  </div>

  <ng-template #elseTemplate>
    <div class="container-delete">
      <div mat-dialog-actions>
        <button mat-button mat-raised-button (click)="closeDialog()" mat-flat-button>{{ 'Cancel' | translate }}</button>
        <button mat-button mat-raised-button (click)="doAction()" color="primary">{{ action | translate }}</button>
      </div>
  </div>
  </ng-template>
</div>

