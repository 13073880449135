import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { LanguageService } from '../../services/language.service';
import { DriveBookQuery } from '../../store/drive-book/state/drive-book.query';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslateService } from '@ngx-translate/core';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { NavigationQuery } from '../../store/navigation/state/navigation.query';
import { DriveBookDetailQuery } from '../../store/drive-book-detail/state/drive-book-detail.query';
import { AuthService } from 'src/app/api/auth.service';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { CarsSignalRService } from 'src/app/services/carsSignalR.service';
import { UrlRoutes } from 'src/app/app-routes';
import { ProfileService } from 'src/app/store/profile/state/profile.service';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';
import { AppConfigService } from 'src/app/services/appConfig.service';

const DEFAULT_LANGUAGE = 'cs';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit, OnDestroy {

  private subs = new Subscription();

  @ViewChild('fullMenuOverlay', { static: true })
  public fullMenuOverlay: ElementRef;

  @ViewChild('leftMenu', { static: true })
  public leftMenu: ElementRef;

  public openedSidebar = true;
  public openedSideBarData = false;
  public panelOpened = false;
  public sidebarExpanded;

  public fullMenuOpened = false;

  public showTranslationKeys = false;

  public showDriveBook: boolean;

  public activeMenuItem: ActiveMenuItem;

  constructor(
    private router: Router,
    private authService: AuthService,
    private navigationQuery: NavigationQuery,
    private navigationService: NavigationService,
    public language: LanguageService,
    private renderer: Renderer2,
    private driveBookQuery: DriveBookQuery,
    private routerQuery: RouterQuery,
    private translation: TranslateService,
    private driveBookDetailQuery: DriveBookDetailQuery,
    private carsPositionQuery: CarsPositionQuery,
    private signalRService: CarsSignalRService,
    private profileService: ProfileService,
    private workspaceStore: WorkspaceStore,
    private appConfigService: AppConfigService) {
  }

  public ngOnInit(): void {
    const redirectToCarsPageList = [
      UrlRoutes.urls.dashboardUrl
    ];

    this.subs.add(
      this.navigationQuery.activeMenuItem$
        .pipe(delay(0))
        .subscribe((activeMenuItem) => this.activeMenuItem = activeMenuItem)
    );

    this.subs.add(
      this.routerQuery.select((state) => state.state.url).pipe(
        filter((url) => redirectToCarsPageList.includes(url))
      ).subscribe(() => this.router.navigateByUrl(UrlRoutes.urls.dashboardCarsUrl))
    );

    this.subs.add(
      combineLatest([
        this.driveBookQuery.isCarSelected$,
        this.driveBookDetailQuery.isDriveBookSelected$
      ]).pipe(
        delay(0),
        map(([isCarSelected, isDriveBookSelected]) => (isCarSelected || isDriveBookSelected)),
        tap((showDriveBook) => this.showDriveBook = showDriveBook)
      ).subscribe()
    );

    this.setLanguage(this.translation.store.currentLang);
    this.showTranslationKeys = this.appConfigService.configuration.showTranslationKeys;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public windowWidth() {
    return window.innerWidth;
  }
  public closeSidenav() {
    if (this.windowWidth() < 800) {
      this.openedSidebar = false;
    }
  }
  public toogleSidebar() {
    this.openedSidebar = !this.openedSidebar;
  }

  public toggleSidebarData() {
    this.openedSideBarData = !this.openedSideBarData;
  }

  public expandCollapseSidebar(): void {
    this.navigationService.setSidebarExpanded(!this.sidebarExpanded);
  }

  public setLanguage(language: string): void {
    let lang = language.toLocaleLowerCase();

    if (lang.includes('-')) {
      const parts = lang.split('-');
      const first = parts.length > 0 ? parts[0] : DEFAULT_LANGUAGE;

      lang = first;
    }

    this.language.changeLanguage(lang);
  }

  public toggleFullMenu(): void {
    this.fullMenuOpened = !this.fullMenuOpened;

    const el = this.fullMenuOverlay.nativeElement;
    const left = this.leftMenu.nativeElement;

    if (this.fullMenuOpened) {
      this.renderer.removeClass(el, 'hidden');
      this.renderer.addClass(el, 'visible');
      this.renderer.addClass(left, 'opened');
    } else {
      this.renderer.removeClass(left, 'opened');

      setTimeout(() => {
        this.renderer.removeClass(el, 'visible');
        this.renderer.addClass(el, 'hidden');
      }, 350);
    }
  }

  public logout(): void {
    this.authService.logoutUser();
    this.signalRService.stopConnection();
    this.profileService.saveUrl(this.router.url);
    this.router.navigateByUrl(UrlRoutes.urls.loginUrl);
  }

  public get getActiveVehicles$(): Observable<string> {
    return this.carsPositionQuery.selectedCarsIDs$.pipe(map((ids) => ids.length ? `${ ids.length }` : ''));
  }

  public get getActiveDriveBooks$(): Observable<string> {
    return this.driveBookQuery.selectedDriveBookIDs$.pipe(map((ids) => ids.length ? `${ ids.length }` : ''));
  }

  get contactCompanyUrl(): string {
    return this.workspaceStore.getValue().contactCompanyUrl;
  }

  get smallLogoPath(): string {
    return './' + this.workspaceStore.getValue().smallLogoPath;
  }

  get tooltipText(): string {
    switch (this.workspaceStore.getValue().id) {
      case 1:
        return 'auta.nasec.cz';
      case 2:
        return 'Nasec';
      default:
        return '';
    }
  }
}
