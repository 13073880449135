import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { FormControl, Validators } from '@angular/forms';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { ICarGroupDTO, ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { Subscription } from 'rxjs';
import { ExportService } from 'src/app/store/export/state/export.service';
import { saveAs } from 'file-saver';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import * as moment from 'moment';
import { DriveTypeOverwrite, DriveType, SumType } from 'src/app/store/export/state/export.store';
import { IDriveBookFilter } from 'src/app/store/drive-book/state/drive-book.store';
import { UserHttpService } from 'src/app/api/user.service';

const NAV_PATH = '/dashboard/export-page';
const PAGE_TITLE = 'page-title.export';

@Component({
  selector: 'app-export-page',
  templateUrl: './export-page.component.html',
  styleUrls: ['./export-page.component.scss']
})
export class ExportPageComponent implements OnInit, OnDestroy {

  public formats: string[] = ['PDF', 'Word', 'Excel', 'XML'];
  public exportedFormat = 'PDF';

  public sumFormat = [SumType['total-sum'], SumType['daily-sum']];
  public dailySum = SumType['total-sum'];

  public overwriteoptions = [DriveTypeOverwrite['no-change'], DriveTypeOverwrite['all-private'], DriveTypeOverwrite['all-public']];
  public overwrite = DriveTypeOverwrite['no-change'];

  public isShortPrinting = false;

  public hidePrivateAddress = false;

  public isHidePrivateAddressAvailable = true;

  public carSelect = new FormControl([], Validators.required);

  public carGroups: ICarGroupDTO[] = [];

  public filter: IDriveBookFilter;

  private subs = new Subscription();

  private isClosedSubscription: Subscription;

  public get isLoading(): boolean {
    if (!this.isClosedSubscription) {
      return false;
    }
    return !this.isClosedSubscription.closed;
  }

  public constructor(
    private navigationService: NavigationService,
    private carsState: CarsService,
    private carsQuery: CarsQuery,
    public readonly driveBookQuery: DriveBookQuery,
    private exportService: ExportService,
    private userHttpService: UserHttpService,
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.Export,
      pageTitle: PAGE_TITLE,
      panelWidth: 400
    });

    this.carsState.fetchGroupsWithCars();
    this.navigationService.showContentPanel(NAV_PATH);
    this.subs.add(this.carsQuery.carGroups$.subscribe((carGroups) => this.carGroups = carGroups));
    this.subs.add(this.driveBookQuery.filter$.subscribe((filter) => this.filter = filter));

    this.userHttpService.fetchContactData().subscribe((res) => {
      this.isHidePrivateAddressAvailable = res.gdpr;
      this.hidePrivateAddress = true;
    }, (error) => {
    });

    if (this.driveBookQuery.selectedCarID) {
      const group = this.carGroups.find(x => x.cars.find(y => y.carID === this.driveBookQuery.selectedCarID));
      const car = group.cars.find(y => y.carID === this.driveBookQuery.selectedCarID);
      this.carSelect.setValue([car]);
    }
  }

  public ngOnDestroy(): void {
    setTimeout(() => {
      this.navigationService.clearActiveMenuItem();
    }, 0);
    this.subs.unsubscribe();
  }

  public export() {
    const cars: ICarDTO[] = this.carSelect.value;
    const Spz: string = cars[0].spz;

    switch (this.exportedFormat) {
      case 'PDF': {
        this.isClosedSubscription = this.exportService.exportToPDF({
          carIds: cars.map(car => car.carID),
          from: this.filter.intervalFrom,
          to: moment(this.filter.intervalTo).endOf('day').toDate(),
          type: DriveType[this.filter.type],
          typeOverwrite: this.overwrite,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dailySum: this.dailySum === SumType['daily-sum'],
          isShortPrinting: this.isShortPrinting,
          hidePrivateAddress: !this.isHidePrivateAddressAvailable ? true : this.hidePrivateAddress
        }
        ).subscribe((data: Blob) => {
          if (data.type === 'application/pdf') {
            saveAs(data, Spz + '.pdf');
          } else {
            saveAs(data, 'kniha jizd.zip');
          }
        });
        break;
      }
      case 'Word': {
        this.isClosedSubscription = this.exportService.exportToDocx({
          carIds: cars.map(car => car.carID),
          from: this.filter.intervalFrom,
          to: moment(this.filter.intervalTo).endOf('day').toDate(),
          type: DriveType[this.filter.type],
          typeOverwrite: this.overwrite,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dailySum: false,
          isShortPrinting: this.isShortPrinting,
          hidePrivateAddress: this.hidePrivateAddress
        }
        ).subscribe((data: Blob) => {
          if (data.type === 'application/msword') {
            saveAs(data, Spz + '.docx');
          } else {
            saveAs(data, 'kniha jizd.zip');
          }
        });
        break;
      }
      case 'Excel': {
        this.isClosedSubscription = this.exportService.exportToXLSX({
          carIds: cars.map(car => car.carID),
          from: this.filter.intervalFrom,
          to: moment(this.filter.intervalTo).endOf('day').toDate(),
          type: DriveType[this.filter.type],
          typeOverwrite: this.overwrite,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dailySum: false,
          isShortPrinting: this.isShortPrinting,
          hidePrivateAddress: this.hidePrivateAddress
        }
        ).subscribe((data: Blob) => {
          if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(data, Spz + '.xlsx');
          } else {
            saveAs(data, 'kniha jizd.zip');
          }
        });
        break;
      }
      case 'XML': {
        this.isClosedSubscription = this.exportService.exportToXML({
          carIds: cars.map(car => car.carID),
          from: this.filter.intervalFrom,
          to: moment(this.filter.intervalTo).endOf('day').toDate(),
          type: DriveType[this.filter.type],
          typeOverwrite: this.overwrite,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dailySum: false,
          isShortPrinting: this.isShortPrinting,
          hidePrivateAddress: this.hidePrivateAddress
        }
        ).subscribe((data: Blob) => {
          if (data.type === 'text/plain') {
            saveAs(data, Spz + '.xml');
          } else {
            saveAs(data, 'kniha jizd.zip');
          }
        });
        break;
      }
      default: {

        break;
      }
    }
  }
}
