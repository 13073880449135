import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IUserContactDataRequest, IUserInfoDTO } from 'src/app/api/models/user/user-info';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';

@Component({
  selector: 'app-profile-contact-details',
  templateUrl: './profile-contact-details.component.html',
  styleUrls: ['./profile-contact-details.component.scss']
})
export class ProfileContactDetailsComponent implements OnInit {

  public form: FormGroup;

  @Input() contactData: IUserInfoDTO;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClose = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSave = new EventEmitter<IUserContactDataRequest>();
  public constructor(
    private fb: FormBuilder,
    private workspaceStore: WorkspaceStore
  ) { }

  public ngOnInit(): void {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      emailAddress: new FormControl(!!this.contactData ? this.contactData.emailAddress : null, [Validators.email]),
      fullName: new FormControl(!!this.contactData ? this.contactData.fullName : null)
    });
  }

  public close(): void {
    this.onClose.emit();
  }

  public save(): void {
    const request: IUserContactDataRequest = {
      emailAddress: this.form.value.emailAddress,
      fullName: this.form.value.fullName,
      workspaceId: this.workspaceStore.getValue().id
    };
    this.onSave.emit(request);
  }
}
