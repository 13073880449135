import { Pipe, PipeTransform } from '@angular/core';
import { ICarDTO, CarType, ICarPositionExtra } from 'src/app/api/models/dto/car-groups.dto';
import { ICarConciseDTO } from 'src/app/api/models/dto/car-concise.dto';

@Pipe({
  name: 'vehicleIcon',
  pure: true
})
export class VehicleIconPipe implements PipeTransform {

  public transform(value: number, vehicles: any[], vehiclePositions?: ICarPositionExtra[], isCarConcise: boolean = false): string {
    if (!value || !vehicles) {
      return;
    }
    let type: string = null;
    if (isCarConcise) {
      const vehicle = vehicles?.find((car: ICarConciseDTO) => car.carId === value) as ICarConciseDTO;
      type = vehicle?.carBody?.strName;
    } else {
      const vehicle = vehicles?.find((car: ICarDTO) => car.carID === value) as ICarDTO;
      type = vehicle?.type;
    }
    const currentPosition = !!vehiclePositions ? vehiclePositions?.find(p => p?.carId === value) : null;
    const path = 'assets/images/';
    switch (type) {
      case CarType.CAR:
        return `${ path }${ currentPosition?.isOnline ? 'car-online.png' : 'car.png' }`;
      case CarType.SUV:
        return `${ path }${ currentPosition?.isOnline ? 'suv-online.png' : 'suv.png' }`;
      case CarType.DELIVERY_TRUCK:
        return `${ path }${ currentPosition?.isOnline ? 'delivery-truck-online.png' : 'delivery-truck.png' }`;
      case CarType.BIKE:
        return `${ path }${ currentPosition?.isOnline ? 'bike-online.png' : 'bike.png' }`;
      case CarType.BOAT:
        return `${ path }${ currentPosition?.isOnline ? 'boat-online.png' : 'boat.png' }`;
      case CarType.BUS:
        return `${ path }${ currentPosition?.isOnline ? 'bus-online.png' : 'bus.png' }`;
      case CarType.CONSTRUCTION_MACHINE:
        return `${ path }${ currentPosition?.isOnline ? 'construction-machine-online.png' : 'construction-machine.png' }`;
      case CarType.TRANSPORTER:
        return `${ path }${ currentPosition?.isOnline ? 'transporter-online.png' : 'transporter.png' }`;
      case CarType.TRUCK:
        return `${ path }${ currentPosition?.isOnline ? 'truck-online.png' : 'truck.png' }`;
      case CarType.LOCATOR:
        return `${ path }${ currentPosition?.isOnline ? 'locator-online.png' : 'locator.png' }`;
      case CarType.TRACTOR:
        return `${ path }${ currentPosition?.isOnline ? 'tractor-online.png' : 'tractor.png' }`;
      default:
        return `${ path }${ currentPosition?.isOnline ? 'car-online.png' : 'car.png' }`;
    }
  }
}
