import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../api/auth.service';
import { LanguageService } from '../../services/language.service';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UrlRoutes } from 'src/app/app-routes';
import { ProfileService } from 'src/app/store/profile/state/profile.service';
import { IUserSetChangePassword } from 'src/app/api/models/user/user-info';
import { Subscription } from 'rxjs';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password-set.component.html',
  styleUrls: ['./forgot-password-set.component.scss'],
})
export class ForgotPasswordSetComponent implements OnInit, OnDestroy {
  public selectedLanguage;
  public form: FormGroup;
  public token: string;
  public isLoading: boolean;
  private subs = new Subscription();
  public showTranslationKeys = false;

  constructor(private authService: AuthService,
              private router: Router,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private profileService: ProfileService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private profileQuery: ProfileQuery,
              private appConfigService: AppConfigService) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([UrlRoutes.urls.dashboardUrl]);
    }
    this.selectedLanguage = this.languageService.getLanguage();
    this.subs.add(this.translateService.onLangChange.subscribe(() => this.selectedLanguage = this.languageService.getLanguage()));
    this.subs.add(this.profileQuery.isLoading$.subscribe(isLoading => this.isLoading = isLoading));
    this.showTranslationKeys = appConfigService.configuration.showTranslationKeys;
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.form = this.initializeForm();
  }

  private initializeForm(): FormGroup {
    return this.fb.group({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      newPasswordConfirm: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });
  }

  public changeFormValue(name: string, value: string): void {
    this.form.controls[name].patchValue(value);
  }

  get arePasswordsEqual() {
    return this.form.value.newPassword === this.form.value.newPasswordConfirm;
  }

  public changeLanguage(lang): void {
    this.languageService.changeLanguage(lang);
  }

  public setPassword(): void {
    const request: IUserSetChangePassword = {
      newPassword: this.form.value.newPassword,
      token: this.token,
    };
    this.profileService.sendSetForgotPassword(request, () => {
      this.router.navigate([UrlRoutes.urls.loginUrl]);
    });
  }

  public onLogin(): void {
    this.router.navigateByUrl(UrlRoutes.urls.loginUrl);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
