import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';
import { Observable } from 'rxjs';
import { IExportRequest } from './models/export-request.model';
import { map } from 'rxjs/operators';
import { IExportSchedules, IExportSchedule, ICreateExportSchedule, IGetExportScheduleByCarIdResponse } from './models/dto/export-schedule.dto';

@Injectable({ providedIn: 'root' })
export class ExportHttpService {
  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchExportScheduleByCarId(carID: number): Observable<IExportSchedules> {
    return this.http.get<IGetExportScheduleByCarIdResponse>(this.urls.fetchExportScheduleByCarId(carID)).pipe(
      map((response) => ({
        exportSchedules: response.exportSchedules.schedules, 
        gdprEnabled: response.exportSchedules.gdprEnabled}) as IExportSchedules));
  }

  public fetchExportScheduleById(id: number): Observable<IExportSchedule> {
    return this.http.get<IExportSchedule>(this.urls.fetchExportScheduleById(id));
  }

  public updateExportSchedule(id: number, exportSchedule: IExportSchedule): Observable<IExportSchedule> {
    return this.http.put<IExportSchedule>(this.urls.updateExportSchedule(id), exportSchedule);
  }

  public createExportSchedule(carId: number, exportSchedule: ICreateExportSchedule): Observable<IExportSchedule> {
    return this.http.post<IExportSchedule>(this.urls.createExportSchedule(carId), exportSchedule);
  }

  public deleteExportSchedule(id: number): Observable<IExportSchedule> {
    return this.http.delete<IExportSchedule>(this.urls.deleteExportSchedule(id));
  }

  public exportToPDF(exportRequest: IExportRequest): Observable<any> {
    return this.http.post(this.urls.exportToPdf(), exportRequest, { responseType: 'blob' }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public exportToDocx(exportRequest: IExportRequest): Observable<any> {
    return this.http.post(this.urls.exportToDocx(), exportRequest, { responseType: 'blob' }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public exportToXLSX(exportRequest: IExportRequest): Observable<any> {
    return this.http.post(this.urls.exportToXLSX(), exportRequest, { responseType: 'blob' }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public exportToXML(exportRequest: IExportRequest): Observable<any> {
    return this.http.post(this.urls.exportToXML(), exportRequest, { responseType: 'blob' }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
