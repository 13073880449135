<div>
    <div *ngIf="!data?.isCarMovementAllowed" class="title">
        {{ 'allow-car-movement' | translate: { carToolTip: data?.toolTipText } }}
    </div>
    <div *ngIf="data?.isCarMovementAllowed" class="title">
        {{ 'forbid-car-movement' | translate: { carToolTip: data?.toolTipText } }}
    </div>
    <mat-divider></mat-divider>
    <div class="container">
        <mat-dialog-content>
            <mat-spinner *ngIf="isLoading" class="center"></mat-spinner>
            <p [innerHTML]="message">
            </p>
        </mat-dialog-content>
        <p>{{info}}</p>
        <mat-dialog-actions align="center">
            <button mat-raised-button (click)="closeDialog()" tabindex="-1">{{ !isLoading ? cancelButtonText : 'profile.close' | translate}}</button>
            <button mat-raised-button color="primary" *ngIf="!isLoading" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
        </mat-dialog-actions>
    </div>
</div>