  <table mat-table [dataSource]="refuelings" class="fueling-table" >
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{  "fueling.date" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">{{ fueling?.date | date: 'd. M. yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="place">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{  "fueling.place" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">{{ fueling?.place || " - " }}</td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{  "fueling.amount" | translate }}</p>
      </th>
      <td  mat-cell *matCellDef="let fueling">{{ fueling?.fuelRefillLitres }} l</td>
    </ng-container>
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{ "fueling.price" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">
        <ng-container *ngIf="fueling?.fuelRefillLitres === 0"> - </ng-container>
        <ng-container *ngIf="fueling?.fuelRefillLitres > 0"> 
          {{ (fueling?.fuelRefillPrice / fueling?.fuelRefillLitres) | number: '1.0-1'}} Kč
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="total-price">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{ "fueling.price-total" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">{{ fueling?.fuelRefillPrice }} Kč</td>
    </ng-container>
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{ "fueling.distance" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling"> 
        <p style="padding-left: 7px;"> &ensp;{{ fueling?.traceDistance <= 0 ? '-' : ((fueling?.traceDistance  | number: '1.0-0') + 'km') }} </p></td>
    </ng-container>
    <ng-container matColumnDef="tachometer">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p>{{ "fueling.tachometer" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">{{ fueling?.tachometer  | number: '1.0-0'}} km</td>
    </ng-container>
    <ng-container matColumnDef="consumption">
      <th mat-header-cell *matHeaderCellDef class="wrapper"><p> &#8709; {{ "fueling.consumption" | translate }}</p>
      </th>
      <td mat-cell *matCellDef="let fueling">
          <p>{{ fueling?.consumption <= 0 ? '-' : ((fueling?.consumption | number: '1.0-1') + 'l/100km') }} </p> 
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="wrapper">
      </th>
      <td mat-cell *matCellDef="let fueling">
        <div *ngxPermissionsOnly="['drivebook-edit', 'drivebook-edit-limited']">
          <button *ngIf="isDisplayed(fueling)"                       
            (click)="navigateToEdit(fueling.driveBookID)" class="edit-drive-button" mat-icon-button>
            <mat-icon matTooltipPosition="right" [matTooltip]="'drive-book.row.edit' | translate"> edit </mat-icon>
          </button>
        </div> 
    </ng-container> 
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <ng-container *ngIf="loadingRefueling">
    <app-content-loading type="progress-bar"></app-content-loading>
    <p class="no-data light"> {{ "fueling.loading" | translate}} </p>
  </ng-container>
  <ng-container *ngIf="!loadingRefueling && refuelings?.length === 0">
    <p class="no-data light"> {{ "fueling.no-data" | translate}} </p>
  </ng-container>
  <ng-container *ngIf="resultsLength > paginatedObject.pageSize">
    <mat-paginator #paginator
    [length] = "resultsLength"
    [pageIndex]="paginatedObject.pageIndex"
    [pageSize]="paginatedObject.pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageFired($event)">
  </mat-paginator>     
</ng-container>     
