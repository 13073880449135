import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ICarGroupDTO,  } from '../../../api/models/dto/car-groups.dto';

@Component({
  selector: 'app-car-group-row',
  templateUrl: './car-group-row.component.html',
  styleUrls: ['./car-group-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarGroupRowComponent {

  @Output()
  public selectCar = new EventEmitter<number>();

  @Output()
  public selectGroup = new EventEmitter<ICarGroupDTO>();

  @Input()
  public group: ICarGroupDTO;

  @Input()
  public searchQuery: string;

  @Input()
  public selectedCarIds: number[];

  @Input()
  isExpanded = false;

  @Input()
  updateTriger: number;

  public constructor() { }

  public handleCarSelect(event: MouseEvent, id: number): void {
    const target = event.target as HTMLElement;

    if (target.classList.contains('actions-icon') || target.classList.contains('actions-button')) {
      return;
    }

    this.selectCar.emit(id);
  }

  public handleGroupSelect(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.classList.contains('actions-icon') || target.classList.contains('actions-button')) {
      return;
    }

    this.selectGroup.emit(this.group);
  }
}
