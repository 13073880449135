import { Pipe, PipeTransform } from '@angular/core';
import { FileFormat } from 'src/app/api/models/dto/export-schedule.dto';

@Pipe({
  name: 'fileFormat',
  pure: false
})
export class FileFormatPipe implements PipeTransform {

  public constructor(
  ) {}

  public transform(value: number): string {
    switch (value) {
      case FileFormat.DOCX:
        return 'DOCX';
      case FileFormat.XLSX:
        return 'XLSX';
      case FileFormat.PDF:
        return 'PDF';
      case FileFormat.XML:
        return 'XML';
      default:
        return '';
    }
  }
}
