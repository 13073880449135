<a [matMenuTriggerFor]="userMenu"  data-test="hamburger-menu-button" [matTooltip]="this.fullName ? this.fullName : userFullUsername" matTooltipPosition="left">
  <mat-icon class="white">menu</mat-icon>
</a>

<mat-menu #userMenu="matMenu">
  <span mat-menu-item class="userName">
    <mat-icon>person</mat-icon>
    <span>{{this.fullName ? this.fullName : userFullUsername}}</span>
  </span>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="navigateToProfile()">
    <mat-icon>assignment</mat-icon>
    <span>{{ 'user-menu.profile' | translate }}</span>
  </button>
  <span *ngxPermissionsOnly="['administration']">
    <button mat-menu-item *ngIf="isAdmin" (click)="navigateToAdministration()">
      <mat-icon>settings</mat-icon>
      <span>{{ 'user-menu.admin' | translate }}</span>
    </button>
  </span>
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'user-menu.logout' | translate }}</span>
  </button>
</mat-menu>
