import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as ENDPOINTS from './endpoints/area-control.endpoints';
import { IAreaControlAddRequest, IAreaVehicleEditRequest, IAreaControlResponseDTO } from './models/dto/area-control.dto';
import { AppConfigService } from '../services/appConfig.service';
@Injectable()
export class AreaControlHttpService {

  public constructor(private http: HttpClient, private appConfigService: AppConfigService) {
  }

  public fetchUserAreas(): Observable<IAreaControlResponseDTO> {
    return this.http.get<IAreaControlResponseDTO>(ENDPOINTS.getUserAreasUrl(this.appConfigService.configuration.API_URL));
  }

  public addArea(request: IAreaControlAddRequest): Observable<any> {
    return this.http.post(ENDPOINTS.addNewAreaUrl(this.appConfigService.configuration.API_URL), request);
  }

  public editArea(areaId: number, request: IAreaControlAddRequest): Observable<any> {
    return this.http.put(ENDPOINTS.editAreaUrl(this.appConfigService.configuration.API_URL, areaId), request);
  }

  public deleteArea(id: number): Observable<any> {
    return this.http.delete(ENDPOINTS.deleteAreaUrl(this.appConfigService.configuration.API_URL, id));
  }

  public addEditAreaVehicle(areaId: number, request: IAreaVehicleEditRequest): Observable<any> {
    return this.http.post(ENDPOINTS.addEditVehicleAreaUrl(this.appConfigService.configuration.API_URL, areaId), request);
  }

  public getAreasByCarIdUrl(vehicleId: number): Observable<IAreaControlResponseDTO> {
    return this.http.get<IAreaControlResponseDTO>(ENDPOINTS.getAreasByCarIdUrl(this.appConfigService.configuration.API_URL, vehicleId));
  }
}
