import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DriveTypeString as StringDriveType } from '../../store/drive-book/state/drive-book.store';
import { DriveType } from '../../api/models/dto/drive-books.dto';

const KEY_PREFIX = 'drive-book.type';

@Pipe({
  name: 'driveType',
  pure: false
})
export class DriveTypePipe implements PipeTransform {

  public constructor(
    private translateService: TranslateService
  ) {}

  public transform(value: number): string {

    switch (value) {
      case DriveType.Business:
        return this.translate(StringDriveType.Business);
      case 1:
        return this.translate(StringDriveType.Private);
      case 2:
        return this.translate(StringDriveType.All);
      default:
        return '';
    }
  }

  private translate(driveType: string): string {
    return this.translateService.instant(`${KEY_PREFIX}.${driveType}`) as string;
  }

}
