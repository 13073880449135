<app-page-wrapper [hasSidePadding]="false" [hasTopPadding]="false">
    <ng-template appPageContent>

        <div class="vehicle-container">
            <div class="vehicle-heading">
                {{ 'drive-book.vehicle' | translate }}
            </div>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>{{'export.select-vehicle' | translate}}</mat-label>
                <mat-select [formControl]="carSelect" multiple>
                <span  *ngxPermissionsOnly="['car-list', 'admin-car-list']">
                    <mat-optgroup *ngFor="let group of carGroups"
                        [label]="group?.group?.name + ' (' + group?.cars?.length + ')'">
                        <mat-option *ngFor="let car of group.cars | sortVehicles : '' " [value]="car">
                            <span><b>{{ car.spz }}</b> {{ car.toolTipText }}</span>
                        </mat-option>
                    </mat-optgroup>
                </span>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="filters">
            <app-filter [forExport]="true"></app-filter>
        </div>

        <div class="radio-container">
            <div class="radio-heading">
                {{'export.select-drive-type-overwrite' | translate}}
            </div>
            <div class="radio-button-container">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="overwrite">
                    <mat-radio-button class="example-radio-button" *ngFor="let option of overwriteoptions"
                        [value]="option">
                        {{ option | overWriteType }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="radio-container">
            <div class="radio-heading">
                {{'export.select-format' | translate}}
            </div>
            <div class="radio-button-container">
                <mat-radio-group aria-labelledby="format-radio-group-label" class="example-radio-group"
                    [(ngModel)]="exportedFormat">
                    <mat-radio-button class="example-radio-button" *ngFor="let format of formats" [value]="format">
                        {{format}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <ng-container *ngIf="exportedFormat === 'PDF'">
            <div class="radio-container">
                <div class="radio-heading">
                    {{'export.export-PDF-type' | translate}}
                </div>
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="format-radio-group-label" class="example-radio-group"
                        [(ngModel)]="dailySum">
                        <mat-radio-button class="example-radio-button" *ngFor="let format of sumFormat" [value]="format">
                            {{ format | dailySum }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </ng-container>
        <div class="checkbox-container">
            <mat-checkbox [(ngModel)]="isShortPrinting" matListIcon  [matTooltip]="'export.simple-report-tooltip' | translate">
                <mat-label>{{ 'export.simple-report' | translate }}</mat-label>
            </mat-checkbox>
        </div>
        <ng-container *ngIf="isHidePrivateAddressAvailable">
            <div class="checkbox-container">
                <mat-checkbox [(ngModel)]="hidePrivateAddress" matListIcon>
                    <mat-label>{{ 'export.hide-private-address' | translate }}</mat-label>
                </mat-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <app-content-loading type="progress-bar"></app-content-loading>
        </ng-container>
        <span *ngxPermissionsOnly="['export']">
            <button mat-raised-button color="primary" (click)="export()" [disabled]="carSelect.invalid"> {{'export.export-button' | translate}}
            </button>
        </span>
    </ng-template>
</app-page-wrapper>