import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { IReminderDTO, IConditionDTO, IOperandTypeDTO, OperatorEnum } from '../../../api/models/dto/reminders.dto';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatTable, MatDialog, MatTableDataSource, MatDialogRef } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReminderDialogBoxComponent } from '../reminder-dialog-box/reminder-dialog-box.component';
import { Guid } from 'guid-typescript';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { CarRemindersService } from 'src/app/store/car-reminders/state/car-reminders.service';
import { Subscription } from 'rxjs';
import { CarRemindersStore } from 'src/app/store/car-reminders/state/car-reminders.store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-reminders-conditions-list',
  templateUrl: './car-reminder-conditions-list.component.html',
  styleUrls: ['./car-reminder-conditions-list.component.scss'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'cs'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class ReminderConditionsListComponent implements OnInit, OnDestroy {

  @Input()
  public reminder: IReminderDTO;

  @Output()
  public outputConditions = new EventEmitter<IConditionDTO[]>();

  public allConditions: IConditionDTO[];
  public reminderConditions = new MatTableDataSource([]);
  private sub = new Subscription();

  public displayedColumns: string[] = ['operator', 'reminderFrom', 'reminderTo', 'action'];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  public constructor(
    public dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private carRemindersState: CarRemindersService,
    private carRemindersStore: CarRemindersStore,
    public readonly carRemindersQuery: CarRemindersQuery,
    private translateService: TranslateService) {
  }

  public reminderTypes = [];

  private translate(keyWord: string): string {
    return this.translateService.instant(keyWord) as string;
  }

  ngOnInit(): void {
    this.reminderConditions = new MatTableDataSource(!!this.reminder ? this.reminder.conditions : []);
    this.sub.add(
      this.carRemindersQuery.activeEditReminder$.subscribe((reminder) => {
        if (this.carRemindersQuery.getAction() === 'update' || this.carRemindersQuery.getAction() === 'create') {
          const conditions = reminder.conditions.filter(x => {
            if (x.operatorType) {
              return x;
            }
            if (x.operandType) {
              return x;
            }
          });
          this.allConditions = conditions;
          this.reminderConditions = new MatTableDataSource(this.updateList(conditions));
        }
      })
    );
  }

  public updateList(condition: IConditionDTO[]): IConditionDTO[] {
    const updatedList = condition.filter(x => x.operatorType == null);
    return updatedList;
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getReminderFrom(type: IConditionDTO) {
    let returnValue = '';
    const moment = require('moment');
    if (type.operandType) {
      if (type.operandType.operandType === 0) {
        if (!type.operandType.repeat) {
          returnValue = this.translate('remider.operation.drive') + ': ' + this.numberWithCommas(type.operandType.number) + ' km';
        } else {
          returnValue = this.translate('remider.operation.firstdrive') + ': ' + this.numberWithCommas(type.operandType.number) + ' km';
        }
      }
      if (type.operandType.operandType === 1) {
        let dateshow = 'invalidDate';
        if (moment(type.operandType.number, 'MM-DD-YYYY').isValid()) {
          dateshow = moment.utc(type.operandType.number, 'MM-DD-YYYY').local().format('DD. MM. YYYY');
        } else if (moment(new Date(type.operandType.number)).isValid()) {
          dateshow = moment.utc(new Date(type.operandType.number)).local().format('DD. MM. YYYY');
        }
        if (!type.operandType.repeat) {
          returnValue = this.translate('remider.operation.onlyday') + ': ' + dateshow;
        } else {
          returnValue = this.translate('remider.operation.firstsince') + ': ' + dateshow;
        }
      }
      if (type.operandType.operandType === 3) {
        let dateshow = 'invalidDate';
        if (moment(type.operandType.number, 'MM-DD-YYYY').isValid()) {
          dateshow = moment.utc(type.operandType.number, 'MM-DD-YYYY').local().format('DD. MM. YYYY');
        } else {
          dateshow = moment.utc(new Date(type.operandType.number)).local().format('DD. MM. YYYY');
        }
        returnValue = this.translate('remider.operation.firstsince') + ': ' + dateshow;
      }

      if (type.operandType.operandType === 2) {
        if (type.operandType.dateValidFrom) {
          const date = moment.utc(type.operandType.dateValidFrom, 'MM-DD-YYYY').local().toDate();
          const myDate = moment.utc(type.operandType.dateValidFrom, 'MM-DD-YYYY').local().format('DD. MM. YYYY');
          this.carRemindersStore.update((state) => {
            state.activeEditReminder.dateFrom = date;
          });
          returnValue = this.translate('remider.operation.begin') + ': ' + myDate;
        } else {

          const dateFormat = new Date(this.reminder.dateFrom);
          const myDate = moment.utc(dateFormat, 'MM-DD-YYYY').local().format('DD. MM. YYYY');
          returnValue = this.translate('remider.operation.begin') + ': ' + myDate;
        }
      }
      return returnValue;
    }
  }

  public numberWithCommas(value) {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  getReminderOperator(type: IConditionDTO) {
    const indexElement = this.allConditions.findIndex(x => x.id === type.id);
    if (indexElement <= 1) {
      return;
    }

    if (!this.allConditions[indexElement - 1].operatorType) {
      return;
    }

    const operatorType = this.allConditions[indexElement - 1].operatorType.operatorType;
    if (operatorType > 0) {
      return this.translate(OperatorEnum[operatorType].toString());
    }
  }

  getReminderTo(type: IConditionDTO) {
    if (!type.operandType) {
      return;
    }

    let returnValue = '';
    const moment = require('moment');
    if (type.operandType.repeat) {
      returnValue = this.translate('remider.operation.afterevery') + ': ' + this.numberWithCommas(type.operandType.data) + ' km';
    }

    if (type.operandType.operandType === 1 && type.operandType.repeat) {
        const date = new Date(type.operandType.number);
        returnValue = this.translate('remider.operation.aftereveryyear');
    }

    if (type.operandType.operandType === 3 && type.operandType.repeat) {
      returnValue = this.translate('remider.operation.aftereverysecondyear');
    }

    if (type.operandType.operandType === 2 && type.operandType.repeat) {
        returnValue = `${this.translate('remider.operation.after')} ${type.operandType.data}
        ${this.translate('remider.operation.months')}`;
    }
    return returnValue;
  }

  public openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  public remove(condition): void {
    this.openConfirmDialog('reminder.list.confirm.message').afterClosed().subscribe((result) => {
      if (result) {
        this.deleteRowData(condition);
        this.changeDetectorRefs.detectChanges();
        this.table.renderRows();
        this.outputConditions.emit(this.allConditions);
      }}
    );
  }

  openDialog(action, condition) {
    condition.action = action;
    const dialogRef = this.dialog.open(ReminderDialogBoxComponent, {
      data: condition,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        return;
      }

      if (result.event === 'Add') {
        this.addRowData(result.data);
      } else if (result.event === 'Update') {
        this.updateRowData(result.data, condition);
      }

      this.changeDetectorRefs.detectChanges();
      this.table.renderRows();
      this.outputConditions.emit(this.allConditions);
      if (result?.data?.operandType === 2) {
        this.carRemindersState.changeDateFromValidReminder(result.dateValid);
      }
    });

  }

  addRowData(operand: IOperandTypeDTO) {
    if (this.allConditions.length > 0) {
      const operator = {
        id: Guid.create().toString(),
        operandType: null,
        reminderId: this.reminder.reminderId,
        operatorType: this.reminderConditions.data.length > 0
          ? { operatorType: OperatorEnum.or }
          : null
      };
      this.allConditions.push(operator);
    }

    const condition = {
      id: Guid.create().toString(),
      operandType: operand,
      reminderId: this.reminder.reminderId,
      operatorType: null
    };
    this.allConditions.push(condition);
    this.reminderConditions.data = this.updateList(this.allConditions);
  }

  updateRowData(rowobj: IOperandTypeDTO, condition: IConditionDTO) {
    this.reminderConditions.data = this.reminderConditions.data.map((value) => {
      if (value.id === condition.id) {
        value.operandType = rowobj;
      }
      return value;
    });

    this.allConditions = this.allConditions.map((value) => {
      if (value.id === condition.id) {
        value.operandType = rowobj;
      }
      return value;
    });
  }

  deleteRowData(rowobj: IConditionDTO) {
    this.reminderConditions.data = this.reminderConditions.data.filter((value) => {
      return value.id !== rowobj.id;
    });
    const index = this.allConditions.findIndex(x => x.id === rowobj.id);
    if (index > 0) {
      this.allConditions.splice(index - 1, 2);
    } else {
      this.allConditions.splice(index, 1);
    }
  }
}
