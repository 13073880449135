<mat-toolbar>
  {{'user-menu.admin' | translate}}
  <mat-divider vertical class="divider"></mat-divider>
  <button mat-button class="back-button" (click)="navigate('/dashboard')">
    {{'form-actions.back' | translate}}
  </button>
    <span class="spacer"> </span>
    <button mat-button [matMenuTriggerFor]="langSelector" matTooltip="{{ 'language' | translate }}">
      <mat-icon class="">language</mat-icon> {{ selectedLanguage | uppercase }}

    </button>
    <mat-menu #langSelector="matMenu" x-position="after" y-position="below">
      <button mat-menu-item (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
      <button mat-menu-item (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
      <button mat-menu-item (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
      <button *ngIf="showTranslationKeys" mat-menu-item (click)="changeLanguage('de')">{{ 'translation-keys' | translate }}</button>
    </mat-menu>
   <button *ngIf="authService.isLoggedIn()" mat-button [matMenuTriggerFor]="userDropdown">
      <mat-icon class="">account_circle</mat-icon>
    </button>
    <mat-menu #userDropdown="matMenu" x-position="after" y-position="below">
      <span mat-menu-item class="userName">
        <mat-icon>person</mat-icon>
        <span>{{this.username}}</span>
      </span>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="navigate('/dashboard')">
        <mat-icon>arrow_back</mat-icon>
        <span>{{ 'form-actions.back' | translate }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'logout' | translate }}</span>
      </button>
    </mat-menu>
  </mat-toolbar>
