import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { ActivatedRoute } from '@angular/router';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import {
  IAreaControlData,
  IAreaVehicle,
  AreaRestrictionType,
  IAreaVehicleEditRequest,
  IAreaVehicleDialogInfo,
} from 'src/app/api/models/dto/area-control.dto';
import { ICarPositionExtra, ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IMapPoint } from 'src/app/store/map/state/map.store';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { UrlRoutes } from 'src/app/app-routes';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'src/app/store/map/state/map.service';
import { AreaControVehicleEditDialogComponent } from '../area-control-vehicle-edit-dialog/area-control-vehicle-edit-dialog.component';
import { CarsPositionStore } from 'src/app/store/cars-position/cars-position.store';

const PAGE_TITLE = 'page-title.area-control-detail';

@Component({
  selector: 'app-area-control-detail',
  templateUrl: './area-control-detail.component.html',
  styleUrls: ['./area-control-detail.component.scss'],
})
export class AreaControlDetailComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  public allAreas: IAreaControlData[];
  public selectedPolygon: IAreaControlData;
  public vehicles: ICarDTO[];
  public vehiclesPositions: ICarPositionExtra[];
  public activeAreaId: number;
  public isLoading = false;
  public selectedCarIds: number[];
  public translatedLabel: string;
  displayedColumns: string[] = ['vehicleName', 'restrictionType', 'notificationEmail', 'position', 'actions'];
  displayedColumnsNonActive: string[] = ['vehicleName', 'position', 'actions'];
  dataSourceActive = new MatTableDataSource();
  dataSourceNotActive = new MatTableDataSource();

  public constructor(
    private navigationService: NavigationService,
    private areaControlService: AreaControlService,
    private vehicleService: CarsService,
    private carsQuery: CarsQuery,
    private carService: CarsService,
    private areaControlQuery: AreaControlQuery,
    private carsPositionQuery: CarsPositionQuery,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private utilityService: UtilitiesService,
    private translateService: TranslateService,
    private mapService: MapService,
    public carsPositionStore: CarsPositionStore
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.AreaControl,
      pageTitle: PAGE_TITLE,
      backUrl: UrlRoutes.urls.areaControl.home,
      panelWidth: 700
    });
    this.translateService.get(PAGE_TITLE).subscribe((translation) => {
      this.translatedLabel = translation;
    });
    this.navigationService.showContentPanel(UrlRoutes.urls.areaControl.home);
    this.subs.add(this.areaControlQuery.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    }));
    this.subs.add(this.route.url.subscribe((data) => {
      this.activeAreaId = parseInt(this.route.snapshot.paramMap.get('area_id'), 10);
    }));
    this.subs.add(this.carsQuery.carGroups$.subscribe((groups) => {
      this.vehicles = !!groups ? groups.reduce((accumulator, obj) => [...accumulator, ...obj.cars], []) : [];
      if (this.vehicles.length > 0) {
        this.proccessSelectedArea(this.selectedPolygon);
      }
    }));
    this.subs.add(this.carsPositionQuery.carsPosition$.subscribe((positions) => {
      this.vehiclesPositions = positions;
      if (!!this.vehiclesPositions && this.vehiclesPositions.length > 0) {
        this.proccessSelectedArea(this.selectedPolygon);
      }
    }));
    this.subs.add(this.carsPositionQuery.selectedCarsIDs$.subscribe((selectedCarIds) => {
      this.selectedCarIds = selectedCarIds;
    }));
    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((polygon) => {
      this.proccessSelectedArea(polygon);
    }));
    this.subs.add(this.areaControlQuery.areaPolygons$.subscribe((polygons) => {
      this.allAreas = polygons;
      if (!!this.activeAreaId && !!this.allAreas) {
        const polygon = polygons.find(p => p.id === this.activeAreaId);
        this.areaControlService.setSelectedPolygon(polygon);
      }
    }));
    if (!this.vehicles || this.vehicles.length === 0) {
      this.carService.fetchGroupsWithCars(true);
    }
    if (!this.allAreas || this.allAreas?.length === 0) {
      this.areaControlService.fetchUserAreas();
    }
  }

  public proccessSelectedArea(area: IAreaControlData): void {
    this.selectedPolygon = area;
    if (!area || !this.vehiclesPositions || this.vehiclesPositions.length === 0 || !this.vehicles) {
      return;
    }
    const polygonPath: IMapPoint[] = area.points.map(p => ({ latitude: p.lat, longitude: p.lng }));
    const tableData: IAreaVehicle[] = this.vehicles.map((vehicle) => {
      const areaVehicle = !!area.vehicles ? area.vehicles.find(v => v.vehicleId === vehicle.carID) : null;
      const vehiclePosition = this.vehiclesPositions.find(v => v.carId === vehicle.carID);

      return {
        vehicleName: vehicle.spz,
        vehicleDescription: vehicle.toolTipText,
        vehicleId: vehicle.carID,
        sendNotifications: !!areaVehicle ? areaVehicle.sendNotifications : false,
        type: !!areaVehicle ? areaVehicle.type : AreaRestrictionType.NOT_SET,
        inArea: this.utilityService.isInAreaPolygon(vehiclePosition, polygonPath),
        notificationEmail: !!areaVehicle ? areaVehicle.notificationEmail : null,
        notificationPhone: !!areaVehicle ? areaVehicle.notificationPhone : null,
        editable: false,
      };
    });
    this.dataSourceActive.data = tableData?.filter(vehicle => vehicle.type !== AreaRestrictionType.NOT_SET);
    this.dataSourceNotActive.data = tableData?.filter(vehicle => vehicle.type === AreaRestrictionType.NOT_SET);
    setTimeout(() => {
      this.navigationService.setPageTitle(`${ this.translatedLabel } - ${ this.selectedPolygon?.name || '' }`);
    }, 10);
    this.changeDetector.detectChanges();
  }

  public onAreaVehicleEdit(row: any): void {
    if (!row) {
      return;
    }
    const areaVehicle: IAreaVehicleDialogInfo = {
      areaId: this.selectedPolygon.id,
      sendNotifications: row.sendNotifications,
      type: row.type,
      vehicleId: row.vehicleId,
      notificationEmail: row.notificationEmail,
      notificationPhone: row.notificationPhone,
      areaName: this.selectedPolygon?.name
    };
    const vehicle = this.vehicles.find(veh => veh.carID === row.vehicleId);
    this.dialog.open(AreaControVehicleEditDialogComponent,
      { data: { areaVehicle, vehicle }, minWidth: 550 }).afterClosed().subscribe((result) => {
        if (!!result && result !== false) {
          this.onAreaVehicleSave(result);
        }
      });
  }

  public isRestrictionMet(element: IAreaVehicle): boolean {
    if (element.type === AreaRestrictionType.ALLOWED && element.inArea) {
      return true;
    }
    if (element.type === AreaRestrictionType.FORBIDDEN && !element.inArea) {
      return true;
    }
    return false;
  }

  public isSelected(row: IAreaVehicle): string {
    if (!this.selectedCarIds.includes(row?.vehicleId)) {
      return;
    }
    if (this.isRestrictionMet(row)) {
      return 'selected-green';
    } else {
      return 'selected-red';
    }
  }

  public onAreaVehicleSave(result: IAreaVehicleEditRequest): void {
    this.areaControlService.addEditAreaVehicle(this.selectedPolygon.id, result, () => {});
  }

  onShowOnMap(areaVehicle: IAreaVehicle): void {
    this.vehicleService.selectCar(areaVehicle?.vehicleId);
  }

  rowShowOnMap(row: any): void {
    this.vehicleService.selectCar(row?.vehicleId);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.areaControlService.setSelectedPolygon(null);
    this.mapService.clearPoints();
  }
}
