import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SidebarItemComponent {

  @Input()
  public icon: string;

  @Input()
  public label: string;

  @Input()
  public path: string;

  @Input()
  public title?: string;

  @Input()
  public activeCount: string;

  @Input()
  public activeItem?: ActiveMenuItem;

  public constructor(private navigationService: NavigationService, private router: Router
  ) { }

  public handleClick(): void {
    this.navigationService.navigate(this.path, this.title, () => {
      this.router.navigateByUrl(this.path);
    });
  }
}
