<div class="container">
    <form class="form" [formGroup]="form">
        <div class="container">
            <p class="dialog-title"><b>{{ 'drive-book.multiply-edit' | translate }}</b></p>
            <mat-divider></mat-divider>
            <ui-divider></ui-divider>
            <mat-form-field class="row">
                <mat-label>{{ 'drive-book.edit-form.drive.drive-type' | translate }}</mat-label>
                <mat-select formControlName="driveTypeOverWrite">
                    <mat-option *ngFor="let option of overwriteOptions" [value]="option">
                        {{ option | overWriteType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="row" *ngIf="drivers?.drivers?.drivers?.length > 0">
                <mat-label>{{ 'drive-book.edit-form.drive.driver' | translate }}</mat-label>
                <mat-select formControlName="driver">
                    <mat-option *ngFor="let driver of drivers.drivers.drivers" [value]="driver.nPersonID">
                        {{ driver.strSurname }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width text-input">
                <mat-label>{{ 'drive-book.edit-form.drive.purpose' | translate }}</mat-label>
                <input matInput formControlName="purpose">
            </mat-form-field>
            <div class="full-width select-box">
                <mat-checkbox matListIcon formControlName="deletePurposes">
                    <mat-label>{{ 'drive-book.edit-dialog.delete-purposes' | translate }}</mat-label>
                </mat-checkbox>
            </div>
        </div>
    </form>
    <div mat-dialog-actions class="buttons">
        <button mat-button mat-raised-button
            (click)="closeDialog()">
            {{ 'drive-book.edit-dialog.cancel' | translate }}
        </button> 
        <div class="action-buttons">  
            <button mat-button
                mat-raised-button
                color="primary"         
                (click)="save()">
                {{ 'drive-book.edit-dialog.save' | translate }}
            </button>
        </div>
    </div>
</div>