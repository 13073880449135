import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';
import { NavigationQuery } from 'src/app/store/navigation/state/navigation.query';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})

export class ContentComponent implements OnDestroy {
  showContentPanel: boolean;
  pageTitle: string;
  subscriptions = new Subscription();

  constructor(
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery) {
      this.subscriptions.add(this.navigationQuery.pageTitle$.subscribe((data) => {
        setTimeout(() => {
          this.pageTitle = data;
        }, 0);
      }));
      this.subscriptions.add(this.navigationQuery.showContentPanel$.subscribe((data) => {
        this.showContentPanel = data;
      }));
  }

  public togglePanel(): void {
    this.navigationService.setShowContentPanel(!this.showContentPanel);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
