import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule, AppDataResolver } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { AgmCoreModule } from '@agm/core';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminHeaderComponent } from './layouts/admin-header/admin-header.component';
import { RouterModule } from '@angular/router';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { ContentComponent } from './components/content/content.component';
import { AdminUserListComponent } from './components/admin/admin-user-list/admin-user-list.component';
import { AdminUserAddEditComponent } from './components/admin/admin-user-add-edit/admin-user-add-edit.component';
import { ApiModule } from './api/api.module';
import { PagesModule } from './pages/pages.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { UserCredentialsComponent } from './components/user-credentials/user-credentials.component';
import { ContentTitlePanelComponent } from './components/content-title-panel/content-title-panel.component';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { MapViewComponent } from './components/map-view/map-view.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthorizeInterceptor } from './interceptors/authorize.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ChartsModule } from 'ng2-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordSetComponent } from './components/forgot-password-set/forgot-password-set.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AdminRolesDescriptionComponent } from './components/admin/admin-roles-description/admin-roles-description.component';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatPaginatorIntl } from '@angular/material';
import { MatPaginatorIntlCustomLanguage } from './ui-components/pagination/mat-paginator-intl';
import { MapLegendComponent } from './components/map-legend/map-legend.component';
import { AppConfigService } from './services/appConfig.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function initConfig(appConfigService: AppConfigService) {
  return () => appConfigService.loadAppConfig();
}

const DEFAULT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordSetComponent,
    HomeComponent,
    LoginLayoutComponent,
    SiteLayoutComponent,
    AdminLayoutComponent,
    AdminHeaderComponent,
    HelpPageComponent,
    SidebarItemComponent,
    ContentComponent,
    AdminUserListComponent,
    AdminUserAddEditComponent,
    ContentComponent,
    UserCredentialsComponent,
    ContentTitlePanelComponent,
    MapViewComponent,
    AdminRolesDescriptionComponent,
    MapLegendComponent
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC8PNBMsLSTGOy7PL5ihqFjIc7JR8NAjBk',
      libraries: ['drawing']
    }),
    ApiModule,
    PagesModule,
    UiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule.forRoot(),
    ChartsModule,
    NgxMaterialTimepickerModule.setLocale('cs-CZ'),
    NgxPermissionsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ AppConfigService ],
  },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    CookieService,
    MatDatepickerModule,
    ColorPickerModule,
    AppDataResolver,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_SCROLLBAR_CONFIG
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'cs'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: forwardRef(() => MatPaginatorIntlCustomLanguage)
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
