import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';
import { Observable } from 'rxjs';
import { ICarColorDTO, ICarColorsDTO } from './models/dto/car-colors.dto';
import { ICarFuelDTO, ICarFuelsDTO } from './models/dto/car-fuels.dto';
import { map } from 'rxjs/operators';
import { ICarBodyDTO, ICarBodiesDTO } from './models/dto/car-body.dto';

@Injectable()
export class CarPropertiesHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchColors(): Observable<ICarColorDTO[]> {
    return this.http.get<ICarColorsDTO>(this.urls.fetchCarColors()).pipe(
      map((response) => response.carColors)
    );
  }

  public fetchFuels(): Observable<ICarFuelDTO[]> {
    return this.http.get<ICarFuelsDTO>(this.urls.fetchCarFuels()).pipe(
      map((response) => response.fuels)
    );
  }

  public fetchCarBodies(): Observable<ICarBodyDTO[]> {
    return this.http.get<ICarBodiesDTO>(this.urls.fetchCarBodies()).pipe(
      map((response) => response.carBodies)
    );
  }
}
