import { Injectable } from '@angular/core';
import { UserInfo, IUserInfoDTO } from './models/user/user-info';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserHttpService } from './user.service';
import { UserLoginInfo } from './models/user/user-login-info';
import { UserGroups } from './models/user/user-groups';
import { MapService } from '../store/map/state/map.service';
import { NavigationService } from '../store/navigation/state/navigation.service';
import { CarsService } from '../store/cars/state/cars.service';
import { DriveBookService } from '../store/drive-book/state/drive-book.service';
import { DriveBookDetailService } from '../store/drive-book-detail/state/drive-book-detail.service';
import { CarsPositionService } from '../store/cars-position/cars-position.service';
import { CarRemindersService } from '../store/car-reminders/state/car-reminders.service';
import { AreaControlService } from '../store/area-control/state/area-control.service';
import { ProfileService } from '../store/profile/state/profile.service';
import { map } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { LoggerService } from '../services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject: BehaviorSubject<UserInfo>;
  public user: Observable<UserInfo>;
  public isWebAdmin: boolean;

  constructor(private mapService: MapService,
              private navigationService: NavigationService,
              private carService: CarsService,
              private drivebookService: DriveBookService,
              private carRemindersService: CarRemindersService,
              private carPositionService: CarsPositionService,
              private drivebookDetailService: DriveBookDetailService,
              private areaControlService: AreaControlService,
              private profileService: ProfileService,
              private userHttpService: UserHttpService,
              private permissionsService: NgxPermissionsService,
              private rolesService: NgxRolesService,
              private loger: LoggerService

  ) {
    this.userSubject = new BehaviorSubject<UserInfo>(null);
    const userToken = JSON.parse(localStorage.getItem('auth_token'));
    if (userToken !== null) {
      const user = new UserInfo();
      user.email = this.getEmail();
      user.username = this.getUsername();
      this.userSubject.next(user);
    }
    this.user = this.userSubject.asObservable();
  }

  public isLoggedIn() {
    return !!this.getUser() ? true : false;
  }

  public getUser() {
    return this.userSubject.value;
  }

  public getToken() {
    return JSON.parse(localStorage.getItem('auth_token'));
  }

  public getEmail() {
    return JSON.parse(localStorage.getItem('auth_email'));
  }

  public getUsername() {
    return JSON.parse(localStorage.getItem('auth_username'));
  }

  public getRole() {
    return this.getUser() !== null ? this.getUser().role : null;
  }

  public isAdmin() {
    return this.isWebAdmin;
  }

  public isEnabledFor(role: UserGroups) {
    if (this.getRole() === role) {
      return true;
    } else {
      return false;
    }
  }

  public saveUser(userLoginInfo: UserLoginInfo) {
    localStorage.setItem('auth_token', JSON.stringify(userLoginInfo.token));
    localStorage.setItem('auth_username', JSON.stringify(userLoginInfo.username));
    const permissions = userLoginInfo.permissions.map(p => p.name);
    this.loadPermissions(permissions);
    const user = new UserInfo();
    user.username = userLoginInfo.username;
    user.id = userLoginInfo.id;
    this.userSubject.next(user);
  }

  public logoutUser() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_email');
    localStorage.removeItem('auth_username');
    this.permissionsService.flushPermissions();
    this.clearAllStores();
    this.userSubject.next(null);
  }

  private clearAllStores(): void {
    this.profileService.clearStore();
    this.mapService.clearStore();
    this.carService.clearStore();
    this.drivebookService.clearStore();
    this.drivebookDetailService.clearStore();
    this.navigationService.clearStore();
    this.carPositionService.clearStore();
    this.carRemindersService.clearStore();
    this.areaControlService.clearStore();
  }

  public refreshToken(): void {
    this.userHttpService.refreshToken().subscribe((response) => {
      this.loger.logInfo(`RefreshToken: new token requested at ${ new Date().toLocaleTimeString() }`);
      localStorage.setItem('auth_token', JSON.stringify(response.accessToken));
    }, () => { });
  }

  public loadAuthData(userHttpService: UserHttpService): Observable<IUserInfoDTO> {
    this.userHttpService.fetchContactData().subscribe(
      profile => {
        if (profile != null) {
          const permissions = profile.userPermissions.map(p => p.name);
          if (profile.gdpr) {
            permissions.push('GDPR');
          }
          this.profileService.setContactData(profile);
          this.isWebAdmin = profile.userGroupName === UserGroups.UserWebAdmin;
          this.loadPermissions(permissions);
          this.loadRole(profile.userGroupName, permissions);
        }
      }
    );

    return userHttpService.loadAuthData(this.getToken())
      .pipe(map((response) => {
        this.profileService.setContactData(response);
        const user = new UserInfo();
        user.username = this.userSubject.value.username;
        user.role = response.userGroupName;
        this.userSubject.next(user);
        this.user = this.userSubject.asObservable();
        return response;
      }), () => {
        return null;
      });
  }

  loadRole(userRole: string, permissions: string[]) {
    this.rolesService.addRole(userRole, permissions);
  }

  loadPermissions(permissions: string[]) {
    this.permissionsService.loadPermissions(permissions);
  }
}
