<app-page-wrapper [hasSidePadding]="false" [hasTopPadding]="false" [isLoading]="false">
  <ng-template appPageContent>
    <div class="wrapper">
      <div class="area-info">
        <form [formGroup]="form" (ngSubmit)="savePolygon()">
          <div class="area-container">
            <div class="area-heading">
              {{ 'area-control.area' | translate }}
            </div>
            <div class="area-form-data">
              <mat-form-field class="area-type" [floatLabel]="'never'">
                <mat-select
                  required
                  formControlName="areaPredefinedType"
                  [placeholder]="'area-control.select-area-choices' | translate"
                  disableOptionCentering
                  (valueChange)="handleTypeChange($event)" >
                  <mat-select-trigger *ngIf="form?.value?.areaPredefinedType === 'manual'">
                    <span class="row">{{ 'area-control.manual-draw' | translate }}<mat-icon class="draw-icon transparent">edit</mat-icon></span>
                 </mat-select-trigger>
                    <mat-option *ngFor="let area of predefinedAreas" [value]="area.name">
                      <span><b>{{ ('area-control.predefined-type-' + area.name) | translate }}</b></span>
                    </mat-option>
                    <mat-option [value]="'manual'">
                      <span><mat-icon class="draw-icon transparent">edit</mat-icon><b>{{ 'area-control.manual-draw' | translate }}</b></span>
                    </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <input
                  required
                  type="text"
                  matInput
                  formControlName="areaName"
                  [placeholder]="'area-control.area-name' | translate">
              </mat-form-field>
              <mat-form-field>
                <div class="color-preview" [style.backgroundColor]="areaColor" matPrefix></div>
                <input
                  autocomplete="off"
                  type="text"
                  matInput
                  (colorPickerChange)="colorChanged()"
                  [(colorPicker)]="areaColor"
                  [cpPosition]="'bottom'"
                  [cpOutputFormat]="'hex'"
                  [cpAlphaChannel]="false"
                  [cpPositionOffset]="'50%'"
                  [cpPositionRelativeToArrow]="true"
                  formControlName="areaColor"
                  [placeholder]="'area-control.area-color' | translate">
              </mat-form-field>
              <button type="submit" mat-button mat-raised-button color="primary" [disabled]="!form?.valid">
                {{ 'area-control.save-area' | translate }}
              </button>
              <ui-divider></ui-divider>
              <button type="button" mat-button *ngIf="form?.valid && form?.value?.areaPredefinedType === 'manual'" (click)="resetPolygon()">
                {{ 'area-control.reset-area' | translate }}
              </button>
              <ui-divider></ui-divider>
              <mat-divider></mat-divider>
              <p class="info-text" [innerHTML]="'area-control.info-text' | translate"></p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</app-page-wrapper>
