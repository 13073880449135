import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as moment from 'moment';
import { ICarConciseDTO } from '../../../api/models/dto/car-concise.dto';
import { IDriveBookDayDTO, IDriveBookTotalsDTO, IDriveBookPathWrapperDTO } from '../../../api/models/dto/drive-books.dto';

export enum DriveTypeString {
  All = 'all',
  Business = 'business',
  Private = 'private'
}

export enum DriveTypeOverwrite {
  'no-change' = -1,
  'all-public' = 0,
  'all-private' = 1
}

export enum DriveBookLockOption {
  'lock' = 1,
  'unlock' = 2
}

export interface IDriveBookLockRequest {
  driveBooksIds: number[];
}

export interface IDriveBookMultiplyEditRequest {
  driveBooksIDs: number[];
  driveTypeOverWrite: DriveTypeOverwrite;
  purpose: string;
  driverId: number;
}

export interface IDriveBookFilter {
  intervalFrom: Date;
  intervalTo: Date;
  type: DriveTypeString;
  driver: number;
}

export interface IDriveBookState {
  selectedCarID: number;
  loadingCar: boolean;
  selectedCar?: ICarConciseDTO | null;
  filter: IDriveBookFilter;
  totals: IDriveBookTotalsDTO;
  loadingTotalsAndDriveBooks: boolean;
  driveBooks: IDriveBookDayDTO[];
  selectedDriveBookID?: number;
  selectedDriveBookIDs: number[];
  selectedDriveBookPaths: IDriveBookPathWrapperDTO[];
  loadingPaths: boolean;
  expandedRow: any;
}

export function createInitialState(): IDriveBookState {
  const intervalFrom = moment().add(-5, 'days').toDate();
  intervalFrom.setUTCHours(0, 0, 0, 0);
  const intervalTo = new Date();
  intervalTo.setUTCHours(0, 0, 0, 0);
  return {
    selectedCarID: null,
    loadingCar: false,
    selectedCar: null,
    filter: {
      intervalFrom,
      intervalTo,
      type: DriveTypeString.All,
      driver: null
    },
    totals: {
      totalDistance: 0,
      totalDuration: 0,
      totalNumberOfTrips: 0,
      privateDistance: 0,
      privateDuration: 0,
      privateNumberOfTrips: 0,
      businessDistance: 0,
      businessDuration: 0,
      businessNumberOfTrips: 0,
      speedLimitExceeded: false
    },
    loadingTotalsAndDriveBooks: false,
    driveBooks: [],
    selectedDriveBookID: null,
    selectedDriveBookIDs: [],
    selectedDriveBookPaths: [],
    loadingPaths: false,
    expandedRow: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'drive-book' })
export class DriveBookStore extends Store<IDriveBookState> {

  public constructor() {
    super(createInitialState());
  }

}
