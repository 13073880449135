import { ICustomFilter, DateInterval } from './types';
import * as moment from 'moment';

const MONTH_PREFIX = 'months';

export class FilterToday implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.today';

  public get interval(): DateInterval {
    const now = moment().toDate();
    return [ now, now ];
  }
}

export class FilterYesterday implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.yesterday';

  public get interval(): DateInterval {
    const now = moment();
    const day = now.add(-1, 'days');
    const date = day.toDate();

    return [ date, date ];
  }
}

export class FilterWeek implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.week';

  public get interval(): DateInterval {
    const now  = new Date();
    const day = now.getDay();
    const first = now.getDate() - day + (day === 0 ? -6 : 1);
    const second = first + (day - 1);
    const firstDay = new Date(now.setDate(first));
    const secondDay = new Date(now.setDate(second));

    return [ firstDay, secondDay ];
  }
}

export class FilterSevenDays implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.seven-days';

  public get interval(): DateInterval {
    const now = moment();
    const lastDayDate = now.toDate();
    const firstDayDate = now.add(-7, 'days').toDate();

    return [ firstDayDate, lastDayDate ];
  }
}

export class FilterThirtyDays implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.thirty-days';

  public get interval(): DateInterval {
    const now = moment();
    const lastDayDate = now.toDate();
    const firstDayDate = now.add(-30, 'days').toDate();

    return [ firstDayDate, lastDayDate ];
  }
}

export class FilterCurrentMonth implements ICustomFilter {
  public get label(): string {
    const now = moment();
    const months = moment.months();
    const currentMonth = now.month() + 1;
    const currentMonthName = months[currentMonth - 1].toLowerCase();

    return `${MONTH_PREFIX}.${currentMonthName}`;
  }

  public get interval(): DateInterval {
    const now = moment();
    const first = 1;
    const last = now.date();
    const firstDayDate = new Date(now.toDate().setDate(first));
    const lastDayDate = new Date(now.toDate().setDate(last));

    return [ firstDayDate, lastDayDate ];
  }
}

export class FilterPreviousMonth implements ICustomFilter {
  public get label(): string {
    const now = moment();
    const months = moment.months();
    const currentMonth = now.month() + 1;
    const previousMonth = currentMonth === 1 ? 12 : (currentMonth - 1);
    const previousMonthName = months[previousMonth - 1].toLowerCase();

    return `${MONTH_PREFIX}.${previousMonthName}`;
  }

  public get interval(): DateInterval {
    const now = moment();
    const day = now.date();
    const previousMonthDate = now.add(-1, 'months');
    const firstDay = previousMonthDate.add(-(day - 1), 'days');
    const firstDayDate = firstDay.toDate();
    const daysInMonth = firstDay.daysInMonth();
    const lastDay = firstDay.add((daysInMonth - 1), 'days');
    const lastDayDate = lastDay.toDate();

    return [ firstDayDate, lastDayDate ];
  }
}

export class FilterYear implements ICustomFilter {
  public readonly label = 'drive-book.custom-filters.year';

  public get interval(): DateInterval {
    const now = moment();
    const day = now.date();
    const month = now.month() + 1;
    const firstDay = now.add(-(month - 1), 'months').add(-(day - 1), 'days');
    const firstDayDate = firstDay.toDate();
    const lastDay = firstDay.add(month - 1, 'months').add(day - 1, 'days');
    const lastDayDate = lastDay.toDate();

    return [ firstDayDate, lastDayDate ];
  }
}
