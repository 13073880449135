import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IDayStatsDTO, ICarStatsDTO, IBarChart, IPieChart } from 'src/app/api/models/dto/stats.dto';
import * as moment from 'moment';

export interface IStatsState {
   daysStats: IDayStatsDTO [];
   totalStats: ICarStatsDTO [];
   barChart: IBarChart;
   pieChart: IPieChart;
   selectedCars: number [];
   loadingStats: boolean;
   filter: IStatsFilter;
}

export interface IStatsFilter {
  from: moment.Moment;
  to: moment.Moment;
}

export function createInitialState(): IStatsState {
  return {
    daysStats: [],
    totalStats: [],
    selectedCars: [],
    loadingStats: false,
    barChart: { series: [], labels: []},
    pieChart: { data: [], labels: []},
    filter: { from: moment().subtract(1, 'months').utc(true), to: moment().utc(true) }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stats' })
export class StatsStore extends Store<IStatsState> {

  public constructor() {
    super(createInitialState());
  }
}
