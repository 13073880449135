<div class="wrapper">
  <div class="headline-wrapper">
    <h3 class="mat-h3">{{ title | translate }}</h3>
  </div>

  <div *ngIf="allowNavigateBack" class="back-button">
    <mat-icon (click)="navigateBack()" [matTooltip]="'back' | translate" matTooltipPosition="right">
      arrow_back
    </mat-icon>
  </div>
</div>
