import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IGuardianDTO } from 'src/app/api/models/dto/guardian.dto';

export interface ICarGuardianState {
   guardian?: IGuardianDTO;
   loading?: boolean;
}

export function createInitialState(): ICarGuardianState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'car-guardian' })
export class ICarGuardianStore extends Store<ICarGuardianState> {

  public constructor() {
    super(createInitialState());
  }

}
