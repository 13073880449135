import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { catchError, filter, flatMap, map, switchMap, tap, toArray } from 'rxjs/operators';
import { DriveBookHttpService } from '../../../api/drive-book-http.service';
import { DriveType, IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';
import { CarsService } from '../../cars/state/cars.service';
import { MapService } from '../../map/state/map.service';
import { DriveBookQuery } from './drive-book.query';
import { createInitialState, DriveBookStore, DriveTypeString as StringDriveType, IDriveBookFilter } from './drive-book.store';
import { ProfileQuery } from '../../profile/state/profile.query';

@Injectable({ providedIn: 'root' })
export class DriveBookService {

  public constructor(
    private driveBookStore: DriveBookStore,
    private driveBookHttp: DriveBookHttpService,
    private driveBookQuery: DriveBookQuery,
    private mapState: MapService,
    private carsState: CarsService,
    private profileQuery: ProfileQuery
  ) { }

  private convertDriveType(type: string): number {
    if (type === StringDriveType.Business) {
      return DriveType.Business;
    } else if (type === StringDriveType.Private) {
      return DriveType.Private;
    } else {
      return DriveType.All;
    }
  }

  public selectCar(carID: number): void {
    this.driveBookStore.update({
      selectedCarID: carID,
    });
  }

  public resetSelectedCar(): void {
    this.driveBookStore.update({ selectedCar: null });
  }

  public updateDriveBookData(carID: number | null = null, driveBookFilter: IDriveBookFilter | null = null): void {
    if (!carID) {
      if (!this.driveBookQuery.selectedCarID) {
        return;
      }
      carID = this.driveBookQuery.selectedCarID;
    }

    if (!driveBookFilter) {
      driveBookFilter = this.driveBookQuery.filter;
    }

    of(true).pipe(
      tap(() => {
        this.mapState.clearLines();
        this.mapState.setIsInitialized(false);
      }),
      tap((loading) => this.driveBookStore.update({
        loadingTotalsAndDriveBooks: loading,
        selectedDriveBookIDs: [],
        selectedDriveBookID: 0
      })),
      flatMap(() => this.driveBookHttp.fetchDriveBooksAndTotals(
        carID,
        driveBookFilter.intervalFrom,
        driveBookFilter.intervalTo,
        this.convertDriveType(driveBookFilter.type),
        driveBookFilter.driver
      )),
      tap(([totals, driveBooks]) => this.driveBookStore.update({
        loadingTotalsAndDriveBooks: false,
        driveBooks: [...driveBooks],
        totals: { ...totals }
      })),
      catchError((error) => {
        this.driveBookStore.update({ loadingTotalsAndDriveBooks: false });
        return of(error);
      }),
      tap(() => this.mapState.setIsInitialized(true))
    ).subscribe();
  }

  public updateFilter(nextFilter: Partial<IDriveBookFilter>): void {
    const { selectedCarID } = this.driveBookQuery.getValue();
    const { filter: driveBookFilter } = this.driveBookQuery.getValue();

    const next = {
      ...driveBookFilter,
      ...nextFilter
    };

    this.driveBookStore.update({ filter: next });
    // this.updateDriveBookData(selectedCarID, next);
  }

  public IsDaySelected(day: any) {
    const selectedDriveIds = Object.values(this.driveBookQuery.getValue().selectedDriveBookIDs);
    const driveBookDays = Object.values(this.driveBookQuery.getValue().driveBooks);
    if (driveBookDays?.length === 0) {
      return false;
    }
    const driveBookDay = driveBookDays?.filter(x => x.day === day)[0];
    const DrivebookIdsByDay = driveBookDay?.driveBooks?.driveBooks?.map(drive => drive.driveBookId);

    for (const drivebookId of DrivebookIdsByDay) {
      if (!selectedDriveIds.includes(drivebookId)) {
        return false;
      }
    }
    return true;
  }

  public IsAnyDriveOfDaySelected(day: any) {
    const selectedDriveIds = Object.values(this.driveBookQuery.getValue().selectedDriveBookIDs);
    const driveBookDays = Object.values(this.driveBookQuery.getValue().driveBooks);
    if (driveBookDays?.length === 0) {
      return;
    }
    const driveBookDay = driveBookDays?.filter(x => x.day === day)[0];
    const DrivebookIdsByDay = driveBookDay?.driveBooks?.driveBooks?.map(drive => drive.driveBookId);

    for (const drivebookId of DrivebookIdsByDay) {
      if (selectedDriveIds.includes(drivebookId)) {
        return true;
      }
    }
    return false;
  }

  public setExpandedRow(expandedRow: any): void {
    this.driveBookStore.update({expandedRow});
  }

  public SelectAllDriveBooksByDay(driveBook: IDriveBookDTO[], SelectAllIsChecked: boolean): void {

    let selectedPaths = [];
    if (SelectAllIsChecked) {
      const selectedDriveIds = Object.values(this.driveBookQuery.getValue().selectedDriveBookIDs);
      const drivebookIds = driveBook.map(item => item.driveBookId);
      const drivebookIdsKept: number[] = selectedDriveIds.filter(id => !(drivebookIds.indexOf(id) > -1));

      if (drivebookIdsKept.length === 0) {
        this.removeAllPaths();
        return;
      }

      this.updateDriveBookStore(drivebookIdsKept);
      for (const drivebookId of drivebookIdsKept) {
        this.fetchAndStorePaths(drivebookId, selectedPaths);
      }
      return;
    }

    selectedPaths = Object.values(this.driveBookQuery.getValue().selectedDriveBookPaths);

    let filteredDriveBooks = driveBook;
    if (!this.profileQuery.getValue().contactData.gdpr) {
      filteredDriveBooks = driveBook.filter(item => item.driveType !== 1);
    }
    const driveBookIds = filteredDriveBooks.map(item => item.driveBookId);
    for (const driveBookId of driveBookIds) {
      const selectedDriveIds = Object.values(this.driveBookQuery.getValue().selectedDriveBookIDs);
      const nextIDs = [...selectedDriveIds];
      const index = selectedDriveIds.indexOf(driveBookId);

      if (index === -1) {
        nextIDs.push(driveBookId);
      }

      this.updateDriveBookStore(nextIDs);

      this.fetchAndStorePaths(driveBookId, selectedPaths);
    }
  }

  private fetchAndStorePaths(driveBookId: number, selectedPaths: any[]) {
    const driveBookPaths = this.driveBookHttp.fetchDriveBookPaths(driveBookId);
    driveBookPaths.subscribe(path => {
      if (path?.driveBookPath?.drivebookId > 0 && path?.driveBookPath?.path?.length > 0) {
        selectedPaths.push({
          drivebookId: path.driveBookPath.drivebookId,
          paths: path.driveBookPath.path.map((newpath) => ({
            latitude: newpath.latitude,
            longitude: newpath.longitude,
            speed: newpath.speed,
            date: newpath.date,
          }))
        });
      }
      this.updateStore(selectedPaths);
    });
  }

  private updateDriveBookStore(nextIDs: number[]) {
    this.driveBookStore.update({
      selectedDriveBookIDs: nextIDs,
      loadingPaths: true
    });
  }

  private updateStore(selectedPaths: any[]) {
    this.mapState.setLines(selectedPaths as any);
    this.driveBookStore.update({
      loadingPaths: false,
      selectedDriveBookPaths: [...selectedPaths]
    });
    this.mapState.setIsInitialized(true);
  }

  public removeAllPaths() {
    this.mapState.setLines([]);
    this.driveBookStore.update({
      selectedDriveBookIDs: [],
      loadingPaths: false,
      selectedDriveBookPaths: []
    });
    this.mapState.setIsInitialized(true);
  }

  public removeAllDrivebooks() {
    this.driveBookStore.update({
      driveBooks: [],
    });
  }
  public removeTotals() {
    this.driveBookStore.update({
      totals: {
        totalDistance: 0,
        totalDuration: 0,
        totalNumberOfTrips: 0,
        privateDistance: 0,
        privateDuration: 0,
        privateNumberOfTrips: 0,
        businessDistance: 0,
        businessDuration: 0,
        businessNumberOfTrips: 0,
        speedLimitExceeded: false,
      },
    });
  }

  public selectDriveBook(driveBookID: number): void {
    of(Object.values(this.driveBookQuery.getValue().selectedDriveBookIDs)).pipe(
      tap(() => {
        this.carsState.clearSelectedCarIDs();
      }),
      map((ids) => {
        const nextIDs = [...ids];
        const index = ids.indexOf(driveBookID);

        if (index >= 0) {
          nextIDs.splice(index, 1);
        } else {
          nextIDs.push(driveBookID);
        }

        return nextIDs;
      }),
      tap((ids) => this.driveBookStore.update({
        selectedDriveBookIDs: ids,
        loadingPaths: true
      })),
      switchMap((selectedDriveBookIDs) => from(selectedDriveBookIDs)),
      flatMap((selectedDriveBookID) => this.driveBookHttp.fetchDriveBookPaths(selectedDriveBookID).pipe(
        tap((v) => {
          if (v.driveBookPath.drivebookId === 0 || v.driveBookPath.path.length === 0) {
            const selectedDriveBookIDs = this.driveBookQuery.selectedDriveBookIDs;
            const index = selectedDriveBookIDs.indexOf(selectedDriveBookID);
            // selectedDriveBookIDs.splice(index, 1);
            this.driveBookStore.update({ selectedDriveBookIDs });
          }
        })
      )),
      filter((paths) => paths.driveBookPath.drivebookId !== 0 && paths.driveBookPath.path.length > 0),
      map((paths) => ({
        drivebookId: paths.driveBookPath.drivebookId,
        paths: paths.driveBookPath.path.map((path) => ({
          latitude: path.latitude,
          longitude: path.longitude,
          speed: path.speed,
          date: path.date,
        }))
      })),
      toArray(),
      tap((lines) => {
        this.updateStore(lines);
      })
    ).subscribe();
  }

  public clearStore(): void {
    this.driveBookStore.update(createInitialState());
  }
}
