<div class="item-wrapper" [class.active]="!!title && !!activeItem && title === activeItem">
  <button mat-list-item
          (click)="handleClick()"
          [matTooltip]="label"
          matTooltipPosition="right">

    <mat-icon class="transparent" [matBadge]="activeCount" matBadgeColor="primary">{{ icon }}</mat-icon>
    <div class="label mat-caption">{{ label }}</div>
  </button>
</div>
