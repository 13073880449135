import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { Subscription } from 'rxjs';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { DriveBookDetailService } from '../../store/drive-book-detail/state/drive-book-detail.service';
import { DriveBookDetailQuery } from '../../store/drive-book-detail/state/drive-book-detail.query';
import { Router } from '@angular/router';
import { DriveBookService } from 'src/app/store/drive-book/state/drive-book.service';
import { IFormValues } from '../drive-book-detail/edit-form/edit-form.component';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import { CarsService } from 'src/app/store/cars/state/cars.service';

const NAV_PATH = '/dashboard/drive-book-add';
const PAGE_TITLE = 'page-title.drive-book-add';

@Component({
  selector: 'app-drive-book-add-page',
  templateUrl: './drive-book-add-page.component.html',
  styleUrls: ['./drive-book-add-page.component.scss']
})
export class DriveBookAddPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();

  public selectedCarId: number;

  public constructor(
    private navigationService: NavigationService,
    private driveBookDetailState: DriveBookDetailService,
    private router: Router,
    public readonly driveBookDetailQuery: DriveBookDetailQuery,
    private driveBookState: DriveBookService,
    private driveBookQuery: DriveBookQuery,
    private carsState: CarsService
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.DriveBook,
      pageTitle: PAGE_TITLE,
      backUrl: '/dashboard/drive-book',
      panelWidth: 500
    });

    this.navigationService.showContentPanel('/dashboard/drive-book');

    this.subs.add(this.driveBookQuery.selectedCarID$.subscribe((carId) => this.selectedCarId = carId));
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.subs.unsubscribe();
  }

  public submitForm(data: IFormValues): void {
    this.driveBookDetailState.addNewDriveBook(this.selectedCarId, data).subscribe(async () => {
      this.driveBookState.removeAllDrivebooks();
      this.driveBookState.removeTotals();
      this.driveBookState.updateDriveBookData();
      this.carsState.fetchGroupsWithCars(true);
    });

  }

  public async cancelForm(): Promise<void> {
    await this.navigateBackToDriveBook();
  }

  private async navigateBackToDriveBook(): Promise<boolean> {
    return await this.router.navigateByUrl(`${'/dashboard/drive-book'}/${this.selectedCarId}`);
  }

}
