import { Component, OnDestroy, OnInit } from '@angular/core';
import { CarsHttpService } from '../../../api/cars-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, flatMap, map, tap } from 'rxjs/operators';
import { IFormValues } from '../car-edit-form/car-edit-form.component';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { CarPropertiesHttpService } from '../../../api/car-properties-http.service';
import { ICarColorDTO } from '../../../api/models/dto/car-colors.dto';
import { ICarFuelDTO } from '../../../api/models/dto/car-fuels.dto';
import { ICarConciseDTO } from '../../../api/models/dto/car-concise.dto';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { ICarBodyDTO } from 'src/app/api/models/dto/car-body.dto';
import { UrlRoutes } from 'src/app/app-routes';
import { UtilitiesService } from 'src/app/services/utilities.service';

const NAV_PATH = '/dashboard/car-detail/:car_id/edit';
const PAGE_TITLE = 'page-title.car-edit';

@Component({
  selector: 'app-car-info-edit-page',
  templateUrl: './car-info-edit-page.component.html',
  styleUrls: ['./car-info-edit-page.component.scss']
})
export class CarInfoEditPageComponent implements OnInit, OnDestroy {

  private dataSubscription = new Subscription();

  public loadingData = false;

  public car: ICarConciseDTO | null;

  public carId: any;

  public colors: ICarColorDTO[];

  public fuels: ICarFuelDTO[];

  public bodies: ICarBodyDTO[];

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private carsHttp: CarsHttpService,
    private carPropertiesHttp: CarPropertiesHttpService,
    private navigationService: NavigationService,
    private carState: CarsService,
    private urilityService: UtilitiesService,
  ) { }

  public ngOnInit(): void {
    this.carId = this.route.snapshot.params.car_id;
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.Cars,
      pageTitle: PAGE_TITLE,
      backUrl: this.urilityService.format(UrlRoutes.urls.dashboardCarsDetailUrl, this.carId),
    });
    this.navigationService.showContentPanel(NAV_PATH);
    this.dataSubscription = this.route.params.pipe(
      tap(() => this.setData(true, null, [], [], [])),
      filter((params) => !!params && !!this.carId),
      map((params) => parseInt(params.car_id, 10)),
      flatMap((carID) => this.fetchData(carID)),
      tap(([car, colors, fuels, bodies]) => this.setData(false, car, [...colors], [...fuels], [...bodies]))
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.dataSubscription.unsubscribe();
  }

  private fetchData(carID: number): Observable<[ICarConciseDTO, ICarColorDTO[], ICarFuelDTO[], ICarBodyDTO[]]> {
    return forkJoin([
      this.carsHttp.fetchCarConcise(carID),
      this.carPropertiesHttp.fetchColors(),
      this.carPropertiesHttp.fetchFuels(),
      this.carPropertiesHttp.fetchCarBodies()
    ]);
  }

  private setData(loading: boolean, car: ICarConciseDTO | null, colors: ICarColorDTO[], fuels: ICarFuelDTO[], bodies: ICarBodyDTO[]): void {
    this.loadingData = loading;
    this.car = car;
    this.colors = colors;
    this.fuels = fuels;
    this.bodies = bodies;
  }

  public submitForm(carID: number, values: IFormValues): void {
    this.carState.updateCarConcise(carID, values).subscribe(async () => {
      this.carState.fetchGroupsWithCars(true);
      await this.navigateBackToCarDetail();
    });
  }

  public async cancelForm(): Promise<void> {
    await this.navigateBackToCarDetail();
  }

  private async navigateBackToCarDetail(): Promise<boolean> {
    return await this.router.navigateByUrl(this.urilityService.format(UrlRoutes.urls.dashboardCarsDetailUrl, this.carId));
  }
}
