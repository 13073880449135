import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IExportSchedule, PeriodType, FileFormat, ICreateExportSchedule } from 'src/app/api/models/dto/export-schedule.dto';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DriveTypeOverwrite, SumType, DriveType } from 'src/app/store/export/state/export.store';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

interface IDialogInput {
  carId: number;
  schedule: IExportSchedule;
  gdprEnabled: boolean;
}

@Component({
  selector: 'app-export-schedule-edit-dialog',
  templateUrl: './export-schedule-edit-dialog.component.html',
  styleUrls: ['./export-schedule-edit-dialog.component.scss']
})

export class ExportScheduleEditDialogComponent implements OnInit {

  public email = new FormControl('');
  public form: FormGroup;

  public formats = [FileFormat.PDF, FileFormat.DOCX, FileFormat.XLSX, FileFormat.XML];
  public sumFormat  = [SumType['total-sum'], SumType['daily-sum']];
  public overwriteoptions = [DriveTypeOverwrite['no-change'], DriveTypeOverwrite['all-public'], DriveTypeOverwrite['all-private']];
  public periodOptions = [PeriodType.monthly, PeriodType.weekly, PeriodType.daily];
  public driveTypes = [DriveType.all, DriveType.business, DriveType.private ];
  public currentHintDay = 1;

  constructor(
    public fb: FormBuilder,
    public utilitiesService: UtilitiesService,
    public dialogRef: MatDialogRef<ExportScheduleEditDialogComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogInput
  ) { }

  ngOnInit(): void {
    const defaultMonthlyPeriodStartDay = 1;
    this.form = this.fb.group({
      driveType: [
        !!this.data?.schedule ? this.data?.schedule?.driveType : DriveType.all,
        [Validators.required]
      ],
      driveTypeOverWrite: [
        !!this.data?.schedule ? this.data?.schedule?.driveTypeOverWrite : DriveTypeOverwrite['no-change'],
        [Validators.required]
      ],
      emailAddress: [
        !!this.data?.schedule?.emailAddress ? this.data?.schedule?.emailAddress : null,
        [this.utilitiesService.validateEmails(false), Validators.required]
      ],
      fileFormat: [
        !!this.data?.schedule ? this.data?.schedule?.fileFormat : FileFormat.PDF,
        [Validators.required]
      ],
      periodType: [
        !!this.data?.schedule ? this.data?.schedule?.periodType : PeriodType.monthly,
        [Validators.required]
      ],
      dailySum: [
        !this.data?.schedule?.dailySum ? SumType['total-sum'] : SumType['daily-sum'],
        [Validators.required]
      ],
      monthlyPeriodStartDay: [
        this.data?.schedule?.monthlyPeriodStartDay ? this.data?.schedule?.monthlyPeriodStartDay : defaultMonthlyPeriodStartDay,
        [this.utilitiesService.validateNumber(false)]
      ],
      gdpr: [
        !!this.data?.schedule?.gdpr ? this.data?.schedule?.gdpr : true,
        [Validators.required]
      ],
      isShortPrinting: [
        !!this.data?.schedule?.isShortPrinting ? this.data?.schedule?.isShortPrinting : false,
        [Validators.required]
      ]
    });

    this.currentHintDay = this.data?.schedule?.monthlyPeriodStartDay
      ? this.data?.schedule?.monthlyPeriodStartDay
      : defaultMonthlyPeriodStartDay;

    this.form.controls.monthlyPeriodStartDay.valueChanges.pipe(
      map((changedValue) => {
        if (changedValue === null || changedValue < 1 || changedValue > 28) {
          return defaultMonthlyPeriodStartDay;
        }
        return changedValue;
      })
    ).subscribe((changedValue) => {
      this.currentHintDay = changedValue;
    });
  }

  public save() {
    if (!!this.data?.schedule) {
      this.onEdit();
    } else {
      this.onSave();
    }
  }

  public onSave() {
    const request: ICreateExportSchedule = {
      carId: this.data.carId,
      emailAddress: this.form.value.emailAddress,
      periodType: this.form.value.periodType,
      driveType: this.form.value.driveType,
      driveTypeOverWrite: this.form.value.driveTypeOverWrite,
      fileFormat: this.form.value.fileFormat,
      active: true,
      dailySum: this.form.value.dailySum === SumType['daily-sum'],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      monthlyPeriodStartDay: this.form.value.periodType === PeriodType.monthly ? this.form.value.monthlyPeriodStartDay : null,
      isShortPrinting: this.form.value.isShortPrinting,
      gdpr: !this.data?.gdprEnabled ? true : this.form.value.gdpr,
    };
    this.dialogRef.close(request);
  }

  public onEdit() {
    const request: IExportSchedule = {
    id: this.data.schedule.id,
    carId: this.data.carId,
    emailAddress: this.form.value.emailAddress,
    periodType: this.form.value.periodType,
    driveType: this.form.value.driveType,
    driveTypeOverWrite: this.form.value.driveTypeOverWrite,
    fileFormat: this.form.value.fileFormat,
    active: true,
    dailySum: this.form.value.dailySum === SumType['daily-sum'],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    monthlyPeriodStartDay: this.form.value.periodType === PeriodType.monthly ? this.form.value.monthlyPeriodStartDay : null,
    isShortPrinting: this.form.value.isShortPrinting,
    gdpr: this.form.value.gdpr,
    };
    this.dialogRef.close(request);
  }

  public driveTypeNameKey(driveType: DriveType): string {
    return `drive-book.totals.${DriveType[driveType].toString().toLocaleLowerCase()}`;
  }

  getDay(monthlyPeriodStartDay: number, date: moment.Moment): string {
    const newDate = date.set('date', monthlyPeriodStartDay);
    if (monthlyPeriodStartDay > 1 && monthlyPeriodStartDay < 29) {
      return newDate.format('D.M.');
    } else {
      return newDate.format('1.M.');
    }
  }

  getDayTo(monthlyPeriodStartDay: number, date: moment.Moment): string {
    const newDate = date.set('date', monthlyPeriodStartDay).add(-1, 'days');
    return newDate.format('D.M.');
  }

  getHintText(monthlyPeriodStartDay: number): string {
    return this.translateService.instant('export-schedule.hint',
        { hintDay: this.getDay(monthlyPeriodStartDay, moment.utc().add(1, 'months')),
          hintFrom: this.getDay(monthlyPeriodStartDay, moment.utc()),
          hintTo: this.getDayTo(monthlyPeriodStartDay, moment.utc().add(1, 'months'))});
  }
}
