import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NavigationStore, INavigationState } from './navigation.store';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigationQuery extends Query<INavigationState> {

  public readonly backUrl$ = this.select((state) => state.backUrl);
  public readonly allowNavigateBack$ = this.select((state) => (!!state.backUrl && state.backUrl !== '') || state.enableBackAction);
  public readonly pageTitle$ = this.select((state) => state.pageTitle);
  public readonly showContentPanel$ = this.select((state) => state.showContentPanel);
  public readonly activeMenuItem$ = this.select((state) => state.activeMenuItem);
  public readonly backButtonClicked$ = this.select((state) => !!state.backUrlClicked ?  state.backUrlClicked : null);

  public constructor(protected store: NavigationStore) {
    super(store);
  }

  public get allowNavigateBack(): boolean {
    return this.backUrl !== '';
  }

  public get backUrl(): string {
    return this.getValue().backUrl;
  }
}
