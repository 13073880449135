<div class="area-info">
    <ng-container>
      <table mat-table [dataSource]="areas" class="area-table">
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef class="wrapper"><p style="text-align: center;">{{ "area-control.area-color" | translate }}</p>
          </th>
          <td mat-cell *matCellDef="let area"><div class="color-preview" [style.backgroundColor]="area.color"></div></td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.area-name" | translate }}
          </th>
          <td mat-cell *matCellDef="let area">{{ area?.name }}</td>
        </ng-container>
        <ng-container matColumnDef="showOnMap">
          <th mat-header-cell *matHeaderCellDef class="wrapper">{{ "area-control.show-on-map" | translate }}
          </th>
          <td mat-cell *matCellDef="let area">
            <mat-icon class="show-map" [ngClass]="{ active: area?.id === selectedPolygon?.id }" (click)="onAreaSelect(area)">
              gps_fixed
          </mat-icon>
        </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ "form-actions.actions" | translate }} </th>
          <td mat-cell *matCellDef="let area">
            <div class="action-buttons">

              <button [matMenuTriggerFor]="actions" mat-icon-button
                [matTooltip]="'vehicles.vehicles-options' | translate">
                <mat-icon class="actions-icon transparent">more_vert</mat-icon>
              </button>
              <mat-menu #actions="matMenu" xPosition="after" yPosition="below">
                <app-car-action-item (action)="onAreaDetail(area)" icon="visibility" [text]="'area-control.detail' | translate">
                </app-car-action-item>

                <app-car-action-item (action)="onAreaEdit(area)" icon="edit" [text]="'area-control.vehicle-position-edit' | translate">
                </app-car-action-item>

                <app-car-action-item (action)="handleDeleteArea(area)" icon="delete" [text]="'area-control.delete-area-icon' | translate">
                </app-car-action-item>

            </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </ng-container>
  </div>
  <ui-divider></ui-divider>
  <button
    type="button"
    mat-button
    mat-raised-button
    color="primary"
    (click)="onNewArea()">
    {{ 'area-control.add-area' | translate }}
  </button>
