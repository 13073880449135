import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlsService } from './urls.service';
import { IGuardianDTO, IGuardian } from './models/dto/guardian.dto';

@Injectable()
export class CarGuardianHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchCarGuardian(carID: number): Observable<IGuardianDTO> {
    return this.http.get<IGuardianDTO>(this.urls.fetchCarGuardian(carID));
  }

  public createCarGuardian(carID: number, schedule: IGuardian): Observable<IGuardianDTO> {
    return this.http.post<IGuardianDTO>(this.urls.createCarGuardian(carID), schedule);
  }

  public updateCarGuardian(guardianID: number, schedule: IGuardian): Observable<IGuardianDTO> {
    return this.http.put<IGuardianDTO>(this.urls.updateCarGuardian(guardianID), schedule);
  }
}
