import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICarGroupDTO } from '../../../api/models/dto/car-groups.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap, defaultIfEmpty } from 'rxjs/operators';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { CarsStore } from 'src/app/store/cars/state/cars.store';
import { CarRemindersStore } from 'src/app/store/car-reminders/state/car-reminders.store';
import { DriveBookStore } from 'src/app/store/drive-book/state/drive-book.store';

@Component({
  selector: 'app-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarInfoComponent implements OnInit, OnDestroy {

  @Input()
  public allCars: ICarGroupDTO[];

  @Input()
  public fromReminders: boolean;

  @Output()
  public carChanged = new EventEmitter<number>();

  public form: FormGroup;

  private subs = new Subscription();

  public preselectedCars: number[];

  public carsfromList: number[];

  public constructor(
    public readonly driveBookQuery: DriveBookQuery,
    private fb: FormBuilder,
    private carsQuery: CarsQuery,
    public readonly carRemindersQuery: CarRemindersQuery,
    public readonly carsPositionQuery: CarsPositionQuery,
    public readonly carsStore: CarsStore,
    public readonly reminderStore: CarRemindersStore,
    public readonly drivebookStore: DriveBookStore
  ) { }

  public ngOnInit(): void {
    this.subs.add(this.carsQuery.allCars$.subscribe(cars => {
      if (!this.driveBookQuery.selectedCarID) {
        if (cars?.length > 0) {
          this.carChanged.emit(cars[0].carID);
             }}
    })
    );
    this.form = this.createForm();

    this.subs.add(
      this.form.valueChanges.pipe(
        distinctUntilChanged(),
        map((values) => parseInt(values.car, 10)),
        distinctUntilChanged(),
        tap((carID) => this.carChanged.emit(carID))
      ).subscribe()
    );

    const carSelected$: Observable<any[]> = this.carsQuery.changed$.pipe(defaultIfEmpty(null));
    carSelected$.subscribe(carChanged => {
      if (carChanged) {
        this.carsPositionQuery.selectedCarsIDs$.subscribe(data => {
            if (data?.length > 0) {
              this.form.patchValue({car: data[data?.length - 1]});
              return;
            }
      });
      } else {
        if (this.fromReminders) {
          this.subs.add(this.carRemindersQuery.carID$.subscribe((id) => {
            if (!!id) {
              this.form.patchValue({ car: id});
              this.drivebookStore.update({selectedCarID: id});
              return;
            }}));
        } else {
          this.subs.add(this.driveBookQuery.selectedCarID$.subscribe((id) => {
            if (!!id) {
              this.form.patchValue({ car: id});
              this.reminderStore.update({carID: id});
              return;
            }}));
        }}
    });
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.carsStore.update({changed : false});
  }

  private createForm(): FormGroup {
    return this.fb.group({
      car: [null, Validators.required]
    });
  }

}
