<mat-card class="login-form-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
  <div class="flexLayout headerContainer">
    <span class="row">
      <span class="main-form-header"> {{ 'forgot-password-set.title' | translate }} </span>
    </span>
    <span class="spacer"></span>
    <span>
      <span class="">
        <button mat-button
          [matMenuTriggerFor]="langSelector"
          matTooltip="{{ 'language' | translate }}">
          {{ selectedLanguage | uppercase }}
          <mat-icon class="transparent">language</mat-icon>
        </button>
        <mat-menu #langSelector="matMenu"
          x-position="after"
          y-position="below">
          <button mat-menu-item
            (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
          <button mat-menu-item
            (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
            <button mat-menu-item
            *ngIf="showTranslationKeys"
            (click)="changeLanguage('de')">{{ 'translation-keys' | translate }}</button>
        </mat-menu>
      </span>
    </span>
  </div>
  <p>{{ 'forgot-password-set.message'| translate }}</p>
  <ui-divider></ui-divider>
  <form [formGroup]="form"
    class="form">
    <ui-password-input [value]="''"
      [placeholder]="'profile.password-new-password'"
      [name]="'newPassword'"
      (onInputChange)="changeFormValue($event.name, $event.value)"></ui-password-input>

    <ui-password-input [value]="''"
      [placeholder]="'profile.password-new-password-confirm'"
      [name]="'newPasswordConfirm'"
      (onInputChange)="changeFormValue($event.name, $event.value)">
    </ui-password-input>

    <p class="error-label centered"
      *ngIf="!!form.value.newPasswordConfirm && !arePasswordsEqual">{{ 'profile.password-match-error' | translate }}</p>
    <p class="error-label centered"
      *ngIf="!!form.value.newPassword && form.value.newPassword.length < 8">
      {{ 'profile.password-format-error' | translate }}</p>

    <button mat-button
      mat-raised-button
      [disabled]="form.invalid || !arePasswordsEqual || isLoading"
      color="primary"
      class="main-button"
      (click)="setPassword()">
      {{ 'profile.change-password' | translate }}
    </button>
    <ui-divider></ui-divider>
    <button
      mat-button
      class="main-button light"
      (click)="onLogin()">
      {{ 'profile.back-to-login' | translate }}
  </button>
  </form>
</mat-card>
