import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, filter, flatMap, map, tap } from 'rxjs/operators';
import { CarsHttpService } from '../../../api/cars-http.service';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { ICarConciseDTO } from '../../../api/models/dto/car-concise.dto';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { UrlRoutes } from 'src/app/app-routes';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { CarRemindersService } from 'src/app/store/car-reminders/state/car-reminders.service';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';

class CarDetailItem {
  key?: string;
  value?: string;
  isTranslated?: boolean;
  icon?: any;
  divider?: boolean;
}

const NAV_PATH = '/dashboard/car-detail';
const PAGE_TITLE = 'page-title.car-detail';

@Component({
  selector: 'app-car-detail-page',
  templateUrl: './car-detail-page.component.html',
  styleUrls: ['./car-detail-page.component.scss']
})
export class CarDetailPageComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public loadingData = false;
  public car: ICarConciseDTO | null;
  public items: CarDetailItem[];
  public lastTachoValue: string;

  public constructor(
    private route: ActivatedRoute,
    private carsHttp: CarsHttpService,
    private navigationService: NavigationService,
    private router: Router,
    public readonly carRemindersQuery: CarRemindersQuery,
    public readonly carQuery: CarsQuery,
    private carRemindersState: CarRemindersService,
    private areaControlService: AreaControlService
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.Cars,
      pageTitle: PAGE_TITLE,
      backUrl: UrlRoutes.urls.dashboardCarsUrl,
      panelWidth: 750
    });

    this.navigationService.showContentPanel(NAV_PATH);

    this.subscription = this.route.params.pipe(
      tap(() => this.setData(null, true)),
      filter((params) => (!!params && !!params.car_id)),
      map((params) => parseInt(params.car_id, 10)),
      flatMap((carID) => this.carsHttp.fetchCarConcise(carID)),
      catchError((err) => of(null)),
      tap((car) => {
        this.setData({ ...car }, false);
        this.subscription.add(this.carRemindersState.selectCar(car?.carId));
        this.subscription.add(this.carsHttp.fetchCarLastTachoValue(car?.carId).subscribe(lastTachoData => {
          this.lastTachoValue = this.numberWithCommas(Math.round(lastTachoData?.tachoValue)) + ' km';
          this.initData();
        }));
      })
    ).subscribe();
  }

  private setData(car: ICarConciseDTO | null, loading: boolean): void {
    this.loadingData = loading;
    this.car = car;
    if (!!this.car) {
      this.initData();
    }
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.subscription.unsubscribe();
    const x = document.getElementById('reminderList');
    if (x?.classList.contains('hide')) {
      x.classList.remove('hide');
    }
    this.carRemindersState.setIsStartEditing(false);
  }

  public navigateToCarEdit(): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-detail`;
    const path = `${ basePath }/${ this.car.carId }/edit`;

    this.navigationService.showContentPanel(basePath);
    this.router.navigateByUrl(path);
  }

  public initData(): void {
    this.items = [
      { key: 'car-detail.description', value: this.car.toolTipText, isTranslated: false, icon: this.car },
      { key: 'car-detail.plate-number-short', value: !this.car ? '' : this.car.spz, isTranslated: false, icon: null },
      { key: 'car-detail.fuel', value: !!this.car.fuel ? this.car.fuel.keyVal : '--', isTranslated: true, icon: null },
      { key: 'car-detail.color', value: this.car?.carColor?.keyVal, isTranslated: true, icon: null },
      { key: 'car-detail.entity-serial-number', value: this.car.entitySerialNumber, isTranslated: false, icon: null },
      { key: 'car-detail.ccs-card-number', value: this.car.ccsCardNumber, isTranslated: false, icon: null },
      { key: 'car-detail.tacho', value: this.lastTachoValue, isTranslated: false, icon: null },
      { key: 'car-detail.note', value: this.car.note, isTranslated: false, icon: null }
    ];
  }

  public numberWithCommas(value) {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  public async isEddited(eddited: boolean): Promise<void> {
    const x = document.getElementById('reminderList');
    if (x.classList.contains('hide')) {
      x.classList.remove('hide');
    } else {
      x.classList.add('hide');
    }
  }
}
