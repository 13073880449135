<app-page-wrapper [hasSidePadding]="false"
  [hasTopPadding]="false"
  [isLoading]="isLoading">
  <ng-template appPageContent>
    <div class="wrapper">
      <app-area-control-list [areas]="allAreas">       
      </app-area-control-list>
      <ui-divider></ui-divider>
    </div>
    <p class="info-text" [innerHTML]="'area-control-list.info-text' | translate"></p>
    <ui-divider></ui-divider>
  </ng-template>
</app-page-wrapper>
