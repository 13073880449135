<div class="totals-wrapper">
  <div class="left-column"></div>
  <div class="right-column">
    <div class="totals-row">
      <div class="type mat-body-strong">
        {{ 'drive-book.type.all' | translate }}
      </div>

      <div class="values mat-small">
        <div>
          <mat-icon class="inline transparent">equalizer</mat-icon> {{ totals.totalNumberOfTrips | number: '1.0-1' }} {{ 'drive-book.totals.drives' | translate }}
        </div>
        <div>
          <mat-icon class="inline transparent">timeline</mat-icon> {{ totals.totalDistance | number: '1.0-1' }} km
        </div>
        <div>
          <mat-icon class="inline transparent">access_time</mat-icon> {{ totals.totalDuration | durationTime }}
        </div>
      </div>
    </div>

    <div class="totals-row">
      <div class="type mat-body-strong">
        {{ 'drive-book.totals.business' | translate }}
        <img src="./../../../../assets/icons/business.svg" height="30px">
      </div>

      <div class="values mat-small">
        <div>
          <mat-icon class="inline transparent">equalizer</mat-icon>  {{ totals.businessNumberOfTrips | number: '1.0-1' }} {{ 'drive-book.totals.drives' | translate }}
        </div>
        <div>
          <mat-icon class="inline transparent">timeline</mat-icon> {{ totals.businessDistance | number: '1.0-1' }} km
        </div>
        <div>
          <mat-icon class="inline transparent">access_time</mat-icon> {{ totals.businessDuration | durationTime }}
        </div>
      </div>
    </div>

    <div class="totals-row">
      <div class="type mat-body-strong">
        {{ 'drive-book.totals.private' | translate }}
        <img src="./../../../../assets/icons/private.svg" height="30px">
      </div>

      <div class="values mat-small">
        <div>
          <mat-icon class="inline transparent">equalizer</mat-icon> {{ totals.privateNumberOfTrips | number: '1.0-1' }} {{ 'drive-book.totals.drives' | translate }}
        </div>
        <div>
          <mat-icon class="inline transparent">timeline</mat-icon> {{ totals.privateDistance | number: '1.0-1' }} km
        </div>
        <div>
          <mat-icon class="inline transparent">access_time</mat-icon> {{ totals.privateDuration | durationTime }}
        </div>
      </div>
    </div>
  </div>
</div>
