import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { CarsQuery } from '../../../store/cars/state/cars.query';
import { CarsService } from '../../../store/cars/state/cars.service';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { ICarGroupDTO } from '../../../api/models/dto/car-groups.dto';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { AuthService } from 'src/app/api/auth.service';
import { UpdateService } from 'src/app/services/update-service';
import { LoggerService } from 'src/app/services/logger.service';
import { ProfileService } from 'src/app/store/profile/state/profile.service';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { permisionsData } from 'src/app/app-permissions';

const NAV_PATH = '/dashboard/cars';
const PAGE_TITLE = 'page-title.cars';

@Component({
  selector: 'app-cars-page',
  templateUrl: './cars-page.component.html',
  styleUrls: ['./cars-page.component.scss']
})
export class CarsPageComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public selectedCarIds: number[] = [];
  public selectedCarStaticIds: number[] = [];
  public updateTriger: number;
  public carGroups: ICarGroupDTO[] = [];
  private subs = new Subscription();
  public searchQuery: string;
  public defaultMaxGroupsOpenedCount = 10;
  refreshTokenInterval = 1000 * 60 * 1; // 1min
  updateTriggerInterval = 1000 * 5 * 1; // 5sec
  updateAddressInterval = 1000 * 5 * 60 * 1; // 5min
  savedUrl: string;

  public constructor(
    private navigationService: NavigationService,
    private carsStateQuery: CarsQuery,
    private carService: CarsService,
    private carsPositionQuery: CarsPositionQuery,
    private authService: AuthService,
    private updateService: UpdateService,
    private loggerService: LoggerService,
    private profileService: ProfileService,
    private profileQuery: ProfileQuery,
    private router: Router,
    private permissionsService: NgxPermissionsService,
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.Cars,
      pageTitle: PAGE_TITLE
    });

    this.navigationService.showContentPanel(NAV_PATH);
    this.carService.fetchGroupsWithCars();

    this.subs.add(this.carsStateQuery.isLoadingCars$.subscribe((loading) => this.isLoading = loading));
    this.subs.add(this.carsStateQuery.carGroups$.subscribe((carGroups) => this.carGroups = carGroups));
    this.subs.add(this.carsPositionQuery.selectedCarsIDs$.subscribe((ids) => this.selectedCarIds = ids));
    this.subs.add(this.carsPositionQuery.selectedCarsStaticIDs$.subscribe((ids) => {
      this.selectedCarStaticIds = ids;
      this.showStaticCars();
    }));
    this.subs.add(interval(this.updateTriggerInterval).subscribe(() => this.updateTriger = new Date().getTime()));
    this.subs.add(interval(this.refreshTokenInterval).subscribe(() => this.authService.refreshToken()));
    this.subs.add(this.updateService.checkForUpdate$().subscribe(() => {
      this.loggerService.logInfo('RELOADING APP');
    }));
    this.subs.add(this.profileQuery.savedUrl$.subscribe((savedUrl) => this.savedUrl = savedUrl));
    this.subs.add(interval(this.updateAddressInterval).subscribe(() => this.carService.fetchLastAddresses(this.carGroups)));
    this.redirectAfterLogin();
  }

  public ngOnDestroy(): void {
    this.carService.selectStaticCars(this.selectedCarIds);
    this.navigationService.destroyPage();
    this.subs.unsubscribe();
  }

  public showStaticCars(): void {
    if (!!this.selectedCarStaticIds) {
      this.carService.selectCars(this.selectedCarStaticIds);
    }
  }

  public redirectAfterLogin(): void {
    if (!!this.savedUrl) {
      const permissions = this.permissionsService.getPermissions();
      const requestedUrlPermission = permisionsData.find(data => this.savedUrl.includes(data.url));
      if (!!requestedUrlPermission) {
        if (!!permissions[requestedUrlPermission.permission]) {
          this.router.navigateByUrl(this.savedUrl);
        } else {
          return;
        }
      }
      this.profileService.saveUrl(null);
    }
  }

  public selectCar(carID: number): void {
    this.carService.selectCar(carID);
  }

  public selectGroup(group: ICarGroupDTO): void {
    this.carService.selectGroup(group);
  }

  public searchClear(): void {
    this.searchQuery = null;
  }
}
