<div class="card">
    <div class="content">
        <ng-container *ngFor="let item of items">
            <div class="row">
                <img class='icon' [src]="item.icon">
                <div class='description'> {{ item.translationString | translate }}</div>
            </div>
        </ng-container>
    </div>
</div>
