<ui-divider></ui-divider>
<div class="options">
  <button mat-raised-button class="mat-button" (click)="openDialog('Add',{ defaultType: 1 })">
    <mat-icon>show_chart</mat-icon>
    <span>{{'condition.list.distance' | translate}}</span>
  </button>
  <button mat-raised-button class="mat-button" (click)="openDialog('Add',{ defaultType: 2})">
    <mat-icon>date_range</mat-icon>
    {{'condition.list.date' | translate}}
  </button>
</div>
<ui-divider></ui-divider>
<div class="table-container" [ngClass]="{'hidden' : !reminderConditions.data || reminderConditions.data.length === 0}">
  <table #table mat-table [dataSource]="reminderConditions" matSort class="basic-table wide-table">

    <ng-container matColumnDef="operator">
      <th mat-header-cell *matHeaderCellDef class="header-first-cell wrapper"></th>
      <td mat-cell *matCellDef="let element" class="wrapper"> {{getReminderOperator(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="reminderFrom">
      <th mat-header-cell *matHeaderCellDef class="wrapper"> {{ 'condition.list.sincewhen' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{getReminderFrom(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="reminderTo">
      <th mat-header-cell *matHeaderCellDef class="wrapper"> {{ 'condition.list.untildate' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{getReminderTo(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="wrapper distance-cell actionButtons">
      </th>
      <td mat-cell *matCellDef="let element" class="action-link actionButtons">
        <button [matMenuTriggerFor]="actions" mat-icon-button class="actions-button"
          [matTooltip]="'vehicles.vehicles-options' | translate">
          <mat-icon class="actions-icon transparent">more_vert</mat-icon>
        </button>

        <mat-menu #actions="matMenu" xPosition="before" yPosition="below">
          <app-car-action-item (action)="openDialog('Update',element)" icon="edit" [text]="'Update' | translate">
          </app-car-action-item>

          <app-car-action-item (action)="remove(element)" icon="delete_forever"
            [text]="'Delete' | translate">
          </app-car-action-item>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'blocked-row': row.blocked == true}"></tr>
  </table>
</div>
