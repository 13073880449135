<mat-form-field class="full-width">
  <input matInput
         [name]="name"
         [placeholder]="placeholder | translate"
         #p1
         (keyup)="onChange($event)"
         type="password"
         autocomplete="new-password"
         [value]="value">
  <button type="button"
          tabindex="-1"
          mat-button
          matSuffix
          mat-icon-button
          [matTooltip]="(p1.type === 'password' ? 'show-password' : 'hide-password') | translate"
          (click)="p1.type === 'password' ? p1.type ='text' : p1.type='password'">
    <mat-icon>{{ p1.type === 'text'? 'lock' : 'lock_open'}}</mat-icon>
  </button>
</mat-form-field>
