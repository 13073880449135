import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './api/auth.service';
import { CarsSignalRService } from './services/carsSignalR.service';
import { WorkspaceService } from './store/workspace/workspace.service';
import { CarsPositionService } from './store/cars-position/cars-position.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public constructor(
    private authService: AuthService,
    private signalRService: CarsSignalRService,
    private workspaceService: WorkspaceService,
    private carsPositionService: CarsPositionService,
   
    ) {    }

    private workingOnlineData = true;
    private lastQueryTime: number = 0;

  ngOnInit(): void {
    this.workspaceService.getWorkspace();
    if (this.authService.isLoggedIn()) {
      this.signalRService.startConnection();
    }
    this.updateOnlinePositions();
  }

  private updateOnlinePositions(){

    if(this.workingOnlineData){
      setTimeout(()=>{
        if (this.authService.isLoggedIn()) {
          console.log('updateOnlinePositions');
          if(!this.carsPositionService.getNewPositionsFromServer(this.lastQueryTime).subscribe(newLastQueryTime => {
            this.lastQueryTime = newLastQueryTime;
            this.updateOnlinePositions();
          })){
            this.updateOnlinePositions();
          }
        }
        else{
          this.updateOnlinePositions();
        }
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.signalRService.stopConnection();
    this.workingOnlineData = false;
  }
}
