
export interface IAreaControlResponseDTO {
  areas: IAreaControlAreasResponseDTO[];
}

export interface IAreaControlData {
  id: number;
  name: string;
  color: string;
  notificationEmail?: string;
  type: AreaControlDataType;
  points: IAreaControlPathPoint[];
  vehicles?: IAreaVehicle[];
}

export interface IAreaControlAreasResponseDTO {
  id: number;
  name: string;
  color: string;
  notificationEmail?: string;
  type: AreaControlDataType;
  points: IAreaControlPathPointResponseDTO[];
  vehicles?: IAreaVehicle[];
}

export enum AreaControlDataType {
  PREDEFINED = 'PREDEFINED',
  CUSTOM = 'CUSTOM',
}

export interface IAreaControlPathPoint {

  order: number;
  id: number;
  lat: number;
  lng: number;
}

export interface IAreaVehicle {
  vehicleId: number;
  type: AreaRestrictionType;
  sendNotifications: boolean;
  notificationEmail?: string;
  notificationPhone?: string;
  inArea: boolean;
}

export enum AreaRestrictionType {
  NOT_SET = 0,
  FORBIDDEN = 1,
  ALLOWED = 2,
}

export interface IAreaControlAddRequest {
  name: string;
  color: string;
  type: AreaControlDataType;
  points: IAreaControlPathPointAddRequest[];
}
export interface IAreaControlEditRequest {
  id: number;
  name: string;
  color: string;
  type: AreaControlDataType;
  points: IAreaControlPathPointAddRequest[];
}
export interface IAreaControlPathPointAddRequest {
  order: number;
  longitude: number;
  latitude: number;
}

export interface IAreaControlPathPointResponseDTO {
  id: number;
  order: number;
  longitude: number;
  latitude: number;
}

export interface IAreaVehicleEditRequest {
  areaId: number;
  vehicleId: number;
  type: AreaRestrictionType;
  sendNotifications: boolean;
  notificationEmail?: string;
  notificationPhone?: string;
}

export interface IAreaVehicleDialogInfo {
  areaId: number;
  vehicleId: number;
  type: AreaRestrictionType;
  sendNotifications: boolean;
  notificationEmail?: string;
  notificationPhone?: string;
  areaName: string;
}
