import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { IUpcomingReminderDTO } from 'src/app/api/models/dto/reminders.dto';

@Component({
  selector: 'app-alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.scss']
})
export class AlertIconComponent implements OnInit {

  @Input()
  public reminder: IUpcomingReminderDTO;

  @Output()
  public resolveReminder = new EventEmitter<void>();

  @Output()
  public navigateToDetail = new EventEmitter<void>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  public getDate(): string {
    const year = new Date(this.reminder.nextDate).getFullYear();
    if (year === 1) {
      return '';

    } else {
      const dateFormat = new Date(this.reminder.nextDate);
      const date = moment.utc(dateFormat).local().format('D. M. YYYY');
      return date;
    }
  }

  public getClass(reminderLevel: number): string {
    switch (reminderLevel) {
      case 2:
        return 'red-svg';
      case 1:
        return 'red-svg';
      case 0:
        return 'orange-svg';
      default:
        return 'none-svg';
    }
  }

  public getText(reminderLevel: number): string {
    switch (reminderLevel) {
      case 2:
        return 'reminders.level-exceeded';
      case 1:
        return 'reminders.level-rushed';
      case 0:
        return 'reminders.level-close';
      default:
        return 'reminders.level-none';
    }
  }

  public resolve(): void {
    this.resolveReminder.emit();
  }

  public toDetail(): void {
    this.navigateToDetail.emit();
  }

  public getReminderIcon(type: number): string {
    switch (type) {
      case 0: {
        // TODO: change to icon for general reminder, once we get a new one from desginer
        return './../../../../assets/images/engine-oil.svg';
      }
      case 1: {
        return './../../../../assets/images/engine-oil.svg';
      }
      case 2: {
        return './../../../../assets/images/stk.svg';
      }
    }
  }
}
