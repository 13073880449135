<div class="container">
  <form class="form"
    [formGroup]="form">
    <ng-container>
      <p class="dialog-title"><b>{{ 'area-control.set-control-dialog' | translate }}</b></p>
      <mat-divider></mat-divider>
    </ng-container>
    <ui-divider></ui-divider>
    <div class="title-container">
      <div class="row">
        <div class="title">{{ 'area-control.set-control-selected-car' | translate }}</div>
        <div class="title-content">{{ data?.vehicle?.spz }} {{ data?.vehicle?.toolTipText }}</div>
      </div>
      <ng-container *ngIf="data?.withDropdown">
        <div class="row">
          <div class="title move-up">{{ 'area-control.set-control-selected-area' | translate }}</div>
          <div class="title-content">
            <mat-form-field >
              <mat-label>{{ 'area-control.set-control-selected-area' | translate }}</mat-label>
              <mat-select formControlName="area">
                <mat-option *ngFor="let area of areas" [value]="area.id">
                  {{ area.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-hint class="small-text">{{ 'area-control.add-area.help' | translate }}</mat-hint>
          </div>
        </div>        
      </ng-container>
      <ng-container *ngIf="!data?.withDropdown">
        <div class="row">
          <div class="title">{{ 'area-control.set-control-selected-area' | translate }}</div>
          <div class="title-content">
            <mat-form-field appearance="none">
              <input readonly type="text" formControlName="area" matInput>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="title">{{ 'area-control.vehicle-area-type' | translate }}</div>
        <div class="title-content">
          <mat-radio-group formControlName="type"
          aria-label="area-control.vehicle-area-type">
            <mat-radio-button [value]="1">{{ 'area-control.forbidden-area' | translate }}</mat-radio-button>&nbsp;&nbsp;
            <mat-radio-button [value]="2">{{ 'area-control.allowed-area' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="title">{{ 'area-control.email-address' | translate }}</div>
        <div class="title-content">
          <mat-form-field class="email">
            <input [placeholder]="'area-control.area-notification-email' | translate"
                type="text" formControlName="email" matInput>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="title">{{ 'area-control.phone-number' | translate }}</div>
        <div class="title-content">
          <mat-form-field class="email">
            <input [placeholder]="'area-control.area-notification-phone' | translate"
                type="text" formControlName="phone" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="buttons">
    <button mat-button mat-raised-button
      [mat-dialog-close]="false">
      {{ 'area-control.vehicle-position-cancel' | translate }}
    </button>

    <div class="action-buttons">
      <button mat-button mat-raised-button
      *ngIf="data?.areaVehicle?.type !== areaRestrictionTypes.NOT_SET" (click)="onDisableAreaControl()">
        {{ 'area-control.vehicle-disable-control' | translate }}
      </button>

      <button mat-button
        mat-raised-button
        color="primary"
        [disabled]="!form?.valid"
        (click)="onSave()"
        cdkFocusInitial>
        {{ 'area-control.vehicle-position-save' | translate }}
      </button>
    </div>
  </div>
</div>

