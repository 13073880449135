import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapQuery } from '../../store/map/state/map.query';
import { MapService } from '../../store/map/state/map.service';
import { IMapPoint, ICarMapMarker, IMapPolyline, IMapPolylinePoint } from '../../store/map/state/map.store';
import { Subscription, interval } from 'rxjs';
import { ControlPosition, MapTypeControlOptions } from '@agm/core';
import { AgmMap } from '@agm/core';
import { CarsQuery } from '../../store/cars/state/cars.query';
import { ICarDTO, ICarPositionExtra } from '../../api/models/dto/car-groups.dto';
import { Router } from '@angular/router';
import { CarsPositionQuery } from 'src/app/store/cars-position/cars-position.query';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { IAreaControlData, IAreaControlPathPoint, AreaControlDataType } from 'src/app/api/models/dto/area-control.dto';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import * as moment from 'moment';
import { STROKE_OPACITY, STROKE_WEIGHT, FILL_OPACITY } from 'src/app/constants/vehicle-constants';
import { UrlRoutes } from 'src/app/app-routes';
import { VehicleStateEnum } from 'src/app/api/models/car.model';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { Console } from 'console';
declare var google;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, OnDestroy {

  @ViewChild('AgmMap') agmMap: AgmMap;
  subs = new Subscription();
  cars: ICarDTO[];

  lastCourses = new Map<number,number>();

  markers: ICarMapMarker[] = [];
  liveLines: IMapPolyline[] = [];
  lines: IMapPolyline[] = [];

  infoWindowDetail: any = {};
  infoWindowOpened: any[] = [];
  updateTriger: number;

  zoom: number;

  centerPoint: IMapPoint;

  activeVehicleId: number;

  positions: ICarPositionExtra[];

  mapRef: any;
  isDrawingMode = false;
  isEditMode = false;

  drawingManager: any;

  drawOverlay: any;

  selectedPolygon: IAreaControlData;
  editablePolygon: any;
  polygonColor: string;
  vehicleStates = VehicleStateEnum;

  panelExpanded = false;

  useAutoZoom = true;

  intervalData = 5;
  speedThreshold = 1;

  public readonly mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.BOTTOM_RIGHT,
  };

  public constructor(
    public readonly mapQuery: MapQuery,
    public readonly mapService: MapService,
    public readonly carsQuery: CarsQuery,
    public readonly areaControlService: AreaControlService,
    public readonly carsPositionQuery: CarsPositionQuery,
    public readonly areaControlQuery: AreaControlQuery,
    private utilityService: UtilitiesService,
    private carsService: CarsService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.subs.add(this.mapQuery.points$.subscribe((points: ICarMapMarker[]) => {
      this.processMarkers(points);
    }));

    this.subs.add(this.mapQuery.lines$.subscribe((lines) => {
      this.processLines(lines);
    }));
    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((selectedPolygon) => {
      this.processSelectedPolygon(selectedPolygon);
    }));

    this.subs.add(this.mapQuery.zoom$.subscribe((zoom) => {
      this.zoom = zoom;
    }));

    this.subs.add(this.mapQuery.centerPoint$.subscribe((centerPoint) => {
      this.centerPoint = centerPoint;
    }));

    this.subs.add(this.carsQuery.carGroups$.subscribe((groups) => {
      this.cars = !!groups ? groups.reduce((accumulator, obj) => [...accumulator, ...obj.cars], []) : [];
    }));

    this.subs.add(this.mapQuery.focusedVehicle$.subscribe((activeVehicleId) => {
      this.activeVehicleId = activeVehicleId;
    }));

    this.subs.add(this.carsPositionQuery.carsPosition$.subscribe((positions) => {
      this.positions = positions;
    }));

    this.subs.add(this.areaControlQuery.isDrawingMode$.subscribe((isDrawingMode) => {
      this.isDrawingMode = isDrawingMode;
      this.initDrawingManager();
    }));
    this.subs.add(this.areaControlQuery.isEditMode$.subscribe((isEditMode) => {
      this.isEditMode = isEditMode;
      this.initDrawingManager();
    }));

    this.subs.add(this.areaControlQuery.selectedColor$.subscribe((polygonColor) => {
      this.polygonColor = polygonColor;
      if (!!this.editablePolygon) {
        this.editablePolygon.setOptions({
          strokeColor: polygonColor,
          fillColor: polygonColor,
        });
      }
    }));

    this.subs.add(interval(5000).subscribe(() => {
      this.updateTriger = new Date().getTime();
      this.processLiveLines();
    }));
  }

  onMapReady(map) {
    this.mapRef = map;
    this.mapRef.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('zoomBtn'));
    this.mapRef.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('legend'));
    this.initDrawingManager();
  }

  initDrawingManager() {
    if (!this.mapRef) {
      return;
    }
    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingControl: this.isDrawingMode,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        position: google.maps.ControlPosition.TOP_CENTER,
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: this.isDrawingMode ? google.maps.drawing.OverlayType.POLYGON : null,
    };
    if (!this.drawingManager) {
      this.drawingManager = new google.maps.drawing.DrawingManager(options);
      this.handleGoogleEvents();
    } else {
      this.drawingManager.setOptions(options);
    }

    if (this.isDrawingMode) {
      this.drawingManager.setMap(this.mapRef);
    } else if (this.isEditMode) {
      if (!this.selectedPolygon) {
        return;
      }
      this.drawingManager.setMap(this.mapRef);
      this.editablePolygon = new google.maps.Polygon({
        paths: this.selectedPolygon.points,
        strokeColor: this.selectedPolygon.color || this.polygonColor,
        strokeOpacity: STROKE_OPACITY,
        strokeWeight: STROKE_WEIGHT,
        fillColor: this.selectedPolygon.color || this.polygonColor,
        fillOpacity: FILL_OPACITY,
        editable: true
      });
      this.editablePolygon.setMap(this.mapRef);
      google.maps.event.addListener(this.editablePolygon.getPath(), 'set_at', () => {
        this.saveEditPoints(this.editablePolygon?.getPath());
      });

      google.maps.event.addListener(this.editablePolygon.getPath(), 'insert_at', () => {
        this.saveEditPoints(this.editablePolygon?.getPath());
      });
    } else {
      if (!!this.editablePolygon) {
        this.editablePolygon.setMap(null);
        this.editablePolygon = null;
      }
      if (!!this.drawOverlay) {
        this.drawOverlay.overlay.setMap(null);
      }
      this.drawingManager.setDrawingMode(null);
    }
  }

  saveEditPoints(polygonPath: any): void {
    const coordinates: IAreaControlPathPoint[] = [];
    for (let i = 0; i < polygonPath?.length; i++) {
      coordinates.push({
        order: i,
        id: null,
        lat: polygonPath?.getAt(i).lat(),
        lng: polygonPath?.getAt(i).lng()
      });
    }
    this.areaControlService.seteditablePoints(coordinates);
  }

  handleGoogleEvents(): void {
    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (e) => {
      const path = e.getPath();
      const coordinates: IAreaControlPathPoint[] = [];

      for (let i = 0; i < path.length; i++) {
        coordinates.push({
          order: i,
          id: null,
          lat: path.getAt(i).lat(),
          lng: path.getAt(i).lng()
        });
      }
      const polygon: IAreaControlData = {
        color: null,
        id: 0,
        name: 'new_area',
        points: coordinates,
        type: AreaControlDataType.CUSTOM,
      };
      this.areaControlService.setSelectedPolygon(polygon);
      this.areaControlService.seteditablePoints(coordinates);
      this.areaControlService.setDrawingMode(false);
      this.areaControlService.setEditMode(true);
    });
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (e) => {
      this.drawOverlay = e;
      this.drawOverlay.overlay.setMap(null);
      this.drawingManager.setDrawingMode(null);
      this.areaControlService.setEditMode(true);
      this.drawOverlay = null;
    });
  }

  public trackByFunctionPolyline(index, item: IMapPolyline) {
    return !!item ? item.drivebookId : index;
  }

  public trackByFunctionPoint(index, item: ICarMapMarker) {
    return !!item ? item.carId : index;
  }

  private processMarkers(points: ICarMapMarker[]) {
    for (const point of points) {
      const markerIndex = this.markers.findIndex(x => x.carId === point.carId);
      if (markerIndex < 0) {
        this.infoWindowOpened.push(point.carId);
      }
    }
    const prevLength = this.markers.length;
    this.markers = points;
    this.processLiveLines();
    if (!!this.markers && this.markers.length > 0 && prevLength !== points.length) {
      const center = this.utilityService.calculateCenterPoint(this.markers);
      const radius = this.utilityService.calculateRadius(this.markers);
      this.onZoomChange(this.utilityService.calculateZoomLevel(radius));
      setTimeout(() => {
        this.setCenterPoint(center);
      }, 0);
    }
  }

  private processLines(lines: IMapPolyline[]) {
    const prevLength = this.lines.length;
    this.lines = lines;
    if (!!this.lines && this.lines.length > 0 && prevLength !== lines.length) {
      const points = this.calculatePointsFromLines(lines);
      const center = this.utilityService.calculateCenterPoint(points);
      const radius = this.utilityService.calculateRadius(points);
      this.onZoomChange(this.utilityService.calculateZoomLevel(radius));
      setTimeout(() => {
        this.setCenterPoint(center);
      }, 0);
    }
  }

  private processLiveLines() {
    this.markers.forEach((point) => {
      const line = this.liveLines.find(x => x.paths.find(y => y.carId === point.carId));
      const position = this.positions.find(p => p.carId === point.carId);
      const lastPathTimestamp = moment.utc(line?.paths[0]?.timestamp).local();
      const lastPathDiff = this.utilityService.getTimeDiffInMinutes(moment().local(), lastPathTimestamp);
      if (line && line?.paths?.length > 20 || lastPathDiff > 2) {
        line.paths.splice(0, 1);
      }

      if (!line) {
        if (!!position && position.isOnline) {
          const pointMarker: IMapPolylinePoint = {
            carId: point.carId,
            latitude: point.latitude,
            longitude: point.longitude,
            speed: 0,
            timestamp: position?.lastKnownDate,
            driverName: point.driverName,
            direction: point.lastKnownDirection
          };

          const paths: IMapPolyline = { drivebookId: point.carId, paths: [], isDriveBook: false };
          paths.paths.push(pointMarker);
          this.liveLines.push(paths);
        }
      } else {
        const pointMarker: IMapPolylinePoint = {
          carId: point.carId,
          latitude: point.latitude,
          longitude: point.longitude,
          speed: point.speed,
          timestamp: position?.lastKnownDate,
          driverName: point.driverName,
          direction:  point.lastKnownDirection
      };
        line.paths.push(pointMarker);
      }
    });
    this.liveLines = this.liveLines.filter(l => this.markers.map((m) => m.carId).includes(l.drivebookId));
  }

  processSelectedPolygon(selectedPolygon): void {
    this.selectedPolygon = selectedPolygon;
    if (!!this.selectedPolygon) {
      const points = this.utilityService.calculatePointsFromPolygon(this.selectedPolygon.points);
      const center = this.utilityService.calculateCenterPoint(points);
      const radius = this.utilityService.calculateRadius(points);
      this.onZoomChange(this.utilityService.calculateZoomLevel(radius));
      setTimeout(() => {
        this.setCenterPoint(center);
      }, 0);
    }
  }

  calculatePointsFromLines(lines: IMapPolyline[]): IMapPoint[] {
    const points = [];
    if (!lines) {
      return points;
    }

    lines.forEach(line => {
      line.paths.forEach(path => {
        const point: IMapPoint = {
          latitude: path.latitude,
          longitude: path.longitude,
        };
        points.push(point);
      });
    });
    return points;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onZoomChange(zoom: number) {
    if (this.useAutoZoom) {
      this.zoom = zoom;
    }
  }

  public setCenterPoint(center: any): void {
    this.centerPoint = center;
    this.mapService.setCenterPoint(center);
  }

  public getPopupData(vehicleId: number): any {
    const car = this.cars.find(c => c.carID === vehicleId);
    const currentPosition = this.positions.find(p => p.carId === vehicleId);
    if (!!currentPosition && currentPosition.isOnline) {
      return {
        vehicleId,
        speed: car.speed || 0,
        title: car.spz,
        state: 'online',
        description: car.toolTipText,
        lastTimestamp: moment.utc(currentPosition?.lastKnownDate).local(),
        driverName: currentPosition.driverName,
      };
    }
    return {
      vehicleId,
      speed: car.speed || 0,
      title: car.spz,
      state: 'offline',
      description: car.toolTipText,
      lastTimestamp: moment.utc(currentPosition?.lastKnownDate).local(),
      driverName: car.lastKnownDriver,
    };
  }

  public navigateToDrivesBook(vehicleId: number): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/drive-book`;
    const path = `${ basePath }/${ vehicleId }`;
    this.router.navigateByUrl(path);
  }

  public navigateToVehicleDetail(vehicleId: number): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-detail`;
    const path = `${ basePath }/${ vehicleId }`;
    this.router.navigateByUrl(path);
  }

  public navigateToRemainders(vehicleId: number): void {
    const basePath = `${ UrlRoutes.urls.dashboardUrl }/car-reminders`;
    const path = `${ basePath }/${ vehicleId }`;
    this.router.navigateByUrl(path);
  }

  public infoWindowClosed(id: any): void {
    this.infoWindowOpened = this.infoWindowOpened.filter(i => i !== id);
  }

  public clickedMarker(id: any): void {
    if (this.infoWindowOpened.indexOf(id) === -1) {
      this.infoWindowOpened.push(id);
    } else {
      this.infoWindowOpened = this.infoWindowOpened.filter(x => x !== id);
    }
  }

  public getAnimation(point: ICarMapMarker): string {
    return '';
    return this.activeVehicleId === point.carId ? 'BOUNCE' : '';
  }

  public autoZoomBtnClick(value: boolean) {
    this.useAutoZoom = value;
    if (this.useAutoZoom) {
      if (!!this.markers && this.markers.length > 0) {
        const center = this.utilityService.calculateCenterPoint(this.markers);
        const radius = this.utilityService.calculateRadius(this.markers);
        this.onZoomChange(this.utilityService.calculateZoomLevel(radius));
        setTimeout(() => {
          this.setCenterPoint(center);
        }, 0);
      }
    }
  }

  public getOnlineCarImageUrl(vehicleId: number): string{

    const car = this.cars.find(c => c.carID === vehicleId);
    const currentPosition = this.positions.find(p => p.carId === vehicleId);

    let status = 0;

    const marker = this.markers.find(x => x.carId === vehicleId);

    if (currentPosition) {
      const dataOn = this.utilityService
        .getTimeDiffInMinutes(moment().local(), moment.utc(currentPosition?.lastKnownDate).local()) < this.intervalData;
      const keyOn = currentPosition?.isKeyOn;
      const speedOn = currentPosition?.speed > this.speedThreshold;

      if (keyOn)
      {
          if (speedOn)
          {
              //-- VehicleDriving
              if (dataOn)
              {
                  status = 0;
              }
              else
              {
                  status = 1;
              }
          }
          else
          {
              //-- VehicleStopped
              if (dataOn)
              {
                  status = 4;
              }
              else
              {
                  status = 5;
              }
          }
      }
      else
      {
          //-- VehicleDisabled
          if (dataOn)
          {
              status = 2;
          }
          else
          {
              status = 3;
          }
      }

      var course = 0;

      if(currentPosition.longitude !== currentPosition.previousPositions.longitude && currentPosition.latitude !== currentPosition.previousPositions.latitude){
        var angleDeg = Math.atan2(currentPosition.longitude - currentPosition.previousPositions.longitude, currentPosition.latitude - currentPosition.previousPositions.latitude) * 1800 / Math.PI;
        course = Math.round(angleDeg);

        this.lastCourses.set(vehicleId, course);
         // console.log('set to map ' + course);
      } else {
        course = this.lastCourses.get(vehicleId);
        if(!course){
          course = 0;
        }
        //console.log('last from map ' + course);
      }

      return this.carsService.getOnlineCarImage(vehicleId, course, status, 0);
    }

    if(car.lastKnownDirection){
      //console.log('from car ' + car.lastKnownDirection);
      return this.carsService.getOnlineCarImage(vehicleId, car.lastKnownDirection, 3, 0);
    }

//    console.log('not found');

    return this.carsService.getOnlineCarImage(vehicleId, 0, 3, 0);
  }

  public getOnlineCarRotation(vehicleId: number): number{

    const car = this.cars.find(c => c.carID === vehicleId);
    const currentPosition = this.positions.find(p => p.carId === vehicleId);

    let status = 0;

    const marker = this.markers.find(x => x.carId === vehicleId);

    if (currentPosition) {
      var course = 0;

      if(currentPosition.longitude !== currentPosition.previousPositions.longitude && currentPosition.latitude !== currentPosition.previousPositions.latitude){
        var angleDeg = Math.atan2(currentPosition.longitude - currentPosition.previousPositions.longitude, currentPosition.latitude - currentPosition.previousPositions.latitude) * 1800 / Math.PI;
        course = Math.round(angleDeg);
        this.lastCourses.set(vehicleId, course);
      } else {
        course = this.lastCourses.get(vehicleId);
        if(!course){
          course = 0;
        }
      }

      return course / 10;
    }

    if(car.lastKnownDirection){
      return car.lastKnownDirection / 10;
    }

    return 40;
  }
}
