import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { formatDate } from '@angular/common';

const DEFAULT_FORMAT = 'mediumDate';

@Pipe({
  name: 'timestampToString',
  pure: false
})
export class TimestampToStringPipe implements PipeTransform {

  public constructor(
    private langService: LanguageService
  ) {}

  public transform(value: number, ...args: string[]): string {
    const date = new Date(value);
    const lang = this.langService.lang;
    const [ format ] = args;

    return formatDate(date, !format ? DEFAULT_FORMAT : format, lang.toLowerCase());
  }

}
