<div>
  <mat-list-item #row class="row-item" [class.selected]="isSelected" (mouseenter)="setActiveHoverVehicle(true)"
    (mouseleave)="setActiveHoverVehicle(false)">
    <mat-checkbox matListIcon [(ngModel)]="isSelected" class="select-checkbox" (click)="$event.preventDefault()">
    </mat-checkbox>
    <div class="main-text">
      <div class="car-headline" [ngClass]="{'alarm': !car?.isCarMovementAllowed}" [matTooltip]="!car?.isCarMovementAllowed ? ('vehicles.inactive' | translate) : ''">{{ car.spz }}</div>
      <div matLine class="car-description">{{ car.toolTipText }}</div>
    </div>
    <img [src]="car?.carID | vehicleIcon: [car] : [currentPosition]" class="car-type-image" height="15px" matTooltipPosition="right" [matTooltip]="car?.typeKeyVal | translate">
    <div class="expanded-informations">
      <ng-container *ngIf="([currentPosition] | vehicleState: currentPosition?.carId : updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_OFF || ([currentPosition] | vehicleState: currentPosition?.carId : updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_ON;else activeSpeed">
        <div class="car-info-item speed">
          0 km/h
        </div>
      </ng-container>
        <ng-template #activeSpeed>
          <div class="car-info-item speed" [ngClass]="{'moving' : !!currentPosition?.speed }">
            {{ !!currentPosition?.speed ? currentPosition?.speed : 0 }} km/h
          </div>
        </ng-template>
      <ng-container  *ngIf="showContentPanel">
        <div class="main-text">
          <div class="car-info-item position" [matTooltip]="car?.lastKnownPositionAdress?.length > 30 ? car?.lastKnownPositionAdress : ''">
            {{ car?.lastKnownPositionAdress }}
          </div>
          <div class="car-info-item date">{{ car?.lastKnownDate | date:"d.L.yyy, HH:mm"}}</div>
        </div>
    </ng-container>
    </div>

    <div class="actions-button">
      <span *ngIf="showContentPanel">
        <button *ngIf="!car?.isResponding" mat-icon-button [matTooltip]="'vehicles.no-response' | translate">
          <mat-icon class="error-icon actions-icon">error_outline</mat-icon>
        </button>

        <span *ngxPermissionsOnly="['car-list-reminders-read', 'reminder-list']" >
          <div *ngIf="car?.activeReminders?.length > 0; then showIcon else hideIcon"></div>
          <ng-template #showIcon>
            <button [matMenuTriggerFor]="upcomingReminders" mat-icon-button
              [matTooltip]="'vehicles.reminders' | translate">
              <mat-icon [ngClass]="getClass(car?.activeReminders)" class="actions-icon transparent">notifications_none
              </mat-icon>
            </button>
          </ng-template>
          <ng-template #hideIcon>
            <button style="visibility: hidden; cursor: default;">
              <mat-icon >notifications_none</mat-icon>
            </button>
          </ng-template>
        </span>
      </span>

      <mat-menu class="reminders-panel" #upcomingReminders="matMenu" xPosition="after" yPosition="below">
        <p class="reminder-menu-title">{{'vehicles.reminders-list' | translate}}</p>
        <div *ngFor="let reminder of car?.activeReminders">
          <mat-divider></mat-divider>
          <app-alert-icon (navigateToDetail)="navigateToReminderDetail(reminder)" (resolveReminder)="resolveRemider(reminder)" [reminder]="reminder" >
          </app-alert-icon>
        </div>
      </mat-menu>

      <button [matMenuTriggerFor]="actions" mat-icon-button
        [matTooltip]="'vehicles.vehicles-options' | translate">
        <mat-icon class="actions-icon transparent">more_vert</mat-icon>
      </button>

      <mat-menu #actions="matMenu" xPosition="before" yPosition="below">
        <span *ngxPermissionsOnly="['car-detail']">
          <app-car-action-item (action)="navigateToCarDetail()" icon="remove_red_eye" [text]="'car-detail' | translate">
          </app-car-action-item>
        </span>

        <span *ngxPermissionsOnly="['drivebook-read']">
          <app-car-action-item (action)="navigateToRidesBook()" icon="menu_book" [text]="'drive-book' | translate">
          </app-car-action-item>
        </span>

        <span *ngxPermissionsOnly="['reminder-list']">
          <app-car-action-item (action)="navigateToCarReminders()" icon="warning" [text]="'reminders' | translate">
          </app-car-action-item>
        </span>

        <span *ngxPermissionsOnly="['car-movement-edit']">
          <app-car-action-item *ngIf="!car?.isCarMovementAllowed && car?.isCarMovementSupported" (action)="switchMovement()" icon="check_circle" [text]="'allow-movement' | translate">
          </app-car-action-item>

          <app-car-action-item *ngIf="car?.isCarMovementAllowed && car?.isCarMovementSupported" (action)="switchMovement()" icon="cancel" [text]="'forbid-movement' | translate">
          </app-car-action-item>
        </span>
      </mat-menu>
    </div>
    <mat-divider></mat-divider>
  </mat-list-item>
</div>
