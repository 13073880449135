import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDriveBookTotals } from '../../../api/models/car-drive-data.model';
import { IDriveBookTotalsDTO } from '../../../api/models/dto/drive-books.dto';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalsComponent {

  @Input()
  public totals: IDriveBookTotalsDTO;

  public constructor() {}

}
