import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlsService } from './urls.service';

@Injectable()
export class CarMovementHttpService {
  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public allowCarMovement(carID: number): Observable<any> {
    return this.http.post(this.urls.allowCarMovement(carID), carID);
  }

  public forbidCarMovement(carID: number): Observable<any> {
    return this.http.post(this.urls.forbidCarMovement(carID), carID);
  }
}
