import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ProfileStore, IProfileState } from './profile.store';

@Injectable({ providedIn: 'root' })
export class ProfileQuery extends Query<IProfileState> {

  public readonly contactData$ = this.select((state) => state.contactData);
  public readonly username$ = this.select((state) => !!state.contactData ? state.contactData.username : null);
  public readonly profileFilled$ = this.select((state) => !!state.contactData ? state.contactData.hasFilledProfile : null);
  public readonly isLoading$ = this.select((state) => state.isLoading);
  public readonly savedUrl$ = this.select((state) => state.savedUrl);

  public constructor(protected store: ProfileStore) {
    super(store);
  }
}
