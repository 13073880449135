<div class="reminder-content" *ngxPermissionsOnly="['car-list-reminders-read', 'reminder-list']">
  <div class="reminder-row">
    <div (click)="toDetail()" class="icon">
    <img [src]="getReminderIcon(reminder.reminderType)" [ngClass]="getClass(reminder.level)" class="icon-img">
    </div>
    <div class="labels">
        <p (click)="toDetail()" class="title">{{reminder.reminderTitle}}</p>
        <div class="limits"> {{getDate()}} {{reminder.nextTachometer == -1 ? "" : reminder.nextTachometer.toLocaleString() + ' km'}} </div>
    </div>
     
      <div class="actions">
          <p class="importance" [ngClass]="{'red' : reminder.level === 2}">{{ getText(reminder.level) | translate}}</p>
          <ng-container *ngxPermissionsOnly="['car-list-reminders-solve']">
          <p class="resolve" (click)="resolve()">
            {{'reminders.resolve' | translate}}
          </p>
        </ng-container>
       </div>
  </div>
</div>