import { Injectable } from '@angular/core';
import { IMapPoint, IMapPolyline, MapStore, createInitialState, ICarMapMarker } from './map.store';
import { applyTransaction } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class MapService {
  public constructor(
    private mapStore: MapStore
  ) { }

  public setIsInitialized(isInitialized: boolean): void {
    this.mapStore.update({ isInitialized });
  }

  public setCenterPoint(centerPoint: IMapPoint): void {
    this.mapStore.update({ centerPoint });
  }

  public setPoints(points: ICarMapMarker[]): void {
    this.mapStore.update({ points: [...points] });
  }

  public setSpeedPoint(speedPoint: IMapPoint): void {
    this.mapStore.update({ speedPoint });
  }

  public setFocusOnVehicle(focusedVehicleId: number): void {
    this.mapStore.update({ focusedVehicleId });
  }

  public setLines(lines: IMapPolyline[]): void {
    this.mapStore.update({ lines: [...lines] });
  }

  public setZoom(zoomVal: number): void {
    this.mapStore.update({ zoom: zoomVal });
  }

  public addLine(line: IMapPolyline): void {
    this.mapStore.update((state) => ({
      ...state,
      lines: [
        ...state.lines,
        line
      ]
    }));
  }

  public clearMap(): void {
    applyTransaction(() => {
      this.clearPoints();
      this.clearLines();
    });
  }

  public clearPointsExceptOne(objectId: number): void {
    this.mapStore.update((state) => ({
      ...state,
      points: state.points.filter(p => p.objectId === objectId)
    }));
  }

  public clearPoints(): void {
    this.mapStore.update({ points: [] });
  }

  public clearLines(): void {
    this.mapStore.update({ lines: [] });
  }

  public clearStore(): void {
    this.mapStore.update(createInitialState());
  }
}
