import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DriveTypeOverwrite } from 'src/app/store/export/state/export.store';

const KEY_PREFIX = 'export.select-drive-type-overwrite';

@Pipe({
  name: 'overWriteType',
  pure: false
})
export class DriveTypeOverWritePipe implements PipeTransform {

  public constructor(
    private translateService: TranslateService
  ) {}

  public transform(value: number): string {

    switch (value) {
      case DriveTypeOverwrite['no-change']:
        return this.translate(DriveTypeOverwrite['no-change']);
      case DriveTypeOverwrite['all-public']:
        return this.translate(DriveTypeOverwrite['all-public']);
      case DriveTypeOverwrite['all-private']:
        return this.translate(DriveTypeOverwrite['all-private']);
      default:
        return '';
    }
  }

  private translate(driveType: number): string {
    return this.translateService.instant(`${KEY_PREFIX}.${DriveTypeOverwrite[driveType]}`) as string;
  }

}
