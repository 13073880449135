  <div class="map-container">
    <agm-map  #AgmMap
    [longitude]="centerPoint?.longitude"
    [latitude]="centerPoint?.latitude"
    [mapTypeControl]="true"
    [mapTypeControlOptions]="mapTypeControlOptions"
    [streetViewControl]="false"
    [zoom]="zoom"
    (mapReady)="onMapReady($event)"
    (zoomChange)="onZoomChange($event)"
    [fitBoundsPadding]="150"
 >
 
    <button id="zoomBtn" class="zoom" mat-button [matMenuTriggerFor]="menu" [ngClass]="useAutoZoom ? 'on' : 'off'">
      {{ 'auto-zoom.button' | translate  }} 
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="autoZoomBtnClick(true)">
        <mat-icon>check</mat-icon>
        <span>{{ 'auto-zoom.on' | translate  }} </span>
      </button>
      <button mat-menu-item (click)="autoZoomBtnClick(false)">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'auto-zoom.off' | translate  }} </span>
      </button>
    </mat-menu>
    
    
    <mat-expansion-panel hideToggle #panel id="legend" class="toggle-legend" [ngClass]="panelExpanded ? 'expanded': 'collapsed'" (afterCollapse)="panelExpanded = false" (opened)="panelExpanded = true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'map-legend.button' | translate  }}
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon *ngIf="!!panel.expanded">clear</mat-icon>
      </mat-panel-description>
      </mat-expansion-panel-header>
      <app-map-legend></app-map-legend>
    </mat-expansion-panel>

    

      <ng-container *ngFor="let point of markers; trackBy: trackByFunctionPoint">
        <agm-marker
          [latitude]="point.latitude"
          [longitude]="point.longitude"
          [animation]="getAnimation(point)"
          [iconUrl]="{url: getOnlineCarImageUrl(point.objectId), anchor : {x:20, y:20}, scaledSize: {width:40, height:40}}"
          (markerClick)="clickedMarker(point.objectId)">
                  
          <agm-info-window *ngIf="!!point.text" #infoWindow{{data.vehicleId}} (infoWindowClose)="infoWindowClosed(point.objectId)" [disableAutoPan]="!useAutoZoom" [isOpen]="infoWindowOpened.includes(point.objectId)">
            <div class="popup-content">
            <ng-container *ngTemplateOutlet="popupData;context: {infoPopup: getPopupData(point.objectId)}">
            </ng-container>
            <ng-template #popupData let-data="infoPopup">
              <div class="simple-row first-row">
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <div class="popup-icon">
                  <img [src]="point?.carId | vehicleIcon: cars : positions" class="car-type-image" height="20px" width="30px" matTooltipPosition="right"  [matTooltip]="data?.type">
                </div>
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <div class="header-labels">
                  <h4 class="header">{{ data.title }}</h4>
                  <div class="description"> {{ data?.description }}</div>
                </div>
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <div class="speed-container">
                  <ng-container *ngIf="(positions | vehicleState: point?.objectId : updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_OFF || (positions | vehicleState: point?.objectId : updateTriger) === vehicleStates.KEY_OFF_SPEED_ON_DATA_ON;else activeSpeed">
                    <h4 class="speed not-moving">0 km/h</h4>
                  </ng-container>
                  <ng-template #activeSpeed>
                    <h4 class="speed" [ngClass]="{ 'not-moving': !point?.speed || point?.speed < 1}"> {{ !!point?.speed ?  point?.speed : 0 }} km/h</h4>
                  </ng-template>

                  <p class="speed time" [ngClass]="{ 'not-moving': !point?.speed || point?.speed < 1}" style="margin-top: 1px" > {{data.lastTimestamp | date:"d.L.yy HH:mm"}}</p>
                </div>
              </div>
              <div class="simple-row">
                <div class="driver" style="margin-left: 46px;">{{ data?.driverName}}</div>                
              </div>
            </ng-template>
          </div>
          </agm-info-window>
        </agm-marker>
      </ng-container>

      <ng-container *ngFor="let line of (mapQuery.lines$ | async); trackBy: trackByFunctionPolyline">
        <ng-container *ngFor="let path of line.paths; let i = index">
          <agm-polyline [strokeWeight]="path?.speed | speedStrokeWidth" [strokeColor]="path?.speed | speedColor">
            <agm-icon-sequence
              *ngIf="i === 1"
              [fixedRotation]="false"
              [strokeColor]="'#0000FF'"
              path="FORWARD_CLOSED_ARROW">
            </agm-icon-sequence>

            <agm-icon-sequence
              *ngIf="i === line?.paths?.length - 2"
              [fixedRotation]="false"
              [strokeColor]="'#0000FF'"
              path="FORWARD_CLOSED_ARROW">
            </agm-icon-sequence>

            <agm-marker
              [latitude]="path.latitude"
              [longitude]="path.longitude"
              [visible]="false"
              >
            </agm-marker>

            <agm-polyline-point
              [latitude]="path.latitude"
              [longitude]="path.longitude">
            </agm-polyline-point>

            <agm-polyline-point
              *ngIf="line.paths[i + 1] as nextPath"
              [latitude]="nextPath.latitude"
              [longitude]="nextPath.longitude">
            </agm-polyline-point>
          </agm-polyline>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let line of liveLines; trackBy: trackByFunctionPolyline">
        <ng-container *ngFor="let path of line.paths; let i = index">
          <agm-polyline [strokeWeight]="path?.speed | speedStrokeWidth" [strokeColor]="path?.speed | speedColor">

            <agm-marker
              [latitude]="path.latitude"
              [longitude]="path.longitude"
              [visible]="false"
              >
            </agm-marker>

            <agm-polyline-point
              [latitude]="path.latitude"
              [longitude]="path.longitude">
            </agm-polyline-point>

            <agm-polyline-point
              *ngIf="line.paths[i + 1] as nextPath"
              [latitude]="nextPath.latitude"
              [longitude]="nextPath.longitude">
            </agm-polyline-point>
          </agm-polyline>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="mapQuery.speedPoint$ | async as speedPoint">
          <agm-marker
            [latitude]="speedPoint?.latitude"
            [longitude]="speedPoint?.longitude"
            >
          </agm-marker>
        </ng-container>

        <ng-container *ngIf="!!selectedPolygon && !isEditMode">
          <agm-polygon [strokeColor]="selectedPolygon?.color || polygonColor" [fillColor]="selectedPolygon?.color || polygonColor" [strokeWeight]="2" [paths]="selectedPolygon?.points">
        </agm-polygon>
      </ng-container>

    </agm-map>
  </div>
