import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { filter } from 'rxjs/operators';

@Injectable()
export class ConfirmDialogService {

  public constructor(
    private dialog: MatDialog
  ) {}

  public open(message: string)  {
    return this.createDialog(message)
      .afterClosed()
      .pipe(
        filter((result) => !!result)
      );
  }

  private createDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: message
    });
  }

}
