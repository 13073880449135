<div class="row v-center between" *ngIf="!divider">
   <span class="title"> {{ label.toLocaleUpperCase() }}: </span>

  <div class="value">
    <ng-container *ngIf="value === ''; else hasValue">
      &nbsp;
    </ng-container>

    <ng-template #hasValue>
      <ng-container *ngIf="translateValue; else notTranslate">
        {{ value | translate }}
      </ng-container>

      <ng-template #notTranslate>
        <ng-container *ngIf="!!icon">
          <img [src]="icon?.carId | vehicleIcon: [icon]: null: true" class="car-type-image" height="15px">&nbsp;
        </ng-container>
        {{ value }}
      </ng-template>
    </ng-template>
  </div>
</div>
<ui-divider *ngIf="divider"></ui-divider>

