import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { IReminderDTO, IConditionDTO } from '../../../api/models/dto/reminders.dto';
import { MatDatepickerInputEvent } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CarRemindersService } from 'src/app/store/car-reminders/state/car-reminders.service';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { ConfirmDialogService } from 'src/app/ui-components/services/confirm-dialog.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

const CANCEL_MSG = 'reminder.edit-form.actions.cancel';
export interface IReminderFormValues {
  name: string;
  type: number;
  mails: string;
  message: string;
}

@Component({
  selector: 'app-reminders-operation',
  templateUrl: './car-reminder-operation.component.html',
  styleUrls: ['./car-reminder-operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReminderOperationComponent implements OnInit, OnDestroy {

  public reminderForm: FormGroup;
  public reminder: IReminderDTO;

  private sub = new Subscription();
  @Input()
  public carID: number;

  @Input()
  public action: string;

  @Output()
  public isEdited = new EventEmitter<boolean>();
  public isSaving = false;

  public constructor(
    private confirmDialog: ConfirmDialogService,
    private fb: FormBuilder,
    private carRemindersService: CarRemindersService,
    private carService: CarsService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
    public readonly carRemindersQuery: CarRemindersQuery,
    private utilitiesService: UtilitiesService) { }

  public reminderTypes = [];

  ngOnInit(): void {
    this.reminderTypes = [
      { value: 'REMINDER_NONE', key: 0 },
      { value: 'REMINDER_OIL', key: 1 },
      { value: 'REMINDER_STK', key: 2 }
    ];

    this.reminder = this.carRemindersQuery.getEditReminder();
    this.reminderForm = this.initializeForm();

    this.sub.add(
      this.carRemindersQuery.activeEditReminder$.subscribe((reminder) => {
        this.setForm(reminder);
        this.reminder = reminder;
      })
    );

    this.sub.add(
      this.reminderForm.valueChanges.subscribe((values: IReminderFormValues) => {
        this.reminder.name = values.name;
        this.reminder.mails = values.mails;
        this.reminder.message = values.message;
        this.reminder.type = values.type;
        this.carRemindersService.setReminder(this.reminder);
      })
    );
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private initializeForm(): FormGroup {
    return this.fb.group({
      name: [this.reminder.name, [Validators.required, Validators.minLength(3)]],
      type: [
        { value: this.reminderTypes },
        [Validators.required]
      ],
      reminderOff: [this.reminder.reminderTemporaryOff],
      mails: [this.reminder.mails, this.utilitiesService.validateEmails(false)],
      message: [this.reminder.message],
    });
  }

  private setForm(reminder: IReminderDTO) {
    this.reminderForm.patchValue({
      name: reminder.name,
      type: reminder.type,
      mails: reminder.mails,
      message: reminder.message,
    });
  }

  public selectDate(dateEvent: MatDatepickerInputEvent<Date>, from: boolean): void {
    const reminder = this.getFilledReminder();
    if (from) {
      this.carRemindersService.changeDateFromValidReminder(dateEvent.value);
    }
    this.carRemindersService.setReminder(reminder);
  }

  public submitConditions(data: IConditionDTO[]): void {
    this.reminder.conditions = data;
  }

  saveReminder(): void {
    if (this.reminderForm.invalid) {
      this.abortSave('reminderform.create.warning');
      return;
    }

    const reminder = this.getFilledReminder();
    this.isSaving = true;
    if (this.carRemindersQuery.getAction() === 'update') {
      this.saveUpdate(reminder);
    } else if (this.carRemindersQuery.getAction() === 'create') {
      this.saveNew(reminder);
    } else {
      this.abortSave('condition.list.warning');
    }
  }

  private abortSave(error: string) {
    this.notifyService.showError(this.translateService.instant(error));
    this.isSaving = false;
  }

  private saveNew(reminder: IReminderDTO) {
    if (reminder.conditions.length < 1) {
      this.abortSave('condition.list.warning');
    } else {
    this.carRemindersService.saveNewReminder(reminder).subscribe((data) => {
      this.carRemindersService.setAddedReminder(data);
      this.carRemindersService.setIsStartEditing(false);
      this.isSaving = false;
      this.isEdited.emit(true);
      this.carService.fetchGroupsWithCars(true);
    });
   }
  }

  private saveUpdate(reminder: IReminderDTO) {
    if (reminder.conditions.length < 1) {
      this.abortSave('condition.list.warning');
    }

    this.carRemindersService.updateReminder(reminder).subscribe((data) => {
      this.carRemindersService.setUpdatedReminder(data);
      this.carRemindersService.setIsStartEditing(false);
      this.isSaving = false;
      this.isEdited.emit(true);
      this.carService.fetchGroupsWithCars(true);
    });
  }

  getFilledReminder() {
    this.reminder.carId = this.carID;
    this.reminder.name = this.reminderForm.value.name;
    this.reminder.type = this.reminderForm.value.type === -1 ? 0 : this.reminderForm.value.type;
    this.reminder.dateFrom = this.reminder.dateFrom
      ? moment.utc(this.reminder.dateFrom).local().endOf('day').toDate()
      : moment.utc(new Date()).local().startOf('day').toDate();
    this.reminder.mails = this.reminderForm.value.mails;
    this.reminder.message = this.reminderForm.value.message;
    return this.reminder;
  }

  cancelAddNewReminder() {
    if (this.reminderForm.dirty) {
      this.sub.add(
        this.confirmDialog
          .open(CANCEL_MSG)
          .subscribe(response => {
            if (response) {
              this.carRemindersService.setIsStartEditing(false);
              this.isEdited.emit(true);
            }
          }
      ));
    } else {
      this.carRemindersService.setIsStartEditing(false);
      this.isEdited.emit(true);
    }
  }
}
