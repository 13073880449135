import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';
import { IStatsResponseDTO, IStatsRequestDTO } from './models/dto/stats.dto';

@Injectable()
export class StatsHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) {}

  public fetchStats(statsRequest: IStatsRequestDTO): Observable<IStatsResponseDTO> {
    const body = {
      From: statsRequest.From,
      To: statsRequest.To,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return this.http.post<IStatsResponseDTO>(this.urls.fetcStatsForUser(), body);
  }
}
