import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-detail-item',
  templateUrl: './car-detail-item.component.html',
  styleUrls: ['./car-detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarDetailItemComponent {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Input()
  public translateValue: boolean;

  @Input()
  public icon: any;

  @Input()
  public divider: boolean;

  public constructor() { }

}
