<ng-container *ngIf="exportSchedules?.length > 0">
    <div class="table-container">
        <table mat-table [dataSource]="exportSchedules" class="basic-table wide-table">
            <ng-container matColumnDef="timePeriod">
                <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ 'export.schedule-type' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.periodType | periodType }} </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ 'export-schedule.email' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
              </ng-container>
              <ng-container matColumnDef="driveType">
                <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ 'export-schedule.drive-type' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{driveTypeNameKey(element.driveType) | translate }} </td>
              </ng-container>
              <ng-container matColumnDef="format">
                <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ 'export.select-format' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.fileFormat | fileFormat }} </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell class="wrapper actions-button" *matHeaderCellDef> {{ 'form-actions.actions' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="actions-button">
                  <ng-container *ngxPermissionsOnly="['export-report-edit']">                 
                    <button [matMenuTriggerFor]="actions" mat-icon-button class="actions-button"
                      [matTooltip]="'vehicles.vehicles-options' | translate" (click)="$event.stopPropagation()">
                      <mat-icon>more_vert</mat-icon>
                    </button>         
                    <mat-menu #actions="matMenu" xPosition="before" yPosition="below">
                      <button mat-menu-item (click)="editExportSchedule(element)">
                        <mat-icon>edit</mat-icon>
                        <span>{{'Update' | translate}}</span>
                      </button>
                      <button mat-menu-item (click)="deleteSchedule(element.id)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>{{'Delete' | translate}}</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </td>
              </ng-container>            
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'blocked-row': row.blocked == true}"></tr>
        </table>
    </div>
</ng-container>

<div class="padded-content"> 
    <p *ngIf="exportSchedules?.length === 0" >{{'export-schedule.not-set' | translate }}</p> 
    <ui-divider></ui-divider>
    <div class="row">
    <ng-container *ngxPermissionsOnly="['export-report-edit']">
      <button mat-button mat-raised-button color="primary" (click)="addExportSchedule()" class='save-button'>
          {{ 'export-schedule.enable' | translate }}
      </button>
    </ng-container>
    </div>
</div>
