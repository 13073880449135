<app-page-wrapper [isLoading]="(statsQuery.isLoading$ | async)" [hasSidePadding]="false" [hasTopPadding]="false">
  <ng-template appPageContent *ngxPermissionsOnly="['stats']">
    <div class="header">
      <p>{{'stats-page.date-label' | translate}}</p>
      <app-stats-date-picker></app-stats-date-picker>
    </div>
    <mat-tab-group *ngIf="(statsQuery.daysStats$ | async) != null && (statsQuery.daysStats$ | async).length > 0">

    <mat-tab label="{{ 'stats-page.graph-calendar' | translate  }}">
        <div *ngIf="!totalStats || totalStats.length === 0">
          <p class="bar-char-no-data" >{{ 'stats-page.no-data' | translate  }}</p>
        </div>

    <div class="calendar-container" *ngIf="!!totalStats && totalStats.length > 0">
          <div class="cars-list" >
            <mat-list *ngFor="let group of carGroups?.carGroups" >
              <mat-accordion class="vehicle-group-list">
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header  class="group-headline" [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
                        <h3 matSubheader>
                        <mat-checkbox [checked]="isAllSelected(group)"
                        matListIcon class="select-checkbox" (click)="$event.stopPropagation();$event.preventDefault();selectAll(group)"></mat-checkbox>
                          <span class="main-header">{{ group.group.name }}</span>
                        </h3>
                  </mat-expansion-panel-header>
                  <ng-container>
                    <ng-container *ngFor="let car of group?.cars | sortVehicles : '' ">
                      <mat-list-item #row class="row-item" [class.selected]="isSelected" (click)="$event.preventDefault();toogleCarSelected($event, car.carID)">
                        <mat-checkbox matListIcon  [checked]="isCarSelected(car.carID)" class="select-checkbox">
                        </mat-checkbox>
                        <div class="main-text">
                          <div class="car-headline">{{ car.spz }}</div>
                          <div matLine class="car-description">{{ car.toolTipText }}</div>
                        </div>
                      </mat-list-item>
                  </ng-container>
                </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-list>
          </div>
      <div class="calendar-page" *ngIf="!!totalStats && totalStats.length > 0">
          <div class="calendar">
            <div class="weekday-header">{{ 'stats-page.day-1' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-2' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-3' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-4' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-5' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-6' | translate  }}</div>
            <div class="weekday-header">{{ 'stats-page.day-7' | translate  }}</div>
            <app-stats-calendar-day *ngFor="let stat of (statsQuery.selectedCarStats$ | async)" [stats]="stat"
              (click)="selectActiveDay($event,stat)"></app-stats-calendar-day>
          </div>
          <div class="event-datail" *ngIf="!!activeDay" (click)="closeDetail($event)">
            <mat-card (click)="$event.stopPropagation()">
              <mat-card-title>{{ 'stats-page.table-header-day-detail' | translate  }} {{activeDay?.dayOfTheMonth}}. {{activeDay?.month}}.
                </mat-card-title>
                  <button class="close" mat-icon-button (click)="closeDetail($event)">
                    <mat-icon style="padding-bottom: 30px;">close</mat-icon>
                  </button>
              <table mat-table [dataSource]="activeDay.carStats" class="mat-elevation-z8">
                <ng-container matColumnDef="spz">
                  <th mat-header-cell *matHeaderCellDef class="wrapper"> {{ 'stats-page.table-header-spz' | translate  }} </th>
                  <td mat-cell *matCellDef="let car"> {{car?.spz}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="wrapper"> {{ 'stats-page.table-header-name' | translate  }} </th>
                  <td mat-cell *matCellDef="let car"> {{car?.tooltip}} </td>
                </ng-container>

                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef class="wrapper"> {{ 'stats-page.table-header-distance' | translate  }} </th>
                  <td mat-cell *matCellDef="let car"> {{car?.distance?.toFixed(2)}} km </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
              </table>
            </mat-card>
          </div>
      </div>
    </div>

      </mat-tab>
      <mat-tab label="{{ 'stats-page.graph-pie' | translate  }}">
        <div>
          <div class="chart pie-chart">
            <div *ngIf="!pieChartData || pieChartData.length === 0" style="text-align: center">
              <p>{{ 'stats-page.no-data' | translate  }}</p>
          </div>
            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" width="500" height="500" [options]="pieChartOptions">
            </canvas>
            <p class="note" *ngIf="pieChartData.length > 0"><i>{{ 'stats-page.graph-pie-description' | translate}}</i></p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'stats-page.graph-bar' | translate  }}">
        <div>
          <ui-divider></ui-divider>
          <div class="row end">
            <mat-slide-toggle *ngIf="!!barChartData && barChartData.length > 0"
                (change)="toggleType()">
                {{ 'stats-page.graph-type' | translate }}
            </mat-slide-toggle>
            <ui-divider [vertical]="true"></ui-divider>
          </div>
          <div *ngIf="!!barChartData && barChartData.length > 0" class="chart bar-chart">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [legend]="barChartLegend"
              [chartType]="barChartType" [options]="barChartOptions">
            </canvas>
          </div>
          <div *ngIf="!barChartData || barChartData.length === 0">
            <p class="bar-char-no-data" >{{ 'stats-page.no-data' | translate  }}</p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</app-page-wrapper>
