<app-page-wrapper [isLoading]="carRemindersQuery.isLoadingReminders$ | async" [hasSidePadding]="false" [whiteBackground]="carRemindersQuery.isNewReminder$ | async">
  <ng-template appPageContent>
    <div class="content">
      <div id="reminderList">
        <div class="car-select">
          <app-car-info
            [allCars]="carsQuery.carGroups$ | async"
            (carChanged)="changeCar($event)"
            [fromReminders]="true">
        </app-car-info>
        </div>

        <div class="reminder-list" >
          <app-reminders-list [reminders]="carRemindersQuery.reminders$ | async"
            [carID$]="carRemindersQuery.carID$"
            (isEdited)="isEddited($event)">
          </app-reminders-list>
        </div>
      </div>

      <div *ngIf="carRemindersQuery.isNewReminder$ | async" class="reminder-operation">
        <div class="title-container">
          <div class="reminder-name-car">
            <div class="title">{{ 'remider.page.selectedcar' | translate }} </div>
            <div class="title-content">{{this.selectedCar.spz}} {{this.selectedCar.toolTipText}} </div>           
          </div>
          <div class="reminder-name-car">
            <div class="title">{{ 'remider.page.selectedcar-tacho' | translate }} </div>
            <div class="title-content">{{this.lastTachoValue | number:'1.0-0'}} km </div>          
          </div>
        </div>
        <ui-divider></ui-divider>
        <app-reminders-operation
        [carID]="carRemindersQuery.carID$ | async"
        (isEdited)="isEddited($event)">
        </app-reminders-operation>
      </div>
    </div>
  </ng-template>
</app-page-wrapper>
