export const UrlRoutes = {
  urls: {
    dashboardUrl: '/dashboard',
    dashboardCarsUrl: '/dashboard/cars',
    dashboardCarsEditUrl: '/dashboard/car-detail/{0}/edit',
    dashboardCarsDetailUrl: '/dashboard/car-detail/{0}',
    loginUrl: '/login',
    forgotPasswordUrl: '/login/forgot-password',
    areaControl: {
      home: '/dashboard/area-control',
      area: '/dashboard/area-control/{0}',
      edit: '/dashboard/area-control/{0}/edit',
      add: '/dashboard/area-control/add',
    },
    drivebook: {
      home: '/dashboard/drive-book',
      add: '/dashboard/drive-book-add'
    }
  },
  adminUrls: {
    dashboard: '/admin/users'
  }
};
