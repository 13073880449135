import { Component } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/api/auth.service';
import { Router } from '@angular/router';
import { AdminGuard } from 'src/app/guards/admin.guard';
import { UrlRoutes } from 'src/app/app-routes';
import { CarsSignalRService } from 'src/app/services/carsSignalR.service';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent {

  public selectedLanguage = '';
  public currentUser = '';
  public username = '';
  public showTranslationKeys = false;

  constructor(private languageService: LanguageService,
              private translateService: TranslateService,
              private signalRService: CarsSignalRService,
              public authService: AuthService,
              private router: Router,
              public adminGuardService: AdminGuard,
              private appConfigService: AppConfigService) {
    this.selectedLanguage = this.languageService.getLanguage();
    this.translateService.onLangChange.subscribe(() => {
      this.selectedLanguage = this.languageService.getLanguage();
    });
    this.authService.user.subscribe((newUser) => {
      this.currentUser = newUser?.email;
    });

    this.currentUser = this.authService.getUser().email;
    this.username = this.authService.getUser().username;
    this.showTranslationKeys = appConfigService.configuration.showTranslationKeys;
  }
  public navigate(route) {
    this.router.navigate([route]);
  }

  public changeLanguage(lang) {
    this.languageService.changeLanguage(lang);
  }

  public logout() {
    this.signalRService.stopConnection();
    this.authService.logoutUser();
    this.router.navigate([UrlRoutes.urls.loginUrl]);
  }
}
