import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { ICarsPositionsState, CarsPositionStore } from './cars-position.store';
import { ICarMapMarker } from '../map/state/map.store';
import { CarsQuery } from '../cars/state/cars.query';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CarsPositionQuery extends Query<ICarsPositionsState> {

  public readonly selectedCarsIDs$ = this.select((state) => state.selectedCarsIDs);
  public readonly selectedCarsStaticIDs$ = this.select((state) => state.selectedCarsStaticIDs);
  public readonly carsPosition$ = this.select((state) => state.carPositions);

  public readonly selectedCarsPoints$ = combineLatest([
    this.selectedCarsIDs$,
    this.carq.allCars$,
    this.carsPosition$
  ]).pipe(
    map(([ids, cars, positions]) => {
      return ids
        .map((id) => cars.find((car) => car.carID === id))
        .map((car) => ({
          carId: car.carID,
          latitude: positions?.find(p => p.carId === car.carID)?.latitude || car?.position?.latitude,
          longitude: positions?.find(p => p.carId === car.carID)?.longitude || car?.position?.longitude,
          text: `${ car.spz }`,
          objectId: car.carID,
          speed: positions?.find(p => p.carId === car.carID)?.speed,
          lastKnownPosition: car.lastKnownPositionAdress,
          lastKnownDate: car.lastKnownDate,
          lastKnownDirection: car.lastKnownDirection,
          driverName: car.lastKnownDriver
        } as ICarMapMarker));
    })
  );

  public constructor(public store: CarsPositionStore, protected carq: CarsQuery) {
    super(store);
  }

  public isCarSelected(carID: number): Observable<boolean> {
    return this.selectedCarsIDs$.pipe(
      map((ids) => ids.indexOf(carID) >= 0)
    );
  }

}
