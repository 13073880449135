import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IReminderDTO, IRemindersDTO, IUpcomingReminderDTO, IUpcomingRemindersDTO } from './models/dto/reminders.dto';
import { UrlsService } from './urls.service';

@Injectable()
export class CarRemindersHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchRemindersByCarID(carID: number): Observable<IReminderDTO[]> {
    return this.http.get<IRemindersDTO>(this.urls.fetchRemindersByCarID(carID)).pipe(
      catchError((err) => of({ reminders: [] } as IRemindersDTO)),
      map((response) => response.reminders)
    );
  }

  public fetchUpcomingRemindersByCarID(carID: number): Observable<IUpcomingRemindersDTO> {
    return this.http.get<IUpcomingRemindersDTO>(this.urls.fetchUpcomingRemindersByCarID(carID));
  }

  public resolveReminder(reminderID: number): Observable<IUpcomingReminderDTO> {
    return this.http.get<IUpcomingReminderDTO>(this.urls.resolveReminder(reminderID));
  }

}
