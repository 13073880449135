import { Injectable } from '@angular/core';
import { createInitialState, ProfileStore } from './profile.store';
import { UserHttpService } from '../../../api/user.service';
import { IUserInfoDTO, IUserChangePassword, IUserContactDataRequest, IUserSetChangePassword } from 'src/app/api/models/user/user-info';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ProfileService {

  public constructor(
    private profileStore: ProfileStore,
    private userHttpService: UserHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
  ) { }

  public setContactData(contactData: IUserInfoDTO): void {
    this.profileStore.update({ contactData });
  }

  public setIsLoading(isLoading: boolean): void {
    this.profileStore.update({ isLoading });
  }

  public sendForgotPassword(username: string): void {
    this.setIsLoading(true);
    this.userHttpService.sendForgotPassword(username).subscribe((res) => {
      this.notifyService.showInfo(this.translateService.instant('forgot-password.change-successfull'));
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public sendSetForgotPassword(payload: IUserSetChangePassword, onSuceeded: () => void): void {
    this.setIsLoading(true);
    this.userHttpService.setForgotPassword(payload).subscribe((res) => {
      this.notifyService.showInfo(this.translateService.instant('forgot-password-set.change-successfull'));
      this.setIsLoading(false);
      onSuceeded();
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public fetchContactData(): void {
    this.setIsLoading(true);
    this.userHttpService.fetchContactData().subscribe((res) => {
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public sendContactData(request: IUserContactDataRequest, onSuceeded: () => void): void {
    this.setIsLoading(true);
    this.userHttpService.sendContactData(request).subscribe((res) => {
      const userInfo = this.profileStore.getValue().contactData;
      userInfo.emailAddress = request.emailAddress;
      userInfo.fullName = request.fullName;
      this.profileStore.update({ contactData: userInfo });
      this.fetchContactData();
      this.notifyService.showInfo(this.translateService.instant('profile.change-contact-data-successfull'));
      onSuceeded();
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public sendChangePassword(request: IUserChangePassword, onSuceeded: () => void): void {
    this.setIsLoading(true);
    this.userHttpService.sendChangePassword(request).subscribe((res) => {
      this.notifyService.showInfo(this.translateService.instant('profile.change-password-successfull'));
      this.setIsLoading(false);
      onSuceeded();
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public saveUrl(savedUrl: string): void {
    this.profileStore.update({ savedUrl });
  }

  public clearStore(): void {
    this.profileStore.update(createInitialState());
  }
}
