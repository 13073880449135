<div class="speed-histogram">
  <mat-accordion >
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true">
      <mat-expansion-panel-header class="small-header">
        <mat-panel-title>
          {{ 'drive-book.drives.speed-chart' | translate }}
        </mat-panel-title>
        <mat-panel-description class="mat-small" *ngIf="!selectedDriveBookID">
            {{ 'drive-book.drives.speed-chart-no-drivebook-selected' | translate }}
        </mat-panel-description>
        <ng-container *ngIf="!!selectedDriveBookID">
        <mat-panel-description class="mat-small" >
          <mat-icon *ngIf="!panelOpenState" class="toggle">keyboard_arrow_up</mat-icon>
          <mat-icon *ngIf="!!panelOpenState" class="toggle">keyboard_arrow_down</mat-icon>
      </mat-panel-description>
      </ng-container> 
      </mat-expansion-panel-header>
      <div *ngIf="!!selectedDriveBookID">
        <div>
          <mat-form-field class="full-width">
            <mat-select [(ngModel)]="selectedDriveBookID" name="drivebook" (ngModelChange)="onChangeSource($event)">
              <mat-option *ngFor="let routeId  of selectedDriveBookIDs" [value]="routeId">
                <span>{{ getRouteName(routeId) }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="display: block;">
          <canvas baseChart width="500" height="150" [datasets]="lineChartData" [labels]="lineChartLabels"
            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="true" chartType="line"
            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"(mouseleave)="removeSpeedPoint()" ></canvas>
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>
