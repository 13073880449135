import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICustomFilter, DateInterval } from './filters/types';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFilterComponent {

  @Input()
  public filter: ICustomFilter;

  @Output()
  public useFilter = new EventEmitter<DateInterval>();

  public constructor() {}

  public emitFilter(): void {
    if (!!this.filter) {
      const interval = this.filter.interval;
      this.useFilter.emit(interval);
    }
  }

}
