<div class="grid-wrapper" [ngClass]="{ 'vehicle-page': pageTitle === 'page-title.cars', 'expanded': showContentPanel, 'collapsed': !showContentPanel }">
  <div class="panel-content">
    <div class="content-view">
      <router-outlet></router-outlet>
    </div>

    <div class="search">
      <app-content-search-panel></app-content-search-panel>
    </div>
  </div>
  <div class="map-content">
    <app-map-view></app-map-view>
  </div>
  <div class="toggle-button">
    <div (click)="togglePanel()">
      <mat-icon>
        {{ showContentPanel ? 'arrow_left' : 'arrow_right' }}
      </mat-icon>
    </div>
  </div>
</div>

