import * as moment from 'moment';
import { OnInit, OnDestroy, Component, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material';
import { Moment } from 'moment';
import { IStatsFilter } from 'src/app/store/stats/state/stats.store';
import { StatsQuery } from 'src/app/store/stats/state/stats.query';
import { StatsService } from 'src/app/store/stats/state/stats.service';

@Component({
  selector: 'app-stats-date-picker',
  templateUrl: './stats-date-picker.component.html',
  styleUrls: ['./stats-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsDatePickerComponent implements OnInit, OnDestroy {

  private subs = new Subscription();

  public filter: IStatsFilter;

  public constructor(
    private statsService: StatsService,
    private statsQuery: StatsQuery,
  ) { }

  public ngOnInit(): void {
    this.subs.add(
      this.statsQuery.filter$.subscribe((filter) => {
        this.filter = filter;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public selectDate(dateEvent: MatDatepickerInputEvent<moment.Moment>, isFrom: boolean): void {
    const dateMoment = dateEvent.value as Moment;
    const date = dateMoment.local();
    const copy = dateMoment.clone();

    if (isFrom) {
      const daysInMonth = copy.daysInMonth() - 1;
      const to = copy.add(daysInMonth, 'day');
      this.statsService.updateFilter({ from: date, to });
    } else {
      const daysInMonth = copy.daysInMonth() - 1;
      const from = copy.subtract(daysInMonth, 'day').local();
      this.statsService.updateFilter({ from, to: date });
    }
  }

  public submitSearchForm(): void {
    this.statsService.loadStats();
  }
}
