import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';
import { ActiveMenuItem } from 'src/app/store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { StatsService } from 'src/app/store/stats/state/stats.service';
import { StatsQuery } from 'src/app/store/stats/state/stats.query';
import { IDayStatsDTO, ICarStatsDTO } from 'src/app/api/models/dto/stats.dto';
import { FormControl, Validators } from '@angular/forms';
import { ICarGroupDTO, ICarGroupsDTO } from 'src/app/api/models/dto/car-groups.dto';
import { CarsHttpService } from 'src/app/api/cars-http.service';

const NAV_PATH = '/stats';
const PAGE_TITLE = 'page-title.stats';

@Component({
  selector: 'app-stats-page',
  templateUrl: './stats-page.component.html',
  styleUrls: ['./stats-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  public carSelect = new FormControl([], Validators.required);
  public carGroups: ICarGroupsDTO;
  public isSelected: boolean;
  public selectedCarIds: number[] = [];

  public constructor(
    private navigationState: NavigationService,
    private statsService: StatsService,
    public statsQuery: StatsQuery,
    public carsHttp: CarsHttpService
  ) { }

  public activeDay: IDayStatsDTO = null;

  public pieChartLabels = [];
  public pieChartData = [];
  public pieChartType = 'pie';

  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [];

  public tableColumns = ['spz', 'name', 'distance'];

  public pieChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) =>
          `${data.labels[tooltipItems.index]}: ${Number(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]).toFixed(0)} km`,
      },
    },
  };

  public barChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) =>
          // tslint:disable-next-line:max-line-length
          `${data.datasets[tooltipItems.datasetIndex].label}: ${Number(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]).toFixed(0)} km`,
      },
    },
    maintainAspectRatio: false,
  };

  public totalStats: ICarStatsDTO[];

  public ngOnInit(): void {
    this.navigationState.initPage({
      activeMenuItem: ActiveMenuItem.Stats,
      pageTitle: PAGE_TITLE,
      panelWidth: 1500
    });

    this.statsService.loadStats();

    this.subs.add(this.statsQuery.barchart$.subscribe((stats) => {
        this.barChartLabels = Object.assign([], stats.labels);
        const clone = JSON.parse(JSON.stringify(stats.series));
        this.barChartData = clone;
    }));

    this.subs.add(this.statsQuery.piechart$.subscribe((stats) => {
      const clone = JSON.parse(JSON.stringify(stats.data));
      this.pieChartData = clone;
      this.pieChartLabels = stats.labels;
    }));

    this.subs.add(this.statsQuery.totalStats$.subscribe((stats) => {
      this.totalStats = stats;
    }));

    this.subs.add(this.statsQuery.selectedCars$.subscribe(cars => {this.selectedCarIds = cars; }));
    this.subs.add(this.carsHttp.fetchCarGroups().subscribe((carGroups) => { this.carGroups = carGroups; }));
  }

  public ngOnDestroy(): void {
    this.navigationState.destroyPage();
    this.subs.unsubscribe();
  }

  public selectActiveDay(e: Event, day: IDayStatsDTO): void {
    if (day.carStats.length > 0) {
      this.activeDay = day;
    }
  }

  public closeDetail(event): void {
    this.activeDay = null;
  }

  public toogleCarSelected(event: Event, carId: number): void {
    this.statsService.toogleCarSelected(carId);
  }

  public isCarSelected(id: number): boolean {
    const { selectedCars: carIds } = this.statsQuery.getValue();
    return carIds.includes(id);
  }

  public toggleType(): void {
    this.barChartType = this.barChartType === 'bar' ? 'line' : 'bar';
  }

  public isAllSelected(group: ICarGroupDTO) {
    const groupCarIds = group.cars.map(car => car.carID);
    const result = groupCarIds.filter(x => this.selectedCarIds.some(o => o === x));
    if (result.length === groupCarIds.length) {
      return true;
    }
    return false;
  }

  public selectAll(group: ICarGroupDTO) {
    const groupCarIds = group.cars.map(car => car.carID);
    const result = groupCarIds.filter(x => this.selectedCarIds.some(o => o === x));
    if (result.length > 0) {
      for (const x of result) {
        this.statsService.toogleCarSelected(x);
      }
    } else {
      for (const x of groupCarIds) {
        this.statsService.toogleCarSelected(x);
      }
    }
  }
}
