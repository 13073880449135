import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IStatsState, StatsStore } from './stats.store';

@Injectable({ providedIn: 'root' })
export class StatsQuery extends Query<IStatsState> {

  public readonly daysStats$ = this.select((state) => state.daysStats);

  public readonly totalStats$ = this.select((state) => state.totalStats);

  public readonly barchart$ = this.select((state) => state.barChart);

  public readonly piechart$ = this.select((state) => state.pieChart);

  public readonly filter$ = this.select((state) => state.filter);

  public readonly selectedCars$ = this.select((state) => state.selectedCars);

  public readonly isLoading$ = this.select((state) => state.loadingStats);

  public readonly selectedCarStats$ = this.select((state) => {

    const daystats = [];
    for (const daysStats of state.daysStats) {
      const validCarStars = [];
      if (daysStats.carStats !== null) {
        for (const iterator of daysStats.carStats) {
          if (state.selectedCars.includes(iterator.carId)) {
            validCarStars.push(iterator);
          }
        }
      }
      daystats.push({...daysStats, carStats: validCarStars });
    }

    return daystats;
  });

  public constructor(protected store: StatsStore) {
    super(store);
  }
}
