import { Injectable } from '@angular/core';
import { ActiveMenuItem, NavigationStore, createInitialState, DEFAULT_PANEL_WIDTH } from './navigation.store';
import { asyncScheduler } from 'rxjs';
import { applyTransaction } from '@datorama/akita';
import { Guid } from 'guid-typescript';

export interface INavigationInitData {
  activeMenuItem?: ActiveMenuItem;
  pageTitle?: string;
  backUrl?: string;
  panelWidth?: number;
  enableBackAction?: boolean;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {

  public constructor(
    private navigationStore: NavigationStore
  ) { }

  public setPageTitle(pageTitle: string): void {
    this.navigationStore.update({ pageTitle });
  }

  public clearPageTitle(): void {
    this.setPageTitle('');
  }

  public setActiveMenuItem(item: ActiveMenuItem): void {
    this.navigationStore.update({ activeMenuItem: item });
  }

  public setSidebarExpanded(expanded: boolean): void {
    this.navigationStore.update({ sidebarExpanded: expanded });
  }

  public clearActiveMenuItem(): void {
    this.setActiveMenuItem(ActiveMenuItem.None);
  }

  public setBackUrl(url: string): void {
    this.navigationStore.update({ backUrl: url });
  }
  public enableBackAction(enableBackAction: boolean): void {
    this.navigationStore.update({ enableBackAction });
  }

  public clearBackUrl(): void {
    this.setBackUrl('');
  }

  public setPanelWidth(width: number): void {
    if (width < DEFAULT_PANEL_WIDTH) {
      width = DEFAULT_PANEL_WIDTH;
    }

    this.navigationStore.update({ panelWidth: width });

    const root = document.documentElement;
    root.style.setProperty('--panel-width', `${ width }px`);
    root.style.setProperty('--panel-collapsed-width', `${ -width }px`);
  }

  public resetPanelWidth(): void {
    this.setPanelWidth(DEFAULT_PANEL_WIDTH);
  }

  public backButtonClicked(): void {
    this.navigationStore.update({ backUrlClicked: Guid.create() });
  }

  public initPage(data: INavigationInitData): void {
    asyncScheduler.schedule(() => {
      applyTransaction(() => {
        if (data.activeMenuItem) {
          this.setActiveMenuItem(data.activeMenuItem);
        }

        if (data.pageTitle) {
          this.setPageTitle(data.pageTitle);
        }

        if (data.panelWidth) {
          this.setPanelWidth(data.panelWidth);
        }

        this.setBackUrl(data.backUrl);
        this.enableBackAction(data.enableBackAction);
        this.navigationStore.update({ backUrlClicked: null });
      });
    });
  }

  public destroyPage(): void {
    asyncScheduler.schedule(() => {
      applyTransaction(() => {
        this.clearActiveMenuItem();
        this.clearPageTitle();
        this.clearBackUrl();
        this.resetPanelWidth();
      });
    });
  }

  public navigate(path: string, title: string, onRedirect: () => void): void {
    this.navigationStore.update((state) => {
      if (path === state.contentPath) {
        this.hideContentPanel();
      } else {
        this.showContentPanel(path);
        onRedirect();
      }
      setTimeout(() => {
        this.navigationStore.update({ pageTitle: title });
      }, 0);
    });
  }

  public hideContentPanel(): void {
    setTimeout(() => {
      this.navigationStore.update({ contentPath: '', showContentPanel: false });
    }, 0);
  }

  public showContentPanel(path: string): void {
    setTimeout(() => {
      this.navigationStore.update({ contentPath: path, showContentPanel: true });
    }, 0);
  }

  public setShowContentPanel(show: boolean): void {
    setTimeout(() => {
      this.navigationStore.update({ showContentPanel: show });
    }, 0);
  }

  public clearStore(): void {
    this.navigationStore.update(createInitialState());
  }
}
