<app-page-wrapper [hasSidePadding]="false"
  [hasTopPadding]="false"
  [isLoading]="isLoading">
  <ng-template appPageContent>
    <div class="container">
      <h4 class="list-title">
        {{ 'area-control.active-vehicles-list' | translate }}
      </h4>
      <ng-container *ngIf="!!selectedPolygon && !!dataSourceActive">
        <table mat-table [dataSource]="dataSourceActive" class="vehicle-table">
          <ng-container matColumnDef="vehicleName">
            <th mat-header-cell class="wrapper"
              *matHeaderCellDef> {{ 'area-control.vehicle-name' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <b>{{element.vehicleName}} </b>
                <div>{{element.vehicleDescription}}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="restrictionType">
            <th mat-header-cell class="wrapper" *matHeaderCellDef>
              {{ 'area-control.vehicle-area-type' | translate }}
              <mat-icon class="area-type-help"
                [matTooltip]="'area-control.type-help' | translate"
                matTooltipClass="multiline-tooltip">help_outline</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container [ngSwitch]="element.type">
                <div *ngSwitchCase="0">
                  {{'area-control.not-set' | translate }}
                </div>
                <div *ngSwitchCase="1">
                  {{'area-control.forbidden-area' | translate }}
                </div>
                <div *ngSwitchCase="2">
                  {{'area-control.allowed-area' | translate }}
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="notificationEmail">
            <th mat-header-cell class="wrapper"
              *matHeaderCellDef> {{ 'area-control.vehicle-notification-email' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <p>{{element?.notificationEmail?.split(';').join('; ')}}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="position">
            <th mat-header-cell class="wrapper"
              *matHeaderCellDef> {{ 'area-control.vehicle-position' | translate }} </th>
              <td mat-cell
              *matCellDef="let element">
              <p *ngIf="element.inArea"
                class="vehicle-in">
               {{'area-control.vehicle-position-in-description' | translate}}
              </p>
              <p *ngIf="!element.inArea"
              class="vehicle-in">
             {{'area-control.vehicle-position-out-description' | translate}}
            </p>
            </td> 
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell class="wrapper-last" *matHeaderCellDef> {{ 'form-actions.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="action-buttons">
                <mat-icon class="transparent"
                  [ngClass]="isRestrictionMet(element) ? 'inArea' : 'outOfArea'"                
                  [matTooltip]="'area-control.show-on-map' | translate">
                  gps_fixed
                </mat-icon>
                <mat-icon
                  class="transparent"
                  [matTooltip]="'area-control.vehicle-position-edit' | translate"
                  (click)="onAreaVehicleEdit(element)" (click)="$event.preventDefault()" (click)="$event.stopPropagation();">
                  edit
                </mat-icon>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowShowOnMap(row)" [ngClass]="isSelected(row)"></tr>
        </table>
        <ng-container *ngIf="!vehicles || vehicles?.length === 0">
          <app-content-loading type="progress-bar"></app-content-loading>
        </ng-container>

        <ng-container *ngIf="selectedPolygon.vehicles.length === 0 || !dataSourceActive">
          <p class="no-data">{{ 'area-control.no-vehicles-selected' | translate }}</p>
        </ng-container>
        <ui-divider></ui-divider>
      </ng-container>

      <ui-divider></ui-divider>
      <ui-divider></ui-divider>
      <h4 class="list-title">
        {{ 'area-control.unactive-vehicles-list' | translate }}
      </h4>

      <ng-container *ngIf="!!selectedPolygon && !!dataSourceNotActive">
        <table mat-table [dataSource]="dataSourceNotActive" class="vehicle-table">
          <ng-container matColumnDef="vehicleName">
            <th mat-header-cell class="wrapper" *matHeaderCellDef> {{ 'area-control.vehicle-name' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <b>{{element.vehicleName}} </b>
                <div>{{element.vehicleDescription}}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="position">
            <th mat-header-cell class="wrapper"
              *matHeaderCellDef> {{ 'area-control.vehicle-position' | translate }} </th>
              <td mat-cell *matCellDef="let element">
              <p *ngIf="element.inArea"
                class="vehicle-in">
               {{'area-control.vehicle-position-in-description' | translate}}
              </p>
              <p *ngIf="!element.inArea"
              class="vehicle-out">
             {{'area-control.vehicle-position-out-description' | translate}}
            </p>
            </td> 
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell class="wrapper-last" *matHeaderCellDef> {{ 'form-actions.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="action-buttons">
                <mat-icon
                  class="transparent"
                  [matTooltip]="'area-control.show-on-map' | translate">
                  gps_fixed
                </mat-icon>
                <button type="button" class="edit-area blue-button" mat-button mat-raised-button (click)="onAreaVehicleEdit(element)"
                (click)="$event.preventDefault()" (click)="$event.stopPropagation();">
                  {{'area-control.vehicle-set-control' | translate}}
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsNonActive"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsNonActive;" (click)="rowShowOnMap(row)" [class.selected]="isSelected(row)"></tr>
        </table>
        
        <ng-container *ngIf="!vehicles || vehicles.length === 0">
          <app-content-loading type="progress-bar"></app-content-loading>
        </ng-container>
        <ui-divider></ui-divider>
      </ng-container>
      <ui-divider></ui-divider>
      <p class="info-text" [innerHTML]="'area-control.info-text-detail' | translate"></p>
    </div>
    <ui-divider></ui-divider>
  </ng-template>

</app-page-wrapper>
