<ng-container *ngIf="loading; else notLoading">
  <app-content-loading type="progress-bar"></app-content-loading>
</ng-container>

<ng-template #notLoading>
  <div class="page-wrapper" [ngClass]="{'white-background': whiteBackground,'side-padding': hasSidePadding}">
    <div class="content" [ngClass]="{'full-height': hasFullHeight, 'top-padding': hasTopPadding}">
      <ng-container *ngIf="!templateRef; else fromTemplate">
        <ng-content></ng-content>
      </ng-container>

      <ng-template #fromTemplate>
        <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>