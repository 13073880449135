export interface IOperatorTypeDTO {
  operatorType: number;
}

export interface IOperandTypeDTO {
  operandType: number;
  repeat: boolean;
  number: string;
  data: string;
  dateValidFrom?: string;
}

export interface IConditionDTO {
  id: string;
  reminderId: string;
  operandType: IOperandTypeDTO | null;
  operatorType: IOperatorTypeDTO | null;
}

export interface IReminderDTO {
  reminderId: string;
  carId: number;
  name: string;
  mails: string;
  message: string;
  reminderTemporaryOff?: boolean;
  eventLevel: number;
  type: number;
  dateFrom?: Date;
  dateTo?: Date;
  conditions: IConditionDTO[];
}

export interface IRemindersDTO {
  reminders: IReminderDTO[];
}

export interface IUpcomingReminderDTO {
  id: number;
  reminderId: string;
  reminderTitle: string;
  reminderType: number;
  level: number;
  nextDate: Date;
  nextTachometer: number;
  carId: number;
}

export interface IUpcomingRemindersDTO {
  reminders: IUpcomingReminderDTO[];
  gdprEnabled: boolean;
}

export enum OperandTypes {
  distance = 0,
  date = 1,
  interval = 2,
  istrue = 3,
  isfalse = 4
}

export enum OperatorEnum {
  and = 1,
  or = 2,
  _null = 0
}
