<mat-card class="basic-card card-full-width card-mw-large description">
    <h3>{{ 'admin.description.title' | translate }}</h3>
    <mat-list>
      <mat-list-item>
        <p [innerHTML]="'admin.description.user-web' | translate"></p>
      </mat-list-item>
      <mat-list-item> 
        <p [innerHTML]="'admin.description.user-web-driver' | translate"></p>
      </mat-list-item>
      <mat-list-item> 
        <p [innerHTML]="'admin.description.user-web-edit' | translate"></p>
      </mat-list-item>
      <mat-list-item> 
        <p [innerHTML]="'admin.description.user-web-admin' | translate"></p>
      </mat-list-item>
      <ui-divider></ui-divider>
      <mat-divider></mat-divider>
      <ui-divider></ui-divider>
      <mat-list-item> 
        <p [innerHTML]="'admin.description.note' | translate"></p>
      </mat-list-item>
     </mat-list>
  </mat-card>
