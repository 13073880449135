import { Component, OnInit, Input, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Paginated } from 'src/app/api/models/pagination.model';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserHttpService } from 'src/app/api/user.service';
import { ICarRefuelingDTO } from 'src/app/api/models/dto/car-refueling.dto';
import { IGuardian } from 'src/app/api/models/dto/guardian.dto';

@Component({
  selector: 'app-fueling',
  templateUrl: './fueling.component.html',
  styleUrls: ['./fueling.component.scss']
})
export class FuelingComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() carId: number;

  private subs = new Subscription();
  public GDPR = false;
  public refuelings = [];
  public loadingRefueling = true;
  public pageSizeOptions = [ 5, 10, 20, 50];
  public resultsLength = 0;
  public paginatedObject: Paginated = {
      pageIndex: 0,
      pageSize: 10
  };

  displayedColumns: string[] = ['date', 'place', 'amount', 'price', 'total-price', 'distance', 'tachometer', 'consumption', 'edit'];

  constructor(
    public carsHttp: CarsHttpService,
    private router: Router,
    private userHttpService: UserHttpService
  ) { }

  ngOnInit(): void {
    if (!!this.carId) {
      this.subs.add(this.carsHttp.fetchRefuelingByCardId(this.carId, this.paginatedObject).subscribe(result => {
        this.resultsLength = result.totalCount;
        this.refuelings = result.carRefueling;
        this.loadingRefueling = false; }));
    }
    this.subs.add(this.userHttpService.fetchContactData().subscribe(
      profile => {
       this.GDPR = profile.gdpr; }));
  }

  public isDisplayed(fueling: ICarRefuelingDTO) {
    return fueling?.driveType === 0 || fueling?.driveType === -1 || (fueling?.driveType === 1 && this.GDPR);
    }

  public async navigateToEdit(driveBookID: number): Promise<void> {
    await this.router.navigate(
      [`/dashboard/drive-book-detail/${driveBookID}`], {state : {previous: 'fueling'} }
    );
  }

  onPageFired(event) {
    this.paginatedObject = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    };
    this.loadingRefueling = true;
    this.carsHttp.fetchRefuelingByCardId(this.carId, this.paginatedObject).subscribe(result => {
      this.resultsLength = result.totalCount;
      this.refuelings = result.carRefueling;
      this.loadingRefueling = false; });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
