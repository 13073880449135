<app-page-wrapper [isLoading]="false" [hasSidePadding]="false">
  <ng-template appPageContent>
    <div class="car-info">
      <app-car-info [allCars]="carsQuery.carGroups$ | async" (carChanged)="changeCar($event)">
      </app-car-info>
    </div>

    <div class="filters">
      <app-filter></app-filter>
    </div>

    <div class="totals">
      <app-totals [totals]="driveBookQuery.totals$ | async"></app-totals>
    </div>

    <ng-container *ngIf="isLoadingData">
      <app-content-loading type="progress-bar"></app-content-loading>
    </ng-container>
    <ng-container *ngIf="!isLoadingData;">
      <div class="drive-list" *ngxPermissionsOnly="['drivebook-read']">
        <app-drive-book-list [driveBooks]="driveBookQuery.driveBooks$ | async"
          [selectedDriveBookIDs]="driveBookQuery.selectedDriveBookIDs$ | async">
        </app-drive-book-list>
      </div>
    </ng-container>

    <div class="drive-speed-chart">
      <app-speed-chart></app-speed-chart>
    </div>
  </ng-template>
</app-page-wrapper>
