import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { ActivatedRoute } from '@angular/router';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { IAreaControlData } from 'src/app/api/models/dto/area-control.dto';
import { ICarPositionExtra, ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { UrlRoutes } from 'src/app/app-routes';

const PAGE_TITLE = 'page-title.area-control';

@Component({
  selector: 'app-area-control-page',
  templateUrl: './area-control-page.component.html',
  styleUrls: ['./area-control-page.component.scss'],
})
export class AreaControlPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  public allAreas: IAreaControlData[];
  public selectedPolygon: IAreaControlData;
  public vehicles: ICarDTO[];
  public vehiclesPositions: ICarPositionExtra[];
  public activeAreaId: number;
  public isLoading = false;

  public constructor(
    private navigationState: NavigationService,
    private areaControlService: AreaControlService,
    private carService: CarsService,
    private navigationService: NavigationService,
    private areaControlQuery: AreaControlQuery,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.navigationState.initPage({
      activeMenuItem: ActiveMenuItem.AreaControl,
      pageTitle: PAGE_TITLE,
      panelWidth: 600
    });

    this.navigationService.showContentPanel(UrlRoutes.urls.areaControl.home);
    this.subs.add(this.route.url.subscribe((data) => {
      this.activeAreaId = parseInt(this.route.snapshot.paramMap.get('area_id'), 10);
    }));
    this.subs.add(this.areaControlService.fetchUserAreas());
    this.subs.add(this.areaControlQuery.areaPolygons$.subscribe((polygons) => {
      this.allAreas = polygons;
      if (!!this.activeAreaId && !!this.allAreas) {
        const polygon = polygons.find(p => p.id === this.activeAreaId);
        this.areaControlService.setSelectedPolygon(polygon);
      }
    }));

    if (!this.vehicles || this.vehicles.length === 0) {
      this.carService.fetchGroupsWithCars(true);
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.areaControlService.setSelectedPolygon(null);
  }
}
