import { Injectable } from '@angular/core';
import { createInitialState, AreaControlStore } from './area-control.store';
import { Observable, combineLatest } from 'rxjs';
import { GeoAreasService } from 'src/app/services/geoAreas.service';
import {
  IAreaControlData,
  IAreaControlAddRequest,
  IAreaControlPathPointAddRequest,
  IAreaVehicleEditRequest,
  IAreaControlPathPoint,
  IAreaControlEditRequest
} from 'src/app/api/models/dto/area-control.dto';
import { AreaControlHttpService } from 'src/app/api/area-control-http-service';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AreaControlService {

  public constructor(
    private areaControlStore: AreaControlStore,
    private geoAreasService: GeoAreasService,
    private areaControlHttpService: AreaControlHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public setDrawingMode(isDrawingMode: boolean): void {
    this.areaControlStore.update({ isDrawingMode });
  }
  public setEditMode(isEditMode: boolean): void {
    this.areaControlStore.update({ isEditMode });
  }

  public setAreaPolygons(areaPolygons: IAreaControlData[]): void {
    this.areaControlStore.update({ areaPolygons });
  }

  public setSelectedPolygon(selectedPolygon: IAreaControlData): void {
    this.areaControlStore.update({ selectedPolygon });
  }

  public setPredefinedPolygons(predefinedPolygons: IAreaControlData[]): void {
    this.areaControlStore.update({ predefinedPolygons });
  }

  public setSelectedColor(selectedColor: string): void {
    this.areaControlStore.update({ selectedColor });
  }

  public setIsLoading(isLoading: boolean): void {
    this.areaControlStore.update({ isLoading });
  }

  public seteditablePoints(editablePoints: IAreaControlPathPoint[]): void {
    this.areaControlStore.update({ editablePoints });
  }

  public addArea(area: IAreaControlData): Observable<any> {
    this.setIsLoading(true);
    let points: IAreaControlPathPointAddRequest[] = area.points.map((point) => ({
      order: point.order,
      latitude: point.lat,
      longitude: point.lng
    }));
    points = points.sort((p1, p2) => (p1.order > p2.order) ? 1 : ((p2.order > p1.order) ? -1 : 0));
    const mappedArea: IAreaControlAddRequest = {
      color: area.color,
      name: area.name,
      type: area.type,
      points,
    };
    return this.areaControlHttpService.addArea(mappedArea);
  }

  public deleteArea(id: number): void {
    this.setIsLoading(true);
    this.areaControlHttpService.deleteArea(id).subscribe((res) => {
      this.setSelectedPolygon(null);
      this.fetchUserAreas();
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public editArea(id: number, area: IAreaControlEditRequest): void {
    this.setIsLoading(true);
    this.areaControlHttpService.editArea(id, area).subscribe((res) => {
      this.setEditMode(false);
      this.fetchUserAreas();
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  public addEditAreaVehicle(areaId: number, vehicleArea: IAreaVehicleEditRequest, callback: () => void) {
    this.setIsLoading(true);
    this.areaControlHttpService.addEditAreaVehicle(areaId, vehicleArea).subscribe((res) => {
      callback();
      this.fetchUserAreas();
    }, (error) => {
      this.setEditMode(true);
      this.setIsLoading(false);
      if (error.error.errors.NotificationPhone) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      }
    });
  }

  public editAreaVehicle(areaId: number, vehicleArea: IAreaVehicleEditRequest, carId: number, callback: () => void) {
    this.setIsLoading(true);
    this.areaControlHttpService.addEditAreaVehicle(areaId, vehicleArea).subscribe((res) => {
      callback();
      this.getAreasByCarIdUrl(carId);
    }, (error) => {
      this.setEditMode(true);
      this.setIsLoading(false);
      if (error.error.errors.NotificationPhone) {
        this.notifyService.showError(this.translateService.instant('area-control.wrong-notification-phone'));
      }
    });
  }

  public getAreasByCarIdUrl(vehicleId: number) {
    this.setIsLoading(true);
    this.areaControlHttpService.getAreasByCarIdUrl(vehicleId).subscribe((res) => {
      const mappedArreas = [];
      res.areas.forEach((area) => {
        const points = area.points.map((point) => ({ id: point.id, lng: point.longitude, lat: point.latitude, order: point.order }));
        const mappedArea: IAreaControlData = {
          ...area,
          points
        };
        mappedArreas.push(mappedArea);
      });
      this.setAreaPolygons(mappedArreas);
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  fetchUserAreas(): void {
    this.setIsLoading(true);
    this.areaControlHttpService.fetchUserAreas().subscribe((res) => {
      const mappedArreas = [];
      res.areas.forEach((area) => {
        const points = area.points.map((point) => ({ id: point.id, lng: point.longitude, lat: point.latitude, order: point.order }));
        const mappedArea: IAreaControlData = {
          ...area,
          points
        };
        mappedArreas.push(mappedArea);
      });
      this.setAreaPolygons(mappedArreas);
      this.setIsLoading(false);
    }, (error) => {
      this.setIsLoading(false);
    });
  }

  loadPredefinedPolygons(): void {
    const areasSubs: Observable<any>[] = [
      this.geoAreasService.getGeoJSON('czech'),
      this.geoAreasService.getGeoJSON('slovak'),
      this.geoAreasService.getGeoJSON('prague'),
    ];
    combineLatest(areasSubs).subscribe((areas) => {
      const mappedArreas = [];
      areas.forEach((area) => {
        const points = area.points.map((point) => ({ lng: point[0], lat: point[1] }));
        const mappedArea: IAreaControlData = {
          id: area.id,
          name: area.name,
          type: area.type,
          color: area.color,
          points
        };
        mappedArreas.push(mappedArea);
      });
      this.setPredefinedPolygons(mappedArreas);
    });
  }

  public clearStore(): void {
    this.areaControlStore.update(createInitialState());
  }
}
