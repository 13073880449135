import { Injectable } from '@angular/core';
import * as SignalR from '@aspnet/signalr';
import { ICarCurrentPosition, ICarsCurrentPosition } from '../api/models/cars-current-position.model.js';
import { AuthService } from '../api/auth.service.js';
import { CarsPositionService } from '../store/cars-position/cars-position.service.js';
import { LoggerService } from 'src/app/services/logger.service.js';
import { interval } from 'rxjs';
import { AppConfigService } from './appConfig.service';

const WS_CURRENT_POSITION = 'ReciveCarsCurrentPosition';
@Injectable({
  providedIn: 'root'
})
export class CarsSignalRService {

  reconnectInterval = 5000;
  connectionStarted = false;
  connectionClosed = false;

  timestamp: string;
  connection: SignalR.HubConnection;
  constructor(public authService: AuthService,
              public carsPositionService: CarsPositionService,
              private loggerService: LoggerService,
              private appConfigService: AppConfigService) {
    interval(this.reconnectInterval).subscribe(() => { this.checkConnection(); });
  }

  private buildConnection(): void {
    const wsBaseUrl = this.appConfigService.configuration.WS_API_URL;
    const token = this.authService.getToken();
    this.connection = new SignalR.HubConnectionBuilder()
      .configureLogging(SignalR.LogLevel.Error)
      .withUrl(wsBaseUrl + '/hub/currentcar', {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets
      })
      .build();
  }

  public async startConnection(): Promise<void> {
    return;
    this.connectionStarted = true;
    this.connectionClosed = false;
    if (!this.connection) {
      this.buildConnection();
    }
    try {
      if (this.connection.state === SignalR.HubConnectionState.Disconnected) {
        this.loggerService.logInfo('SignalRService starting connection');
        await this.connection
          .start()
          .then(() => this.connection.invoke('SubscribeClient'));
      } else {
        this.loggerService.logInfo('SignalRService invoke SubscribeClient');
        this.connection.invoke('SubscribeClient');
      }

      this.startListening();
      this.loggerService.logInfo('SignalRService connection started');
    } catch (err) {
      this.loggerService.logError('SignalRService error: ', err);
    }
  }

  public stopConnection(): void {
    if (!!this.connection) {
      if (this.connection.state === SignalR.HubConnectionState.Connected) {
        this.connection.invoke('UnSubscribeClient');
      }
      this.connection.off(WS_CURRENT_POSITION);
      this.connection = null;
    }
    this.connectionStarted = false;
  }

  private startListening(): void {
    this.connection.on(WS_CURRENT_POSITION, (reciveObject: ICarsCurrentPosition) => {
      if (!!reciveObject && reciveObject?.lastPositions?.length > 0) {
        const currentMaxDate = this.getMaxDate(reciveObject?.lastPositions);
        if (this.timestamp !== currentMaxDate) {
          this.timestamp = currentMaxDate;
          this.loggerService.logInfo(`SignalRService: new data`, reciveObject);
          this.carsPositionService.setCarsCurrentPosition(reciveObject);
        }
      }
    });
    this.connection.onclose(async () => {
      this.loggerService.logWarning('SignalRService: close');
      this.connectionClosed = true;
    });
  }

  private checkConnection(): void {
    // tslint:disable-next-line:max-line-length
    if (this.connectionStarted && (!this.connection || this.connection?.state === SignalR.HubConnectionState.Disconnected || this.connectionClosed)) {
      this.stopConnection();
      this.startConnection();
    }
  }

  private getMaxDate(array: ICarCurrentPosition[]): string {
    if (array.length === 1) {
      return array[0].lastKnownDate;
    }
    return array.reduce((a, b) => (a.lastKnownDate > b.lastKnownDate ? a : b)).lastKnownDate;
  }
}
