<div class="container">
    <form class="form" [formGroup]="form">
        <div class="container">
            <p class="dialog-title"><b>{{ 'drive-book.lock-unlock' | translate }}</b></p>
            <mat-divider></mat-divider>
            <ui-divider></ui-divider>
            <div class="radio-container">
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="driveBookLockOption">
                        <mat-radio-button class="example-radio-button" *ngxPermissionsOnly="['drivebook-lock']"
                            [value]="driveBookLockOptions[0]" (click)="driveBookLockOption = driveBookLockOptions[0]">
                            {{ getLockOptionString(driveBookLockOptions[0]) | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button" *ngxPermissionsOnly="['drivebook-unlock']"
                            [value]="driveBookLockOptions[1]" (click)="driveBookLockOption = driveBookLockOptions[1]">
                            {{ getLockOptionString(driveBookLockOptions[1]) | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div> 
            </div>
        </div>
    </form>
    <div mat-dialog-actions class="buttons">
        <button mat-button mat-raised-button
            (click)="closeDialog()">
            {{ 'drive-book.edit-dialog.cancel' | translate }}
        </button> 
        <div class="action-buttons">  
            <button mat-button
                mat-raised-button
                color="primary"  
                [disabled]="!form?.value?.driveBookLockOption"       
                (click)="save()">
                {{ 'drive-book.edit-dialog.save' | translate }}
            </button>
        </div>
    </div>
</div>
