<div class="container">
    <form [formGroup]="form" class="message-form">
        <div class="container">
            <div class="title"> {{ 'contact.title' | translate }} </div>
            <mat-form-field class="field">
                <mat-label>{{ 'contact.email' | translate }}</mat-label>
                <input formControlName="email" matInput required>
            </mat-form-field>
            <mat-form-field class="field">
                <input [placeholder]="'contact.subject' | translate" formControlName="subject" matInput required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="subject-textarea">
                <mat-label>{{ 'contact.content' | translate }}</mat-label>
                <textarea class="subject-textarea" formControlName="content" matInput matTextareaAutosize
                    matAutosizeMinRows=1 matAutosizeMaxRows=3 required></textarea>
            </mat-form-field>
        </div>
        <button type="submit" mat-raised-button color="primary" (click)="sendEmail()"
            [disabled]="form.invalid">{{ 'contact.send' | translate }}</button>
    </form>

    <mat-card class="contact-us">
        <div class="contact-info">
            <div class="heading">
                <p>{{ 'contact.info' | translate }} </p>
            </div>
            <div class="info">
                <p [innerHTML]="contactCompanyName"></p>
                <p [innerHTML]="contactCompanyStreet"></p>
                <p [innerHTML]="contactCompanyCity"></p>
            </div>
        </div>
        <div class="contact-info">
            <div class="heading">
                <p>{{ 'contact.email-address' | translate }}</p>
            </div>
            <div class="info">
                <p [innerHTML]="contactEmail"></p>
            </div>
        </div>
        <div class="contact-info">
            <div class="heading">
                <p>{{ 'contact.phone' | translate }} </p>
            </div>
            <div class="info">
                <p [innerHTML]="contactPhone"></p>
            </div>
        </div>
    </mat-card>
</div>