import { Pipe, PipeTransform } from '@angular/core';
import { ICarDTO } from 'src/app/api/models/dto/car-groups.dto';

@Pipe({
  name: 'sortVehicles',
  pure: true
})
export class SortVehiclesPipe implements PipeTransform {

  public transform(value: ICarDTO[], searchQuery: string): ICarDTO[] {
    return value?.filter(
      list => this.searchFilter(list, searchQuery))
      .slice().sort((n1, n2) => {
        if (n1.spz > n2.spz) {
          return 1;
        }
        if (n1.spz < n2.spz) {
          return -1;
        }
        return 0;
      });
  }

  private searchFilter(vehicle: ICarDTO, searchQuery: string) {
    return !!searchQuery ? vehicle?.spz?.toLowerCase()?.replace(/ /g, '')?.includes(searchQuery?.toLowerCase()?.replace(/ /g, '')) ||
      vehicle?.toolTipText?.toLowerCase()?.includes(searchQuery?.toLowerCase()) : vehicle;
  }
}
