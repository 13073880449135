import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime',
  pure: true
})
export class MinutesToTimePipe implements PipeTransform {

  public transform(value: number): Date {
    const date = new Date();

    date.setFullYear(0, 0,  0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(value * 60);

    return date;
  }

}
