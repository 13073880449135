import { Injectable } from '@angular/core';
import { AppConfigService } from '../services/appConfig.service';

@Injectable()
export class UrlsService {

  public fetchCarGroups = () => this.url('Car/GroupHierarchy');

  public fetchCarGroupsByUserID = (userID: number) => this.url(`Car/GroupHierarchy/${ userID }`);

  public fetchCarsPositionsByUserID = (lastQueryTime: number) => this.url(`Car/CarsPositionsByUserID/${ lastQueryTime }`);

  public fetchCarsByUserId = (userID: number) => this.url(`Car/CarsByUserId/${ userID }`);

  public fetchCarConcise = (carID: number) => this.url(`Car/Concise/${ carID }`);

  public updateCarConcise = (carID: number) => this.url(`Car/Concise/${ carID }`);

  public fetchCarLastAddress = (carID: number) => this.url(`Car/LastAddress/${ carID }`);

  public fetchLastAddresses = () => this.url(`Car/LastAddresses`);

  public fetchCarLastTachoValue = (carID: number) => this.url(`Car/LastTachoValue/${carID}`);

  public fetchCarColors = () => this.url('CarProperties/GetCarColors');

  public fetchCarFuels = () => this.url('CarProperties/GetFuels');

  public fetchCarBodies = () => this.url('CarProperties/GetBodies');

  public fetchDriveBooks = (carID: number) => this.url(`DriveBook/car/${ carID }`);

  public fetchDriveBookPaths = (driveBookID: number) => this.url(`DriveBook/${ driveBookID }/path`);

  public fetchDriveBook = (driveBookID: number) => this.url(`DriveBook/${ driveBookID }`);

  public updateDriveBook = (driveBookID: number) => this.url(`DriveBook/${ driveBookID }`);

  public addNewDriveBook = () => this.url(`DriveBook`);

  public multiplyEditDrivebooks = () => this.url('DriveBook/BulkModifyDriveBooks');

  public lockDriveBooks = () => this.url('DriveBook/lockDriveBooks');

  public unlockDriveBooks = () => this.url('DriveBook/unLockDriveBooks');

  public fetchRemindersByCarID = (carID: number) => this.url(`CarReminders/GetByCarId/${ carID }`);

  public fetchUpcomingRemindersByCarID = (carID: number) => this.url(`CarReminders/GetUpcomingCarRemindersByCarId/${ carID }`);

  public resolveReminder = (reminderID: number) => this.url(`CarReminders/ResolveReminder/${ reminderID }`);

  public exportToPdf = () => this.url(`Export/ExportToPDF`);

  public exportToDocx = () => this.url(`Export/ExportToDocx`);

  public exportToXLSX = () => this.url(`Export/ExportToXLSX`);

  public exportToXML = () => this.url(`Export/ExportToXML`);

  public fetchExportScheduleByCarId = (carId: number) => this.url(`Export/GetExportScheduleByCarId/${carId}`);

  public fetchExportScheduleById = (scheduleId: number) => this.url(`Export/GetExportScheduleById/${scheduleId}`);

  public updateExportSchedule = (scheduleId: number) => this.url(`Export/EditExportSchedule/${scheduleId}`);

  public createExportSchedule = (carId: number) => this.url(`Export/AddExportSchedule/${carId}`);

  public deleteExportSchedule = (scheduleId: number) => this.url(`Export/DeleteExportSchedule/${scheduleId}`);

  public fetcStatsForUser = () => this.url(`Stats`);

  public uploadFuelCardFile = () => this.url(`Import/UploadFuelCard`);

  public saveRemider = () => this.url(`CarReminders`);

  public operationRemider = (reminderId: string) => this.url(`CarReminders/${ reminderId }`);

  public fetchDriversByCarId = (carID: number) => this.url(`Car/Drivers/${ carID }`);

  public fetchRefuelingByCardId = (carID: number) => this.url(`Car/Refueling/${ carID }`);

  public fetchAllDrivers = () => this.url(`Car/Drivers`);

  public sendEmail = () => this.url(`HelpPage/SendEmail`);

  public fetchCarGuardian = (carID: number) => this.url(`CarGuardian/${ carID }`);

  public updateCarGuardian = (guardianID: number) => this.url(`CarGuardian/${ guardianID }`);

  public createCarGuardian = (carID: number) => this.url(`CarGuardian/${ carID }`);

  public allowCarMovement = (carID: number) => this.url(`Car/AllowMovement/${carID}`);

  public forbidCarMovement = (carID: number) => this.url(`Car/ForbidMovement/${carID}`);

  public getWorkspaceByBaseUrl = (url: string) => this.url(`Workspace/GetByBaseUrl/${url}`);

  public constructor(private appConfigService: AppConfigService) {
  }

  private url(path: string): string {
    return `${ this.appConfigService.configuration.API_URL }/api/${ path }`;
  }

}
