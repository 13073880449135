import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { DateAdapter, MatDatepickerInputEvent, MatDialogRef, MatRadioChange, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NotifyService } from 'src/app/services/notify.service';
import { CarRemindersQuery } from 'src/app/store/car-reminders/state/car-reminders.query';
import { IConditionDTO, IOperandTypeDTO } from '../../../api/models/dto/reminders.dto';

export enum RulesEnum {
    distance = 1,
    date = 2
}
@Component({
    selector: 'app-reminders-conditions-dialog',
    templateUrl: './reminder-dialog-box.component.html',
    styleUrls: ['./reminder-dialog-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'cs'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        }
    ]
})

export class ReminderDialogBoxComponent {

    public action: string;
    // tslint:disable-next-line:variable-name
    public local_data: any;
    public type: number;
    public condition: IOperandTypeDTO;
    public step: number;
    public dateValid: Date;
    public dateReminderFrom: Date;
    public minDate: Date;

    public constructor(
        public dialogRef: MatDialogRef<ReminderDialogBoxComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: IConditionDTO,
        public readonly carRemindersQuery: CarRemindersQuery,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private notifyService: NotifyService
    ) {
        this.minDate = moment().add(1, 'days').toDate();
        this.local_data = { ...data };
        this.action = this.local_data.action;
        this.type = this.local_data.operandType ? this.local_data.operandType.operandType + 1 : 0;
        this.condition = {
            data: this.local_data.operandType ? this.local_data.operandType.data : '',
            number: this.local_data.operandType ? this.local_data.operandType.number : '',
            operandType: this.local_data.operandType ? this.local_data.operandType.operandType : -1,
            repeat: this.local_data.operandType ? this.local_data.operandType.repeat : false,
        };

        this.dateReminderFrom = this.getDate();
        if (this.action.toLowerCase() === 'update') {
            this.step = 2;
        } else if (this.action.toLowerCase() === 'add') {
            this.step = 2;
            this.setType(this.local_data.defaultType);
        }

        if (this.type === 2) {
            this.dateValid = moment.utc(new Date(this.condition.number)).local().endOf('day').toDate();
        }

        if (this.type === 4) {
            this.dateValid = moment.utc(new Date(this.condition.number)).local().endOf('day').toDate();
        }

        if (this.type === 3) {
            this.dateValid = moment.utc(this.getDate()).local().toDate();
        }
        this.cd.markForCheck();
    }

    doAction(): void {
        if (this.type === 2 || this.type === 4) {
            this.condition.number = moment.utc(this.dateValid).local().endOf('day').format('MM-DD-YYYY');
            if (this.type === 4) {
            }
        }

        if (this.type === 3) {
            this.condition.dateValidFrom = moment.utc(this.dateValid).local().endOf('day').format('MM-DD-YYYY');
            if (
            (this.condition.data && this.condition.data.length <= 0)) {
                this.notifyService.showError(this.translateService.instant('condition.box.warning.interval'));
                return;
            } else if (
                (this.condition.data && +this.condition.data <= 0)) {
                this.notifyService.showError(this.translateService.instant('condition.box.warning.interval'));
                return;
            }
        }
        this.dialogRef.close({ event: this.action, data: this.condition, dateValid: this.dateValid });
    }

    closeDialog(): void {
        this.dialogRef.close({ event: 'Cancel' });
    }

    setType(type: number): void {
        this.step = 2;
        this.condition.number = '';
        this.condition.data = '';
        this.type = type;
        this.condition.operandType = type - 1;
    }

    public selectDate(dateEvent: MatDatepickerInputEvent<Date>): void {
        if (this.type === 3) {
            this.dateValid = dateEvent.value;
            this.dateReminderFrom = dateEvent.value;
        }
    }

    public repeatRadioChange(event: MatRadioChange): void {
        if (event.value && event.value >= 1 && this.type !== event.value + 1) {
            this.setType(event.value + 1);
        }
    }

    getDate(): Date {
        return this.carRemindersQuery.getEditReminder().dateFrom;
    }

    public isDisabled(): boolean {
        switch (this.type) {
            case 1:
                if (this.condition.number === '' || !this.condition.number) {
                    return true;
                }
                if (this.condition.repeat && (!this.condition.data || this.condition.data === '')) {
                    return true;
                }
                return false;
            case 2:
                if (!moment(this.dateValid).isValid() || !this.dateValid) {
                    return true;
                }
                return false;
            case 3:
                if (!moment(this.dateValid).isValid() || !this.dateValid) {
                    return true;
                }
                if (this.condition.data === '' || !this.condition.data) {
                    return true;
                }
                return false;
            case 4:
                if (!moment(this.dateValid).isValid() || !this.dateValid) {
                    return true;
                }
                return false;
        }
    }
}
