import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DriveBookDetailStore, IDriveBookDetailState } from './drive-book-detail.store';
import { filter, map } from 'rxjs/operators';
import { IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';

@Injectable({ providedIn: 'root' })
export class DriveBookDetailQuery extends Query<IDriveBookDetailState> {

  public readonly isLoadingDriveBook$ = this.select((state) => state.loadingDriveBook);

  public readonly driveBookID$ = this.select((state) => state.driveBookID);

  public readonly isDriveBookSelected$ = this.driveBookID$.pipe(
    map((driveBookID) => driveBookID !== null)
  );

  public readonly driveBook$ = this.select((state) => state.driveBook);

  public readonly driveBookNotNull$ = this.driveBook$.pipe(
    filter((driveBook) => !!driveBook)
  );

  public get driveBook() {
    return this.getValue().driveBook;
  }

  public constructor(protected store: DriveBookDetailStore) {
    super(store);
  }

}
