import { Pipe, PipeTransform } from '@angular/core';
import { CarType } from 'src/app/api/models/dto/car-groups.dto';
import { ICarBodyDTO } from 'src/app/api/models/dto/car-body.dto';

@Pipe({
  name: 'bodyIcon',
  pure: true
})
export class BodyIconPipe implements PipeTransform {

  public transform(value: number | ICarBodyDTO, bodies?: ICarBodyDTO[]): string {
    if (!value) {
      return;
    }
    let type: CarType;
    if (typeof value === 'number') {
      type = bodies?.find(c => c.nCarBodyID === value)?.strName as CarType;
    } else {
      type = value?.strName as CarType;
    }
    const path = 'assets/images/';
    switch (type) {
      case CarType.CAR:
        return `${ path }car.png`;
      case CarType.SUV:
        return `${ path }suv.png`;
      case CarType.DELIVERY_TRUCK:
        return `${ path }delivery-truck.png`;
      case CarType.BIKE:
        return `${ path }bike.png`;
      case CarType.BOAT:
        return `${ path }boat.png`;
      case CarType.BUS:
        return `${ path }bus.png`;
      case CarType.CONSTRUCTION_MACHINE:
        return `${ path }construction-machine.png`;
      case CarType.TRANSPORTER:
        return `${ path }transporter.png`;
      case CarType.TRUCK:
        return `${ path }truck.png`;
      case CarType.LOCATOR:
        return `${ path }locator.png`;
      case CarType.TRACTOR:
        return `${ path }tractor.png`;
      default:
        return `${ path }car.png`;
    }
  }
}
