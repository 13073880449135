import { Component, OnInit } from '@angular/core';

class LegendItem {
  translationString: string;
  icon: string;
}
const path = 'assets/images/';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit {

  public items: LegendItem[];

  constructor() { }

  ngOnInit(): void {
    this.initData();
  }

  public initData(): void {
    this.items = [
      { icon: '../../../assets/images/blue_square.png', translationString: 'map-legend.KEY_OFF_SPEED_ON_DATA_OFF' },
      { icon: '../../../assets/images/green_square.png', translationString: 'map-legend.KEY_OFF_SPEED_ON_DATA_ON' },
      { icon: '../../../assets/images/green_pause.png', translationString: 'map-legend.KEY_ON_SPEED_OFF_DATA_ON' },
      { icon: '../../../assets/images/green_play.png', translationString: 'map-legend.KEY_ON_SPEED_ON_DATA_ON' },
      { icon: '../../../assets/images/red_pause.png', translationString: 'map-legend.KEY_ON_SPEED_OFF_DATA_OFF' },
      { icon: '../../../assets/images/red_play.png', translationString: 'map-legend.KEY_ON_SPEED_ON_DATA_OFF' },
    ];
  }
}
