<div class="container">
    <form class="form" [formGroup]="form">
    <div class="container">
        <p class="dialog-title"><b>{{'export.set-schedule' | translate }}</b></p>
        <mat-divider></mat-divider>
        <ui-divider></ui-divider>
        <div class="title-container">
            <div class="row">
                <div class="title">{{ 'export-schedule.email-label' | translate }} </div>
                <div class="title-content">
                    <mat-form-field class="email">
                        <input [placeholder]="'export-schedule.email' | translate"
                        type="text" formControlName="emailAddress" matInput>
                        <mat-error *ngIf="!!form?.controls?.emailAddress?.errors?.wrongEmail">
                            {{ "area-control.wrong-notification-email" | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="radio-container">
                <div class="radio-heading">
                    {{'export.schedule-type' | translate}}
                </div>
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="format-radio-group-label" class="example-radio-group"
                    formControlName="periodType">
                        <mat-radio-button class="example-radio-button" *ngFor="let period of periodOptions" [value]="period">
                            {{ period | periodType }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <ng-container class="title-container" *ngIf="form?.controls?.periodType?.value === 2">
                <div class="row">
                    <div class="title">
                        {{ 'export-schedule.monthly-period-start' | translate }} 
                    </div>
                    <div class="title-content">
                        <mat-form-field class="number">
                            <input matInput formControlName="monthlyPeriodStartDay" type="number" min="1" max="28" size="2">
                            <mat-error *ngIf="!!form?.controls?.monthlyPeriodStartDay?.errors?.wrongNumber">
                                {{ "export-schedule.wrong-number" | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-hint class="small-text">{{ getHintText(currentHintDay) }}</mat-hint>
                    </div>
                </div>
            </ng-container>
            <div class="radio-container">
                <div class="radio-heading">
                    {{'export-schedule.drive-type' | translate}}
                </div>
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="driveType">
                        <mat-radio-button class="example-radio-button" *ngFor="let driveType of driveTypes"
                            [value]="driveType">
                            {{ driveTypeNameKey(driveType) | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div> 
            </div>
            <div class="radio-container">
                <div class="radio-heading">
                    {{'export.select-drive-type-overwrite' | translate}}
                </div>
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    formControlName="driveTypeOverWrite">
                        <mat-radio-button class="example-radio-button" *ngFor="let option of overwriteoptions"
                            [value]="option">
                            {{ option | overWriteType }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>            
            <div class="radio-container">
                <div class="radio-heading">
                    {{'export.select-format' | translate}}
                </div>
                <div class="radio-button-container">
                    <mat-radio-group aria-labelledby="format-radio-group-label" class="example-radio-group"
                    formControlName="fileFormat">
                        <mat-radio-button class="example-radio-button" *ngFor="let format of formats" [value]="format">
                            {{ format | fileFormat }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>          
            <ng-container *ngIf="form?.controls?.fileFormat?.value === 0">
                <div class="radio-container">
                    <div class="radio-heading">
                        {{'export.export-PDF-type' | translate}}
                    </div>
                    <div class="radio-button-container">
                        <mat-radio-group aria-labelledby="format-radio-group-label" class="example-radio-group"
                        formControlName="dailySum">
                            <mat-radio-button class="example-radio-button" *ngFor="let format of sumFormat" [value]="format">
                                {{ format | dailySum }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </ng-container>

            <div class="row">
                <mat-checkbox formControlName="isShortPrinting" matListIcon  [matTooltip]="'export.simple-report-tooltip' | translate">
                    <mat-label>{{ 'export.simple-report' | translate }}</mat-label>
                </mat-checkbox>
            </div>

            <ng-container *ngIf="data?.gdprEnabled">
                <div class="row">
                    <mat-checkbox formControlName="gdpr">
                        <mat-label>{{ 'export-schedule.gdpr-label' | translate }}</mat-label>
                    </mat-checkbox>
                </div>
            </ng-container>

        </div>
    </div>
    </form>
    <div mat-dialog-actions class="buttons">
        <button mat-button mat-raised-button
            [mat-dialog-close]="false">
            {{ 'export-schedule.cancel' | translate }}
        </button> 
        <div class="action-buttons">  
            <button mat-button
                mat-raised-button
                color="primary"         
                (click)="save()"
                [disabled]="!form?.valid"
                cdkFocusInitial>
                {{ 'export-schedule.save' | translate }}
            </button>
        </div>
    </div>
</div>


