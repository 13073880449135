import { Pipe, PipeTransform } from '@angular/core';
import { SPEED_LIMIT_TOWN, SPEED_LIMIT_ROADS } from 'src/app/constants/vehicle-constants';
import { Color } from 'src/app/api/models/dto/car-groups.dto';

@Pipe({
  name: 'speedColor',
  pure: true
})
export class SpeedColorPipe implements PipeTransform {

  public transform(value: number): string {
    return Color.Green;

    if (value <= SPEED_LIMIT_TOWN) {
      return Color.Green;
    } else if (value > SPEED_LIMIT_TOWN && value <= SPEED_LIMIT_ROADS) {
      return Color.Orange;
    } else {
      return Color.Red;
    }
  }
}
