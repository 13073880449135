import { IUpcomingReminderDTO } from './reminders.dto';

export interface IGroupDTO {
  groupID: number;
  name: string;
  root: number;
}

export interface ICarPositionDTO {
  longitude: number;
  latitude: number;
}

export interface ICarPositionExtra extends ICarPositionDTO {
  carId: number;
  previousPositions: ICarPositionDTO;
  lastKnownDate: string;
  isOnline: boolean;
  speed: number;
  lastKnownPositionAdress: string;
  isKeyOn: boolean;
  driverName: string;
  direction: number;
}

export interface ICarPositions {
  positions: ICarPositionDTO[];
}

export interface ICarDTO {
  carID: number;
  spz: string;
  speed: number;
  toolTipText: string;
  position: ICarPositionDTO;
  lastKnownPositionAdress: string;
  lastKnownDate: string;
  lastKnownDriver: string;
  lastKnownDirection: number;
  maxSpeedLimit: number;
  type: CarType;
  typeKeyVal: string;
  reminders: any[];
  activeReminders: IUpcomingReminderDTO[];
  isResponding: boolean;
  isCarMovementAllowed: boolean;
  isCarMovementSupported: boolean;
}

export enum CarType {
  CAR = 'CAR',
  SUV = 'SUV',
  TRANSPORTER = 'TRANSPORTER',
  DELIVERY_TRUCK = 'DELIVERY_TRUCK',
  BUS = 'BUS',
  TRACTOR = 'TRACTOR',
  TRUCK = 'TRUCK',
  CONSTRUCTION_MACHINE = 'CONSTRUCTION_MACHINE',
  BIKE = 'BIKE',
  LOCATOR = 'LOCATOR',
  BOAT = 'BOAT',
}

export enum Color {
  Red = '#EF5350',
  Green = '#81C784',
  Orange = '#FF9800'
}

export interface ICarReminderDTO {
  name: string;
  type: number;
}

export interface ICarGroupDTO {
  group: IGroupDTO;
  cars: ICarDTO[];
}

export interface ICarGroupsDTO {
  carGroups: ICarGroupDTO[];
}

export interface ICarLastPositionDTO {
  carId: number;
  address: string;
  timeOfDrive: string;
}

export interface ICarLastTachoValueDTO {
  carId: number;
  tachoValue: number;
  timeOfDrive: string;
}

export interface ICarLastAddressesDTO {
  carAddresses: ICarLastPositionDTO[];
}
