import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export const INIT_ZOOM = 8;
export const INIT_POSITION = {
  latitude: 49.80376388888889,
  longitude: 15.474913888888889
};

export interface IMapPoint {
  latitude: number;
  longitude: number;
  text?: string;
  objectId?: number;
}

export interface ICarMapMarker extends IMapPoint {
  carId: number;
  speed?: number;
  lastKnownPosition?: string;
  lastKnownDate?: string;
  driverName: string;
  lastKnownDirection: number;
}

export interface IMapPolylinePoint extends IMapPoint {
  carId?: number;
  speed: number;
  timestamp?: string;
  driverName: string;
  direction: number;
}

export interface IMapPolyline {
  drivebookId: number;
  isDriveBook: boolean;
  paths: IMapPolylinePoint[];
}

export interface IMapState {
  isInitialized: boolean;
  centerPoint: IMapPoint;
  zoom: number;
  points: IMapPoint[];
  speedPoint: IMapPoint;
  lines: IMapPolyline[];
  focusedVehicleId: number;
}

export function createInitialState(): IMapState {
  return {
    isInitialized: false,
    centerPoint: INIT_POSITION,
    zoom: INIT_ZOOM,
    speedPoint: null,
    points: [],
    lines: [],
    focusedVehicleId: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'map' })
export class MapStore extends Store<IMapState> {
  public constructor() {
    super(createInitialState());
  }
}
