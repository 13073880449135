import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AreaControlStore, IAreaControlState } from './area-control.store';

@Injectable({ providedIn: 'root' })
export class AreaControlQuery extends Query<IAreaControlState> {
  public readonly isLoading$ = this.select((state) => state.isLoading);
  public readonly isDrawingMode$ = this.select((state) => state.isDrawingMode);
  public readonly isEditMode$ = this.select((state) => state.isEditMode);
  public readonly areaPolygons$ = this.select((state) => state.areaPolygons);
  public readonly selectedPolygon$ = this.select((state) => state.selectedPolygon);
  public readonly selectedColor$ = this.select((state) => state.selectedColor);
  public readonly predefinedPolygons$ = this.select((state) => state.predefinedPolygons);
  public readonly editablePoints$ = this.select((state) => state.editablePoints);

  public constructor(protected store: AreaControlStore) {
    super(store);
  }
}
