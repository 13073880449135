import { Injectable } from '@angular/core';
import { CarRemindersStore } from './car-reminders.store';
import { of, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CarRemindersHttpService } from '../../../api/car-reminders-http.service';
import { IReminderDTO, IUpcomingReminderDTO } from 'src/app/api/models/dto/reminders.dto';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from 'src/app/api/urls.service';
import { CarsService } from '../../cars/state/cars.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { RemindersListDialogComponent } from 'src/app/pages/car-reminders/reminder-list-confirm-delete/reminders-list-dialog.component';

@Injectable({ providedIn: 'root' })
export class CarRemindersService {

  public constructor(
    private dialog: MatDialog,
    private carRemindersStore: CarRemindersStore,
    private carRemindersHttp: CarRemindersHttpService,
    private urls: UrlsService,
    private carsState: CarsService,
    private translateService: TranslateService,
    private http: HttpClient
  ) { }

  public solveReminder(reminder: IReminderDTO, upcomingReminder: IUpcomingReminderDTO, carSpz: string, carToolTipText: string) {
      if (!!upcomingReminder) {
        const dialogRef = this.dialog.open(RemindersListDialogComponent, {
          data: {
            message: this.translateService.instant('reminders.resolve.dialog-text',
              {spz: carSpz, carToolTip: carToolTipText, reminderName: reminder.name }),
            buttonText: {
              ok: this.translateService.instant('reminders.resolve'),
              cancel: this.translateService.instant('form-actions.back'),
            },
          }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (!confirmed) { return; }
          this.carRemindersHttp.resolveReminder(upcomingReminder.id).subscribe(() => this.carsState.fetchGroupsWithCars(true));
        });
      }
  }

  public selectCar(carID: number): void {
    of(carID).pipe(
      tap((id) => this.carRemindersStore.update({
        carID: id,
        loadingReminders: true
      })),
      switchMap((id) => this.carRemindersHttp.fetchRemindersByCarID(id)),
      tap((reminders) => this.carRemindersStore.update({
        reminders,
        loadingReminders: false
      }))
    ).subscribe();
  }

  public clearStore(): void {
    this.carRemindersStore.update({
      carID: null,
      reminders: [],
      loadingReminders: false,
      action: '',
      activeEditReminder: null,
      isStartEditing: false
    });
  }

  public setIsStartEditing(isNew: boolean): void {
    this.carRemindersStore.update({ isStartEditing: isNew });
  }

  public setAction(action: string): void {
    this.carRemindersStore.update({ action });
  }

  public setReminder(reminder: IReminderDTO): void {
    this.carRemindersStore.update({ activeEditReminder: reminder });
  }

  public setEditedReminder(isNew: boolean, actionType: string, reminder: IReminderDTO): void {
    this.carRemindersStore.update({ isStartEditing: isNew, action: actionType, activeEditReminder: reminder });
  }

  public changeDateFromValidReminder(dateFrom: Date): void {
    this.carRemindersStore.update((state) => {
      const copy = {...state.activeEditReminder};
      copy.dateFrom = dateFrom;
      state.activeEditReminder = copy;
      return {...state, activeEditReminder: copy};
    });
  }

  public setAddedReminder(reminder: IReminderDTO): void {
    this.carRemindersStore.update((state) => {
      const copy = [...state.reminders];
      copy.push(reminder);
      state.reminders = copy;
      return {...state, reminders: copy};
    });
  }

  public setUpdatedReminder(reminder: IReminderDTO): void {
    this.carRemindersStore.update((state) => {
      const copy = [...state.reminders];
      const index = state.reminders.findIndex(x => x.reminderId === reminder.reminderId);
      copy.splice(index, 1, reminder);
      state.reminders = copy;
      return {...state, reminders: copy};
    });
  }

  public setDeletedReminder(reminderId: string): void {
    this.carRemindersStore.update((state) => {
      const copy = [...state.reminders];
      const index = copy.findIndex(x => x.reminderId === reminderId);
      copy.splice(index, 1);
      state.reminders = copy;
      return {...state, reminders: copy};
    });
  }

  public setValidFromActiveReminder(validFrom: Date): void {
    this.carRemindersStore.update((state) => {
      state.activeEditReminder.dateFrom = validFrom;
    });
  }

  public saveNewReminder(reminder: IReminderDTO): Observable<IReminderDTO>  {
    return this.http.post<IReminderDTO>(this.urls.saveRemider(), reminder);
  }

  public updateReminder(reminder: IReminderDTO): Observable<IReminderDTO>  {
    return this.http.put<IReminderDTO>(this.urls.operationRemider(reminder.reminderId), reminder);
  }

  public getReminder(id: string): Observable<IReminderDTO>  {
    return this.http.get<IReminderDTO>(this.urls.operationRemider(id));
  }

  public deleteReminder(reminderId: string): Observable<IReminderDTO>  {
    return this.http.delete<IReminderDTO>(this.urls.operationRemider(reminderId));
  }

  public fetchUpcomingRemindersByCarID(carID: number) {
    return this.carRemindersHttp.fetchUpcomingRemindersByCarID(carID);
  }
}
