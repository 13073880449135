import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-car-action-item',
  templateUrl: './car-action-item.component.html',
  styleUrls: ['./car-action-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarActionItemComponent {

  @Output()
  public action = new EventEmitter<void>();

  @Input()
  public icon: string;

  @Input()
  public text: string;

  public constructor() {}

  public handleClick(): void {
    this.action.emit();
  }

}
