import { Injectable } from '@angular/core';
import { StatsStore, IStatsFilter } from './stats.store';
import { StatsHttpService } from 'src/app/api/stats-http.service';
import { IDayStatsDTO, ICarStatsDTO } from 'src/app/api/models/dto/stats.dto';
import * as moment from 'moment';
import { StatsQuery } from './stats.query';

@Injectable({ providedIn: 'root' })
export class StatsService {

  public constructor(
    private statsStore: StatsStore,
    private statsHttp: StatsHttpService,
    private statsQuery: StatsQuery,
  ) {
    moment.updateLocale('en', {
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM Do YYYY',
        LLL: 'MMMM Do YYYY LT',
        LLLL: 'dddd, MMMM Do YYYY LT',
      }
    });
  }

  public loadStats(): void {
    const { filter: filter } = this.statsQuery.getValue();

    const statsRequest = { From: filter.from.toDate(), To: filter.to.toDate() };

    this.statsStore.update({
      loadingStats: true,
    });

    this.statsHttp.fetchStats(statsRequest).subscribe((response) => {
      const calendar: IDayStatsDTO[] = [];
      const firstDay = Number(response.stats.dayStats[0].dayOfTheWeek);
      const from = moment(response.stats.dayStats[0].date);

      if (firstDay !== 0) {
        const newDay = from.subtract(firstDay, 'day');
        for (let i = 0; i < firstDay; i++) {
          const daystatnew = {
            date: newDay.toString(),
            dayOfTheWeek: newDay.day().toString(),
            dayOfTheMonth: newDay.date().toString(),
            carStats: [] as ICarStatsDTO[],
            month: (newDay.month() + 1).toString(),
            year: newDay.year().toString(),
            filler: true,
          };
          calendar.push(daystatnew);
          from.add(1, 'day');
        }
      }

      for (const day of response.stats.dayStats) {
        calendar.push({ ...day, filler: false});
      }

      const lastday = moment(calendar[calendar.length - 1].date);
      while (calendar.length < 42) {
        const newDay = lastday.add(1, 'day');
        const daystatnew = {
          date: newDay.toString(),
          dayOfTheWeek: newDay.day().toString(),
          dayOfTheMonth: newDay.date().toString(),
          carStats: [] as ICarStatsDTO[],
          month: (newDay.month() + 1).toString(),
          year: newDay.year().toString(),
          filler: true,
        };
        calendar.push(daystatnew);
      }

      this.statsStore.update({
        loadingStats: true,
        daysStats: calendar,
        totalStats: response.stats.totalStats
      });

      const pieChartLabels = [];
      const pieChartData = [];
      const cars = [];
      for (const iterator of response.stats.totalStats) {
        cars.push(iterator.carId);
        pieChartLabels.push(iterator.spz);
        pieChartData.push(iterator.distance.toFixed(2));
      }

      const barChartLabels = [];
      const barChartData = [];
      for (const day of response.stats.dayStats) {
        barChartLabels.push(day.dayOfTheMonth + '.' + day.month + '.' + day.year);
        for (const totalStat of response.stats.totalStats) {
          let index = barChartData.findIndex(x => x.id === totalStat.carId);
          if (day.carStats !== null) {
            const carstat = day.carStats.find(x => x.carId === totalStat.carId);
            if (index === -1) {
              const newBarChartSeries = { data: [], label: totalStat.spz, id: totalStat.carId };
              barChartData.push(newBarChartSeries);
              index = barChartData.length - 1;
            }
            if (carstat === undefined) {
              barChartData[index].data.push(0);
            } else {
              barChartData[index].data.push(carstat.distance.toFixed(2));
            }
          } else {
            if (barChartData.length === 0 || barChartData[index] === undefined) {
              const newBarChartSeries = { data: [], label: totalStat.spz, id: totalStat.carId };
              barChartData.push(newBarChartSeries);
              index = barChartData.length - 1;
            }
            barChartData[index].data.push(0);
          }
        }
      }

      this.statsStore.update({
        loadingStats: false,
        barChart: { labels: barChartLabels, series: barChartData },
        pieChart: { labels: pieChartLabels, data: pieChartData },
        selectedCars: []//cars - tady se puvodne zaskrtavala vsechna vozidla, nove na zacatku neni zaskrtnute zadne vozidlo
      });
    });
  }

  public updateFilter(nextFilter: Partial<IStatsFilter>): void {
    const { filter: statsFilter } = this.statsQuery.getValue();

    const next = {
      ...statsFilter,
      ...nextFilter
    };

    this.statsStore.update({ filter: next });
  }

  public toogleCarSelected(carId: number) {
    const newCarIds = this.getNewSelectedCarsIds(carId);
    this.statsStore.update({
      selectedCars: newCarIds
    });
  }

  private getNewSelectedCarsIds(carId: number): Array<number> {
    const { selectedCars: carIds } = this.statsQuery.getValue();
    const index = carIds.indexOf(carId, 0);
    if (index > -1) {
      return carIds.filter(x => x !== carId );
    } else {
     carIds.push(carId);
     return carIds;
    }
  }
}
