import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { Subscription } from 'rxjs';
import { AreaControlService } from 'src/app/store/area-control/state/area-control.service';
import { IAreaControlData, AreaControlDataType, IAreaControlPathPoint } from 'src/app/api/models/dto/area-control.dto';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AreaControlQuery } from 'src/app/store/area-control/state/area-control.query';
import { Router } from '@angular/router';
import { UrlRoutes } from 'src/app/app-routes';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

const PAGE_TITLE = 'page-title.area-control-add';

@Component({
  selector: 'app-area-control-add-page',
  templateUrl: './area-control-add-page.component.html',
  styleUrls: ['./area-control-add-page.component.scss']
})
export class AreaControlAddPageComponent implements OnInit, OnDestroy {

  public predefinedAreas: IAreaControlData[];
  public selectedPolygon: IAreaControlData;
  public defaultAreaColor = '#295cc9';
  public areaColor: string;
  public isEditMode: boolean;
  public form: FormGroup;
  public areaPoints: IAreaControlPathPoint[];
  private subs = new Subscription();
  public areaAdded = false;

  public constructor(
    private navigationService: NavigationService,
    private areaControlService: AreaControlService,
    public readonly areaControlQuery: AreaControlQuery,
    private router: Router,
    private fb: FormBuilder,
    private notifyService: NotifyService,
    private translateService: TranslateService
  ) { }

  public ngOnInit(): void {
    this.form = this.createForm();
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.AreaControl,
      pageTitle: PAGE_TITLE,
      backUrl: UrlRoutes.urls.areaControl.home,
      panelWidth: 500
    });

    this.subs.add(this.areaControlQuery.predefinedPolygons$.subscribe((polygons) => {
      this.predefinedAreas = polygons;
    }));

    this.subs.add(this.areaControlQuery.selectedPolygon$.subscribe((polygon) => {
      this.form.patchValue({ areaPolygonSelected: !!polygon });
      this.selectedPolygon = polygon;
    }));

    this.subs.add(this.areaControlQuery.editablePoints$.subscribe((points) => {
      this.areaPoints = points;
    }));

    this.subs.add(this.areaControlQuery.isEditMode$.subscribe((isEditMode) => {
      this.isEditMode = isEditMode;
    }));

    this.areaColor = this.defaultAreaColor;
    this.form.patchValue({ areaColor: this.areaColor });
    this.areaControlService.setSelectedColor(this.areaColor);
    this.navigationService.showContentPanel(UrlRoutes.urls.areaControl.add);
    this.areaControlService.loadPredefinedPolygons();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      areaPredefinedType: new FormControl(null, Validators.required),
      areaPolygonSelected: new FormControl(false, [Validators.required, Validators.requiredTrue]),
      areaName: new FormControl(null, Validators.required),
      areaColor: new FormControl(this.areaColor, Validators.required),
    });
  }

  public handleTypeChange(value: any): void {
    if (value === 'manual') {
      this.areaControlService.setDrawingMode(true);
      this.areaControlService.setSelectedPolygon(null);
    } else {
      this.areaControlService.setDrawingMode(false);
      this.areaControlService.setEditMode(false);
      this.areaControlService.seteditablePoints(null);
      const area = this.predefinedAreas.find(a => a.name === value);
      for (let i = 0; i < area.points.length; i++) {
        area.points[i].order = i;
      }
      this.areaControlService.setSelectedPolygon(area);
    }
  }

  colorChanged(): void {
    this.form.patchValue({ areaColor: this.areaColor });
    this.areaControlService.setSelectedColor(this.areaColor);
  }

  savePolygon(): void {
    const request: IAreaControlData = {
      color: this.areaColor,
      name: this.form.value.areaName,
      id: null,
      points: !!this.areaPoints && this.isEditMode ? this.areaPoints : this.selectedPolygon.points,
      type: AreaControlDataType.CUSTOM,
    };

    let areaId: number;
    const response = this.areaControlService.addArea(request);
    response.subscribe((res) => {
      this.areaAdded =  true;
      areaId = res?.areaId;
      this.notifyService.showInfo(this.translateService.instant('area.control-add-success'));
      this.areaControlService.fetchUserAreas();
      this.areaControlService.setEditMode(false);
      this.areaControlService.seteditablePoints(null);
      this.router.navigate([`/dashboard/area-control/${ areaId }`]);
    }, (error) => {
      this.areaControlService.setIsLoading(false);
    });
  }

  public ngOnDestroy(): void {
    if (!this.areaAdded) {
      this.resetPolygon();
    }
    this.areaControlService.setDrawingMode(false);
    this.subs.unsubscribe();
  }

  resetPolygon(): void {
   this.areaControlService.setSelectedPolygon(null);
   this.areaControlService.setEditMode(false);
   this.areaControlService.seteditablePoints(null);
   this.areaControlService.setDrawingMode(true);
  }

}
