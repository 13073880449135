import { DriveType, DriveTypeOverwrite } from 'src/app/store/export/state/export.store';


export interface IGetExportScheduleByCarIdResponse {
    exportSchedules : IGetExportSchedulesByCarIdOutput,
}

export interface IGetExportSchedulesByCarIdOutput {
    schedules: IExportSchedule[];
    gdprEnabled: boolean;
}

export interface IExportSchedules {
    exportSchedules: IExportSchedule[];
    gdprEnabled: boolean;
}

export interface IExportSchedule {
    id: number;
    carId: number;
    emailAddress: string;
    periodType: PeriodType;
    driveType: DriveType;
    driveTypeOverWrite: DriveTypeOverwrite;
    fileFormat: FileFormat;
    active: boolean;
    dailySum: boolean;
    timezone: string;
    monthlyPeriodStartDay: number;
    isShortPrinting: boolean;
    gdpr: boolean;
}
export interface ICreateExportSchedule {
    carId: number;
    emailAddress: string;
    periodType: PeriodType;
    driveType: DriveType;
    driveTypeOverWrite: DriveTypeOverwrite;
    fileFormat: FileFormat;
    active: boolean;
    dailySum: boolean;
    timezone: string;
    monthlyPeriodStartDay: number;
    isShortPrinting: boolean;
    gdpr: boolean;
}

export enum FileFormat {
    PDF = 0,
    DOCX = 1,
    XLSX = 2,
    XML = 3
}

export enum PeriodType {
    daily = 0,
    weekly = 1,
    monthly = 2
}
