<div class="profile-tab">
  <mat-dialog-content [formGroup]="form">
    <ui-divider></ui-divider>

    <ui-password-input [value]="''"
      [placeholder]="'profile.password-previous-password'"
      [name]="'oldPassword'"
      (onInputChange)="changeFormValue($event.name, $event.value)">
    </ui-password-input>

    <ui-password-input [value]="''"
      [placeholder]="'profile.password-new-password'"
      [name]="'newPassword'"
      (onInputChange)="changeFormValue($event.name, $event.value)"></ui-password-input>

    <ui-password-input [value]="''"
      [placeholder]="'profile.password-new-password-confirm'"
      [name]="'newPasswordConfirm'"
      (onInputChange)="changeFormValue($event.name, $event.value)">
    </ui-password-input>

    <p class="error-label centered" *ngIf="!!form.value.newPasswordConfirm && !arePasswordsEqual">{{ 'profile.password-match-error' | translate }}</p>
    <p class="error-label centered" *ngIf="!!form.value.newPassword && form.value.newPassword.length < 8">{{ 'profile.password-format-error' | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons">
    <button mat-button (click)="close()">
      {{ 'profile.close' | translate }}
    </button>
    <button mat-button
      mat-raised-button
      [disabled]="form?.invalid || !arePasswordsEqual"
      color="primary"
      (click)="save()">
      {{ 'profile.change-password' | translate }}
    </button>
  </mat-dialog-actions>
</div>
