import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { ICarDriversDTO, ICarDriverDTO } from 'src/app/api/models/dto/car-driver.dto';
import { DriveType, IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';
import { DriveTypeString } from '../../../store/drive-book/state/drive-book.store';
import { ConfirmDialogService } from '../../../ui-components/services/confirm-dialog.service';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent } from '@angular/material';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';

type DriveTypeValue = [number, string];

const DRIVE_TYPE_PREFIX = 'drive-book.edit-form.drive.drive-type';
const DRIVER_PREFIX = 'drive-book.edit-form.driver';
const CANCEL_MSG = 'drive-book.edit-form.actions.cancel';

export interface IFormValuesDrive {
  driveType: number;
  driver: number;
  purpose: string;
  traceDistance: number;
  newDistance: number;
}

export interface IFormValuesFuel {
  fuelRefillLitres: number;
  fuelRefillPrice: number;
}

export interface IFormValuesStart {
  date: string;
  time: string;
  dateTime: string;
  tachometer: number;
  location: string;
}

export interface IFormValuesStop {
  date: string;
  time: string;
  dateTime: string;
  tachometer: number;
  location: string;
}

export interface IFormValues {
  drive: IFormValuesDrive;
  start: IFormValuesStart;
  stop: IFormValuesStop;
  fuel: IFormValuesFuel;
  note: string;
  newTachometer: number;
}

export interface IFormState {
  driveBookEditForm: IFormValues;
}

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFormComponent implements OnInit, OnDestroy {

  public emptyDriver: ICarDriverDTO = {
    strName: null,
    strSurname: 'drive-book.edit-form.drive.no-driver',
    nPersonID: -1,
    nCarID: null
  };

  emptyDrivebook: IDriveBookDTO = {
    driveBookId: null,
    driverId: null,
    carId: 1,
    purpose: '',
    traceDistance: 0,
    driveType: DriveType.Business,
    fuelRefillLitres: 0,
    fuelRefillPrice: 0,
    receipt: null,
    tachoStart: 0,
    tachoStop: 0,
    dateTimeStart: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
    dateTimeStop: moment.utc(new Date()).add(5, 'm').format('YYYY-MM-DD HH:mm:ss').toLocaleString(),
    gpsPosStartX: 0,
    gpsPosStartY: 0,
    gpsPosStopX: 0,
    gpsPosStopY: 0,
    startDescription: null,
    stopDescription: null,
    maxSpeed: 0,
    speeding: 0,
    note: '',
    deleted: false,
    newTacho: null,
    closed: false,
    newDistance: null,
    totalTime: 0,
    isLocked: false,
    speedLimitExceeded: false,
  };

  drivebook: IDriveBookDTO;

  @Input()
  public set driveBook(driveBook: IDriveBookDTO) {
    this.drivebook = driveBook;
  }

  public get driveBook(): IDriveBookDTO {
    return this.drivebook;
  }

  @Output()
  public formSubmit = new EventEmitter<IFormValues>();

  @Output()
  public cancelForm = new EventEmitter<void>();

  public form: FormGroup;

  private subs = new Subscription();

  public validDates = true;

  public driveTypes: DriveTypeValue[] = [
    [DriveType.Business, DriveTypeString.Business],
    [DriveType.Private, DriveTypeString.Private]
  ];

  public drivers: ICarDriversDTO;

  public constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private formsManager: AkitaNgFormsManager<IFormState>,
    private confirmDialog: ConfirmDialogService,
    private carsHttp: CarsHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
    private driveBookQuery: DriveBookQuery
  ) { }

  public ngOnInit(): void {
    const carId = !!this.driveBook?.carId ? this.driveBook?.carId : this.driveBookQuery.selectedCarID;
    this.getDrivers(carId);
    this.changeDetectorRef.markForCheck();
    if (!carId) {
      this.cancelForm.emit();
    }
    this.emptyDrivebook.carId = this.driveBook?.carId;
  }

  public ngOnDestroy(): void {
    this.formsManager.unsubscribe();
    this.subs.unsubscribe();
  }

  private createForm(driveBook: IDriveBookDTO): FormGroup {
    const timeStart = moment.utc(driveBook.dateTimeStart).local().format('HH:mm');
    const timeEnd = moment.utc(driveBook.dateTimeStop).local().format('HH:mm');

    return this.fb.group({
      drive: this.fb.group({
        driveType: [driveBook.driveType, Validators.required],
        driver: [driveBook.driverId > 0 ? driveBook.driverId : this.emptyDriver.nPersonID, Validators.required],
        purpose: [driveBook.purpose],
        maxSpeed: [this.addSuffix(driveBook.maxSpeed)],
        avgSpeed: [this.addSuffix(driveBook.speeding)],
        newDistance: [driveBook.newDistance > 0 ? Number(Math.round(driveBook.newDistance)) : 0],
      }),
      start: this.fb.group({
        date: [moment.utc(driveBook.dateTimeStart).local(), Validators.required],
        time: [timeStart, Validators.required],
        dateTime: [],
        tachometer: [this.addSuffixKm(Number(Math.round(driveBook.tachoStart)))],
        location: [driveBook.startDescription]
      }),
      stop: this.fb.group({
        date: [moment.utc(driveBook.dateTimeStop).local(), Validators.required],
        time: [timeEnd, Validators.required],
        dateTime: [],
        tachometer: [Number(Math.round(driveBook.tachoStop))],
        location: [driveBook.stopDescription]
      }),
      fuel: this.fb.group({
        fuelRefillLitres: [!!driveBook.fuelRefillLitres ? driveBook.fuelRefillLitres : 0],
        fuelRefillPrice: [!!driveBook.fuelRefillPrice ? driveBook.fuelRefillPrice : 0]
      }),
      newTachometer: [driveBook.newTacho < 0 ? null : driveBook.newTacho],
      note: [driveBook.note]
    });
  }

  public handleSubmit(event: Event): void {
    let values = this.form.value as IFormValues;
    const dateStart = new Date(values.start.date);
    dateStart.setHours(moment(values.start.time, 'HH:mm').get('hour'));
    dateStart.setMinutes(moment(values.start.time, 'HH:mm').get('minute'));
    this.form.get('start.dateTime').patchValue(dateStart.toISOString());
    const dateStop = new Date(values.stop.date);
    dateStop.setHours(moment(values.stop.time, 'HH:mm').get('hour'));
    dateStop.setMinutes(moment(values.stop.time, 'HH:mm').get('minute'));
    this.form.get('stop.dateTime').patchValue(dateStop.toISOString());
    values = this.form.value as IFormValues;
    if (dateStop < dateStart) {
      this.notifyService.showError(this.translateService.instant('drive-book.edit-form.error.invalid-date'));
      return;
    }

    if (!values.drive.newDistance && !this.drivebook) {
      this.notifyService.showError(this.translateService.instant('drive-book.edit-form.error.invalid-input'));
      return;
    }

    this.formSubmit.emit(values);
    this.cancelForm.emit();
  }

  public handleCancel(): void {
    if (this.form.dirty) {
      this.subs.add(
          this.confirmDialog
            .open(CANCEL_MSG)
            .subscribe(() => this.cancelForm.emit())
        );
    } else {
      this.cancelForm.emit();
    }
  }

  public driveTypeLangKey(type: string): string {
    return `${DRIVE_TYPE_PREFIX}.${type}`;
  }

  public driverLangKey(driver: string): string {
    return `${DRIVER_PREFIX}.${driver}`;
  }

  public get distance(): number {
    if (this.driveBook) {
      return this.driveBook.traceDistance;
    } else {
      return 0;
    }
  }

  public addSuffix(input: any): string {
    const str = input.toLocaleString();
    return `${str} km/h`;
  }

  public addSuffixKm(input: any): string {
    const str = input.toLocaleString();
    return `${str} km`;
  }

  public selectDate(dateEvent: MatDatepickerInputEvent<moment.Moment>, from: boolean): void {
    const dateMoment = dateEvent.value as moment.Moment;
    const newDate = dateMoment.local().toDate();
    if (from) {
      this.form.patchValue({stop: {date: newDate}});
      this.form.patchValue({start: {date: newDate}});
    } else {
      this.form.patchValue({stop: {date: newDate}});
    }
  }

  public getDrivers(carId: number): void {
    this.carsHttp.fetchDriversByCarId(carId).subscribe(drivers => {
      this.drivers = {
        carId: 0,
        drivers: {
          drivers: [this.emptyDriver, ...drivers.drivers.drivers]
        }
      };
      if (this.drivebook) {
        this.form = this.createForm(this.drivebook);
      } else {
        this.form = this.createForm(this.emptyDrivebook);
      }
      this.changeDetectorRef.markForCheck();
    }
    );
  }
}
