import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICarConciseDTO, IPutCarConciseDTO } from './models/dto/car-concise.dto';
import { ICarDriverDTO, ICarDriversDTO } from './models/dto/car-driver.dto';
import { ICarDTO, ICarGroupsDTO, ICarLastPositionDTO, ICarLastTachoValueDTO, ICarLastAddressesDTO } from './models/dto/car-groups.dto';
import { UrlsService } from './urls.service';
import { ICarRefuelingWithTotals } from './models/dto/car-refueling.dto';
import { Paginated } from './models/pagination.model';
import { ICarsCurrentPosition } from './models/cars-current-position.model'

@Injectable()
export class CarsHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public fetchCarGroups(): Observable<ICarGroupsDTO> {
    return this.http.get<ICarGroupsDTO>(this.urls.fetchCarGroups());
  }

  public fetchCarGroupsByUserID(userID: number): Observable<ICarGroupsDTO> {
    return this.http.get<ICarGroupsDTO>(this.urls.fetchCarGroupsByUserID(userID));
  }

  public fetchCarsByUserId(userID: number): Observable<{ cars: ICarDTO[] }> {
    return this.http.get<{ cars: ICarDTO[] }>(this.urls.fetchCarsByUserId(userID));
  }

  public fetchCarConcise(carID: number): Observable<ICarConciseDTO> {
    return this.http.get<ICarConciseDTO>(this.urls.fetchCarConcise(carID));
  }

  public fetchCarLastAddress(carID: number): Observable<ICarLastPositionDTO> {
    return this.http.get<ICarLastPositionDTO>(this.urls.fetchCarLastAddress(carID));
  }

  public fetchLastAddresses(carIds: number[]): Observable<ICarLastAddressesDTO> {
    return this.http.post<any>(this.urls.fetchLastAddresses(), { carIds });
  }

  public fetchCarLastTachoValue(carID: number): Observable<ICarLastTachoValueDTO> {
    return this.http.get<ICarLastTachoValueDTO>(this.urls.fetchCarLastTachoValue(carID));
  }

  public updateCar(carID: number, car: IPutCarConciseDTO): Observable<ICarConciseDTO> {
    return this.http.put<ICarConciseDTO>(this.urls.updateCarConcise(carID), car);
  }

  public fetchDriversByCarId(carID: number): Observable<ICarDriversDTO> {
    return this.http.get<ICarDriversDTO>(this.urls.fetchDriversByCarId(carID));
  }

  public fetchRefuelingByCardId(carID: number, page?: Paginated): Observable<ICarRefuelingWithTotals> {
    return this.http.post<ICarRefuelingWithTotals>(this.urls.fetchRefuelingByCardId(carID), page);
  }

  public fetchAllDrivers(): Observable<ICarDriverDTO[]> {
    return this.http.get<ICarDriversDTO>(this.urls.fetchAllDrivers()).pipe(map(x => x.drivers.drivers));
  }

  public fetchCarsPositionsByUserID(lastQueryTime: number): Observable<ICarsCurrentPosition> {
    return this.http.get<ICarsCurrentPosition>(this.urls.fetchCarsPositionsByUserID(lastQueryTime));
  }

}
