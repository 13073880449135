import { ICustomFilterList } from './types';

import {
  FilterCurrentMonth,
  FilterPreviousMonth,
  FilterSevenDays,
  FilterThirtyDays,
  FilterToday,
  FilterWeek,
  FilterYear,
  FilterYesterday
} from './filters';

export const CUSTOM_FILTERS: ICustomFilterList = {
  TODAY: new FilterToday(),
  YESTERDAY: new FilterYesterday(),
  WEEK: new FilterWeek(),
  SEVEN_DAYS: new FilterSevenDays(),
  THIRTY_DAYS: new FilterThirtyDays(),
  CURRENT_MONTH: new FilterCurrentMonth(),
  PREVIOUS_MONTH: new FilterPreviousMonth(),
  YEAR: new FilterYear()
};
