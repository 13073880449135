import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../api/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  enabledForRefresh = true;
  refreshTokenInterval = 1000 * 60 * 1; // 1min
  refreshDisabledUrls = ['/refresh', '/assets', '/token'];
  constructor(private authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.enabledForRefresh &&
      !!this.authService.getToken() &&
      !this.refreshDisabledUrls.find(url => req.url.toLowerCase().includes(url))
    ) {
      this.enabledForRefresh = false;
      this.authService.refreshToken();
      setTimeout(() => {
        this.enabledForRefresh = true;
      }, this.refreshTokenInterval);
    }

    return next.handle(req);
  }
}
