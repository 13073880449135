<mat-list *ngIf="!!searchQuery ? (group?.cars | sortVehicles: searchQuery)?.length > 0 : true">
  <mat-accordion class="vehicle-group-list">
    <mat-expansion-panel [expanded]="isExpanded" (closed)="isExpanded=false" (opened)="isExpanded=true">
      <mat-expansion-panel-header  class="group-headline" [ngClass]="{'selected-group': group | isGroupSelected: selectedCarIds}" [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
            <h3 matSubheader>
            <mat-checkbox matListIcon [checked]="group | isGroupSelected: selectedCarIds" class="select-checkbox" (click)="$event.stopPropagation();$event.preventDefault();handleGroupSelect($event)"></mat-checkbox>
              <span class="main-header">{{ group.group.name }}</span>
              <span>({{ group.cars.length }})</span>
            </h3>
      </mat-expansion-panel-header>
      <ng-container *ngIf="isExpanded">
        <ng-container *ngFor="let car of group?.cars | sortVehicles: searchQuery">
          <app-car-row
          (click)="handleCarSelect($event, car?.carID)"
          [car]="car"
          [updateTriger]="updateTriger"
          [isSelected]="selectedCarIds?.includes(car?.carID)">
        </app-car-row>
      </ng-container>
    </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</mat-list>

