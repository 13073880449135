import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlCustomLanguage extends MatPaginatorIntl {

    itemsPerPageLabel = this.translate('fueling.per-page');
    itemsOfLabel = this.translate('fueling.per-page');

    public constructor(private translationService: TranslateService) {
        super();
        }

    translate(label: string): string {
        return this.translationService.instant(label);
    }

    getRangeLabel = (page: number, pageSize: number, length: number) =>  {
        if (length === 0 || pageSize === 0) {
          return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.translate('fueling.of')} ${length}`;
      }
}
