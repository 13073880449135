import { Injectable } from '@angular/core';
import { Store, StoreConfig, EntityStore } from '@datorama/akita';
import { ICarGroupDTO, ICarGroupsDTO, ICarDTO } from '../../../api/models/dto/car-groups.dto';

export interface ICarsState {
  carGroups: ICarGroupDTO[];
  loading: boolean;
  changed: boolean;
}

export function createInitialState(): ICarsState {
  return {
    carGroups: [],
    loading: false,
    changed: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cars' , idKey: 'carID'})
export class CarsStore extends EntityStore<ICarsState> {

  public constructor() {
    super(createInitialState());
  }
}
