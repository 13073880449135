import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore } from '@datorama/akita';
import { IUserInfoDTO } from 'src/app/api/models/user/user-info';

export interface IProfileState {
  contactData: IUserInfoDTO;
  isLoading: boolean;
  savedUrl: string;
}

export function createInitialState(): IProfileState {
  return {
    contactData: null,
    isLoading: false,
    savedUrl: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'profile' })
export class ProfileStore extends EntityStore<IProfileState> {

  public constructor() {
    super(createInitialState());
  }
}
