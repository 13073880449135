import { Pipe, PipeTransform } from '@angular/core';
import { ICarBodyDTO } from 'src/app/api/models/dto/car-body.dto';

@Pipe({
  name: 'bodyName',
  pure: true
})
export class BodyNamePipe implements PipeTransform {

  public transform(value: number | ICarBodyDTO, bodies?: ICarBodyDTO[]): string {
    if (!value) {
      return;
    }
    if (typeof value === 'number') {
      return bodies?.find(c => c.nCarBodyID === value)?.keyVal;
    } else {
      return value?.keyVal;
    }
  }
}
