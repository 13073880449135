import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { ICarDTO, ICarGroupDTO } from 'src/app/api/models/dto/car-groups.dto';
import { AddEditUser } from 'src/app/api/models/user/user-add-edit';
import { UserGroupPreview } from 'src/app/api/models/user/user-group-preview';
import { UserHttpService } from 'src/app/api/user.service';
import { CarsQuery } from 'src/app/store/cars/state/cars.query';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-admin-user-add-edit',
  templateUrl: './admin-user-add-edit.component.html',
  styleUrls: ['./admin-user-add-edit.component.scss']
})
export class AdminUserAddEditComponent implements OnInit, OnDestroy {
  public userId: any;
  public loadingData = false;
  public mode: string;
  public userGroups: UserGroupPreview[] = [];
  public userForm: FormGroup;
  public user: AddEditUser;
  public carGroups: ICarGroupDTO[] = [];
  public preselectedCars: ICarDTO[] = [];
  public loggedUserId: number;
  public preselectedFiltered: ICarDTO[] = [];
  public emptyUser: AddEditUser = {
    userId: null,
    loggedUserId: this.loggedUserId,
    username: '',
    password: '',
    fullName: '',
    emailAddress: '',
    gdpr: false,
    userGroupId: null,
    assignedCars: null
  };

  private subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private apiService: UserHttpService,
    private location: Location,
    private fb: FormBuilder, private dialog: MatDialog,
    private carsQuery: CarsQuery,
    private router: Router,
    public readonly profileQuery: ProfileQuery,
    private carsHttp: CarsHttpService
  ) {
    this.mode = route.snapshot.data.mode;
    this.user = new AddEditUser();
  }

  ngOnInit(): void {
    this.subs.add(this.profileQuery.contactData$.subscribe(data => this.loggedUserId = data.id));
    this.subs.add(this.apiService.getAllUserGroups().subscribe((response) => this.userGroups = this.filterUserGroupPreview(response.userGroups)));
    this.userId = this.route.snapshot.params.id;
    this.subs.add(
      this.carsQuery.carGroups$.subscribe((carGroups) => {
        this.carGroups = carGroups;
        if (this.userId !== undefined && this.mode !== 'add') {
          this.getUser();
        }
        this.userForm = this.initializeForm(this.emptyUser, []);
      })
    );
  }
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private filterUserGroupPreview(inUserGroups: UserGroupPreview[]): UserGroupPreview[] {
    const ugs = ['User-web-edit', 'User-web-admin', 'User-web-driver', 'User-web'];
    return inUserGroups.filter((ug)=> ugs.includes(ug.name));
  }

  private initializeForm(user: AddEditUser, cars: ICarDTO[]): FormGroup {
    return this.fb.group({
      username: [
        { value: user.username, disabled: this.isEditMode() },
        [Validators.required, Validators.minLength(3)]
      ],
      fullName: [user.fullName],
      emailAddress: [
        user.emailAddress,
        [Validators.email]
      ],
      password1: [
        user.password,
        [this.isRequired(this.isAddMode())]
      ],
      password2: [
        user.password ,
        [this.isRequired(this.isAddMode())]
      ],
      userGroupId: [
        user.userGroupId,
        [Validators.required]
      ],
      GDPR: [user.gdpr],
      carSelect: [cars],
    }, { validators: [this.checkPassword] });
  }

  public checkPassword(group: FormGroup) {
    const password1 = group.get('password1').value;
    const password2 = group.get('password2').value;

    return password1 === password2 ? null : { notMatchingPasswords: true };
  }

  isRequired(isRequired: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (isRequired) {
        return Validators.required(c);
      }
      return null;
    };
  }

  storno() {
    if (this.userForm.dirty) {
      this.openConfirmDialog('user-form.confirm.reset').afterClosed().subscribe((result) => {
        if (result) {
          this.goBack();
        }
        });
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.router.navigate(['/admin/users']);
  }

  getUser() {
    this.loadingData = true;
    forkJoin([
      this.apiService.getUser(this.userId),
      this.carsHttp.fetchCarsByUserId(this.userId)
    ]).subscribe(
      ([user, carsList]) => {
        this.user = user;
        this.preselectedCars = carsList.cars;
        const result = this.carGroups.reduce((r, { cars}) => {
          cars.forEach(o => r.push({...o}));
          return r;
        }, []);
        this.preselectedFiltered = result.filter(x => this.preselectedCars.some(y => y.carID === x.carID));
        this.userForm = this.initializeForm(this.user, this.preselectedFiltered);
        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
      }
    );
  }

  comparer(o1: ICarDTO, o2: ICarDTO): boolean {
    return o1.carID === o2.carID;
  }

  editUser(): void {
    const editUser = this.getFilledUser();
    this.loadingData = true;
    this.apiService.editUser(this.userId, editUser).subscribe((response) => {
      this.goBack();
    });
  }

  addUser(): void {
    const addUser = this.getFilledUser();
    this.loadingData = true;
    this.apiService.addUser(addUser).subscribe(result => {
      this.loadingData = false;
      if (!!result) {
        this.goBack();
      }
    });
  }

  getFilledUser(): AddEditUser {
    const user: AddEditUser = {
      userId: null,
      emailAddress: this.userForm.value.emailAddress,
      username: this.userForm.value.username,
      loggedUserId: this.loggedUserId,
      userGroupId: this.userForm.value.userGroupId,
      fullName: this.userForm.value.fullName,
      password: this.userForm.value.password1,
      gdpr: this.userForm.value.GDPR,
      assignedCars: this.userForm.value.carSelect.map(c => c.carID)
    };
    return user;
  }

  isAddMode = (): boolean => this.mode === 'add';
  isEditMode = (): boolean => this.mode === 'edit';

  private openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  headerText = (): string => {
    switch (this.mode) {
      case 'add':
        return 'user.add';
      case 'edit':
        return 'user.edit';
      default:
        break;
    }
  }
}
