
<mat-card class="login-form-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
  <div class="flexLayout headerContainer">
    <span class="row">
      <mat-icon class="back" (click)="back()">arrow_back_ios</mat-icon>
      <span class="main-form-header"> {{ 'forgot-password.title' | translate }} </span>
    </span>
    <span class="spacer"></span>
    <span>
      <span class="">
        <button mat-button [matMenuTriggerFor]="langSelector" matTooltip="{{ 'language' | translate }}">
          {{ selectedLanguage | uppercase }}
          <mat-icon class="transparent">language</mat-icon>
        </button>
        <mat-menu #langSelector="matMenu" x-position="after" y-position="below">
            <button mat-menu-item (click)="changeLanguage('cs')">{{ 'language-cs' | translate }}</button>
            <button mat-menu-item (click)="changeLanguage('sk')">{{ 'language-sk' | translate }}</button>
            <button mat-menu-item (click)="changeLanguage('en')">{{ 'language-en' | translate }}</button>
            <button *ngIf="showTranslationKeys" mat-menu-item (click)="changeLanguage('de')">{{ 'translation-keys' | translate }}</button>
        </mat-menu>
      </span>
    </span>
  </div>
  <p>{{ 'forgot-password.message'| translate }}</p>
  <ui-divider></ui-divider>
  <form [formGroup]="form" class="form" (ngSubmit)="sendForgotPasswordEmail()">
    <mat-form-field class="full-width">
      <input type="text" matInput formControlName="username" [placeholder]="'user.username' | translate">
    </mat-form-field>


    <button mat-raised-button type="submit" color="primary" class="main-button"
      [disabled]="(form.invalid && form.touched) || isLoading">{{ 'forgot-password.send' | translate }}</button>
  </form>
</mat-card>
