import { Component, Input } from '@angular/core';

export enum LoadingType {
  Spinner = 'spinner',
  ProgressBar = 'progress-bar'
}

@Component({
  selector: 'app-content-loading',
  templateUrl: './content-loading.component.html',
  styleUrls: ['./content-loading.component.scss']
})
export class ContentLoadingComponent {

  @Input()
  public type: LoadingType = LoadingType.Spinner;

  public constructor() {}

  public get isTypeSpinner(): boolean {
    return this.type === LoadingType.Spinner;
  }

}
