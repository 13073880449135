import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationQuery } from '../../store/navigation/state/navigation.query';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/store/navigation/state/navigation.service';

@Component({
  selector: 'app-content-search-panel',
  templateUrl: './content-title-panel.component.html',
  styleUrls: ['./content-title-panel.component.scss']
})
export class ContentTitlePanelComponent implements OnInit, OnDestroy {

  private sub = new Subscription();

  public title: string;

  public allowNavigateBack: boolean;

  public constructor(
    private router: Router,
    public readonly navigationQuery: NavigationQuery,
    private navigationService: NavigationService,
  ) { }

  public ngOnInit(): void {
    this.sub.add(
      this.navigationQuery.pageTitle$.subscribe((title) => this.title = title)
    );

    this.sub.add(
      this.navigationQuery.allowNavigateBack$.subscribe((allow) => this.allowNavigateBack = allow)
    );
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public async navigateBack(): Promise<void> {
    this.navigationService.backButtonClicked();
    if (this.navigationQuery.allowNavigateBack && this.navigationQuery.backUrl) {
      await this.router.navigateByUrl(this.navigationQuery.backUrl);
    }
  }
}
