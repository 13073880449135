export const permisionsData = [
  {
    url: 'export',
    permission: 'export',
  },
  {
    url: 'area-control',
    permission: 'areas',
  },
   {
    url: 'car-detail',
    permission: 'car-detail',
  },
  {
    url: 'fuel-cards',
    permission: 'import',
  },
  {
    url: 'drive-book-add',
    permission: 'drivebook-add',
  },
  {
    url: 'drive-book-detail',
    permission: 'drivebook-read',
  },
  {
    url: 'car-reminders',
    permission: 'reminder-list',
  },
  {
    url: 'stats',
    permission: 'stats',
  }
];
