import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';
import { IEmail } from './models/dto/email.dto';

@Injectable()
export class HelpPageHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) {}

  public sendEmail(data: IEmail): Observable<any> {
    return this.http.post(this.urls.sendEmail(), data);
  }
}
