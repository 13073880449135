import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarsHttpService } from './cars-http.service';
import { UrlsService } from './urls.service';
import { DriveBookHttpService } from './drive-book-http.service';
import { CarPropertiesHttpService } from './car-properties-http.service';
import { CarRemindersHttpService } from './car-reminders-http.service';
import { CarMovementHttpService } from './car-movement-http.service';
import { ImportHttpService } from './import-http.service';
import { AreaControlHttpService } from './area-control-http-service';
import { StatsHttpService } from './stats-http.service';
import { HelpPageHttpService } from './help-page-http-service';
import { CarGuardianHttpService } from './car-guardian-http.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    UrlsService,
    CarsHttpService,
    CarPropertiesHttpService,
    DriveBookHttpService,
    CarRemindersHttpService,
    CarMovementHttpService,
    ImportHttpService,
    AreaControlHttpService,
    StatsHttpService,
    HelpPageHttpService,
    CarGuardianHttpService
  ]
})
export class ApiModule { }
