<mat-card class="basic-card card-full-width card-mw-small">
  <mat-progress-bar *ngIf="loadingData" mode="indeterminate" color="accent"></mat-progress-bar>
  <div class="flexLayout">
    <span>
      <button mat-button matTooltip="{{ 'form-actions.back' | translate }}" (click)="goBack()" class="header">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span>
      <span class="main-form-header"> {{ headerText() | translate }} </span>
    </span>
    <span class="spacer"></span>
    <span>
      <div *ngIf="!isAddMode()">
        <button mat-button (click)="storno()" [matTooltip]="'form-actions.cancel' | translate">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </span>
  </div>
  <br>
  <form class="form" [formGroup]="userForm">
    <mat-form-field class="full-width">
      <input matInput [placeholder]="'user.user-name' | translate" name="username" formControlName="username"
        autocomplete="new-password" required>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="password" matInput [placeholder]="'user.password' | translate" name="password1"
        formControlName="password1" autocomplete="new-password" [required]="isAddMode()">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input type="password" matInput [placeholder]="'user.password-again' | translate" name="password2"
        formControlName="password2" autocomplete="new-password" [required]="isAddMode()">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput [placeholder]="'profile.contact-fullname' | translate" name="fullName" formControlName="fullName">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput [placeholder]="'user.email' | translate" name="emailAddress" formControlName="emailAddress">
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{'user.user-group' | translate}}</mat-label>
      <mat-select formControlName="userGroupId" required>
        <mat-option *ngFor="let item of userGroups" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>{{'user.assigned-vehicles' | translate}}</mat-label>
      <mat-select formControlName="carSelect" [compareWith]="comparer" multiple disableOptionCentering>
        <mat-optgroup *ngFor="let group of carGroups" [label]="group?.group?.name + ' (' + group?.cars?.length + ')'">
          <mat-option *ngFor="let car of group.cars" [value]="car" >
            <span><b>{{ car.spz }}</b> {{ car.toolTipText }}</span>
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="GDPR">
      <mat-label> {{ 'user.GDPR' | translate }}</mat-label>
    </mat-checkbox>

    <ui-divider></ui-divider>
    <p class="error-label centered"
      *ngIf="!!userForm?.value?.password2 && userForm?.hasError('notMatchingPasswords')">{{ 'profile.password-match-error' | translate }}</p>
    <p class="error-label centered"
      *ngIf="!!userForm?.value?.password1 && userForm?.value?.password1?.length < 8">
      {{ 'profile.password-format-error' | translate }}</p>
    <ui-divider></ui-divider>

    <div *ngIf="isEditMode()">
      <button mat-raised-button [disabled]="userForm?.hasError('notMatchingPasswords') || userForm?.invalid"
        color="primary" class="main-button" (click)="editUser()"
        type="submit">{{ 'form-actions.save' | translate }}</button>
    </div>
    <div *ngIf="isAddMode()">
      <button mat-raised-button [disabled]="userForm?.hasError('notMatchingPasswords') || userForm?.invalid"
        color="primary" class="main-button" (click)="addUser()" type="submit">{{ 'user.add' | translate }}</button>
    </div>
  </form>
</mat-card>
<app-admin-roles-description>
</app-admin-roles-description>