export interface IGuardian {
   schedule: boolean[][];
   phoneNumber: string;
   email: string;
   timezone: string;
   active: boolean;
  }

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum WeekDaysString {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}

export interface IGuardianDTO {
  guardianId: number;
  carID: number;
  schedule: boolean[][];
  phoneNumber: string;
  email: string;
  timezone: string;
  active: boolean;
 }
