import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export enum ActiveMenuItem {
  None = '',
  Cars = 'vehicles',
  DriveBook = 'drive-book',
  CarReminders = 'car-reminders',
  Help = 'help',
  Export = 'export',
  Stats = 'stats',
  FuelCard = 'fuel-card',
  AreaControl = 'area-control'
}

export const DEFAULT_PANEL_WIDTH = 650;

export interface INavigationState {
  pageTitle: string;
  activeMenuItem: ActiveMenuItem;
  backUrl: string;
  panelWidth: number;
  backUrlClicked?: any;
  enableBackAction?: boolean;
  sidebarExpanded?: boolean;
  showContentPanel?: boolean;
  contentPath?: string;
}

export function createInitialState(): INavigationState {
  return {
    pageTitle: '',
    activeMenuItem: ActiveMenuItem.None,
    backUrl: '',
    panelWidth: DEFAULT_PANEL_WIDTH
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'navigation' })
export class NavigationStore extends Store<INavigationState> {

  public constructor() {
    super(createInitialState());
  }

}
