import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { IDriveBookDayDTO, IDriveBookDTO } from '../../../api/models/dto/drive-books.dto';
import { DriveBookQuery } from '../../../store/drive-book/state/drive-book.query';
import { DriveBookService } from '../../../store/drive-book/state/drive-book.service';
import { CarsHttpService } from 'src/app/api/cars-http.service';
import { ICarDriverDTO } from 'src/app/api/models/dto/car-driver.dto';
import * as moment from 'moment';
import { UserHttpService } from 'src/app/api/user.service';
import { UrlRoutes } from 'src/app/app-routes';
import { MatDialog } from '@angular/material';
import { DriveBookEditDialogComponent } from '../drive-book-edit-dialog/drive-book-edit-dialog.component';
import { DriveBookLockDialogComponent } from '../drive-book-lock-dialog/drive-book-lock-dialog.component';

@Component({
  selector: 'app-drive-book-list',
  templateUrl: './drive-book-list.component.html',
  styleUrls: ['./drive-book-list.component.scss'],
  animations: [
    trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DriveBookListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public driveBooks: IDriveBookDayDTO[];

  @Input()
  public selectedDriveBookIDs: number[];

  public selectedDriveBooks: IDriveBookDTO[] = [];
  public drivers: ICarDriverDTO[];
  public GDPR = false;
  public tableColumns: string[] = [
    'selectAll',
    'day',
    'totalNumberOfTrips',
    'totalDuration',
    'totalDistance',
    'lastTachoValue',
    'speedLimitExceeded'
  ];

  public dataSource: any;
  public expandedElement: any;
  row: any;
  item: any;
  detail: any;

  isCarSelected = false;

  private subs = new Subscription();

  public isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty('detailRow');

  public constructor(
    public readonly driveBookService: DriveBookService,
    public readonly driveBookQuery: DriveBookQuery,
    public readonly carsHttp: CarsHttpService,
    private router: Router,
    private userHttpService: UserHttpService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dataSource = new ExampleDataSource(this.driveBooks);
    this.carsHttp.fetchAllDrivers().subscribe(drivers => {
      this.drivers = drivers;
    });

    this.subs.add(this.driveBookQuery.selectedCarID$.subscribe((carID) => {
      if (carID) {
        this.isCarSelected = true;
      } else {
        this.isCarSelected = false;
      }
    }));
    this.userHttpService.fetchContactData().subscribe(
      profile => {
        this.GDPR = profile.gdpr;
      });

    this.subs.add(this.driveBookQuery.driveBooks$.subscribe(drivebooks => {
      this.driveBooks = drivebooks;
      this.dataSource = new ExampleDataSource(this.driveBooks);
    }));

    this.subs.add(this.driveBookQuery.expandedRow$.subscribe(expandedRow => {
      this.expandedElement = expandedRow;
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedDriveBookIDs = changes?.selectedDriveBookIDs?.currentValue as number[];
    if (selectedDriveBookIDs) {
      const selectedDriveBooks = [];
      this.driveBooks.map(driveBookDay => driveBookDay.driveBooks.driveBooks).forEach(driveBooks => {
        selectedDriveBooks.push(
          ...driveBooks.filter(driveBook => selectedDriveBookIDs.some(id => driveBook.driveBookId === id))
        );
      });
      this.selectedDriveBooks = selectedDriveBooks;
    }
  }

  public isDriveBookSelected(driveBook: IDriveBookDTO): boolean {
    return this.selectedDriveBookIDs.indexOf(driveBook.driveBookId) >= 0;
  }

  public selectDriveBook(event: Event, driveBook: IDriveBookDTO): void {
    if (!this.GDPR && driveBook.driveType === 1) {
      return;
    }

    const el = event.target as HTMLElement;
    if (
      !el.classList.contains('edit-drive-button') &&
      !el.classList.contains('mat-icon')
    ) {
      this.driveBookService.selectDriveBook(driveBook.driveBookId);
    }
  }

  public async navigateToEdit(driveBookID: number): Promise<void> {
    await this.router.navigate(
      [`/dashboard/drive-book-detail/${ driveBookID }`], { state: { previous: 'list' } }
    );
  }

  public expandRow(row: any): void {
    this.expandedElement === row ? this.driveBookService.setExpandedRow(null) : this.driveBookService.setExpandedRow(row);
  }

  public selectAll(event: Event, item: IDriveBookDayDTO) {
    const isSelected = this.driveBookService.IsDaySelected(item.day);
    const driveBook: IDriveBookDTO[] = item.driveBooks.driveBooks;
    this.driveBookService.SelectAllDriveBooksByDay(driveBook, isSelected);
  }

  public async addDrivebook() {
    await this.router.navigateByUrl(UrlRoutes.urls.drivebook.add);
  }

  public getDriversName(driverId: number): string {
    if (this.drivers !== undefined) {
      if (driverId !== -1) {
        const driver = this.drivers.find(x => x.nPersonID === driverId);
        if (!driver) {
          return '';
        }
        return driver.strSurname;
      } else {
        return '';
      }
    }
  }

  public isDisplayed(driveBook: IDriveBookDTO) {
    return driveBook?.driveType === 0 || driveBook?.driveType === -1 || (driveBook?.driveType === 1 && this.GDPR);
  }

  public getLastTachoValue(item: IDriveBookDayDTO): number {
    return item?.driveBooks?.driveBooks[0].tachoStop;
  }

  public getTime(value: number) {
    const date = moment(value).local();
    return date.valueOf();
  }

  public multiplyEditDrivebooks(): void {
    this.dialog.open(DriveBookEditDialogComponent, { minWidth: 550 });
  }

  public multiplyLockDrivebooks(): void {
    this.dialog.open(DriveBookLockDialogComponent, { minWidth: 450, autoFocus: false });
  }

  get showMultipleEditButton() {
    return this.selectedDriveBooks.some(driveBook => !driveBook.isLocked);
  }
}

export class ExampleDataSource extends DataSource<any> {
  constructor(public driveBooks: IDriveBookDayDTO[]) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rows = [];
    this.driveBooks.forEach(element => rows.push(element, { detailRow: true, element }));
    return of(rows);
  }

  disconnect() { }
}
