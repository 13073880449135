import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {DriveBookStore, IDriveBookFilter, IDriveBookState} from './drive-book.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DriveBookQuery extends Query<IDriveBookState> {

  public readonly filter$ = this.select((state) => state.filter);

  public readonly selectedCar$ = this.select((state) => state.selectedCar);
  public readonly selectedCarID$ = this.select((state) => state.selectedCarID);

  public readonly totals$ = this.select((state) => state.totals);

  public readonly driveBooks$ = this.select((state) => state.driveBooks);

  public readonly isCarSelected$ = this.select((state) => !!state.selectedCarID);

  public readonly isLoadingCar$ = this.select((state) => state.loadingCar);

  public readonly isLoadingTotalsAndDriveBooks$ = this.select((state) => state.loadingTotalsAndDriveBooks);

  public readonly selectedDriveBookIDs$ = this.select((state) => state.selectedDriveBookIDs);

  public readonly selectedDriveBookPaths$ = this.select((state) => state.selectedDriveBookPaths);

  public readonly expandedRow$ = this.select((state) => state.expandedRow);

  public readonly isLoadingData$ = combineLatest([
    this.isLoadingCar$,
    this.isLoadingTotalsAndDriveBooks$
  ]).pipe(
    map(([ isLoadingCar, isLoadingTotalsAndDriveBooks ]) => (isLoadingCar || isLoadingTotalsAndDriveBooks))
  );

  public constructor(protected store: DriveBookStore) {
    super(store);
  }

  public get selectedDriveBookIDs(): number[] {
    const { selectedDriveBookIDs } = this.getValue();
    return Object.values(selectedDriveBookIDs);
  }

  public get filter(): IDriveBookFilter {
    const { filter } = this.getValue();
    return filter;
  }

  public get selectedCarID(): number {
    const { selectedCarID } = this.getValue();
    return selectedCarID;
  }

}
