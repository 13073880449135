<div class="stats-day" [ngClass]="{'day-filler': stats.filler }">
    <div class="date">
      {{stats?.dayOfTheMonth}}. {{ (stats?.month)}}. 
    </div>
    <div *ngFor="let car of stats.carStats.slice(0,4)" class="car-item" >
      <div class="plate-num">{{car?.spz}}</div><div class="distance">{{car?.distance | number: "1.0-0" }} km</div>
    </div>
    <div *ngIf="stats.carStats.length > 4" class="more">
      {{ 'stats-page.more' | translate}} {{ stats?.carStats?.length - 4}} <i class="material-icons">keyboard_arrow_down</i>
    </div>
</div>
