import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SumType } from 'src/app/store/export/state/export.store';

const KEY_PREFIX = 'export.select-sum';

@Pipe({
  name: 'dailySum',
  pure: false
})
export class DailySumPipe implements PipeTransform {

  public constructor(
    private translateService: TranslateService
  ) {}

  public transform(value: number): string {

    switch (value) {
      case SumType['daily-sum']:
        return this.translate(SumType['daily-sum']);
      case SumType['total-sum']:
        return this.translate(SumType['total-sum']);
      default:
        return '';
    }
  }

  private translate(sumType: number): string {
    return this.translateService.instant(`${KEY_PREFIX}.${SumType[sumType]}`) as string;
  }
}
