import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DoNotShowError } from '../constants/error-constants';
import { DURATION, VERTICAL_POSITION, HORIZONTAL_POSITION, CSS_CLASS } from '../constants/snackbar-constants';
import { LoggerService } from '../services/logger.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public constructor(
    private snackbar: MatSnackBar,
    private logger: LoggerService,
    private translate: TranslateService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err) => {
          this.logger.logError(`RequestError`, err);
          const title = this.translate.instant('error-global-title');
          const description = this.translate.instant('error-global-text');
          const detail = `${ err?.error?.detail }`;
          let msg = `${ title } ${ description }`;
          if (err?.status === 400) {
            msg = err?.error?.title || title;
            if (!!err?.error?.errors) {
              Object.keys(err?.error?.errors).forEach(fieldName => msg += `\n   - ${ fieldName }: ${ err?.error?.errors[fieldName] }`);
            } else if (!!err?.error?.detail) {
              msg = this.translate.instant(err?.error?.detail);
            }
          } else if (!!err?.error?.detail) {
            msg = `${ err?.error?.title || title } ${ err?.error?.detail || description }`;
          }
          if (detail !== DoNotShowError) {
            this.snackbar.open(msg, 'x', {
              duration: DURATION,
              verticalPosition: VERTICAL_POSITION,
              horizontalPosition: HORIZONTAL_POSITION,
              panelClass: CSS_CLASS
            });
          }
          return throwError(err);
        })
      );
  }
}
