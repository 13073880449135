<div class="reminder-detail" *ngxPermissionsOnly="['reminder-add-edit']">
  <div class="form" [formGroup]="reminderForm">
    <div class="column-input-4">
      <mat-form-field class="left-column">
        <input [required]="true"  matInput [placeholder]="'reminder.name' | translate" formControlName="name">
      </mat-form-field>

      <mat-form-field class="right-column">
        <mat-select [required]="true"  formControlName="type" [placeholder]="'reminder.type' | translate">
          <mat-option *ngFor="let enum of reminderTypes" [value]="enum.key">
            {{ enum.value  | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>

    <mat-form-field class="full-width">
      <input matInput [placeholder]="'reminder.mail' | translate"  formControlName="mails">
      <mat-error *ngIf="!!reminderForm?.controls?.mails?.errors?.wrongEmail">
        {{ "area-control.wrong-notification-email" | translate }}
    </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
    <mat-label>{{ 'reminder.note' | translate }}</mat-label>
    <textarea matInput rows="1" cols="40"
        formControlName ="message" >
    </textarea>
  </mat-form-field>

    <div class="conditionList">
      <app-reminders-conditions-list [reminder]="reminder" (outputConditions)="submitConditions($event)" >
      </app-reminders-conditions-list>
    </div>
    <ng-container *ngIf="isSaving">
      <app-content-loading type="progress-bar"></app-content-loading>
    </ng-container>
    <div class="actions-block">
      <button mat-button mat-raised-button (click)="cancelAddNewReminder()" class="cancel"> {{ 'Cancel' | translate }}</button>
      <button [disabled]="reminderForm.invalid" 
      mat-button mat-raised-button (click)="saveReminder()" color="primary"> {{ 'reminder.save' | translate }}</button>
    </div>
  </div>
  <div class="info">
      <p [innerHTML]="'required-info' | translate"></p> 
    <mat-divider></mat-divider>
      <p [innerHTML]="'reminders-info-text-1' | translate"></p>
      <p [innerHTML]="'reminders-info-text-2' | translate"></p>
      <p [innerHTML]="'reminders-info-text-3' | translate"></p>
      <ui-divider></ui-divider>
      <ui-divider></ui-divider>
  </div>

</div>

