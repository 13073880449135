import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';

@Injectable()
export class ImportHttpService {

  public constructor(
    private urls: UrlsService,
    private http: HttpClient
  ) { }

  public uploadFile(file: any): Observable<any> {
    const fd: FormData = new FormData();
    fd.append('file', file);
    return this.http.post<any>(this.urls.uploadFuelCardFile(), fd);
  }
}
