import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../store/navigation/state/navigation.service';
import { ActiveMenuItem } from '../../store/navigation/state/navigation.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEmail } from 'src/app/api/models/dto/email.dto';
import { HelpPageHttpService } from 'src/app/api/help-page-http-service';
import { UserHttpService } from 'src/app/api/user.service';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceStore } from 'src/app/store/workspace/workspace.store';

const NAV_PATH = '/dashboard/help';
const PAGE_TITLE = 'page-title.help';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit, OnDestroy {

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(128), Validators.minLength(6)]),
    subject: new FormControl('', [Validators.required, Validators.maxLength(256)]),
    content: new FormControl('', [Validators.required, Validators.maxLength(1024)])
  });

  public constructor(
    private navigationService: NavigationService,
    private helpPageHttpService: HelpPageHttpService,
    private userHttpService: UserHttpService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
    private workspaceStore: WorkspaceStore
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.Help,
      pageTitle: PAGE_TITLE,
      panelWidth: 400
    });

    this.userHttpService.fetchContactData().subscribe(profile => {
      if (!!profile.emailAddress) {
        this.form.patchValue({email : profile.emailAddress });
      }
    });

    this.navigationService.showContentPanel(NAV_PATH);
  }

  public ngOnDestroy(): void {
    setTimeout(() => {
      this.navigationService.clearActiveMenuItem();
    }, 0);
  }

  public sendEmail() {
    const request: IEmail = {
      emailAddress: this.form.value.email,
      subject: this.form.value.subject,
      content: this.form.value.content
    };

    this.helpPageHttpService.sendEmail(request).subscribe((res) => {
      this.notifyService.showInfo(this.translateService.instant('contact.email-sended'));
    }, (error) => {
      this.notifyService.showError(this.translateService.instant('contact.email-error'));
    });

    this.form.reset({email: this.form.get('email').value});
  }

  get contactEmail(): string {
    return this.workspaceStore.getValue().contactEmail;
  }

  get contactPhone(): string {
    return this.workspaceStore.getValue().contactPhone;
  }

  get contactCompanyName(): string {
    return this.workspaceStore.getValue().contactCompanyName;
  }

  get contactCompanyStreet(): string {
    return this.workspaceStore.getValue().contactCompanyStreet;
  }

  get contactCompanyCity(): string {
    return this.workspaceStore.getValue().contactCompanyCity;
  }

}
