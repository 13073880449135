import { Injectable } from '@angular/core';
import { IMapPoint } from '../store/map/state/map.store';
import { ICarPositionExtra, ICarPositionDTO } from '../api/models/dto/car-groups.dto';
import * as moment from 'moment';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { MAX_SPEED_LEVEL, MIN_SPEED_LEVEL } from '../constants/vehicle-constants';
import { LoggerService } from './logger.service';
import { IAreaControlPathPoint } from '../api/models/dto/area-control.dto';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private logger: LoggerService) { }

  public distance_on_geoid(previousPoint: ICarPositionExtra | ICarPositionDTO, actualPoint: ICarPositionExtra | ICarPositionDTO): number {
    const M_PI = Math.PI;
    let lat1 = actualPoint.latitude;
    let lon1 = actualPoint.longitude;
    let lat2 = previousPoint.latitude;
    let lon2 = previousPoint.longitude;
    // Convert degrees to radians
    lat1 = lat1 * M_PI / 180.0;
    lon1 = lon1 * M_PI / 180.0;

    lat2 = lat2 * M_PI / 180.0;
    lon2 = lon2 * M_PI / 180.0;

    // radius of earth in metres
    const r = 6378100;

    // P
    const rho1 = r * Math.cos(lat1);
    const z1 = r * Math.sin(lat1);
    const x1 = rho1 * Math.cos(lon1);
    const y1 = rho1 * Math.sin(lon1);

    // Q
    const rho2 = r * Math.cos(lat2);
    const z2 = r * Math.sin(lat2);
    const x2 = rho2 * Math.cos(lon2);
    const y2 = rho2 * Math.sin(lon2);

    // Dot product
    const dot = (x1 * x2 + y1 * y2 + z1 * z2);
    // tslint:disable-next-line:variable-name
    const cos_theta = dot / (r * r);
    const theta = Math.acos(cos_theta);

    // Distance in Metres
    return r * theta;
  }

  public calculateSpeed(previousPoint: ICarPositionExtra, actualPoint: ICarPositionExtra): number {
    if (previousPoint.lastKnownDate === actualPoint.lastKnownDate) {
      return previousPoint.speed || 0;
    }
    const dist = this.distance_on_geoid(previousPoint, actualPoint);

    const diff = this.toTimestamp(actualPoint.lastKnownDate) - this.toTimestamp(previousPoint.lastKnownDate);
    if (diff <= 0) {
      return 0;
    }
    // tslint:disable-next-line:variable-name
    const time_s = diff;
    // tslint:disable-next-line:variable-name
    const speed_mps = dist / time_s;
    // tslint:disable-next-line:variable-name
    const speed_kph = (speed_mps * 3600.0) / 1000.0;
    // tslint:disable-next-line:max-line-length
    this.logger.logInfo(`CalculateSpeed: vehicle id=${ previousPoint?.carId } drove ${ dist.toFixed(2) } meters in ${ time_s.toFixed(2) } seconds. Calculated speed: ${ Number(speed_kph.toFixed(0)) } km/h`);
    if (Number.isFinite(speed_kph) && Number(speed_kph) > MIN_SPEED_LEVEL && Number(speed_kph) < MAX_SPEED_LEVEL) {
      return Number(speed_kph.toFixed(0));
    }
    this.logger.logInfo(`CalculateSpeed: Not able  to calculate speed, using previous speed: ${ previousPoint.speed } km/h`);
    return previousPoint.speed || 0;
  }

  public toTimestamp(strDate) {
    const date = Date.parse(strDate);
    return date / 1000;
  }

  public round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public getTimeDiffInMinutes(date1: any, date2: any): number {
    const oneMinute = 60 * 1000;
    const timeDiff = Math.round(Math.abs((moment.utc(date1).valueOf() - moment.utc(date2).valueOf()) / oneMinute));
    return timeDiff;
  }

  public calculateCenterPoint(points: IMapPoint[]): IMapPoint {
    const latitudes = points.map((data) => data.latitude);
    const longitudes = points.map((data) => data.longitude);
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    return {
      latitude: (minLat + maxLat) / 2,
      longitude: (minLng + maxLng) / 2,
    };
  }

  public calculateRadius(points: IMapPoint[]): number {
    const latitudes = points.map((data) => data.latitude);
    const longitudes = points.map((data) => data.longitude);
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    const point1 = {
      latitude: minLat,
      longitude: minLng,
    };

    const point2 = {
      latitude: maxLat,
      longitude: maxLng,
    };
    const distance = Number(this.distance_on_geoid(point1, point2).toFixed(0));
    return distance / 2;
  }

  public calculateZoomLevel(radius: number): number {
    if (isNaN(radius) || radius < 2500) {
      return 15;
    }
    const scale = radius / 500;
    const zoomLevel = parseInt((16 - Math.log(scale) / Math.log(2)).toString(), 10);
    return zoomLevel < 8 ? zoomLevel + 1 : zoomLevel;
  }

  public isInAreaPolygon(point: IMapPoint, polygonPath: IMapPoint[]): boolean {
    let inPoly = false;
    let numPoints;
    let i;
    let j;
    let vertex1: IMapPoint;
    let vertex2: IMapPoint;
    const lng = point.longitude;
    const lat = point.latitude;
    numPoints = polygonPath.length;
    j = numPoints - 1;

    for (i = 0; i < numPoints; i++) {
      vertex1 = polygonPath[i];
      vertex2 = polygonPath[j];

      if (
        vertex1.longitude < lng && vertex2.longitude >= lng ||
        vertex2.longitude < lng && vertex1.longitude >= lng
      ) {
        if (
          vertex1.latitude +
          (lng - vertex1.longitude) /
          (vertex2.longitude - vertex1.longitude) *
          (vertex2.latitude - vertex1.latitude) <
          lat
        ) {
          inPoly = !inPoly;
        }
      }

      j = i;
    }
    return inPoly;
  }

  public calculatePointsFromPolygon(paths: IAreaControlPathPoint[]): IMapPoint[] {
    return paths.map(path => ({ latitude: path.lat, longitude: path.lng }));
  }

  public format(input: string, ...args: any[]): string {
    return input.replace(/{(\d+)}/g, (match, num) => (typeof args[num] !== 'undefined' ? args[num] : match));
  }

  validateEmails(isRequired: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let emailValidators = null;
      if (c?.value === '' && !isRequired) {
        return emailValidators;
      } else {
        const emails = c?.value?.split(';');
        emails?.forEach((email) => {
          if (!this.validEmail(email.trim())) {
            emailValidators = { wrongEmail: true };
          }
        });
      }
      return emailValidators;
    };
  }

  validatePhones(isRequired: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let phoneValidators = null;
      if (c?.value === '' && !isRequired) {
        return phoneValidators;
      } else {
        const phones = c?.value?.split(/;|,/);
        phones?.forEach((phone) => {
          if (!this.validPhone(phone.trim())) {
            phoneValidators = { wrongPhone: true };
          }
        });
      }
      return phoneValidators;
    };
  }

  validateNumber(isRequired: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      let numberValidator = null;
      if ((c?.value === '' || c?.value === null) && !isRequired) {
        return numberValidator;
      } else {
        const input = c?.value;
        if (!this.validNumber(input)) {
          numberValidator = { wrongNumber: true };
        }
      }
      return numberValidator;
    };
  }

  validEmail(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  validPhone(phone: string): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /[\+\;\,\ 0-9]$/;
    return regex.test(phone);
  }

  validNumber(input: string): boolean {
    // tslint:disable-next-line:max-line-length
    const regex = /^\b(0?[1-9]|1[0-9]|2[0-8])\b$/;
    return regex.test(input);
  }
}
