import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { MatDialog } from '@angular/material';
import { ProfileService } from 'src/app/store/profile/state/profile.service';
import { IUserChangePassword, IUserContactDataRequest, IUserInfoDTO } from 'src/app/api/models/user/user-info';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private subs = new Subscription();
  contactData: IUserInfoDTO;
  filledProfile = false;
  isLoading: boolean;

  public constructor(private profileQuery: ProfileQuery,
                     private profileService: ProfileService,
                     private dialogService: MatDialog,

  ) { }

  public ngOnInit(): void {
    this.subs.add(this.profileQuery.profileFilled$.subscribe((filled) => {
      this.filledProfile = filled;
    }));
    this.subs.add(this.profileQuery.contactData$.subscribe((contactData) => {
      this.contactData = contactData;
    }));
    this.subs.add(this.profileQuery.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
    }));
  }
  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onSaveProfile(request: IUserContactDataRequest): void {
    request.emailAddress = request.emailAddress === '' ? null : request.emailAddress;
    this.profileService.sendContactData(request, this.closeDialog);
  }

  public onChangePassword(request: IUserChangePassword): void {
    this.profileService.sendChangePassword(request, this.closeDialog);
  }

  public skipWarning(): void {
    const emptyContactData: IUserContactDataRequest = {
      id: null,
      emailAddress: null,
      fullName: null,
      workspaceId: null
    };
    this.profileService.sendContactData(emptyContactData, this.closeDialog);
  }

  public closeDialog = (): void => {
    this.dialogService.closeAll();
  }
}
