import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DriveBookHttpService } from 'src/app/api/drive-book-http.service';
import { NotifyService } from 'src/app/services/notify.service';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import { DriveBookService } from 'src/app/store/drive-book/state/drive-book.service';
import { DriveBookLockOption, IDriveBookLockRequest } from 'src/app/store/drive-book/state/drive-book.store';
import { DriveBookEditDialogComponent } from '../drive-book-edit-dialog/drive-book-edit-dialog.component';

@Component({
    selector: 'app-drive-book-lock-dialog',
    templateUrl: './drive-book-lock-dialog.component.html',
    styleUrls: ['./drive-book-lock-dialog.component.scss']
})

export class DriveBookLockDialogComponent implements OnInit {
  public form: FormGroup;
  public driveBookLockOptions = [DriveBookLockOption.lock, DriveBookLockOption.unlock];
  public driveBookLockOption: DriveBookLockOption;

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<DriveBookEditDialogComponent>,
    private driveBookQuery: DriveBookQuery,
    private driveBookService: DriveBookService,
    private driveBookHttpService: DriveBookHttpService,
    private notifyService: NotifyService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      driveBookLockOption: null
    });
  }

  save(): void {
    const request: IDriveBookLockRequest = {
      driveBooksIds: this.driveBookQuery.getValue().selectedDriveBookIDs
    };
    const requestObservable = this.driveBookLockOption === DriveBookLockOption.lock ?
      this.driveBookHttpService.lockDriveBooks(request) :
      this.driveBookHttpService.unlockDriveBooks(request);
    requestObservable.subscribe((response) => {
      if (!response) {
        this.driveBookService.updateDriveBookData();
        this.closeDialog();
        this.notifyService.showInfo(this.driveBookLockOption === DriveBookLockOption.lock
          ? this.translateService.instant('drive-book.lock-dialog.lock.success')
          : this.translateService.instant('drive-book.lock-dialog.unlock.success'));
      } else {
        this.notifyService.showError(this.driveBookLockOption === DriveBookLockOption.lock
          ? this.translateService.instant('drive-book.lock-dialog.lock.fail')
          : this.translateService.instant('drive-book.lock-dialog.unlock.fail'));
      }
    },
    ((error) => {
      this.notifyService.showError(this.driveBookLockOption === DriveBookLockOption.lock
        ? this.translateService.instant('drive-book.lock-dialog.lock.error')
        : this.translateService.instant('drive-book.lock-dialog.unlock.error'));
    }));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getLockOptionString(option: DriveBookLockOption): string {
    const optionPrefix = 'drive-book.lock-dialog.';
    return option === DriveBookLockOption.lock ? optionPrefix + 'lock' : optionPrefix + 'unlock';
  }
}
