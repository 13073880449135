<div class="drives-wrapper">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="selectAll">
      <mat-header-cell *matHeaderCellDef class="header-first-cell wrapper"></mat-header-cell>
      <mat-cell *matCellDef="let item" class="row-first-cell">
        <mat-checkbox type="checkbox" (click)="selectAll($event, item)" (click)="$event.preventDefault()"
          [checked]="this.driveBookService.IsDaySelected(item.day)"
          (click)="expandRow(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="day">
      <mat-header-cell *matHeaderCellDef class="wrapper">{{ "drive-book.drives.date" | translate | uppercase }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item"  [ngClass]="{ 'driveSelected' : this.driveBookService.IsAnyDriveOfDaySelected(item.day) }">
        {{ item.day | stringToTimestamp | timestampToString: "mediumDate" }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalNumberOfTrips">
      <mat-header-cell *matHeaderCellDef class="wrapper">{{ "drive-book.drives.total-drives" | translate | uppercase }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item" style="text-align: center">
        {{ item.driveBooks?.totalNumberOfTrips | number: "1.0-1" }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalDuration">
      <mat-header-cell *matHeaderCellDef class="wrapper">{{ "drive-book.totals.drives-time" | translate | uppercase }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">{{ item.driveBooks?.totalDuration | durationTime }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalDistance">
      <mat-header-cell *matHeaderCellDef class="wrapper distance-cell">
        {{ "drive-book.drives.total-km" | translate  | uppercase }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item" class="distance-cell">{{ item.driveBooks?.totalDistance | number: "1.0-1" }} km
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastTachoValue">
      <mat-header-cell *matHeaderCellDef class="wrapper distance-cell">
        {{ "drive-book.drives.last-tacho" | translate  | uppercase }}
        <div *ngxPermissionsOnly="['drivebook-add']">
          <button [matTooltip]="'drive-book.add-new' | translate" mat-button (click)="addDrivebook()" [disabled]="!isCarSelected" class="add-new-button">
            +
          </button>
          </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let item" class="distance-cell">{{ getLastTachoValue(item) | number: "1.0-0" }} km
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="speedLimitExceeded">
      <mat-header-cell *matHeaderCellDef class="wrapper speed-limit-cell"></mat-header-cell>
      <mat-cell *matCellDef="let item" class="speed-limit-cell">{{ item.driveBooks?.speedLimitExceeded?'!':'' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-table class="inner-table" #innerTables [dataSource]="item">
        <ng-container matColumnDef="detail">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let detail" class="main-cell">
            <table class="mat-table" *ngIf="expandedElement?.day === detail?.element?.day">
              <thead>
                <tr class="mat-header-row day-group-row">
                  <th class="mat-header-cell"></th>
                  <th class="mat-header-cell">{{ "drive-book.drives.start" | translate }}</th>
                  <th class="mat-header-cell">{{ "drive-book.drives.end" | translate }}</th>
                  <th class="mat-header-cell">{{ "drive-book.drives.type" | translate }}</th>
                  <th class="mat-header-cell">{{ "drive-book.drives.time" | translate }}</th>
                  <th class="mat-header-cell">{{ "drive-book.drives.purpose" | translate }}</th>
                  <th class="mat-header-cell distance-cell">{{ "drive-book.drives.distance" | translate }}</th>
                  <th class="mat-header-cell"></th>
                  <th class="mat-header-cell"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="mat-row detail-row " *ngFor="let driveBook of detail?.element?.driveBooks?.driveBooks"
                  (click)="selectDriveBook($event, driveBook)" 
                  [class.selected]="isDriveBookSelected(driveBook)">
                  <td class="mat-cell row-first-cell">
                    <mat-checkbox type="checkbox" [checked]="isDriveBookSelected(driveBook)"
                    *ngIf="isDisplayed(driveBook)"
                      (click)="$event.preventDefault()">
                    </mat-checkbox>
                  </td>
                  <td class="mat-cell">
                    <div class="time"> {{ driveBook?.dateTimeStart | stringToTimestamp | date:"H:mm" }}</div>
                    <div *ngIf="isDisplayed(driveBook)">
                      {{ driveBook?.startDescription }}
                    </div>
                  </td>
                  <td class="mat-cell">
                    <div class="time"> {{ driveBook?.dateTimeStop | stringToTimestamp | date:"H:mm" }}</div>
                    <div *ngIf="isDisplayed(driveBook)">
                      {{ driveBook?.stopDescription }}
                    </div>
                  </td>
                  <td class="mat-cell" [ngClass]="{ 'drivetype': driveBook?.driverId === -1 }">
                    <ng-container *ngIf="driveBook?.driveType === 1">
                      <img class="drivetype-icon"
                      src="./../../../../assets/icons/private.svg" height="30px" matTooltipPosition="right"
                        [matTooltip]="driveBook?.driveType | driveType">
                    </ng-container>
                    <ng-container *ngIf="driveBook?.driveType === 0">
                      <img class="drivetype-icon"
                      src="./../../../../assets/icons/business.svg" height="30px" matTooltipPosition="right"
                        [matTooltip]="driveBook?.driveType | driveType">
                    </ng-container>
                    <div *ngIf="isDisplayed(driveBook)" class="driver">
                      {{ getDriversName(driveBook?.driverId) }}
                    </div>
                    <td class="mat-cell drivedistance">{{ driveBook?.totalTime | durationTime }}</td>
                    <td class="mat-cell drive-purpose">
                      <mat-icon *ngIf="!!driveBook?.purpose" class="transparent" matTooltipPosition="right" [matTooltip]="driveBook?.purpose">sticky_note_2</mat-icon>
                    </td>
                  <td class="mat-cell drivedistance">{{ (driveBook?.newDistance > 0 ? driveBook?.newDistance : driveBook?.traceDistance) | number: "1.0-1" }} km
                    <b matTooltipPosition="right" [matTooltip]="'drive-book.distance-modified' | translate" *ngIf="driveBook?.newDistance > 0">*</b>
                    <div class="driver"> {{ driveBook?.tachoStop | number: "1.0-0" }} km</div>
                  </td>
                  <td class="mat-cell">
                    <div *ngxPermissionsOnly="['drivebook-edit', 'drivebook-edit-limited']">
                      <button *ngIf="isDisplayed(driveBook) && !driveBook?.isLocked"
                        (click)="navigateToEdit(driveBook?.driveBookId)" class="edit-drive-button" mat-icon-button>
                        <mat-icon matTooltipPosition="right" [matTooltip]="'drive-book.row.edit' | translate"> edit </mat-icon>
                      </button>
                      <button *ngIf="isDisplayed(driveBook) && driveBook?.isLocked" class="edit-drive-button" mat-icon-button>
                        <mat-icon matTooltipPosition="right" [matTooltip]="'drive-book.row.locked' | translate"> lock </mat-icon>
                      </button>
                    </div>
                  </td>
                  <td class="mat-cell">
                    <span *ngIf="driveBook?.speedLimitExceeded" class="speed-limit" matTooltipPosition="right" [matTooltip]="'drive-book.row.seedExceeded' | translate">!</span>
                  </td>                  
                </tr>
              </tbody>
            </table>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="detail"></mat-header-row>
        <mat-row *matRowDef="let row; columns: detail"></mat-row>
      </mat-table>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns" matRipple class="element-row"
      [class.expanded]="expandedElement?.day == row?.day"
      (click)="expandRow(row)"></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" class=" day-group-row"
      [@detailExpand]="row.element?.day == expandedElement?.day ? 'expanded' : 'collapsed'"
      style="overflow: hidden; border: none">
    </mat-row>
  </mat-table>
</div>
<div class="multiply-edit">
  <div class="multiply-edit" *ngxPermissionsOnly="['drivebook-lock', 'drivebook-unlock']">
    <button class="multiply-edit-button" *ngIf="selectedDriveBookIDs.length > 0" mat-raised-button color="primary" tabindex="1" (click)="multiplyLockDrivebooks()">
      {{ 'drive-book.lock-unlock' | translate }}
    </button>
  </div>
  <div class="multiply-edit" *ngxPermissionsOnly="['drivebook-multiple-edit']">
    <button class="multiply-edit-button" *ngIf="showMultipleEditButton" 
      mat-raised-button color="primary" tabindex="1" (click)="multiplyEditDrivebooks()">
      {{ 'drive-book.multiply-edit' | translate }}
    </button>
  </div>
</div>
<div *ngIf="driveBooks?.length === 0" class="drives-wrapper">
  <p class="empty">{{ "drive-book.drives.empty" | translate }}</p>
</div>
