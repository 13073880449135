import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { AdminUserListComponent } from './components/admin/admin-user-list/admin-user-list.component';
import { AdminUserAddEditComponent } from './components/admin/admin-user-add-edit/admin-user-add-edit.component';
import { PagesModule } from './pages/pages.module';
import { CarInfoEditPageComponent } from './pages/car-info-edit/car-info-edit-page/car-info-edit-page.component';
import { CarsPageComponent } from './pages/cars/cars-page/cars-page.component';
import { CarDetailPageComponent } from './pages/car-detail/car-detail-page/car-detail-page.component';
import { DriveBookPageComponent } from './pages/drive-book/drive-book-page/drive-book-page.component';
import { DriveBookDetailPageComponent } from './pages/drive-book-detail/drive-book-detail-page/drive-book-detail-page.component';
import { CarRemindersPageComponent } from './pages/car-reminders/car-reminders-page/car-reminders-page.component';
import { ExportPageComponent } from './pages/export-page/export-page.component';
import { FuelCardPageComponent } from './pages/fuel-card/fuel-card-page.component';
import { DriveBookAddPageComponent } from './pages/drive-book-add/drive-book-add-page.component';
import { AreaControlPageComponent } from './pages/area-control-page/area-control-page.component';
import { AreaControlAddPageComponent } from './pages/area-control-add/page/area-control-add-page.component';
import { AreaControlEditPageComponent } from './pages/area-control-edit/page/area-control-edit-page.component';
import { StatsPageComponent } from './pages/stats/stats-page/stats-page.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordSetComponent } from './components/forgot-password-set/forgot-password-set.component';
import { AuthService } from './api/auth.service';
import { UserHttpService } from './api/user.service';
import { Observable } from 'rxjs';
import { AreaControlDetailComponent } from './pages/area-control-detail/area-control-detail.component';

@Injectable()
export class AppDataResolver implements Resolve<any> {
  constructor(private provider: AuthService, private apiService: UserHttpService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.provider.loadAuthData(this.apiService);
  }
}

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Home' } },
  {
    path: 'login', component: LoginLayoutComponent, children: [
      { path: '', component: LoginComponent, data: { title: 'Login' } },
      { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot password' } },
      { path: 'forgot-password-set/:token', component: ForgotPasswordSetComponent, data: { title: 'Forgot password set' } },
    ]
  },
  {
    path: 'dashboard',
    component: SiteLayoutComponent,
    resolve: { data: AppDataResolver },
    canActivate: [AuthGuard],
    children: [
      { path: 'cars', component: CarsPageComponent },
      { path: 'stats', component: StatsPageComponent },
      { path: 'car-detail/:car_id', component: CarDetailPageComponent },
      { path: 'car-detail/:car_id/edit', component: CarInfoEditPageComponent },
      { path: 'drive-book', component: DriveBookPageComponent },
      { path: 'drive-book/:car_id', component: DriveBookPageComponent },
      { path: 'drive-book-detail/:drive_book_id', component: DriveBookDetailPageComponent },
      { path: 'car-reminders', component: CarRemindersPageComponent },
      { path: 'car-reminders/:car_id', component: CarRemindersPageComponent },
      { path: 'help', component: HelpPageComponent },
      { path: 'export', component: ExportPageComponent },
      { path: 'fuel-cards', component: FuelCardPageComponent },
      { path: 'drive-book-add', component: DriveBookAddPageComponent },
      { path: 'area-control', component: AreaControlPageComponent },
      { path: 'area-control/add', component: AreaControlAddPageComponent },
      { path: 'area-control/:area_id', component: AreaControlDetailComponent },
      { path: 'area-control/:area_id/edit', component: AreaControlEditPageComponent },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      { path: 'users', component: AdminUserListComponent, data: { title: 'user-list' } },
      { path: 'users/add', component: AdminUserAddEditComponent, data: { title: 'user-add', mode: 'add' } },
      { path: 'users/detail/:id', component: AdminUserAddEditComponent, data: { title: 'user-detail', mode: 'detail' } },
      { path: 'users/edit/:id', component: AdminUserAddEditComponent, data: { title: 'user-edit', mode: 'edit' } },
    ]
  },
  { path: '**', redirectTo: 'login', data: { title: 'Login' } }, // TODO  FILL WITH SOME 404 PAGE
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })

  ],
  exports: [
    RouterModule,
    PagesModule
  ],
})

export class AppRoutingModule { }
