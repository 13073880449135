import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reminders-dialog-list',
  templateUrl: './reminders-list-dialog.component.html',
  styleUrls: ['./reminders-list-dialog.component.scss'],
})
export class RemindersListDialogComponent {
  message = this.translateService.instant('reminder.list.confirm.message');
  confirmButtonText = this.translateService.instant('reminder.list.confirm.yes');
  cancelButtonText = this.translateService.instant('reminder.list.confirm.no');
  info: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<RemindersListDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if (!!data.info) {
        this.info = data.info;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
