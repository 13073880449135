import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ui-icon-snackbar',
  template: `<div class="row v-center" (click)="onClick()" style="cursor: pointer;">
    <mat-icon>{{ data?.icon }}</mat-icon>
    <ui-divider [vertical]="true" [compact]="true"></ui-divider>
    <span>{{ data?.message }}</span>
  </div>`
})
export class IconSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  onClick(): void {
    if (!!this.data?.clickCallback) {
      this.data.clickCallback();
    }
  }
}
