import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../api/auth.service';
import { LanguageService } from '../../services/language.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UrlRoutes } from 'src/app/app-routes';
import { ProfileService } from 'src/app/store/profile/state/profile.service';
import { ProfileQuery } from 'src/app/store/profile/state/profile.query';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public selectedLanguage;
  public form: FormGroup;
  public isLoading: boolean;
  public showTranslationKeys = false;
  private subs = new Subscription();

  constructor(private authService: AuthService,
              private router: Router,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private profileService: ProfileService,
              private fb: FormBuilder,
              private profileQuery: ProfileQuery,
              private appConfigService: AppConfigService) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([UrlRoutes.urls.dashboardUrl]);
    }
    this.selectedLanguage = this.languageService.getLanguage();
    this.subs.add(this.translateService.onLangChange.subscribe(() => this.selectedLanguage = this.languageService.getLanguage()));
    this.subs.add(this.profileQuery.isLoading$.subscribe(isLoading => this.isLoading = isLoading));
    this.showTranslationKeys = appConfigService.configuration.showTranslationKeys;
  }

  ngOnInit() {
    this.form = this.initializeForm();
  }

  private initializeForm(): FormGroup {
    return this.fb.group({
      username: [
        '',
        [Validators.required]
      ],
    });
  }

  public changeLanguage(lang): void {
    this.languageService.changeLanguage(lang);
  }

  public sendForgotPasswordEmail(): void {
    this.profileService.sendForgotPassword(this.form.value.username);
  }

  public back(): void {
    this.router.navigateByUrl(UrlRoutes.urls.loginUrl);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
