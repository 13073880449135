import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationTime',
  pure: true
})
export class DurationPipe implements PipeTransform {

  public transform(value: number): string {
    const hours = Math.floor(value / 60).toFixed(0).toString();
    const minutes = (value % 60).toFixed(0).toString();
    return `${ hours.padStart(2, '0') }h ${ minutes.padStart(2, '0') }m`;
  }

}
