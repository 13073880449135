import { Component, OnInit, Input } from '@angular/core';
import { IExportSchedule, ICreateExportSchedule } from 'src/app/api/models/dto/export-schedule.dto';
import { ExportService } from 'src/app/store/export/state/export.service';
import { DriveType } from 'src/app/store/export/state/export.store';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ExportScheduleEditDialogComponent } from '../../export-page/export-schedule-edit-dialog/export-schedule-edit-dialog.component';
import { ConfirmDialogComponent } from 'src/app/ui-components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-export-schedule',
  templateUrl: './export-schedule.component.html',
  styleUrls: ['./export-schedule.component.scss']
})
export class ExportScheduleComponent implements OnInit {

  @Input() carId: number;
  exportSchedules: IExportSchedule[] = [];
  gdprEnabled:boolean = true;
  displayedColumns: string[] = ['timePeriod', 'email', 'driveType', 'format', 'actions'];

  constructor(
    public exportService: ExportService,
    private dialog: MatDialog
  ) {   }

  ngOnInit(): void {
    this.exportService.fetchExportScheduleByCarId(this.carId).subscribe(schedules => {
      if (!!schedules) {
        this.exportSchedules = schedules.exportSchedules;
        this.gdprEnabled = schedules.gdprEnabled;
      }
    });
  }

  public saveSchedule(schedule: ICreateExportSchedule) {
    this.exportService.createExportSchedule(this.carId, schedule).subscribe(() =>
      this.fetchReports());
  }

  public editSchedule(schedule: IExportSchedule) {
    this.exportService.updateExportSchedule(schedule.id, schedule).subscribe(() =>
      this.fetchReports());
  }

  public driveTypeNameKey(driveType: DriveType): string {
    return `drive-book.totals.${DriveType[driveType].toString().toLocaleLowerCase()}`;
  }

  public addExportSchedule(): void {
    this.gdprEnabled = true;
    this.dialog.open(ExportScheduleEditDialogComponent,
      { data: { carId: this.carId, gdprEnabled: this.gdprEnabled }, minWidth: 550 }).afterClosed().subscribe((result) => {
        if (!!result && result !== false) {
          this.saveSchedule(result);
        }
      });
  }

  private fetchReports() {
    this.exportService.fetchExportScheduleByCarId(this.carId).subscribe(schedules => {
      if (!!schedules) {
        this.exportSchedules = schedules.exportSchedules;
        this.gdprEnabled = schedules.gdprEnabled;
      }});
  }

  private openConfirmDialog(message: string): MatDialogRef<ConfirmDialogComponent> {
    return this.dialog.open(ConfirmDialogComponent, { data: message });
  }

  public deleteSchedule(id: number) {
    this.openConfirmDialog('export-schedule.confirm-delete').afterClosed().subscribe((result) => {
      if (result) {
        this.exportService.deleteExportSchedule(id).subscribe(() =>
        this.fetchReports());
      }});
  }

  public editExportSchedule(element: IExportSchedule): void {
    const schedule = element;
    this.dialog.open(ExportScheduleEditDialogComponent,
      { data: { carId: this.carId, schedule: element, gdprEnabled: this.gdprEnabled }, minWidth: 550 }).afterClosed().subscribe((result) => {
        if (!!result && result !== false) {
          this.editSchedule(result);
        }
      });
  }
}
