import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ICarDTO } from 'src/app/api/models/dto/car-groups.dto';
import { CarsService } from 'src/app/store/cars/state/cars.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-car-movement-edit-dialog',
  templateUrl: './car-movement-edit-dialog.component.html',
  styleUrls: ['./car-movement-edit-dialog.component.scss'],
})
export class CarMovementEditDialogComponent {
  message: string;
  public isLoading = false;
  activationMessage = this.translateService.instant('allow-movement.confirm');
  deactivationMessage = this.translateService.instant('forbid-movement.confirm');
  confirmButtonText = this.translateService.instant('allow-forbid-movement.confirm.yes');
  cancelButtonText = this.translateService.instant('allow-forbid-movement.confirm.no');
  info: string;
  car: ICarDTO;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CarMovementEditDialogComponent>,
    private translateService: TranslateService,
    private notifyService: NotifyService,
    private carService: CarsService) {
    if (data) {
      this.car = data;
      this.message = this.getMessage(this.car?.isCarMovementAllowed);
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if (!!data.info) {
        this.info = data.info;
      }
    }
  }

  onConfirmClick(): void {
    this.isLoading = true;
    this.message = this.translateService.instant('allow-forbid-movement.loading');
    const requestObservable = this.car.isCarMovementAllowed ?
      this.carService.forbidCarMovement(this.car.carID) :
      this.carService.allowCarMovement(this.car.carID);

    requestObservable.subscribe((response) => {
      if (response.output.result) {
        this.notifyService.showInfo(this.getNotification(response.output.result, this.car.isCarMovementAllowed));
        this.carService.fetchGroupsWithCars();
      } else {
        this.notifyService.showError(this.getNotification(response.output.result, this.car.isCarMovementAllowed));
      }
      this.closeDialog();
    },
    (error) => {
      this.notifyService.showError(this.translateService.instant('allow-forbid-movement.error'));
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private getMessage(isCarMovementAllowed: boolean): string {
    if (isCarMovementAllowed) {
      return this.deactivationMessage;
    } else {
      return this.activationMessage;
    }
  }

  private getNotification(result: boolean, isCarMovementAllowed: boolean): string {
    switch (true) {
      case result && isCarMovementAllowed:
        return this.translateService.instant('forbid-movement.confirmed');
      case result && !isCarMovementAllowed:
        return this.translateService.instant('allow-movement.confirmed');
      case !result && isCarMovementAllowed:
        return this.translateService.instant('forbid-movement.error');
      case !result && !isCarMovementAllowed:
        return this.translateService.instant('allow-movement.error');
    }
  }
}
