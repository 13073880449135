import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IUserChangePassword } from 'src/app/api/models/user/user-info';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit {

  public form: FormGroup;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClose = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSave = new EventEmitter<IUserChangePassword>();

  public constructor(
    private fb: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.form = this.createForm();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      oldPassword: new FormControl(null, Validators.required),
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      newPasswordConfirm: new FormControl(null, [Validators.required, Validators.minLength(6)]),
    });
  }

  public changeFormValue(name: string, value: string): void {
    this.form.controls[name].patchValue(value);
  }

  get arePasswordsEqual() {
    return this.form.value.newPassword === this.form.value.newPasswordConfirm;
  }

  public close(): void {
    this.onClose.emit();
  }

  public save(): void {
    const request: IUserChangePassword = {
      oldPassword: this.form.value.oldPassword,
      newPassword: this.form.value.newPassword,

    };
    this.onSave.emit(request);
  }
}
