<mat-accordion>
  <mat-expansion-panel #panel [expanded]="forExport ? true : isExpanded" (opened)="toogleExpanded(true)"
    (closed)="toogleExpanded(false)">
    <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
      <div class="filter-heading-left">
        {{ 'drive-book.filter' | translate }}
      </div>
      <div class="filter-info-block" *ngIf="!panel.expanded">
        {{ filterForm.value['intervalFrom'] | date: 'd.L.yyyy' }} -
        {{ filterForm.value['intervalTo'] | date: 'd.L.yyyy' }}
        {{ showType ? ( ' - ' + (driveTypeNameKey(filterForm.value['type']) | translate)) : '' }}
        {{ showDriver ? ' - ' + getDriverById() : '' }}
      </div>
      <div class="filter-info-block" *ngIf="panel.expanded">
        <div class="custom-filters">
          <div class="button-container">
            <div class="custom-filter-button-label">
              {{ 'drive-book.custom-filter' | translate }}
            </div>
            <div class="custom-filter-button">
              <button type="button" mat-icon-button [matMenuTriggerFor]="customFiltersMenu"
                (click)="$event.stopPropagation();">
                <mat-icon>calendar_today</mat-icon>
              </button>
            </div>
          </div>

          <mat-menu #customFiltersMenu="matMenu" yPosition="below">
            <app-custom-filter *ngFor="let customFilter of customFilters" (useFilter)="applyCustomFilter($event)"
              [filter]="customFilter">
            </app-custom-filter>
          </mat-menu>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="filter-container">
      <div class="filter-left">
      </div>
      <div class="filter-right">
        <form (ngSubmit)="submitSearchForm($event)" [formGroup]="filterForm" class="drive-book-filter-form">
          <div class="type-header">
            {{ 'drive-book.drives.type' | translate }}
          </div>

          <div class="type-input">
            <mat-radio-group formControlName="type">
              <ng-container *ngFor="let driveType of driveTypes">
                <mat-radio-button [value]="driveType" color="primary">
                  {{ driveTypeNameKey(driveType) | translate }}
                </mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </div>

          <div class="date-input from">
            <mat-form-field>
              <input matInput [matDatepicker]="filterDateFrom"
                [value]="filter.intervalFrom" (dateChange)="selectDate($event, true)"
                [placeholder]="'filter.from' | translate">
              <mat-datepicker-toggle matSuffix [for]="filterDateFrom"></mat-datepicker-toggle>
              <mat-datepicker #filterDateFrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="date-input to">
            <mat-form-field>
              <input matInput [matDatepicker]="filterDateTo" [value]="filter.intervalTo"
                [min]="filterForm.value['intervalFrom']" (dateChange)="selectDate($event, false)"
                [placeholder]="'filter.to' | translate">
              <mat-datepicker-toggle matSuffix [for]="filterDateTo"></mat-datepicker-toggle>
              <mat-datepicker #filterDateTo></mat-datepicker>
            </mat-form-field>
          </div>

          <ng-container *ngIf="drivers?.drivers.drivers.length > 1">
            <div *ngIf="!forExport" class="date-input driver">
              <mat-form-field style="width: 70%">
                <mat-label>{{ 'drive-book.edit-form.drive.driver' | translate }}</mat-label>
                <mat-select formControlName="driver" (selectionChange)="updateDriver($event.value)">
                  <mat-option *ngFor="let driver of drivers?.drivers.drivers" [value]="driver.nPersonID">
                    {{ driver.strSurname | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <div class="submit-button" *ngIf="!forExport">
            <button type="submit" mat-button mat-raised-button color="primary" [disabled]="!isCarSelected || filterForm.invalid">
              {{ 'drive-book.search' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
