import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserHttpService } from '../../api/user.service';
import { AuthService } from '../../api/auth.service';
import { ILoginUser } from '../../api/models/user/user-login';
import { UserLoginInfo } from '../../api/models/user/user-login-info';
import { LanguageService } from '../../services/language.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CarsSignalRService } from '../../services//carsSignalR.service';
import { MatDialog } from '@angular/material';
import { UrlRoutes } from 'src/app/app-routes';
import { NotifyService } from 'src/app/services/notify.service';
import { WorkspaceStore } from './../../../app/store/workspace/workspace.store';
import { AppConfigService } from 'src/app/services/appConfig.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

  public errorLogin = false;
  public loadingData = false;
  public selectedLanguage;
  public form: FormGroup;
  public showTranslationKeys = false;

  constructor(private apiService: UserHttpService,
              private authService: AuthService,
              private router: Router,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private fb: FormBuilder,
              public carSignalR: CarsSignalRService,
              private notifyService: NotifyService,
              public matDialog: MatDialog,
              private workspaceStore: WorkspaceStore,
              private appConfigService: AppConfigService) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
    this.selectedLanguage = this.languageService.getLanguage();
    this.translateService.onLangChange.subscribe(() => {
      this.selectedLanguage = this.languageService.getLanguage();
    });
    this.showTranslationKeys = appConfigService.configuration.showTranslationKeys;
  }

  ngOnInit() {
    this.form = this.initializeForm();
    this.matDialog.closeAll();
  }

  private initializeForm(): FormGroup {
    return this.fb.group({
      username: [
        '',
        [Validators.required]
      ],
      password: [
        '',
        [Validators.required]
      ],
    });
  }

  login() {
    this.errorLogin = false;
    this.loadingData = true;
    const user: ILoginUser = {
      username: this.form.controls.username.value,
      password: this.form.controls.password.value,
      workspaceId: this.workspaceStore.getValue().id
    };
    this.apiService.loginUser(user).subscribe(response => {
      const loginUser = new UserLoginInfo();
      loginUser.username = response.username;
      loginUser.token = response.accessToken;
      loginUser.permissions = response.permissions;
      loginUser.id = response.userId;
      this.authService.saveUser(loginUser);
      this.authService.loadAuthData(this.apiService);
      this.router.navigate([UrlRoutes.urls.dashboardUrl]);
      this.carSignalR.startConnection();
      this.notifyService.closeSnackbar();

    }, error => {
      this.errorLogin = true;
      this.loadingData = false;
    });
  }
  public changeLanguage(lang) {
    this.languageService.changeLanguage(lang);
  }

  public onForgotPassword(): void {
    this.router.navigate([UrlRoutes.urls.forgotPasswordUrl]);
  }

  get backgroundImg(): string {
    return this.workspaceStore.getValue().loginBackgroundpath;
  }
}
