<div class="profile-tab">
  <mat-dialog-content [formGroup]="form">
    <ui-divider></ui-divider>
    <mat-form-field class="full-width">
      <input type="text"
        matInput
        formControlName="fullName"
        [placeholder]="'profile.contact-fullname' | translate">
    </mat-form-field>
    <mat-form-field class="full-width">
      <input type="email"
        matInput
        formControlName="emailAddress"
        [placeholder]="'profile.contact-email' | translate">
    </mat-form-field>
    <ui-divider></ui-divider>
    <p [innerHTML]="'profile.contact-info' | translate"></p>
  </mat-dialog-content>
  <mat-dialog-actions class="action-buttons">
    <button mat-button
      (click)="close()">
      {{ 'profile.close' | translate }}
    </button>
    <button [disabled]="form?.invalid"
      class="mat-raised-button mat-primary"
      (click)="save()">
      {{ 'profile.save' | translate }}
    </button>
  </mat-dialog-actions>
</div>
